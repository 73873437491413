const Constants = {
  DISTRIBUTER:{"distributerID":"HDFCSECL","distributorName":"HDFC SECURITIES", "distributorLogo": "https://insurance-manufacturer.s3.ap-south-1.amazonaws.com/logos/HSL_Logo_Dark_BG.png", "distributorLogoWhite": "https://insurance-manufacturer.s3.ap-south-1.amazonaws.com/logos/HSL_Logo_White_BG.png","contactEmail": "insure.support@hdfcsec.com", "infoEmail": "info@hdfcsec.com", "contactNumber": "022-39019400",  "loginUrlB2C": "https://nportaluat.hdfcsec.com/onesb?id=onesbinsurance","loginUrlB2B": "https://www.hdfcsec.com/onesbproduct","quoteMsg": "As per Expert suggestion, Annual premium paying mode is recommended","docReq": "Please arrange to have a photograph (PPSize),  proof of DoB and address handy before proceeding further.","disclaimer": "Risk Cover would commence only on acceptance of proposal by Insurance companies after submission of all required documents and premium.","docUploadGuide":"Visit HDFC Life Portal or see your email inbox for uploading documents.","api_encryption": false,},
  INSURER_LIST:[{"company_code":"HDFC","company_name":"HDFC Life","freq":[{"name":"Monthly","freqCode":"M","freqCount":1},{"name":"Quarterly","freqCode":"Q","freqCount":1},{"name":"Half-Yearly","freqCode":"HY","freqCount":1},{"name":"Yearly","freqCode":"Y","freqCount":1},{"name":"Single-Pay","freqCode":"S","freqCount":1}]},{"company_code":"TATA","company_name":"TATA AIA Life","freq":[{"name":"Monthly","freqCode":"M","freqCount":3},{"name":"Quarterly","freqCode":"Q","freqCount":1},{"name":"Half-Yearly","freqCode":"HY","freqCount":1},{"name":"Yearly","freqCode":"Y","freqCount":1},{"name":"Single-Pay","freqCode":"S","freqCount":1}]},{"company_code":"ABSLIFEI","company_name":"Aditya Birla Sun Life Insurance","freq":[{"name":"Monthly","freqCode":"M","freqCount":3},{"name":"Quarterly","freqCode":"Q","freqCount":1},{"name":"Half-Yearly","freqCode":"HY","freqCount":1},{"name":"Yearly","freqCode":"Y","freqCount":1},{"name":"Single-Pay","freqCode":"S","freqCount":1}]}],
  POLICYCOVER:["500000", "1000000", "1500000", "2000000", "2500000", "3000000", "3500000", "4000000",  "4500000", "5000000", "5500000", "6000000", "6500000", "7000000", "7500000", "8000000", "8500000", "9000000", "9500000", "10000000", "11000000", "12000000", "13000000", "14000000", "15000000", "16000000", "17000000", "18000000", "19000000", "20000000", "21000000", "22000000", "23000000", "24000000", "25000000", "26000000", "27000000", "28000000", "29000000", "30000000", "31000000", "32000000", "33000000", "34000000", "35000000", "36000000", "37000000", "38000000", "39000000", "40000000", "41000000", "42000000", "43000000", "44000000", "45000000", "46000000", "47000000", "48000000", "49000000", "50000000","55000000","60000000","65000000","70000000","75000000","80000000","85000000","90000000","95000000","100000000","105000000", "110000000","115000000", "120000000","125000000", "130000000","135000000", "140000000","145000000", "150000000","155000000", "160000000","165000000", "170000000","175000000", "180000000","185000000", "190000000","195000000", "200000000"],
  INSURER_IMGS:{"HDFC":"HDFC_Life.png", "TATA":"tata-aia.jpg", "ABSLIFEI":"ABISL.jpg"},
  LAZY_LOADING:{"inerval":"2000", "nooftimes":"25"},
  ABSLI_MASTER:{"PayoutToNominee":[4],"coverOption":[1],"wholeLifeCover":false},
  OPT_SELECTED:{"10":"Terminal Illness", "11":"Accelerated Critical Illness", "12":"Accidental Death Benefit", "13":"Waiver of Premium on permanent disability", "14":"Waiver of premium on Critical Illness", "16":"Return of Premium", "17":"Spouse cover", "18":"Child Education Support", "19":"LifeStage Benefit", "21":"Terminal Illness + Waiver of premium on permanent disability and diagnosis of CI", "22":"Terminal Illness + Waiver of premium on permanent disability and diagnosis of CI + Accelerated critical illness", "25":"Decrease Sum assured after certain age","27": "Increasing Critical Illness Benefit"},
  DBP_MASTER:{"4":"Lump sum", "5":"Income", "6":"Lump sum and Income", "7":"Increasing Income", "8":"Lumpsum and Increasing Income"},
  PPO_MASTER:{"1":"Only Once", "2": "For entire policy term", "3": "Limited no. of years", "4": "Pay till age 60 years",},
  PLAN_OPTIONS:{
    "P1C2PS": {
      102: { planId: "102", planName: "Life", addonsReq: [{ id: 10, addOnSumAssured: "" }], coverOption: "1", PPO: "", PPT: "" },
      103: { planId: "103", planName: "Life Plus", addonsReq: [{ id: 10, addOnSumAssured: "" }, { id: 12, addOnSumAssured: "" }], coverOption: "1", PPO: "", PPT: "" },
      104: { planId: "104", planName: "Life Goal", addonsReq: [], coverOption: "1", PPO: "3", PPT: 5 },
      153: { planId: "153", planName: "Life with increasing sum assured", addonsReq: [{ id: 10, addOnSumAssured: "" }], coverOption: "3", PPO: "", PPT: "", SAChangePercent: "5" },
      154: { planId: "154", planName: "Life goal with decreasing sum assured", addonsReq: [], coverOption: "2", PPO: "3", PPT: 5, SAChangePercent: "0", levelCoverPeriod: 5 }
    },
    "P1C2PL": {
      178: { planId: "178", planName: "Income Plus", addonsReq: [], coverOption: "" },
      191: { planId: "191", planName: "Life & CI Rebalance", addonsReq: [], coverOption: "" },
      192: { planId: "192", planName: "Life Protect", addonsReq: [], coverOption: "" }
    },
    "ABDGSHLD": {
      33: { planId: "33", planName: "Level Cover Option", addonsReq: [{ id: 10, addOnSumAssured: "" }], coverOption: "1" },
      42: { planId: "42", planName: "Return of Premium", addonsReq: [{ id: 10, addOnSumAssured: "" }, { id: 16, addOnSumAssured: "" }], coverOption: "1" }
    },
    "ABTLSHLD": {
      52: { planId: "52", planName: "Increasing Term Assurance", addonsReq: [], coverOption: "" },
      61: { planId: "61", planName: "Level Term Assurance", addonsReq: [], coverOption: "" },
      123: { planId: "123", planName: "Return of Premium", addonsReq: [], coverOption: "" },
      159: { planId: "159", planName: "Decreasing Term Assurance", addonsReq: [], coverOption: "" }
    },
    "MahRakSp": {
      107: { planId: "107", planName: "Life Stage Plus Option", addonsReq: [{ id: 10, addOnSumAssured: "" }], coverOption: "1" }
    },
    "SamRakSp": {
      57: { planId: "57", planName: "Life Option", addonsReq: [{ id: 10, addOnSumAssured: "" }], coverOption: "1" },
      58: { planId: "58", planName: "Life Plus Option", addonsReq: [{ id: 10, addOnSumAssured: "" }, { id: 16, addOnSumAssured: "" }], coverOption: "1" }
    },
    "SRS_P": {
      149: { planId: "149", planName: "Life Option", addonsReq: [], coverOption: "1" },
      150: { planId: "150", planName: "Life Plus Option", addonsReq: [{ id: 16, addOnSumAssured: "" }], coverOption: "1" }
    },
    "345": {
      118: { planId: "118", planName: "Life Shield", addonsReq: [{ id: 10, addOnSumAssured: "5000000" }, { id: 48, addOnSumAssured: "" }], coverOption: "1", PPO: "", PPT: "" },
      119: { planId: "119", planName: "Life Shield Plus", addonsReq: [{ id: 10, addOnSumAssured: "5000000" }, { id: 12, addOnSumAssured: "3000000" }, { id: 48, addOnSumAssured: "" }], coverOption: "1", PPO: "", PPT: "" },
      126: { planId: "126", planName: "Life Shield ROP", addonsReq: [{ id: 10, addOnSumAssured: "5000000" }, { id: 16, addOnSumAssured: "" }, { id: 48, addOnSumAssured: "" }], coverOption: "1", PPO: "", PPT: "" }
    },
    "321": {
      116: { planId: "116", planName: "Smart Life Reg Risk", addonsReq: [], coverOption: "1" }
    },
    "154": {
      154: { planId: "154", planName: "Life Goal", addonsReq: [], coverOption: "" },
      518: { planId: "518", planName: "iTermPrime", addonsReq: [], coverOption: "" }
    },
    "T51": {
      10: { planId: "10", planName: "Life", addonsReq: [{ id: 10, addOnSumAssured: "" }, { id: 13, addOnSumAssured: "" }], coverOption: "1" },
      11: { planId: "11", planName: "Life Plus", addonsReq: [{ id: 10, addOnSumAssured: "" }, { id: 12, addOnSumAssured: "" }, { id: 13, addOnSumAssured: "" }], coverOption: "1" },
      12: { planId: "12", planName: "Life And Health", addonsReq: [{ id: 10, addOnSumAssured: "" }, { id: 11, addOnSumAssured: "" }, { id: 13, addOnSumAssured: "" }], coverOption: "1" },
      13: { planId: "13", planName: "All in One", addonsReq: [{ id: 10, addOnSumAssured: "" }, { id: 11, addOnSumAssured: "" }, { id: 12, addOnSumAssured: "" }, { id: 13, addOnSumAssured: "" }], coverOption: "1" }
    },
    "T50": {
      4: { planId: "4", planName: "Life", addonsReq: [{ id: 10, addOnSumAssured: "" }, { id: 13, addOnSumAssured: "" }], coverOption: "1" },
      5: { planId: "5", planName: "Life Plus", addonsReq: [{ id: 10, addOnSumAssured: "" }, { id: 12, addOnSumAssured: "" }, { id: 13, addOnSumAssured: "" }], coverOption: "1" }
    }
  },
  COVER_OPTION_MASTER:{"1":"Level Cover Plan" , "2":"Decreasing Cover Plan" , "3":"Increasing Cover Plan"},
  COMPARE_DATA:{"ICICI":{"inception":"2000","solvency_ratio":"2","grievances_solved":"99.96","claim_settlement_ratio":"0.979","death_claim_paid":"1,023.64 Crore","claim_complaints_ratio":"12","claim_settlement_promise":"1 Day","no_of_branches":"514"},"HDFC":{"inception":"2000","solvency_ratio":"2.01","grievances_solved":"99.97","claim_settlement_ratio":"0.98","death_claim_paid":"50.33 Crore","claim_complaints_ratio":"3","claim_settlement_promise":"1 Day","no_of_branches":"421"},"PNB":{"inception":"2001","solvency_ratio":"1.96","grievances_solved":"98.32","claim_settlement_ratio":"NA","death_claim_paid":"332 Crore","claim_complaints_ratio":"23","claim_settlement_promise":"3 Hour","no_of_branches":"150"},"TATA":{"inception":"2000","solvency_ratio":"2.05","grievances_solved":"100","claim_settlement_ratio":"0.98","death_claim_paid":"478 Crore","claim_complaints_ratio":"2","claim_settlement_promise":"4 Hour","no_of_branches":"218"},"IFLI":{"inception":"2008","solvency_ratio":"1.77","grievances_solved":"99.86","claim_settlement_ratio":"0.969","death_claim_paid":"110 Crore","claim_complaints_ratio":"7.24","claim_settlement_promise":"1 Day","no_of_branches":"475"},"ABSLIFEI":{"inception":"2001","solvency_ratio":"1.89","grievances_solved":"99.75","claim_settlement_ratio":"0.9808","death_claim_paid":"440 Crore","claim_complaints_ratio":"9.41","claim_settlement_promise":"5.7 days","no_of_branches":"340"}},
  MAX_POLICY_AGE:{"25":"99","12003":"99","12013":"80","12023":"99","40038":"80","T50":"75","T51":"99","T52":"65","T53":"65","T54":"65","T38":"85","T41":"80","T39":"65","T40":"65","T55":"70","T56":"70","T43":"65","T58":"70","T59":"70","T60":"65","T61":"65","P1C2PL":"100","SamRakSp":"100","ABTLSHLD":"85","ABDGSHLD":"100"},
  RIDER_SUM_ASSURED:{
    "TATA": {
      "CPRADBV":{   //"Accidental Death"
      "min":100000,
      "max":20000000,
      },
      "CPRCBPV": {      //"CritiCare Plus"
      "min":200000,
      "max":10000000,
      },
      "CHRHCBV":{        //"Hospi Care"
      "min":100000,
      "max":4000000,
      },
      "CPRTPDV":{         //"Accidental Total and Permanent Disability
      "min":100000,
      "max":20000000,
      }
      },
    "ABSLIFEI":{            //ABSLI Hospital Care Rider
              "103":{
              "min": 150000,
              "max": 1500000,
              },
              "101":{              //ABSLI Critical Illness Rider
              "min": 75000,
              "max": 5000000,
              },
              "100":{            //ABSLI Accidental Death and Disability Rider
              "min": 75000,
              "max": 5000000,
              },
              "102":{                 //ABSLI Surgical Care Rider
              "min": 150000,
              "max": 1500000,
              },
              "104":{                  //ABSLI Accidental Death Benefit Rider Plus
              "min": 100000,
              "max": 10000000
              }          
            },
      "HDFC":{
              "C4CI":{                //HDFC Life Critical Illness plus Rider
              "min": 25000,
              "max": 15000000
              },
              "INAD":{                  //HDFC Life Income Benefit on Accidental Disability Rider
              "min":100000,
              "max":20000000
              },
              "PPR_CC":{              //HDFC Life Protect Plus rider - Cancer Cover
              "min":500000,
              "max":5000000
              },
              "PPR_ADC":{          //HDFC Life Protect Plus rider - Accidental Death Cover
              "min":50000,
              "max": 15000000
               },
               "PPR_PAC":{        //HDFC Life Protect Plus rider - Personal Accident Cover
              "min":50000,
              "max": 15000000
              }
          } 
  },
  P1C2PS_DBP1: {
    "DbpOption": [
      "6"
    ],
    "lumpsumpayoutpercent": {
      "min": "0",
      "max": "100"
    },
    "incomefrequency": [
      "Y",
      "HY",
      "Q",
      "M"
    ],
    "incometerm": [
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15
    ],
    "incomeTermUnit": "years"
  },
  COMPARE_ADD_ON_DATA: {
    "19": {
      "10": "",
      "11": "Yes",
      "12": "Yes",
      "13": "",
      "14": "",
      "16": "",
      "17": "",
      "19": "Yes"
    },
    "25": {
      "10": "",
      "11": "",
      "12": "",
      "13": "",
      "14": "",
      "16": "Yes",
      "17": "",
      "19": ""
    },
    "12003": {
      "10": "",
      "11": "",
      "12": "",
      "13": "",
      "14": "",
      "16": "No",
      "17": "No",
      "19": "No"
    },
    "12005": {
      "10": "",
      "11": "",
      "12": "",
      "13": "",
      "14": "",
      "16": "",
      "17": "",
      "19": ""
    },
    "12013": {
      "10": "",
      "11": "",
      "12": "",
      "13": "",
      "14": "",
      "16": "No",
      "17": "No",
      "19": "No"
    },
    "12023": {
      "10": "",
      "11": "",
      "12": "",
      "13": "",
      "14": "",
      "16": "No",
      "17": "No",
      "19": "No"
    },
    "12047": {
      "10": "",
      "11": "",
      "12": "",
      "13": "",
      "14": "",
      "16": "No",
      "17": "No",
      "19": "No"
    },
    "40038": {
      "10": "",
      "11": "",
      "12": "",
      "13": "",
      "14": "",
      "16": "",
      "17": "",
      "19": ""
    },
    "T38": {
      "10": "",
      "11": "",
      "12": "",
      "13": "",
      "14": "",
      "16": "",
      "17": "",
      "19": ""
    },
    "T39": {
      "10": "",
      "11": "",
      "12": "",
      "13": "",
      "14": "",
      "16": "",
      "17": "",
      "19": ""
    },
    "T40": {
      "10": "",
      "11": "",
      "12": "Yes",
      "13": "",
      "14": "",
      "16": "",
      "17": "",
      "19": ""
    },
    "T41": {
      "10": "",
      "11": "",
      "12": "Yes",
      "13": "",
      "14": "",
      "16": "",
      "17": "",
      "19": ""
    },
    "T43": {
      "10": "",
      "11": "",
      "12": "",
      "13": "",
      "14": "",
      "16": "",
      "17": "",
      "19": ""
    },
    "T50": {
      "10": "Yes",
      "11": "",
      "12": "No",
      "13": "Yes",
      "14": "",
      "16": "",
      "17": "",
      "19": ""
    },
    "T51": {
      "10": "Yes",
      "11": "No",
      "12": "No",
      "13": "Yes",
      "14": "",
      "16": "",
      "17": "",
      "19": ""
    },
    "T52": {
      "10": "",
      "11": "",
      "12": "No",
      "13": "",
      "14": "",
      "16": "",
      "17": "",
      "19": ""
    },
    "T53": {
      "10": "",
      "11": "",
      "12": "No",
      "13": "",
      "14": "",
      "16": "",
      "17": "",
      "19": ""
    },
    "T54": {
      "10": "",
      "11": "",
      "12": "",
      "13": "",
      "14": "",
      "16": "",
      "17": "",
      "19": ""
    },
    "T55": {
      "10": "",
      "11": "",
      "12": "No",
      "13": "",
      "14": "",
      "16": "",
      "17": "",
      "19": ""
    },
    "T56": {
      "10": "",
      "11": "",
      "12": "No",
      "13": "",
      "14": "",
      "16": "",
      "17": "",
      "19": ""
    },
    "T58": {
      "10": "",
      "11": "",
      "12": "",
      "13": "",
      "14": "",
      "16": "",
      "17": "",
      "19": ""
    },
    "T59": {
      "10": "",
      "11": "",
      "12": "",
      "13": "",
      "14": "",
      "16": "",
      "17": "",
      "19": ""
    },
    "T60": {
      "10": "",
      "11": "",
      "12": "",
      "13": "",
      "14": "",
      "16": "",
      "17": "",
      "19": ""
    },
    "T61": {
      "10": "",
      "11": "",
      "12": "",
      "13": "",
      "14": "",
      "16": "",
      "17": "",
      "19": ""
    },
    "P1C2PL": {
      "10": "",
      "11": "No",
      "12": "",
      "13": "",
      "14": "No",
      "16": "No",
      "17": "",
      "19": ""
    },
    "ABDGSHLD": {
      "10": "Yes",
      "11": "No",
      "12": "",
      "13": "",
      "14": "",
      "16": "Yes",
      "17": "No",
      "19": "No"
    },
    "SamRakSp": {
      "10": "Yes",
      "11": "",
      "12": "",
      "13": "",
      "14": "",
      "16": "Yes",
      "17": "",
      "19": "No"
    },
    "ABTLSHLD": {
      "10": "Free",
      "11": "No",
      "12": "No",
      "13": "No",
      "14": "Paid",
      "16": "Paid",
      "17": "Paid",
      "19": "Paid"
    },
    "154": {
      "10": "Free",
      "11": "Yes",
      "12": "Free",
      "13": "Yes",
      "14": "Yes",
      "16": "Yes",
      "17": "Yes",
      "19": "Yes",
      "20": "Yes",
      "21": "Yes",
      "22": "",
      "25": "",
      "27": "",
      "37": "Yes"
    },
    "P1C2PS": {
      "10": "Free",
      "11": "",
      "12": "Free",
      "13": "",
      "14": "No",
      "15": "Yes",
      "16": "Yes",
      // "17": "No",
      "19": "Yes",
      // "37": "Yes",
    },
  },
  PLAN_ID_DATA: {
    "12023": {
      "1": [],
      "2": [
        "21"
      ],
      "3": [
        "22"
      ]
    },
    "T50": {
      "4": [
        "10",
        "13"
      ],
      "5": [
        "10",
        "12",
        "13"
      ]
    },
    "T52": {
      "6": [],
      "7": [
        "12"
      ]
    },
    "T53": {
      "8": [],
      "9": [
        "12"
      ]
    },
    "T51": {
      "10": [
        "10",
        "13"
      ],
      "11": [
        "10",
        "13",
        "12"
      ],
      "12": [
        "10",
        "13",
        "11"
      ],
      "13": [
        "10",
        "13",
        "11",
        "12"
      ]
    },
    "T41": {
      "14": [
        "12"
      ]
    },
    "T55": {
      "15": [],
      "16": [
        "12"
      ]
    },
    "T56": {
      "17": [],
      "18": [
        "12"
      ]
    },
    "T38": {
      "19": []
    },
    "T39": {
      "20": []
    },
    "T40": {
      "52": [
        "12"
      ]
    },
    "P1C2PL": {
      "30": [
        "27",
        "2",
        "4"
      ],
      "31": [
        "4",
        1
      ],
      "32": [
        "1",
        "4",
        "16",
        "26"
      ]
    },
    "SANCHPV9": {
      "53": [
        "1",
        "4",
        "28"
      ],
      "54": [
        "1",
        "4",
        "29"
      ],
      "55": [
        "1",
        "4",
        "29"
      ],
      "56": [
        "1",
        "4",
        "29"
      ]
    },
    "ABDGSHLD": {
      "33": [
        "1",
        "10",
        "4"
      ],
      "42": [
        "1",
        "10",
        "16",
        "4"
      ]
    },
    "SamRakSp": {
      "57": [
        "10"
      ],
      "58": [
        "10",
        "16"
      ],
    },
    "P1C2PS": {
      "102": [
        "1",
        "10"
      ],
      "103": [
        "1",
        "10",
        "12"
      ],
      "104": [
        "1",
      ],
      "153": [
        "3",
        "10"
      ],
      "154": [
        "2",
      ],
    },
  },
  TERMS_CONDITION: {
    "terms": "",
    "disclaimer": ""
  },
  RIDER_MASTER: {
    "P1C2PL": [
      {
        "ridercode": "C4CI",
        "riderName": "HDFC Life Critical Illness plus Rider",
        "riderFeatures": "Key Feature:This Rider provides Rider Sum Assured as a lump sum benefit if the life assured survives for a period of 30 days following diagnosis of any one of the 19 critical illnesses covered",
        "riderTerm": 27,
        "riderPremiumPayingTerm": 27,
        "riderSA": 1000000,
        "premiumDetails": [
          {
            "mode": "M",
            "riderPremiumValue": 273,
            "tax": [
              {
                "taxDescription": "Life Term GST",
                "taxValue": 49,
                "taxPercentage": 18
              }
            ],
            "totalRiderTax": 49,
            "totalRiderPremiumValue": 322
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ],
        "temp_price": "Add to get price"
      },
      {
        "ridercode": "INAD",
        "riderName": "HDFC Life Income Benefit on Accidental Disability Rider",
        "riderFeatures": "Key Feature: This rider provides an additional cover to protect you  On the Total Permanent Disability of the life assured due to an accident, a regular monthly income equal to 1% of the Sum Assured will be payable to the life assured for a fixed period of 10 years.",
        "riderTerm": 27,
        "riderPremiumPayingTerm": 27,
        "riderSA": 1000000,
        "premiumDetails": [
          {
            "mode": "M",
            "riderPremiumValue": 25,
            "tax": [
              {
                "taxDescription": "Life Term GST",
                "taxValue": 4,
                "taxPercentage": 18
              }
            ],
            "totalRiderTax": 4,
            "totalRiderPremiumValue": 29
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ],
        "temp_price": "Add to get price"
      },
      // {
      //   "ridercode": "PPR_CC",
      //   "riderName": "HDFC Life Protect Plus rider - Cancer Cover",
      //   "riderFeatures": "This option provides a lump sum benefit on detection of early or major stage Cancer",
      //   "riderTerm": 5,
      //   "riderPremiumPayingTerm": 5,
      //   "riderSA": 1500000,
      //   "premiumDetails": [
      //     {
      //       "mode": "M",
      //       "riderPremiumValue": 66,
      //       "tax": [
      //         {
      //           "taxDescription": "Life Term GST",
      //           "taxValue": 12,
      //           "taxPercentage": 18.0
      //         }
      //       ],
      //       "totalRiderTax": 12,
      //       "totalRiderPremiumValue": 78
      //     }
      //   ],
      //   "varFields": [
      //     {
      //       "fieldName": null,
      //       "fieldValue": null
      //     }
      //   ],
      //   "temp_price": "Add to get price"
      // },
      {
        "ridercode": "PPR_ADC",
        "riderName": "HDFC Life Protect Plus rider - Accidental Death Cover",
        "riderFeatures": "This option provides Rider Sum Assured as a lump sum benefit in case of death of the life assured due to an accident.",
        "riderTerm": 5,
        "riderPremiumPayingTerm": 5,
        "riderSA": 1500000,
        "premiumDetails": [
          {
            "mode": "M",
            "riderPremiumValue": 71,
            "tax": [
              {
                "taxDescription": "Life Term GST",
                "taxValue": 13,
                "taxPercentage": 18.0
              }
            ],
            "totalRiderTax": 13,
            "totalRiderPremiumValue": 84
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ],
        "temp_price": "Add to get price"
      },
      {
        "ridercode": "PPR_PAC",
        "riderName": "HDFC Life Protect Plus rider - Personal Accident Cover",
        "riderFeatures": "This option provides Rider Sum Assured as a lump sum benefit/ monthly income for a fixed period in case of death of the life assured due to an accident/ Accidental Total Permanent Disability/ Accidental Partial Permanent Disability, as applicable.",
        "riderTerm": 28,
        "riderPremiumPayingTerm": 1,
        "riderSA": 1500000,
        "premiumDetails": [
          {
            "mode": "S",
            "riderPremiumValue": 53701,
            "tax": [
              {
                "taxDescription": "Life Term GST",
                "taxValue": 9666,
                "taxPercentage": 18.0
              }
            ],
            "totalRiderTax": 9666,
            "totalRiderPremiumValue": 63367
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ],
        "temp_price": "Add to get price"
      }
    ],
    "SamRakSp": [
      {
        "ridercode": "CPRCBPV",
        "riderName": "Tata AIA Vitality Protect (CritiCare Plus)",
        "riderFeatures": "Key Feature: The Insured Amount under the Benefit Option is payable if the Life Insured survives for a period of 30 days following the first diagnosis of any of the covered illness or undergoing of the covered procedure. The benefit will be payable only upon first occurrence of any of the conditions covered under this benefit during the lifetime of the Life Insured. Option to receive return of balance premiums on maturity. Income Tax Benefits.",
        "riderTerm": "39",
        "riderPremiumPayingTerm": 39,
        "riderSA": "1500000",
        "premiumDetails": [
          {
            "mode": "M",
            "riderPremiumValue": 225,
            "tax": [
              {
                "taxDescription": "Life Term GST",
                "taxValue": 41,
                "taxPercentage": 18
              }
            ],
            "totalRiderTax": 41,
            "totalRiderPremiumValue": 266
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ]
      },
      {
        "ridercode": "CHRHCBV",
        "riderName": "Tata AIA Vitality Health (Hospi Care)",
        "riderFeatures": "Key Feature: The following benefits shall be payable subject to hospitalization of the Life Insured for medically necessary treatment of any illness or injury for a minimum period of consecutive 24 hours. 1. 0.5% per day paid in case of hospitalization for medically necessary treatment of any illness or injury. 2. 0.5% per day is paid for each day of stay in the Intensive Care Unit (ICU) 3. 1.5% of the Hospicare benefit Insured Amount will be payable in case of continuous hospitalization in one or more hospitals for 7 or more days (excluding the time taken for transit between hospitals) for the same injury or disease. Option to receive return of balance premiums on maturity. Income Tax Benefits",
        "riderTerm": "39",
        "riderPremiumPayingTerm": 39,
        "riderSA": "1500000",
        "premiumDetails": [
          {
            "mode": "M",
            "riderPremiumValue": 446,
            "tax": [
              {
                "taxDescription": "Life Term GST",
                "taxValue": 80,
                "taxPercentage": 18
              }
            ],
            "totalRiderTax": 80,
            "totalRiderPremiumValue": 526
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ]
      },
      {
        "ridercode": "CPRTPDV",
        "riderName": "Tata AIA Vitality Protect (Accidental Total and Permanent Disability)",
        "riderFeatures": "Key Feature: The Insured Amount under this Benefit Option will be payable in the event of the Total and Permanent Disability of the Life Insured due to an accident within the Benefit Option Term, provided the disability occurs within 180 days from that accident. Option to receive return of balance premiums on maturity. Income Tax Benefits.",
        "riderTerm": "39",
        "riderPremiumPayingTerm": 39,
        "riderSA": "1500000",
        "premiumDetails": [
          {
            "mode": "M",
            "riderPremiumValue": 36,
            "tax": [
              {
                "taxDescription": "Life Term GST",
                "taxValue": 6,
                "taxPercentage": 18
              }
            ],
            "totalRiderTax": 6,
            "totalRiderPremiumValue": 42
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ]
      },
      {
        "ridercode": "CPRADBV",
        "riderName": "Tata AIA Vitality Protect (Accidental Death)",
        "riderFeatures": "Key Feature: The Insured Amount under this Benefit Option will be payable in the event of the death of the Life Insured due to an accident within the Benefit Option Term, provided the death occurs within 180 days from the accident. Option to receive return of balance premiums on maturity. Income Tax Benefits.",
        "riderTerm": "39",
        "riderPremiumPayingTerm": 39,
        "riderSA": "1500000",
        "premiumDetails": [
          {
            "mode": "M",
            "riderPremiumValue": 60,
            "tax": [
              {
                "taxDescription": "Life Term GST",
                "taxValue": 11,
                "taxPercentage": 18
              }
            ],
            "totalRiderTax": 11,
            "totalRiderPremiumValue": 71
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ]
      }
    ],
    "ABTLSHLD": [
      {
        "ridercode": "103",
        "riderName": "ABSLI Hospital Care Rider",
        "riderFeatures": "You can claim your daily cash benefit in case of hospitalisation for medically necessary treatment of any Illness or Injury for a minimum period of 24 hours payable from the first day for the duration of hospitalisation",
        "riderTerm": "20",
        "riderPremiumPayingTerm": "10",
        "riderSA": "150000",
        "premiumDetails": [
          {
            "mode": "M",
            "riderPremiumValue": 300,
            "tax": [
              {
                "taxDescription": "Life Term GST",
                "taxValue": 54,
                "taxPercentage": 18.0
              }
            ],
            "totalRiderTax": 54,
            "totalRiderPremiumValue": 354
          },
          {
            "mode": "Q",
            "riderPremiumValue": 363,
            "tax": [
              {
                "taxDescription": "Life Term GST",
                "taxValue": 65,
                "taxPercentage": 18.0
              }
            ],
            "totalRiderTax": 65,
            "totalRiderPremiumValue": 428
          },
          {
            "mode": "HY",
            "riderPremiumValue": 363,
            "tax": [
              {
                "taxDescription": "Life Term GST",
                "taxValue": 65,
                "taxPercentage": 18.0
              }
            ],
            "totalRiderTax": 65,
            "totalRiderPremiumValue": 428
          },
          {
            "mode": "Y",
            "riderPremiumValue": 363,
            "tax": [
              {
                "taxDescription": "Life Term GST",
                "taxValue": 65,
                "taxPercentage": 18.0
              }
            ],
            "totalRiderTax": 65,
            "totalRiderPremiumValue": 428
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ],
        "temp_price": "Add to get price"
      },
      {
        "ridercode": "101",
        "riderName": "ABSLI Critical Illness Rider",
        "riderFeatures": "In the unfortunate event that the life insured is diagnosed to be suffering from any one of the above 4 critical illnesses, we will pay 100% of the rider sum assured on survival of 30 days following the date of confirmed diagnosis",
        "riderTerm": "20",
        "riderPremiumPayingTerm": "10",
        "riderSA": "150000",
        "premiumDetails": [
          {
            "mode": "M",
            "riderPremiumValue": 270,
            "tax": [
              {
                "taxDescription": "Life Term GST",
                "taxValue": 48,
                "taxPercentage": 18.0
              }
            ],
            "totalRiderTax": 48,
            "totalRiderPremiumValue": 318
          },
          {
            "mode": "Q",
            "riderPremiumValue": 270,
            "tax": [
              {
                "taxDescription": "Life Term GST",
                "taxValue": 48,
                "taxPercentage": 18.0
              }
            ],
            "totalRiderTax": 48,
            "totalRiderPremiumValue": 318
          },
          {
            "mode": "HY",
            "riderPremiumValue": 270,
            "tax": [
              {
                "taxDescription": "Life Term GST",
                "taxValue": 48,
                "taxPercentage": 18.0
              }
            ],
            "totalRiderTax": 48,
            "totalRiderPremiumValue": 318
          },
          {
            "mode": "Y",
            "riderPremiumValue": 270,
            "tax": [
              {
                "taxDescription": "Life Term GST",
                "taxValue": 48,
                "taxPercentage": 18.0
              }
            ],
            "totalRiderTax": 48,
            "totalRiderPremiumValue": 318
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ],
        "temp_price": "Add to get price"
      },
      {
        "ridercode": "100",
        "riderName": "ABSLI Accidental Death and Disability Rider",
        "riderFeatures": "In the unfortunate event the death of the life insured within 180 days of the occurrence of an accident, we will pay rider sum assured to the nominee",
        "riderTerm": "20",
        "riderPremiumPayingTerm": "10",
        "riderSA": "150000",
        "premiumDetails": [
          {
            "mode": "M",
            "riderPremiumValue": 225,
            "tax": [
              {
                "taxDescription": "Life Term GST",
                "taxValue": 40,
                "taxPercentage": 18.0
              }
            ],
            "totalRiderTax": 40,
            "totalRiderPremiumValue": 265
          },
          {
            "mode": "Q",
            "riderPremiumValue": 225,
            "tax": [
              {
                "taxDescription": "Life Term GST",
                "taxValue": 40,
                "taxPercentage": 18.0
              }
            ],
            "totalRiderTax": 40,
            "totalRiderPremiumValue": 265
          },
          {
            "mode": "HY",
            "riderPremiumValue": 225,
            "tax": [
              {
                "taxDescription": "Life Term GST",
                "taxValue": 40,
                "taxPercentage": 18.0
              }
            ],
            "totalRiderTax": 40,
            "totalRiderPremiumValue": 265
          },
          {
            "mode": "Y",
            "riderPremiumValue": 225,
            "tax": [
              {
                "taxDescription": "Life Term GST",
                "taxValue": 40,
                "taxPercentage": 18.0
              }
            ],
            "totalRiderTax": 40,
            "totalRiderPremiumValue": 265
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ],
        "temp_price": "Add to get price"
      },
      {
        "ridercode": "102",
        "riderName": "ABSLI Surgical Care Rider",
        "riderFeatures": "If you are hospitalised for undergoing medically necessary surgery in India for a minimumperiod of 24 hours and actually undergo that surgery, a lump sum benefit will be paid. Youcan choose your benefit amount from `3,000 to `30,000. The rider sum assured will be 50 times this benefit amount",
        "riderTerm": "20",
        "riderPremiumPayingTerm": "10",
        "riderSA": "150000",
        "premiumDetails": [
          {
            "mode": "M",
            "riderPremiumValue": 258,
            "tax": [
              {
                "taxDescription": "Life Term GST",
                "taxValue": 46,
                "taxPercentage": 18.0
              }
            ],
            "totalRiderTax": 46,
            "totalRiderPremiumValue": 304
          },
          {
            "mode": "Q",
            "riderPremiumValue": 376,
            "tax": [
              {
                "taxDescription": "Life Term GST",
                "taxValue": 67,
                "taxPercentage": 18.0
              }
            ],
            "totalRiderTax": 67,
            "totalRiderPremiumValue": 443
          },
          {
            "mode": "HY",
            "riderPremiumValue": 376,
            "tax": [
              {
                "taxDescription": "Life Term GST",
                "taxValue": 67,
                "taxPercentage": 18.0
              }
            ],
            "totalRiderTax": 67,
            "totalRiderPremiumValue": 443
          },
          {
            "mode": "Y",
            "riderPremiumValue": 376,
            "tax": [
              {
                "taxDescription": "Life Term GST",
                "taxValue": 67,
                "taxPercentage": 18.0
              }
            ],
            "totalRiderTax": 67,
            "totalRiderPremiumValue": 443
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ],
        "temp_price": "Add to get price"
      },
      {
        "ridercode": "104",
        "riderName": "ABSLI Accidental Death Benefit Rider Plus",
        "riderFeatures": "In the unfortunate event of death of the life insured due to an Accident within 180 days of occurrence of the accident, we will pay 100% of the rider sum assured to the nominee only if this rider cover is inforce as on the date of accident including the grace period",
        "riderTerm": "10",
        "riderPremiumPayingTerm": "10",
        "riderSA": "300000",
        "premiumDetails": [
          {
            "mode": "M",
            "riderPremiumValue": 210,
            "tax": [
              {
                "taxDescription": "Life Term GST",
                "taxValue": 37,
                "taxPercentage": 18.0
              }
            ],
            "totalRiderTax": 37,
            "totalRiderPremiumValue": 247
          },
          {
            "mode": "Q",
            "riderPremiumValue": 105,
            "tax": [
              {
                "taxDescription": "Life Term GST",
                "taxValue": 18,
                "taxPercentage": 18.0
              }
            ],
            "totalRiderTax": 18,
            "totalRiderPremiumValue": 123
          },
          {
            "mode": "HY",
            "riderPremiumValue": 105,
            "tax": [
              {
                "taxDescription": "Life Term GST",
                "taxValue": 18,
                "taxPercentage": 18.0
              }
            ],
            "totalRiderTax": 18,
            "totalRiderPremiumValue": 123
          },
          {
            "mode": "Y",
            "riderPremiumValue": 105,
            "tax": [
              {
                "taxDescription": "Life Term GST",
                "taxValue": 18,
                "taxPercentage": 18.0
              }
            ],
            "totalRiderTax": 18,
            "totalRiderPremiumValue": 123
          },
          {
            "mode": "S",
            "riderPremiumValue": 1056,
            "tax": [
              {
                "taxDescription": "Life Term GST",
                "taxValue": 190,
                "taxPercentage": 18.0
              }
            ],
            "totalRiderTax": 190,
            "totalRiderPremiumValue": 1246
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ],
        "temp_price": "Add to get price"
      }
    ],
    // "ABDGSHLD": [
    //   {
    //     "ridercode": "103",
    //     "riderName": "ABSLI Hospital Care Rider",
    //     "riderFeatures": "You can claim your daily cash benefit in case of hospitalisation for medically necessary treatment of any Illness or Injury for a minimum period of 24 hours payable from the first day for the duration of hospitalisation",
    //     "riderTerm": "20",
    //     "riderPremiumPayingTerm": "10",
    //     "riderSA": "150000",
    //     "premiumDetails": [
    //       {
    //         "mode": "M",
    //         "riderPremiumValue": 300,
    //         "tax": [
    //           {
    //             "taxDescription": "Life Term GST",
    //             "taxValue": 54,
    //             "taxPercentage": 18.0
    //           }
    //         ],
    //         "totalRiderTax": 54,
    //         "totalRiderPremiumValue": 354
    //       },
    //       {
    //         "mode": "Q",
    //         "riderPremiumValue": 363,
    //         "tax": [
    //           {
    //             "taxDescription": "Life Term GST",
    //             "taxValue": 65,
    //             "taxPercentage": 18.0
    //           }
    //         ],
    //         "totalRiderTax": 65,
    //         "totalRiderPremiumValue": 428
    //       },
    //       {
    //         "mode": "HY",
    //         "riderPremiumValue": 363,
    //         "tax": [
    //           {
    //             "taxDescription": "Life Term GST",
    //             "taxValue": 65,
    //             "taxPercentage": 18.0
    //           }
    //         ],
    //         "totalRiderTax": 65,
    //         "totalRiderPremiumValue": 428
    //       },
    //       {
    //         "mode": "Y",
    //         "riderPremiumValue": 363,
    //         "tax": [
    //           {
    //             "taxDescription": "Life Term GST",
    //             "taxValue": 65,
    //             "taxPercentage": 18.0
    //           }
    //         ],
    //         "totalRiderTax": 65,
    //         "totalRiderPremiumValue": 428
    //       }
    //     ],
    //     "varFields": [
    //       {
    //         "fieldName": null,
    //         "fieldValue": null
    //       }
    //     ],
    //     "temp_price": "Add to get price"
    //   },
    //   {
    //     "ridercode": "101",
    //     "riderName": "ABSLI Critical Illness Rider",
    //     "riderFeatures": "In the unfortunate event that the life insured is diagnosed to be suffering from any one of the above 4 critical illnesses, we will pay 100% of the rider sum assured on survival of 30 days following the date of confirmed diagnosis",
    //     "riderTerm": "20",
    //     "riderPremiumPayingTerm": "10",
    //     "riderSA": "150000",
    //     "premiumDetails": [
    //       {
    //         "mode": "M",
    //         "riderPremiumValue": 270,
    //         "tax": [
    //           {
    //             "taxDescription": "Life Term GST",
    //             "taxValue": 48,
    //             "taxPercentage": 18.0
    //           }
    //         ],
    //         "totalRiderTax": 48,
    //         "totalRiderPremiumValue": 318
    //       },
    //       {
    //         "mode": "Q",
    //         "riderPremiumValue": 270,
    //         "tax": [
    //           {
    //             "taxDescription": "Life Term GST",
    //             "taxValue": 48,
    //             "taxPercentage": 18.0
    //           }
    //         ],
    //         "totalRiderTax": 48,
    //         "totalRiderPremiumValue": 318
    //       },
    //       {
    //         "mode": "HY",
    //         "riderPremiumValue": 270,
    //         "tax": [
    //           {
    //             "taxDescription": "Life Term GST",
    //             "taxValue": 48,
    //             "taxPercentage": 18.0
    //           }
    //         ],
    //         "totalRiderTax": 48,
    //         "totalRiderPremiumValue": 318
    //       },
    //       {
    //         "mode": "Y",
    //         "riderPremiumValue": 270,
    //         "tax": [
    //           {
    //             "taxDescription": "Life Term GST",
    //             "taxValue": 48,
    //             "taxPercentage": 18.0
    //           }
    //         ],
    //         "totalRiderTax": 48,
    //         "totalRiderPremiumValue": 318
    //       }
    //     ],
    //     "varFields": [
    //       {
    //         "fieldName": null,
    //         "fieldValue": null
    //       }
    //     ],
    //     "temp_price": "Add to get price"
    //   },
    //   {
    //     "ridercode": "100",
    //     "riderName": "ABSLI Accidental Death and Disability Rider",
    //     "riderFeatures": "In the unfortunate event the death of the life insured within 180 days of the occurrence of an accident, we will pay rider sum assured to the nominee",
    //     "riderTerm": "20",
    //     "riderPremiumPayingTerm": "10",
    //     "riderSA": "150000",
    //     "premiumDetails": [
    //       {
    //         "mode": "M",
    //         "riderPremiumValue": 225,
    //         "tax": [
    //           {
    //             "taxDescription": "Life Term GST",
    //             "taxValue": 40,
    //             "taxPercentage": 18.0
    //           }
    //         ],
    //         "totalRiderTax": 40,
    //         "totalRiderPremiumValue": 265
    //       },
    //       {
    //         "mode": "Q",
    //         "riderPremiumValue": 225,
    //         "tax": [
    //           {
    //             "taxDescription": "Life Term GST",
    //             "taxValue": 40,
    //             "taxPercentage": 18.0
    //           }
    //         ],
    //         "totalRiderTax": 40,
    //         "totalRiderPremiumValue": 265
    //       },
    //       {
    //         "mode": "HY",
    //         "riderPremiumValue": 225,
    //         "tax": [
    //           {
    //             "taxDescription": "Life Term GST",
    //             "taxValue": 40,
    //             "taxPercentage": 18.0
    //           }
    //         ],
    //         "totalRiderTax": 40,
    //         "totalRiderPremiumValue": 265
    //       },
    //       {
    //         "mode": "Y",
    //         "riderPremiumValue": 225,
    //         "tax": [
    //           {
    //             "taxDescription": "Life Term GST",
    //             "taxValue": 40,
    //             "taxPercentage": 18.0
    //           }
    //         ],
    //         "totalRiderTax": 40,
    //         "totalRiderPremiumValue": 265
    //       }
    //     ],
    //     "varFields": [
    //       {
    //         "fieldName": null,
    //         "fieldValue": null
    //       }
    //     ],
    //     "temp_price": "Add to get price"
    //   },
    //   {
    //     "ridercode": "102",
    //     "riderName": "ABSLI Surgical Care Rider",
    //     "riderFeatures": "If you are hospitalised for undergoing medically necessary surgery in India for a minimumperiod of 24 hours and actually undergo that surgery, a lump sum benefit will be paid. Youcan choose your benefit amount from `3,000 to `30,000. The rider sum assured will be 50 times this benefit amount",
    //     "riderTerm": "20",
    //     "riderPremiumPayingTerm": "10",
    //     "riderSA": "150000",
    //     "premiumDetails": [
    //       {
    //         "mode": "M",
    //         "riderPremiumValue": 258,
    //         "tax": [
    //           {
    //             "taxDescription": "Life Term GST",
    //             "taxValue": 46,
    //             "taxPercentage": 18.0
    //           }
    //         ],
    //         "totalRiderTax": 46,
    //         "totalRiderPremiumValue": 304
    //       },
    //       {
    //         "mode": "Q",
    //         "riderPremiumValue": 376,
    //         "tax": [
    //           {
    //             "taxDescription": "Life Term GST",
    //             "taxValue": 67,
    //             "taxPercentage": 18.0
    //           }
    //         ],
    //         "totalRiderTax": 67,
    //         "totalRiderPremiumValue": 443
    //       },
    //       {
    //         "mode": "HY",
    //         "riderPremiumValue": 376,
    //         "tax": [
    //           {
    //             "taxDescription": "Life Term GST",
    //             "taxValue": 67,
    //             "taxPercentage": 18.0
    //           }
    //         ],
    //         "totalRiderTax": 67,
    //         "totalRiderPremiumValue": 443
    //       },
    //       {
    //         "mode": "Y",
    //         "riderPremiumValue": 376,
    //         "tax": [
    //           {
    //             "taxDescription": "Life Term GST",
    //             "taxValue": 67,
    //             "taxPercentage": 18.0
    //           }
    //         ],
    //         "totalRiderTax": 67,
    //         "totalRiderPremiumValue": 443
    //       }
    //     ],
    //     "varFields": [
    //       {
    //         "fieldName": null,
    //         "fieldValue": null
    //       }
    //     ],
    //     "temp_price": "Add to get price"
    //   },
    //   {
    //     "ridercode": "104",
    //     "riderName": "ABSLI Accidental Death Benefit Rider Plus",
    //     "riderFeatures": "In the unfortunate event of death of the life insured due to an Accident within 180 days of occurrence of the accident, we will pay 100% of the rider sum assured to the nominee only if this rider cover is inforce as on the date of accident including the grace period",
    //     "riderTerm": "10",
    //     "riderPremiumPayingTerm": "10",
    //     "riderSA": "300000",
    //     "premiumDetails": [
    //       {
    //         "mode": "M",
    //         "riderPremiumValue": 210,
    //         "tax": [
    //           {
    //             "taxDescription": "Life Term GST",
    //             "taxValue": 37,
    //             "taxPercentage": 18.0
    //           }
    //         ],
    //         "totalRiderTax": 37,
    //         "totalRiderPremiumValue": 247
    //       },
    //       {
    //         "mode": "Q",
    //         "riderPremiumValue": 105,
    //         "tax": [
    //           {
    //             "taxDescription": "Life Term GST",
    //             "taxValue": 18,
    //             "taxPercentage": 18.0
    //           }
    //         ],
    //         "totalRiderTax": 18,
    //         "totalRiderPremiumValue": 123
    //       },
    //       {
    //         "mode": "HY",
    //         "riderPremiumValue": 105,
    //         "tax": [
    //           {
    //             "taxDescription": "Life Term GST",
    //             "taxValue": 18,
    //             "taxPercentage": 18.0
    //           }
    //         ],
    //         "totalRiderTax": 18,
    //         "totalRiderPremiumValue": 123
    //       },
    //       {
    //         "mode": "Y",
    //         "riderPremiumValue": 105,
    //         "tax": [
    //           {
    //             "taxDescription": "Life Term GST",
    //             "taxValue": 18,
    //             "taxPercentage": 18.0
    //           }
    //         ],
    //         "totalRiderTax": 18,
    //         "totalRiderPremiumValue": 123
    //       },
    //       {
    //         "mode": "S",
    //         "riderPremiumValue": 1056,
    //         "tax": [
    //           {
    //             "taxDescription": "Life Term GST",
    //             "taxValue": 190,
    //             "taxPercentage": 18.0
    //           }
    //         ],
    //         "totalRiderTax": 190,
    //         "totalRiderPremiumValue": 1246
    //       }
    //     ],
    //     "varFields": [
    //       {
    //         "fieldName": null,
    //         "fieldValue": null
    //       }
    //     ],
    //     "temp_price": "Add to get price"
    //   }
    // ],
    "154": [
      // {
      //   "ridercode": "155",
      //   "riderName": "Max Life Waiver of Premium Plus ",
      //   "riderFeatures": "In the unfortunate event of critical illness,Dismemberment and death the Rider provides waiver for all future premiums under the policy.",
      //   "riderTerm": "70",
      //   "riderPremiumPayingTerm": "67",
      //   "riderSA": "2500000",
      //   "premiumDetails": [
      //     {
      //       "mode": "M",
      //       "riderPremiumValue": 210,
      //       "tax": [
      //         {
      //           "taxDescription": "Life Term GST",
      //           "taxValue": 37,
      //           "taxPercentage": 18.0
      //         }
      //       ],
      //       "totalRiderTax": 37,
      //       "totalRiderPremiumValue": 247
      //     },
      //     {
      //       "mode": "Q",
      //       "riderPremiumValue": 105,
      //       "tax": [
      //         {
      //           "taxDescription": "Life Term GST",
      //           "taxValue": 18,
      //           "taxPercentage": 18.0
      //         }
      //       ],
      //       "totalRiderTax": 18,
      //       "totalRiderPremiumValue": 123
      //     },
      //     {
      //       "mode": "HY",
      //       "riderPremiumValue": 105,
      //       "tax": [
      //         {
      //           "taxDescription": "Life Term GST",
      //           "taxValue": 18,
      //           "taxPercentage": 18.0
      //         }
      //       ],
      //       "totalRiderTax": 18,
      //       "totalRiderPremiumValue": 123
      //     },
      //     {
      //       "mode": "Y",
      //       "riderPremiumValue": 105,
      //       "tax": [
      //         {
      //           "taxDescription": "Life Term GST",
      //           "taxValue": 18,
      //           "taxPercentage": 18.0
      //         }
      //       ],
      //       "totalRiderTax": 18,
      //       "totalRiderPremiumValue": 123
      //     },
      //     {
      //       "mode": "S",
      //       "riderPremiumValue": 1056,
      //       "tax": [
      //         {
      //           "taxDescription": "Life Term GST",
      //           "taxValue": 190,
      //           "taxPercentage": 18.0
      //         }
      //       ],
      //       "totalRiderTax": 190,
      //       "totalRiderPremiumValue": 1246
      //     }
      //   ],
      //   "varFields": [
      //     {
      //       "fieldName": null,
      //       "fieldValue": null
      //     }
      //   ],
      //   "temp_price": "Add to get price"
      // }
    ],
    "P1C2PS": [
      {
        "ridercode": "C4CI",
        "riderName": "HDFC Life Critical Illness plus Rider",
        "riderFeatures": "Key Feature:This Rider provides Rider Sum Assured as a lump sum benefit if the life assured survives for a period of 30 days following diagnosis of any one of the 19 critical illnesses covered",
        "riderTerm": 27,
        "riderPremiumPayingTerm": 27,
        "riderSA": 1000000,
        "premiumDetails": [
          {
            "mode": "M",
            "riderPremiumValue": 273,
            "tax": [
              {
                "taxDescription": "Life Term GST",
                "taxValue": 49,
                "taxPercentage": 18
              }
            ],
            "totalRiderTax": 49,
            "totalRiderPremiumValue": 322
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ],
        "temp_price": "Add to get price"
      },
      {
        "ridercode": "INAD",
        "riderName": "HDFC Life Income Benefit on Accidental Disability Rider",
        "riderFeatures": "Key Feature: This rider provides an additional cover to protect you  On the Total Permanent Disability of the life assured due to an accident, a regular monthly income equal to 1% of the Sum Assured will be payable to the life assured for a fixed period of 10 years.",
        "riderTerm": 27,
        "riderPremiumPayingTerm": 27,
        "riderSA": 1000000,
        "premiumDetails": [
          {
            "mode": "M",
            "riderPremiumValue": 25,
            "tax": [
              {
                "taxDescription": "Life Term GST",
                "taxValue": 4,
                "taxPercentage": 18
              }
            ],
            "totalRiderTax": 4,
            "totalRiderPremiumValue": 29
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ],
        "temp_price": "Add to get price"
      },
      {
        "ridercode": "PPR_CC",
        "riderName": "HDFC Life Protect Plus rider - Cancer Cover",
        "riderFeatures": "This option provides a lump sum benefit on detection of early or major stage Cancer",
        "riderTerm": 5,
        "riderPremiumPayingTerm": 5,
        "riderSA": 1500000,
        "premiumDetails": [
          {
            "mode": "M",
            "riderPremiumValue": 66,
            "tax": [
              {
                "taxDescription": "Life Term GST",
                "taxValue": 12,
                "taxPercentage": 18.0
              }
            ],
            "totalRiderTax": 12,
            "totalRiderPremiumValue": 78
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ],
        "temp_price": "Add to get price"
      },
      {
        "ridercode": "PPR_ADC",
        "riderName": "HDFC Life Protect Plus rider - Accidental Death Cover",
        "riderFeatures": "This option provides Rider Sum Assured as a lump sum benefit in case of death of the life assured due to an accident.",
        "riderTerm": 5,
        "riderPremiumPayingTerm": 5,
        "riderSA": 1500000,
        "premiumDetails": [
          {
            "mode": "M",
            "riderPremiumValue": 71,
            "tax": [
              {
                "taxDescription": "Life Term GST",
                "taxValue": 13,
                "taxPercentage": 18.0
              }
            ],
            "totalRiderTax": 13,
            "totalRiderPremiumValue": 84
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ],
        "temp_price": "Add to get price"
      },
      {
        "ridercode": "PPR_PAC",
        "riderName": "HDFC Life Protect Plus rider - Personal Accident Cover",
        "riderFeatures": "This option provides Rider Sum Assured as a lump sum benefit/ monthly income for a fixed period in case of death of the life assured due to an accident/ Accidental Total Permanent Disability/ Accidental Partial Permanent Disability, as applicable.",
        "riderTerm": 28,
        "riderPremiumPayingTerm": 1,
        "riderSA": 1500000,
        "premiumDetails": [
          {
            "mode": "S",
            "riderPremiumValue": 53701,
            "tax": [
              {
                "taxDescription": "Life Term GST",
                "taxValue": 9666,
                "taxPercentage": 18.0
              }
            ],
            "totalRiderTax": 9666,
            "totalRiderPremiumValue": 63367
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ],
        "temp_price": "Add to get price"
      }
    ]
  },
  DISTRIBUTOR_INFO: {
    "HDFCSECL": {
    "distributorName": "HDFC securities Ltd",
    "CIN": "U67120MH2000PLC152193 |",
    "License category": "Corporate Agent |", 
    "IRDAI License Number": "CA0062 |",
    "IRDAI Registration Code No": "",
    "Valid Till": "",
    "Email ID": "investmentadvisers@hdfcsec.com |",
    "Registered Office": "Think Techno Campus, Building, B, Alpha, Office Floor 8, Near Kanjurmarg Station, Kanjurmarg (East), Mumbai -400 042 |",
    "Tel no": "022-30753400",
    },
    
    "PINC":{
     "distributorName":"Pioneer Insurance & Reinsurance Brokers Pvt. Ltd ",
     "CIN" : " U67200MH2002PTC137896 |",
     "License category" : " Composite Brokers |",
     "IRDAI License Number" : "118 |",
     "IRDAI Registration Code No" : "",
     "Valid Till" : "23.02.2024 |",
     "Email ID" : "",
     "Registered Office" : "1219, 12th floor, Maker Chamber 5, Jamnalal Bajaj Rd, Nariman Point, Mumbai, Maharashtra 400021 ",
     "Tel no" : "",
    },
      "ICICI": {
       "distributorName":"ICICI Securities Limited",
       "CIN" : "L67120MH1995PLC086241 |",
       "License category" : "",
       "IRDAI License Number" : "",
       "IRDAI Registration Code No" : "",
       "Valid Till" : "",
       "Email ID" : "",
       "Registered Office" : "ICICI Venture House, Appasaheb Marathe Marg, Prabhadevi, Mumbai - 400 025|",
       "Tel no" : "(91 22) 6807 7100 ",
       },
    
    "CUBE":{
     "distributorName":"D CUBE",
     "CIN" : "",
     "License category":"Web Aggregator|",
     "IRDAI License Number" : "",
     "IRDAI Registration Code No":"IRDAI/INT/WBA/60/2021|",
     "Valid Till":"15.06.2024 |",
     "Email ID":"dcubeinsure@gmail.com |",
     "Registered Office":"10th Floor, Block No. - 01, 1004, Sonorus - B, Vapi Silvassa Road, Vapi, Valsad (Gujarat) – 396191 |",
     "Tel no":"97379 33332 |"
     
    },
    
     "Insurance":{
     "distributorName":"Choice Insurance Broking India Private Limited",
     "CIN" : "",
     "License category" : "Direct Broker (Life & General) |",
     "IRDAI License Number" : "167 |",
     "IRDAI Registration Code No" : "",
     "Valid Till" : "29-05-2024 |",
     "Email ID" : "insurance.support@choiceindia.com |",
     "Registered Office" : "Sunil Patodia Tower,JB Nagar, Andheri East Mumbai -400 099 |",
     "Tel no" : "022-69092436",
     }
  },
    
    
  PAY_MODE_LIST: {
    "PNB": [
      "M",
      "HY",
      "Y"
    ],
    "HDFC": [
      "M",
      "Q",
      "HY",
      "Y"
    ],
    "TATA": [
      "M",
      "Q",
      "HY",
      "Y"
    ],
    "ICICI": [
      "M",
      "HY",
      "Y"
    ],
    "MAXLIFE": [
      "M",
      "Q",
      "HY",
      "Y"
    ],
    "ABSLIFEI": [
      "M",
      "Q",
      "HY",
      "Y",
      "S"
    ]
  },
  ADDONS_MASTER: {
    "12005": [
      {
        "optionSelected": "16",
        "optionName": "None",
        "optionDesc": "None",
        "addOnSumAssured": null,
        "inbuiltFeature": "None",
        "premiumIncludedFlag": "None",
        "premiumDetails": [
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          },
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          },
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ],
        "temp_price": "Add to get price"
      },
      {
        "optionSelected": "17",
        "optionName": "None",
        "optionDesc": "None",
        "addOnSumAssured": null,
        "inbuiltFeature": " ",
        "premiumIncludedFlag": "None",
        "premiumDetails": [
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          },
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          },
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ],
        "temp_price": "Add to get price"
      }
    ],
    "P1C2PL": [
      {
        "optionSelected": "16",
        "optionName": " Return of Premium (ROP) option",
        "optionDesc": "You will receive a return of 100% of the Total Premiums Paid as a lump sum, upon survival until maturity.",
        "addOnSumAssured": null,
        "premiumIncludedFlag": "Yes",
        "mandatoryInProduct": "No",
        "inbuiltFeature": "No",
        "premiumDetails": [
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          },
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          },
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          },
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ],
        "temp_price": "Add to get price"
      },
      {
        "optionSelected": "27",
        "optionName": "Critical Illness Benefit",
        "optionDesc": "Critical Illness cover increases at each policy anniversary",
        "addOnSumAssured": null,
        "premiumIncludedFlag": "Yes",
        "mandatoryInProduct": "No",
        "inbuiltFeature": "Yes",
        "premiumDetails": [
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ],
        "temp_price": "Add to get price"
      },
      {
        "optionSelected": "26",
        "optionName": "Income after certain age",
        "optionDesc": "Income after certain age",
        "addOnSumAssured": null,
        "premiumIncludedFlag": "Yes",
        "inbuiltFeature": "Yes",
        "mandatoryInProduct": "No",
        "premiumDetails": [
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ],
        "temp_price": "Add to get price"
      },
    ],
    "SamRakSp": [
      {
        "optionSelected": "16",
        "optionName": "Return of Premium",
        "optionDesc": "In case the life assured survives till maturity, an amount equal to the 105% of the Total Premiums Paid (excluding loading for modal premiums) towards the benefit option shall be payable at the end of the Policy Term.",
        "addOnSumAssured": null,
        "premiumIncludedFlag": "Yes",
        "inbuiltFeature": "Yes",
        "mandatoryInProduct": "No",
        "premiumDetails": [
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          },
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          },
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          },
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ],
        "temp_price": "Add to get price"
      },
      {
        "optionSelected": "10",
        "optionName": "Payor Accelerator Benefit",
        "optionDesc": "Payor Accelerator Benefit - The benefit amount equal to 50% of the Base SA is paid out as lump sum on acceptance of Terminal Illness (TI) claim by us. Upon payment of the TI claim, the policy continues and any due premiums continue to remain payable.",
        "addOnSumAssured": null,
        "premiumIncludedFlag": "Yes",
        "inbuiltFeature": "Yes",
        "mandatoryInProduct": "Yes",
        "premiumDetails": [
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          },
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          },
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          },
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ],
        "temp_price": "Add to get price"
      }
    ],
    "T51": [
      {
        "optionSelected": "10",
        "optionName": "Terminal Illness",
        "optionDesc": "A Life Assured shall be regarded as Terminally Ill only if the Life Assured is diagnosed as suffering from a condition which, in the opinion of two independent medical practitioners' specializing in treatment of such illness, is highly likely to lead to death within 6 months.",
        "addOnSumAssured": null,
        "inbuiltFeature": "Yes",
        "mandatoryInProduct": "Yes",
        "premiumIncludedFlag": "Yes",
        "premiumDetails": [
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          },
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          },
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ],
        "temp_price": "Add to get price"
      },
      {
        "optionSelected": "11",
        "optionName": "Accelerated Critical Illness",
        "optionDesc": "The Accelerated Critical Illness Benefit offers you coverage against 34 critical illnesses. The benefit is payable, on first occurrence of any of the covered illnesses.",
        "addOnSumAssured": 5000000,
        "inbuiltFeature": "Yes",
        " premiumIncludedFlag": "No",
        "mandatoryInProduct": "No",
        "premiumDetails": [
          {
            "mode": "M",
            "premiumValue": 1346,
            "tax": [
              {
                "taxDescription": "Life Term GST",
                "taxValue": 242,
                "taxPercentage": 18
              }
            ],
            "totalTax": 242,
            "totalPremiumValue": 1588
          },
          {
            "mode": "Y",
            "premiumValue": 15764,
            "tax": [
              {
                "taxDescription": "Life Term GST",
                "taxValue": 2838,
                "taxPercentage": 18
              }
            ],
            "totalTax": 2838,
            "totalPremiumValue": 18602
          },
          {
            "mode": "HY",
            "premiumValue": 7981,
            "tax": [
              {
                "taxDescription": "Life Term GST",
                "taxValue": 1437,
                "taxPercentage": 18
              }
            ],
            "totalTax": 1437,
            "totalPremiumValue": 9418
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ],
        "temp_price": "Add to get price"
      },
      {
        "optionSelected": "12",
        "optionName": "Accidental Death Benefit",
        "optionDesc": "In case of death due to an accident, we will pay your nominee/ legal heir Accidental Death Benefit as lump sum",
        "addOnSumAssured": 5000000,
        "inbuiltFeature": "Yes",
        "premiumIncludedFlag": "No",
        "mandatoryInProduct": "No",
        "premiumDetails": [
          {
            "mode": "M",
            "premiumValue": 256,
            "tax": [
              {
                "taxDescription": "Life Term GST",
                "taxValue": 46,
                "taxPercentage": 18
              }
            ],
            "totalTax": 46,
            "totalPremiumValue": 302
          },
          {
            "mode": "Y",
            "premiumValue": 3000,
            "tax": [
              {
                "taxDescription": "Life Term GST",
                "taxValue": 540,
                "taxPercentage": 18
              }
            ],
            "totalTax": 540,
            "totalPremiumValue": 3540
          },
          {
            "mode": "HY",
            "premiumValue": 1519,
            "tax": [
              {
                "taxDescription": "Life Term GST",
                "taxValue": 273,
                "taxPercentage": 18
              }
            ],
            "totalTax": 273,
            "totalPremiumValue": 1792
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ],
        "temp_price": "Add to get price"
      },
      {
        "optionSelected": "13",
        "optionName": "Waiver of Premium on permanent disability",
        "optionDesc": "On occurrence of Permanent Disability due to accident, while the policy is in force, all future premiums for all benefits under the policy are waived.",
        "addOnSumAssured": null,
        "inbuiltFeature": "Yes",
        "premiumIncludedFlag": "Yes",
        "mandatoryInProduct": "Yes",
        "premiumDetails": [
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          },
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          },
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ],
        "temp_price": "Add to get price"
      }
    ],
    "154": [
      {
        "optionSelected": "10",
        "optionName": "Terminal Illness",
        "optionDesc": "A Life Assured shall be regarded as Terminally Ill only if the Life Assured is diagnosed as suffering from a condition which, in the opinion of two independent medical practitioners' specializing in treatment of such illness, is highly likely to lead to death within 6 months.",
        "addOnSumAssured": null,
        "inbuiltFeature": "Yes",
        "mandatoryInProduct": "Yes",
        "premiumIncludedFlag": "Yes",
        "premiumDetails": [
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          },
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          },
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ],
        "temp_price": "Add to get price"
      },
      {
        "optionSelected": "11",
        "optionName": "Accelerated Critical Illness",
        "optionDesc": "The Accelerated Critical Illness Benefit offers you coverage against 34 critical illnesses. The benefit is payable, on first occurrence of any of the covered illnesses.",
        "addOnSumAssured": 5000000,
        "inbuiltFeature": "Yes",
        " premiumIncludedFlag": "No",
        "mandatoryInProduct": "No",
        "premiumDetails": [
          {
            "mode": "M",
            "premiumValue": 1346,
            "tax": [
              {
                "taxDescription": "Life Term GST",
                "taxValue": 242,
                "taxPercentage": 18
              }
            ],
            "totalTax": 242,
            "totalPremiumValue": 1588
          },
          {
            "mode": "Y",
            "premiumValue": 15764,
            "tax": [
              {
                "taxDescription": "Life Term GST",
                "taxValue": 2838,
                "taxPercentage": 18
              }
            ],
            "totalTax": 2838,
            "totalPremiumValue": 18602
          },
          {
            "mode": "HY",
            "premiumValue": 7981,
            "tax": [
              {
                "taxDescription": "Life Term GST",
                "taxValue": 1437,
                "taxPercentage": 18
              }
            ],
            "totalTax": 1437,
            "totalPremiumValue": 9418
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ],
        "temp_price": "Add to get price"
      },
      {
        "optionSelected": "12",
        "optionName": "Accidental Death Benefit",
        "optionDesc": "In case of death due to an accident, we will pay your nominee/ legal heir Accidental Death Benefit as lump sum",
        "addOnSumAssured": 5000000,
        "inbuiltFeature": "Yes",
        "premiumIncludedFlag": "No",
        "mandatoryInProduct": "No",
        "premiumDetails": [
          {
            "mode": "M",
            "premiumValue": 256,
            "tax": [
              {
                "taxDescription": "Life Term GST",
                "taxValue": 46,
                "taxPercentage": 18
              }
            ],
            "totalTax": 46,
            "totalPremiumValue": 302
          },
          {
            "mode": "Y",
            "premiumValue": 3000,
            "tax": [
              {
                "taxDescription": "Life Term GST",
                "taxValue": 540,
                "taxPercentage": 18
              }
            ],
            "totalTax": 540,
            "totalPremiumValue": 3540
          },
          {
            "mode": "HY",
            "premiumValue": 1519,
            "tax": [
              {
                "taxDescription": "Life Term GST",
                "taxValue": 273,
                "taxPercentage": 18
              }
            ],
            "totalTax": 273,
            "totalPremiumValue": 1792
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ],
        "temp_price": "Add to get price"
      },
      {
        "optionSelected": "16",
        "optionName": "Return of Premium",
        "optionDesc": "In case the life assured survives till maturity, an amount equal to the 105% of the Total Premiums Paid (excluding loading for modal premiums) towards the benefit option shall be payable at the end of the Policy Term.",
        "addOnSumAssured": null,
        "premiumIncludedFlag": "No",
        "inbuiltFeature": "Yes",
        "mandatoryInProduct": "No",
        "premiumDetails": [
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          },
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          },
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          },
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ],
        "temp_price": "Add to get price"
      },
      {
        "optionSelected": "17",
        "optionName": "Spouse Coverage",
        "optionDesc": "Spouse Coverage to protect your spouse within the same plan",
        "addOnSumAssured": null,
        "premiumIncludedFlag": "Yes",
        "mandatoryInProduct": "No",
        "inbuiltFeature": "Yes",
        "premiumDetails": [
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ]
      },
      {
        "optionSelected": "19",
        "optionName": "Life Stage",
        "optionDesc": "Under the Life Stage option, the policyholder can increase the life cover at specific life stage events of the life assured, by paying an additional premium for every increase.",
        "addOnSumAssured": null,
        "premiumIncludedFlag": "Yes",
        "mandatoryInProduct": "No",
        "inbuiltFeature": "Yes",
        "premiumDetails": [
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ]
      },
      {
        "optionSelected": "20",
        "optionName": "Premium break",
        "optionDesc": "Under the Premium Break option, the policyholder has the option to skip paying the Premium for a year and still stay covered. 2 Premium breaks will be available during the Policy Term.",
        "addOnSumAssured": null,
        "premiumIncludedFlag": "No",
        "mandatoryInProduct": "No",
        "inbuiltFeature": "Yes",
        "premiumDetails": [
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ]
      },
      {
        "optionSelected": "37",
        "optionName": "Special Exit Value",
        "optionDesc": "Option to receive all Premiums paid back, at a specified ",
        "addOnSumAssured": null,
        "premiumIncludedFlag": "Yes",
        "mandatoryInProduct": "Yes",
        "inbuiltFeature": "Yes",
        "premiumDetails": [
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ]
      }
    ],
    "ABTLSHLD": [
      {
        "optionSelected": "10",
        "optionName": "Terminal Illness Benefit",
        "optionDesc": "Terminal Illness Benefit",
        "addOnSumAssured": null,
        "premiumIncludedFlag": "Yes",
        "inbuiltFeature": "Yes",
        "premiumDetails": [
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ]
      },
      {
        "optionSelected": "16",
        "optionName": "Return of Premium",
        "optionDesc": "Return of Premium",
        "addOnSumAssured": null,
        "premiumIncludedFlag": "Yes",
        "inbuiltFeature": "Yes",
        "premiumDetails": [
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ]
      },
      {
        "optionSelected": "17",
        "optionName": "Joint Life Protection",
        "optionDesc": "Joint Life Protection",
        "addOnSumAssured": null,
        "premiumIncludedFlag": "Yes",
        "inbuiltFeature": "No",
        "premiumDetails": [
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ]
      }
    ],
    "ABDGSHLD": [
      {
        "optionSelected": "10",
        "optionName": "Terminal Illness Benefit",
        "optionDesc": "The Terminal Illness during the Policy Term, up to an age of 80 years, provided the Policy is in-force, 50% of the applicable Sum Assured on Death, subject to a maximum of 2 Crores, will be paid immediately as a lump sum and future due premiums shall be waived off. Terminal Illness Benefit shall only be payable on the first diagnosis of any Terminal Illness of the Life Insured during the Policy Term.",
        "addOnSumAssured": null,
        "premiumIncludedFlag": "Yes",
        "inbuiltFeature": "Yes",
        "mandatoryInProduct": "Yes",
        "premiumDetails": [
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ]
      },
      {
        "optionSelected": "16",
        "optionName": "Return of Premium",
        "optionDesc": "Under Return of Premium, on survival of Life Insured at the end of the Policy term, the total premiums, as defined under Maturity Benefit, will be paid to the Policyholder.",
        "addOnSumAssured": null,
        "premiumIncludedFlag": "Yes",
        "inbuiltFeature": "Yes",
        "mandatoryInProduct": "No",
        "premiumDetails": [
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ]
      },
      {
        "optionSelected": "19",
        "optionName": "Life Stage",
        "optionDesc": "Under the Life Stage option, the policyholder can increase the life cover at specific life stage events of the life assured, by paying an additional premium for every increase.",
        "addOnSumAssured": null,
        "premiumIncludedFlag": "Yes",
        "inbuiltFeature": "No",
        "mandatoryInProduct": "No",
        "premiumDetails": [
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ]
      },
      {
        "optionSelected": "11",
        "optionName": "Accelerated Critical Illness",
        "optionDesc": "The Accelerated Critical Illness Benefit offers you coverage against 34 critical illnesses. The benefit is payable, on first occurrence of any of the covered illnesses.",
        "addOnSumAssured": 500000,
        "premiumIncludedFlag": "No",
        "inbuiltFeature": "No",
        "mandatoryInProduct": "No",
        "premiumDetails": [
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ]
      }
    ],
    "P1C2PS": [
      {
        "optionSelected": "16",
        "optionName": " Return of Premium (ROP) option",
        "optionDesc": "You will receive a return of 100% of the Total Premiums Paid as a lump sum, upon survival until maturity.",
        "addOnSumAssured": null,
        "premiumIncludedFlag": "Yes",
        "mandatoryInProduct": "No",
        "inbuiltFeature": "No",
        "premiumDetails": [
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ]
      },
      {
        "optionSelected": "10",
        "optionName": "Terminal Illness Benefit",
        "optionDesc": "Terminal Illness Benefit",
        "addOnSumAssured": null,
        "premiumIncludedFlag": "Yes",
        "mandatoryInProduct": "No",
        "inbuiltFeature": "Yes",
        "premiumDetails": [
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ]
      },
      {
        "optionSelected": "12",
        "optionName": "Accidental Death Benefit (ADB) Option ",
        "optionDesc": "An additional amount equal to 100% of Basic Sum Assured will be payable to the Nominee on Life Assured’s death due to accident during the policy term.",
        "addOnSumAssured": null,
        "premiumIncludedFlag": "Yes",
        "mandatoryInProduct": "No",
        "inbuiltFeature": "Yes",
        "premiumDetails": [
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ]
      },
      {
        "optionSelected": "14",
        "optionName": "Waiver of Premium on CI (WOP CI) Option",
        "optionDesc": "All future premiums payable under the plan will be waived, the life assured are diagnosed with any of the covered critical illnesses.",
        "addOnSumAssured": null,
        "premiumIncludedFlag": "Yes",
        "mandatoryInProduct": "No",
        "inbuiltFeature": "No",
        "premiumDetails": [
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ]
      },
      {
        "optionSelected": "15",
        "optionName": "Waiver of premium on Total Permanent disability",
        "optionDesc": "Waiver of premium on Total Permanent disability",
        "addOnSumAssured": null,
        "premiumIncludedFlag": "Yes",
        "mandatoryInProduct": "No",
        "inbuiltFeature": "No",
        "premiumDetails": [
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ]
      },
      /* {
        "optionSelected": "17",
        "optionName": "Spouse Coverage",
        "optionDesc": "Spouse Coverage to protect your spouse within the same plan",
        "addOnSumAssured": null,
        "premiumIncludedFlag": "Yes",
        "mandatoryInProduct": "No",
        "inbuiltFeature": "No",
        "premiumDetails": [
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ]
      }, */
      {
        "optionSelected": "19",
        "optionName": "Life Stage",
        "optionDesc": "Under the Life Stage option, the policyholder can increase the life cover at specific life stage events of the life assured, by paying an additional premium for every increase.",
        "addOnSumAssured": null,
        "premiumIncludedFlag": "Yes",
        "mandatoryInProduct": "No",
        "inbuiltFeature": "No",
        "premiumDetails": [
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ]
      },
      /* {
        "optionSelected": "37",
        "optionName": "Special Exit Value",
        "optionDesc": "Option to receive all Premiums paid back, at a specified ",
        "addOnSumAssured": null,
        "premiumIncludedFlag": "Yes",
        "mandatoryInProduct": "No",
        "inbuiltFeature": "No",
        "premiumDetails": [
          {
            "mode": null,
            "premiumValue": null,
            "tax": [
              {
                "taxCode": null,
                "taxDescription": null,
                "taxValue": null,
                "taxPercent": null
              }
            ],
            "totalTax": null,
            "totalPremiumValue": null
          }
        ],
        "varFields": [
          {
            "fieldName": null,
            "fieldValue": null
          }
        ]
      } */
    ]
  },
  MINIMUM_ANNUAL_INCOME:300000,
  LOGOUT_URL_REDIRECTION: ["https://nportaluat.hdfcsec.com/onesbproduct"]
};

Object.freeze(Constants);
export function getDistributer() {
  return Constants.DISTRIBUTER;
}
export function getMinAnnualIncome() {
  return Constants.MINIMUM_ANNUAL_INCOME;
}
export function getInsurerList() {
  return Constants.INSURER_LIST;
}
export function getPolicyCover() {
  return Constants.POLICYCOVER;
}
export function getInsuranceImages() {
  return Constants.INSURER_IMGS;
}
export function getLazyLoadingConfig() {
  return Constants.LAZY_LOADING;
}
export function getOPTSelected() {
  return Constants.OPT_SELECTED;
}
export function getDBPMaster() {
  return Constants.DBP_MASTER;
}
export function getPPOMaster() {
  return Constants.PPO_MASTER;
}

export function getCoverOptionMaster() {
  return Constants.COVER_OPTION_MASTER;
}
export function getConpareData() {
  return Constants.COMPARE_DATA;
}
export function getMaxPolicyAge() {
  return Constants.MAX_POLICY_AGE;
}
export function getAddOnData() {
  return Constants.COMPARE_ADD_ON_DATA;
}
export function getP1C2PSDbp() {
  return Constants.P1C2PS_DBP1;
}
export function getPlanData() {
  return Constants.PLAN_ID_DATA;
}
export function getTermsCondition() {
  return Constants.TERMS_CONDITION;
}
export function getRider() {
  return Constants.RIDER_MASTER;
}
export function getPayModeList() {
  return Constants.PAY_MODE_LIST;
}
export function getAddOnsList() {
  return Constants.ADDONS_MASTER;
}
export function getLogoutURL() {
  return Constants.LOGOUT_URL_REDIRECTION;
}
export function getABSLIMaster() {
  return Constants.ABSLI_MASTER;
}
export function getPlanMaster() {
  return Constants.PLAN_OPTIONS;
}
export function getRiderSumAssured() {
  return Constants.RIDER_SUM_ASSURED;
}
export function getDistributorInfo() {
  return Constants.DISTRIBUTOR_INFO;
}


