<template>
  <div>
    <innerheader />
    <login />
  </div>
</template>

<script>
import innerheader from "@/components/innerheader/innerheader.vue";
import login from "@/components/welcome-login/welcome-login.vue";

export default {
  name: 'loginpage',
  components: {
     innerheader,
     login
  }
}
</script>

<style scoped lang="scss">
</style>