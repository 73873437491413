<template>
  <div  class="content1">
        <div v-if="this.distributerID =='PINC'  || 'HDFCSECL' ">
        <div>
          <li v-if="this.distributorInfo[this.distributorID] && this.distributorInfo[this.distributorID]['distributorName'] !=''">{{this.distributorInfo[this.distributorID]['distributorName']}} </li>
       </div>
        <div class="name"> 
        <li v-if="this.distributorInfo[this.distributorID] && this.distributorInfo[this.distributorID]['CIN'] !=''"> CIN: {{this.distributorInfo[this.distributorID] ['CIN']}}</li> 
        <li v-if="this.distributorInfo[this.distributorID] &&this.distributorInfo[this.distributorID]['License category'] !=''">License category :{{this.distributorInfo[this.distributorID]['License category']}} </li>  
        <li v-if="this.distributorInfo[this.distributorID] &&this.distributorInfo[this.distributorID]['IRDAI License Number'] != ''">IRDAI License Number :{{this.distributorInfo[this.distributorID]['IRDAI License Number']}} </li>
        <li v-if="this.distributorInfo[this.distributorID] &&this.distributorInfo[this.distributorID] ['IRDAI Registration Code No'] !=''">IRDAI Registration Code No : {{this.distributorInfo[this.distributorID] ['IRDAI Registration Code No']}}</li> 
        <li v-if="this.distributorInfo[this.distributorID] && this.distributorInfo[this.distributorID] ['Valid Till'] != ''">Valid Till :{{this.distributorInfo[this.distributorID] ['Valid Till']}}</li> 
       <li v-if="this.distributorInfo[this.distributorID] &&this.distributorInfo[this.distributorID]['Email ID'] !=''">Email Id: {{this.distributorInfo[this.distributorID]['Email ID']}}</li> 
         </div>
        <div class="name">
        <li  v-if="this.distributorInfo[this.distributorID] && this.distributorInfo[this.distributorID]['Registered Office'] !=''">Registered Office :{{this.distributorInfo[this.distributorID]['Registered Office']}} </li> 
        <li  v-if="this.distributorInfo[this.distributorID] && this.distributorInfo[this.distributorID]['Tel no'] !=''">Tel No :{{this.distributorInfo[this.distributorID]['Tel no']}} </li> 
       </div>
    
  </div> 
  </div>
</template>

<script>

import {  getDistributorInfo, getDistributer} from '@/mixins/common.js';
export default {
  name: "complianceText",
  props: {
  },
  mixins:[getDistributorInfo, getDistributer],
  mounted() {
    this.distributorInfo = getDistributorInfo()
    this.distributorID = getDistributer()['distributerID'];
    // console.log("aaa", this.distributorInfo,this.distributorID,this.distributorInfo[this.distributorID]['distributorName'])
  },
  data() {
      return {
    distributorInfo :"",
      distributorID : "",
      }
  }
};
</script>

<style scoped lang="scss">
@import '~@/assets/scss/components/complianceText.scss';
</style>