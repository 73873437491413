<template>
  <div class="loading_block_outer" v-if="loading">
    <ProposalLoading />
  </div>
  <div class="inner-page-wrapper" v-else>
    <div class="inner-page-bg">
      <div class="checkout-container">
        <div>
          <router-link to="/checkout" class="back-to-link">
            <img src="~@/assets/images/back-arrow.png" alt="" />
            Back to Policy Review
          </router-link>
         
          <div class="product-name-section" v-if="selectedPlan">
            <div>  <span class="d-inline-block align-middle brand-img">
              <img :src="
                getInsurerImgs(
                  selectedPlan['insuranceAndProducts']['insuranceCompanyCode']
                )
              " alt="" />
            </span> &nbsp;
            <span class="d-inline-block align-middle brand-text">
              {{ selectedPlan.insuranceAndProducts.productName }}
            </span>
          </div>
           
          <div>
            <div v-if="this.applicationNo != '' && this.applicationNo != null " class="appno">Insurer Application Number : {{this.applicationNo}}</div>
            <div v-if="this.uiQuoteId != '' && this.uiQuoteId != null " class="propno">Reference Number : {{this.uiQuoteId}}</div>
          </div>
        </div>
        </div>

        <ul class="nav nav-tabs progressBar" id="myTab" role="tablist">
          <template v-for="(value, ind) in responseData" :key="ind" >
          <div :class="checkVisibility(value.visibility)?'visble':''" v-if="!value.visibility ? true : checkVisibility(value.visibility)">
            <li :class="'nav-item ' + activePage(ind)" :id="'tablink_' + ind" 
              v-if="!value.visibility ? true : checkVisibility(value.visibility)">
              <a :class="'nav-link ' + activePage(ind)" :id="'tablinka_' + ind" data-toggle="tab" @click="switchTab(value,ind)"
                :href="'#proposalId_' + ind" role="tab" :aria-controls="ind" aria-selected="true">
                <!-- {{ convertSentenceCase(value["name"]) }} -->
                {{ value["label"] }}
              </a>
            </li>
          </div>
          </template>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div v-for="(value, ind) in responseData" :key="ind" :class="'tab-pane fade ' + isTabContentActive(ind)"
            :id="'proposalId_' + ind" role="tabpanel" aria-labelledby="home-tab">
             <div class="loading_block text-center" v-if="loadingPage">
        
        <img src="~@/assets/images/loading_gif.gif" alt="" />
       </div>
            <div v-else class="rounded application-section">
              <!-- <h4>Fill out Application ({{ convertSentenceCase(value["name"]) }})</h4> -->
              <h4>Fill out Application ({{ value["label"] }})</h4>
              <span>You are 5 minutes away from securing your family's future</span>
              <form @submit.prevent="submitForm(ind)" :id="value.name" method="post">
                <div class="application-form">
                  <!-- if fields are available -->
                  <div class="">
                    <div class="form-fields">
                      <div class="row">
                        <template v-for="(value2, ind2) in value.fields" :key="ind2">

                          <div :class="getColSize(value2.type, ind, ind2)"
                            v-if="!value2.visibility ? true : checkVisibility(value2.visibility)">
                            <!-- Text Field -->
                            <div v-if="value2.type == 'text'">
                              <div class="reverse-col">
                                <input class="form-text"
                                  type="text"
                                  :id="value2.name"
                                  :name="getFieldName(value2)"
                                  :minlength="value2.min"
                                  :maxlength="value2.max"
                                  :autocomplete="value2.name ==='PAN' ? 'one-time-code' : 'false'"
                                  :disabled="formInfo.fieldGroups[ind].fields[ind2].mandatory == 2"
                                  v-model.trim="formInfo.fieldGroups[ind].fields[ind2].input"
                                  @keyup="getValidation($event, value2.name, value2.id, ind, ind2)"
                                  @focus="getValidation2($event, value2.name, value2.id, ind, ind2)"
                                  :ph="value2.label" 
                                  :title="getDescriptionText(value2.description)"/>
                                <!-- {{formInfo.fieldGroups[ind].fields[ind2].input}} -->
                                <label for="email" class="form-item-label">
                                  {{
                                    value2.label
                                  }} <text v-if="value2.mandatory == '1' || value2.mandatory == '2'" class="mandatory-mark">*</text>
                                </label>
                              </div>
                              <span class="error-msg" :id="'error_' + value2.id"></span>
                            </div>
                            <!-- Number Field -->
                            <div v-if="value2.type == 'number'">
                              <div class="reverse-col">
                                <input class="form-text"
                                  type="number"
                                  :id="value2.name"
                                  :name="getFieldName(value2)"
                                  :disabled="formInfo.fieldGroups[ind].fields[ind2].mandatory == 2"
                                  v-model="formInfo.fieldGroups[ind].fields[ind2].input"
                                  @keypress="getValidation($event, value2.name, value2.id)"
                                  :ph="value2.label" 
                                  :title="getDescriptionText(value2.description)"/>
                                <!-- {{formInfo.fieldGroups[ind].fields[ind2].input}} -->
                                <label for="email" class="form-item-label">
                                  {{
                                    value2.label
                                  }} <text v-if="value2.mandatory == '1'||value2.mandatory == '2'" class="mandatory-mark">*</text>
                                </label>
                              </div>
                              <span class="error-msg" :id="'error_' + value2.id"></span>
                            </div>
                            <!-- Currency Field -->
                            <div v-if="value2.type == 'currency'">
                              <div class="reverse-col">
                                <input class="form-text"
                                  type="text"
                                  :id="value2.name"
                                  :name="getFieldName(value2)"
                                  :disabled="formInfo.fieldGroups[ind].fields[ind2].mandatory == 2"
                                  v-model="formInfo.fieldGroups[ind].fields[ind2].input"
                                  @keypress="getValidation($event, value2.name, value2.id)"
                                  @keyup="formatCurrency(ind,ind2)"
                                  :ph="value2.label" 
                                  :title="getDescriptionText(value2.description)"/>
                                <!-- {{formInfo.fieldGroups[ind].fields[ind2].input}} -->
                                <label for="email" class="form-item-label">
                                  {{
                                    value2.label
                                  }} <text v-if="value2.mandatory == '1'||value2.mandatory == '2'" class="mandatory-mark">*</text>
                                </label>
                              </div>
                              <span class="error-msg" :id="'error_' + value2.id"></span>
                            </div>
                            <!-- Date Field -->
                            <div v-if="value2.type == 'date'">
                              <div class="reverse-col">
                                <input
                                  class="form-text"
                                  type="date"
                                  :id="value2.name"
                                  :name="getFieldName(value2)"
                                  :minlength="value2.min"
                                  :maxlength="value2.max"
                                   max="9999-12-31"
                                  :disabled="formInfo.fieldGroups[ind].fields[ind2].mandatory == 2"
                                  v-model="formInfo.fieldGroups[ind].fields[ind2].input"
                                  @keypress="getValidation($event, value2.name, value2.id)"
                                  :ph="value2.label" 
                                  :title="getDescriptionText(value2.description)"/>
                                <!-- {{formInfo.fieldGroups[ind].fields[ind2].input}} -->
                                <label for="email" class="form-item-label">
                                  {{
                                    value2.label
                                  }} <text v-if="value2.mandatory == '1'||value2.mandatory == '2'" class="mandatory-mark">*</text>
                                </label>
                              </div>
                              <span class="error-msg" :id="'error_' + value2.id"></span>
                            </div>
                            <!-- Radio Button Field -->
                            <div v-if="value2.type == 'boolean'" class="small-grid yn-radio-type">
                              <span class="radio-span-wrap">
                                <div for="email" class="form-item-label text-left">
                                  {{
                                    value2.label
                                  }} <text v-if="value2.mandatory == '1'||value2.mandatory == '2'" class="mandatory-mark">*</text>
                                </div>
                                <div class="d-flex radio-question">
                                  <div class="d-flex position-relative">
                                    <input class="checkbox-tools" type="radio" :name="getFieldName(value2)" v-model="formInfo.fieldGroups[ind].fields[ind2].input" v-bind:value="'1'"
                                    :disabled="formInfo.fieldGroups[ind].fields[ind2].mandatory == 2"
                                     @click="getValidation($event, value2.name, value2.id, ind, ind2)"
                                    :title="getDescriptionText(value2.description)"
                                    >
                                    <label class="for-checkbox-tools mr-2" :for="value2.name">
                                      Yes
                                    </label>
                                  </div>
                                  <div class="d-flex position-relative">
                                    <input class="checkbox-tools" type="radio" :name="getFieldName(value2)" v-model="formInfo.fieldGroups[ind].fields[ind2].input" v-bind:value="'0'"
                                    :disabled="formInfo.fieldGroups[ind].fields[ind2].mandatory == 2"
                                     @click="getValidation($event, value2.name, value2.id, ind, ind2)"
                                    :title="getDescriptionText(value2.description)"
                                    >
                                    <label class="for-checkbox-tools" :for="value2.name">
                                      No
                                    </label>
                                  </div>
                                </div>
                                <!-- {{formData.fieldGroups[ind].fields[ind2].input}}  -->
                                <span class="error-msg" :id="'error_' + value2.id"></span>
                              </span>
                            </div>
                            <!-- Single Select Field -->
                            <div v-if="value2.type == 'single-select'">
                              <div class="reverse-col">
                                <select :name="getFieldName(value2)" @change="getValidation($event, value2.name, value2.id)" :id="value2.name" :disabled="formInfo.fieldGroups[ind].fields[ind2].mandatory == 2" v-model="formInfo.fieldGroups[ind].fields[ind2].input" :title="getDescriptionText(value2.description)">
                                  <option value="">Select</option>
                                  <option :value="item.Value" v-for="(item, ind3) in sortDropDown(value2.value)"
                                    :key="ind3">
                                    {{ item.Text }}
                                  </option>
                                </select>
                                <!-- {{formInfo.fieldGroups[ind].fields[ind2].input}} -->
                                <label for="email" class="form-item-label">
                                  {{
                                    value2.label
                                  }} <text v-if="value2.mandatory == '1'||value2.mandatory == '2'" class="mandatory-mark">*</text>
                                </label>
                              </div>
                              <span class="error-msg" :id="'error_' + value2.id"></span>
                            </div>
                            <!-- Multi Select Field -->
                            <div v-if="value2.type == 'multi-select'">
                              <div class="reverse-col mltislct">
                                <multiselect
                                    v-model="formInfo.fieldGroups[ind].fields[ind2].input"
                                    mode="tags"
                                    :disabled="formInfo.fieldGroups[ind].fields[ind2].mandatory == 2"
                                    :createTag="true"
                                    :options="getMultiSelectValues(value2.value)"
                                    :title="getDescriptionText(value2.description)"
                                  >
                                </multiselect>
                                <!-- <select :name="getFieldName(value2)" @change="getValidation($event, value2.name, value2.id)" :id="value2.name" multiple v-model="formInfo.fieldGroups[ind].fields[ind2].input">
                                  <option value="">Select</option>
                                  <option
                                    :value="item.Value"
                                    v-for="(item, ind3) in sortDropDown(value2.value)"
                                    :key="ind3"
                                  >
                                    {{ item.Text }}
                                  </option>
                                </select> -->
                                <!-- {{formInfo.fieldGroups[ind].fields[ind2].input}} -->
                                <label for="email" class="form-item-label">
                                  {{
                                    value2.label
                                  }} <text v-if="value2.mandatory == '1'||value2.mandatory == '2'" class="mandatory-mark">*</text>
                                </label>
                              </div>
                              <span class="error-msg" :id="'error_' + value2.id"></span>
                            </div>
                            <!-- Static Field -->
                            <div v-if="value2.type == 'static'">
                              <div class="reverse-col">
                                <label for="email" class="form-item-label">
                                  {{
                                      value2.label
                                  }}
                                </label>
                              </div>
                            </div>
                            <!-- Checkbox -->
                            <div v-if="value2.type == 'checkbox'">
                              <div class="checkbox-block">
                                <input class="checkbox-effect checkbox-effect-1" type="checkbox" :id="value2.id" :name="value2.id" v-model="formInfo.fieldGroups[ind].fields[ind2].input" v-bind:value="'1'" :title="getDescriptionText(value2.description)"  @change="getValidation($event, value2.name, value2.id, ind, ind2)">
                                <label :for="value2.id">
                                  {{
                                    value2.label
                                  }} <text v-if="value2.mandatory == '1'|| value2.mandatory == '2'" class="mandatory-mark">*</text>
                                </label>
                              </div>
                              <span class="error-msg" :id="'error_' + value2.id"></span>
                            </div>
                            <!-- File Input Field -->
                              <div v-if="value2.type == 'file'">
                                <div class="doc-upload d-flex doc-upload-lable">
                                  <div class="upload-input">
                                    <label class="form-item-label"> {{ value2.label }}</label>
                                    <input type="file" class="file-input" @change="selectFiles($event, value2, ind, ind2)" :title="getDescriptionText(value2.description)">
                                  </div>
                                  <label>&nbsp;</label>

                                  <button type="button" class="btn btn-primary eyeicon-btn" data-toggle="modal" data-target="#imageModal" v-if="formInfo.fieldGroups[ind].fields[ind2].input" @click="previewFiles(formInfo.fieldGroups[ind].fields[ind2].input)">
                                    <img src="~@/assets/images/eye.png" alt="" />
                                  </button>
                                  <button type="button" class="btn btn-primary remicon-btn" v-if="formInfo.fieldGroups[ind].fields[ind2].input" @click="removeFile(value3.id, ind, ind2)">
                                    <img src="~@/assets/images/remove-25.png" alt="" /> 
                                  </button>
                                </div>
                                <span class="error-msg" :id="'error_' + value2.id"></span>
                              </div>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>

                  <!-- if fieldGroups are available -->
                  <template v-for="(value2, ind2) in value.fieldGroups" :key="ind2">
                    <fieldset v-if="!value2.visibility ? true : checkVisibility(value2.visibility)" :key="ind2">
                      <legend>{{ value2["label"] }}</legend>

                      <div class="form-fields">
                        <div class="row">
                          <template v-for="(value3, ind3) in value2.fields" :key="ind3">
                            <div :class="getColSize(value3.type, ind, ind2)"
                              v-if="!value3.visibility ? true : checkVisibility(value3.visibility)">
                              <div class="loading_block text-center" v-if="loadingPage">
                                <img src="~@/assets/images/loading_gif.gif" alt="" />
                              </div>
                              <div v-else>
                              <!-- Text Field -->
                              <div v-if="value3.type == 'text'">
                                <div class="reverse-col">
                                  <input
                                    class="form-text qwer"
                                    type="text"
                                    :id="value3.name"
                                    :name="getFieldName(value3)"
                                    :minlength="value3.min"
                                    :maxlength="value3.max"
                                    :autocomplete="value3.name ==='PAN' ? 'one-time-code' : 'false'"
                                    :disabled="formInfo.fieldGroups[ind].fieldGroups[ind2].fields[ind3].mandatory == 2"
                                    v-model.trim="formInfo.fieldGroups[ind].fieldGroups[ind2].fields[ind3].input"
                                    @keyup="getValidation($event, value3.name, value3.id, ind, ind2, ind3)"
                                    @focus="getValidation2($event, value3.name, value3.id, ind, ind2, ind3)"
                                    :ph="value3.label" 
                                    :title="getDescriptionText(value3.description)"/>

                                  <label for="email" class="form-item-label">
                                    {{
                                      value3.label
                                    }}<text v-if="value3.mandatory == '1'||value3.mandatory == '2'" class="mandatory-mark">*</text>
                                  </label>
                                </div>
                                <span class="error-msg" :id="'error_' + value3.id"></span>
                              </div>
                              <!-- Number Field -->
                              <div v-if="value3.type == 'number'">
                                <div class="reverse-col">
                                <input
                                  class="form-text"
                                  type="number"
                                  :id="value3.name"
                                  :name="getFieldName(value3)"
                                  :disabled="formInfo.fieldGroups[ind].fieldGroups[ind2].fields[ind3].mandatory == 2"
                                  v-model="formInfo.fieldGroups[ind].fieldGroups[ind2].fields[ind3].input"
                                  @keyup="getValidation($event, value3.name, value3.id, ind, ind2, ind3)"
                                  @keypress="getValidation($event, value3.name, value3.id)"
                                  :ph="value3.label"
                                  :title="getDescriptionText(value3.description)" 
                                />
                              
                                <label for="email" class="form-item-label">
                                  {{
                                    value3.label
                                  }} <text v-if="value3.mandatory == '1'||value3.mandatory == '2'" class="mandatory-mark">*</text>
                                </label>
                                </div>
                                <span class="error-msg" :id="'error_' + value3.id"></span>
                              </div>
                              <!-- Currency Field -->
                              <div v-if="value3.type == 'currency'">
                                <div class="reverse-col">
                                <input
                                  class="form-text"
                                  type="text"
                                  :id="value3.name"
                                  :name="getFieldName(value3)"
                                  :disabled="formInfo.fieldGroups[ind].fieldGroups[ind2].fields[ind3].mandatory == 2"
                                  v-model="formInfo.fieldGroups[ind].fieldGroups[ind2].fields[ind3].input"
                                  @keypress="getValidation($event, value3.name, value3.id)"
                                 
                                  @keyup="formatCurrency(ind,ind2,ind3)"
                                  :ph="value3.label"
                                  :title="getDescriptionText(value3.description)" 
                                />
                              
                                <label for="email" class="form-item-label">
                                  {{
                                    value3.label
                                  }} <text v-if="value3.mandatory == '1'||value3.mandatory == '2'" class="mandatory-mark">*</text>
                                </label>
                                </div>
                                <span class="error-msg" :id="'error_' + value3.id"></span>
                              </div>
                              <!-- Date Field -->
                              <div v-if="value3.type == 'date'">
                                <div class="reverse-col">
                                <input
                                  class="form-text"
                                  type="date"
                                  :id="value3.name"
                                  :name="getFieldName(value3)"
                                  :minlength="value3.min"
                                  :maxlength="value3.max"
                                   max="9999-12-31"
                                  :disabled="formInfo.fieldGroups[ind].fieldGroups[ind2].fields[ind3].mandatory == 2"
                                  v-model="formInfo.fieldGroups[ind].fieldGroups[ind2].fields[ind3].input"
                                  @keypress="getValidation($event, value3.name, value3.id)"
                                  :ph="value3.label"
                                  :title="getDescriptionText(value3.description)"
                                />
                              
                                <label for="email" class="form-item-label">
                                  {{
                                    value3.label
                                  }}
                                  <text v-if="value3.mandatory == '1'||value3.mandatory == '2'" class="mandatory-mark">*</text>
                                </label>
                                </div>
                                <span class="error-msg" :id="'error_' + value3.id"></span>
                              </div>
                              <!-- Radio Button Field -->
                              <template v-if="value3.type == 'boolean'">
                                <div v-if="value3.type == 'boolean'" class="small-grid yn-radio-type">
                                  <span v-if="!value3.visibility ? true : checkVisibility(value3.visibility)"
                                    class="radio-span-wrap">
                                    <div for="email" class="form-item-label text-left mr-2">
                                      {{
                                          value3.label
                                        }} <text v-if="value3.mandatory == '1'||value3.mandatory == '2'" class="mandatory-mark">*</text>
                                      </div>
      
                                        <div class="d-flex radio-question">
                                          <div class="d-flex position-relative">
                                            <input class="checkbox-tools" type="radio" :name="getFieldName(value3)" v-bind:value="'1'"
                                              :disabled="formInfo.fieldGroups[ind].fieldGroups[ind2].fields[ind3].mandatory == 2"
                                              v-model="formInfo.fieldGroups[ind].fieldGroups[ind2].fields[ind3].input"
                                              :title="getDescriptionText(value3.description)"
                                               @click="getValidation($event, value3.name, value3.id, ind, ind2, ind3)"
                                              >
                                            <label class="for-checkbox-tools mr-2" :for="value3.name">
                                              Yes
                                            </label>
                                          </div>
                                          <div class="d-flex position-relative">
                                            <input class="checkbox-tools" type="radio" :name="getFieldName(value3)" v-bind:value="'0'"
                                              :disabled="formInfo.fieldGroups[ind].fieldGroups[ind2].fields[ind3].mandatory == 2"
                                              v-model="formInfo.fieldGroups[ind].fieldGroups[ind2].fields[ind3].input" 
                                              :title="getDescriptionText(value3.description)"
                                               @click="getValidation($event, value3.name, value3.id, ind, ind2, ind3)"
                                              >
                                            <label class="for-checkbox-tools" :for="value3.name">
                                              No
                                            </label>
                                          </div>
                                        </div>
      
                                     <!-- <div class="d-flex">  
                                        <div class="radio">
                                          <label class=" mr-2 radio-label" :for="value3.name">
                                            <input class="" type="radio" :name="getFieldName(value3)" v-bind:value="'1'" v-model="formInfo.fieldGroups[ind].fieldGroups[ind2].fields[ind3].input">
                                              Yes
                                          </label>
                                        </div>
                                        <div class="radio">
                                          <label class="radio-label" :for="value3.name">
                                            <input class="" type="radio" :name="getFieldName(value3)" v-bind:value="'0'" v-model="formInfo.fieldGroups[ind].fieldGroups[ind2].fields[ind3].input">
                                              No
                                          </label>
                                        </div>
                                      </div> -->





                                    <!-- {{formInfo.fieldGroups[ind].fieldGroups[ind2].fields[ind3].input}} -->
                                    <span class="error-msg" :id="'error_' + value3.id"></span>
                                  </span>
                                </div>
                              </template>

                              <!-- Single Select Field -->
                              <div v-if="value3.type == 'single-select'">
                                <div class="reverse-col">
                                  <select :name="getFieldName(value3)" @change="getValidation($event, value3.name, value3.id,ind,ind2,ind3)"
                                    :id="value3.name"
                                    v-model="formInfo.fieldGroups[ind].fieldGroups[ind2].fields[ind3].input"
                                    :title="getDescriptionText(value3.description)"
                                    :disabled="formInfo.fieldGroups[ind].fieldGroups[ind2].fields[ind3].mandatory == 2"
                                    >
                                    <option value="">Select</option>
                                    <option v-for="(item, ind4) in sortDropDown(value3.value)" :value="item.Value"
                                      :key="ind4">
                                      {{ item.Text }}
                                    </option>
                                  </select>

                                  <label for="email" class="form-item-label">
                                    {{
                                      value3.label
                                    }}<text v-if="value3.mandatory == '1'||value3.mandatory == '2'" class="mandatory-mark">*</text>
                                  </label>
                                </div>
                                <span class="error-msg" :id="'error_' + value3.id"></span>
                              </div>
                              <!-- Multi Select Field -->
                              <div v-if="value3.type == 'multi-select'">
                                <div class="reverse-col">
                                  <multiselect
                                    v-model="formInfo.fieldGroups[ind].fieldGroups[ind2].fields[ind3].input"
                                    mode="tags"
                                    :disabled="formInfo.fieldGroups[ind].fieldGroups[ind2].fields[ind3].mandatory == 2"
                                    :createTag="true"
                                    :options="getMultiSelectValues(value3.value)"
                                    :title="getDescriptionText(value3.description)"
                                   
                                  >
                                  </multiselect>

                                  <!-- <select :name="getFieldName(value3)" @change="getValidation($event, value3.name, value3.id)" :id="value3.name" multiple v-model="formInfo.fieldGroups[ind].fieldGroups[ind2].fields[ind3].input">
                                    <option value="">Select</option>
                                    <option
                                      :value="item.Value"
                                      v-for="(item, ind4) in sortDropDown(value3.value)"
                                      :key="ind4"
                                    >
                                      {{ item.Text }}
                                    </option>
                                  </select> -->

                                  <label for="email" class="form-item-label">
                                    {{
                                      value3.label
                                    }}<text v-if="value3.mandatory == '1'|| value3.mandatory == '2'" class="mandatory-mark">*</text>
                                  </label>
                                </div>
                                <span class="error-msg" :id="'error_' + value3.id"></span>
                              </div>
                              <!-- Static Field -->
                              <div v-if="value3.type == 'static'">
                                <div class="reverse-col">
                                  <label for="email" class="form-item-label">
                                    {{
                                        value3.label
                                    }}
                                  </label>
                                </div>
                              </div>
                              <!-- Checkbox -->
                              <div v-if="value3.type == 'checkbox'">
                                <div class="checkbox-block">
                                  <input class="checkbox-effect checkbox-effect-1" type="checkbox" @change="getValidation($event, value3.name, value3.id, ind, ind2, ind3)" :id="value3.id" :name="value3.id" v-model="formInfo.fieldGroups[ind].fieldGroups[ind2].fields[ind3].input" v-bind:value="'1'">
                                  <label :for="value3.id">
                                    {{
                                      value3.label
                                    }} <text v-if="value3.mandatory == '1'|| value3.mandatory == '2'" class="mandatory-mark">*</text>
                                  </label>
                                </div>
                                <span class="error-msg" :id="'error_' + value3.id"></span>
                              </div>
                              <!-- File Input Field -->
                              <div v-if="value3.type == 'file'">
                                <div class="doc-upload d-flex doc-upload-lable">
                                  <div class="upload-input">
                                    <label class="form-item-label"> {{ value3.label }} <text v-if="value3.mandatory == '1'|| value3.mandatory == '2'" class="mandatory-mark">*</text></label>
                                    <input type="file" :id="value3.id" class="file-input" @change="selectFiles($event, value3, ind, ind2, ind3)" :title="getDescriptionText(value3.description)">
                                  </div>
                                  <label>&nbsp;</label>

                                  <button type="button" class="btn btn-primary eyeicon-btn" data-toggle="modal" data-target="#imageModal" v-if="formInfo.fieldGroups[ind].fieldGroups[ind2].fields[ind3].input" @click="previewFiles(formInfo.fieldGroups[ind].fieldGroups[ind2].fields[ind3].input)">
                                    <img src="~@/assets/images/eye.png" alt="" />
                                  </button>
                                  <button type="button" class="btn btn-primary remicon-btn" v-if="formInfo.fieldGroups[ind].fieldGroups[ind2].fields[ind3].input" @click="removeFile(value3.id, ind, ind2, ind3)">
                                    <img src="~@/assets/images/remove-25.png" alt="" /> 
                                  </button>
                                </div>
                                <span class="error-msg" :id="'error_' + value3.id"></span>
                              </div>
                            </div>
                            </div>
                          </template>
                        </div>
                      </div>

                      <!-- if fieldGroups.fieldGroups are available -->
                      <template v-for="(value3, ind3) in value2.fieldGroups" :key="ind3">
                        <fieldset v-if="!value3.visibility ? true : checkVisibility(value3.visibility)" :key="ind3">
                          <legend>{{ value3["label"] }}</legend>


                          <!-- 33333 -->
                          <div class="form-fields">
                            <div class="row">
                              <template v-for="(value4, ind4) in value3.fields" :key="ind4">
                                <div :class="getColSize(value4.type, ind, ind2)"
                                  v-if="!value4.visibility ? true : checkVisibility(value4.visibility)">

                                  <!-- Text Field -->
                                  <div v-if="value4.type == 'text'">
                                    <div class="reverse-col">
                                      <input
                                        class="form-text qwer"
                                        type="text"
                                        :id="value4.name"
                                        :name="getFieldName(value4)"
                                        :minlength="value4.min"
                                        :maxlength="value4.max"
                                        :autocomplete="value4.name ==='PAN' ? 'one-time-code' : 'false'"
                                        :disabled="formInfo.fieldGroups[ind].fieldGroups[ind2].fieldGroups[ind3].fields[ind4].mandatory == 2"
                                        v-model.trim="formInfo.fieldGroups[ind].fieldGroups[ind2].fieldGroups[ind3].fields[ind4].input"
                                        @keyup="getValidation($event, value4.name, value4.id, ind, ind2, ind3)"
                                        @focus="getValidation2($event, value4.name, value4.id, ind, ind2, ind3)"
                                        :ph="value4.label" 
                                        :title="getDescriptionText(value4.description)"/>
                                      <!-- {{formInfo.fieldGroups[ind].fieldGroups[ind2].fields[ind3].input}}  -->

                                      <label for="email" class="form-item-label">
                                        {{
                                          value4.label
                                        }} <text v-if="value4.mandatory == '1'||value4.mandatory == '2'" class="mandatory-mark">*</text>
                                      </label>
                                    </div>
                                    <span class="error-msg" :id="'error_' + value4.id"></span>
                                  </div>
                                  <!-- Number Field -->
                                  <div v-if="value4.type == 'number'">
                                    <div class="reverse-col">
                                    <input
                                      class="form-text"
                                      type="number"
                                      :id="value4.name"
                                      :name="getFieldName(value4)"
                                      :disabled="formInfo.fieldGroups[ind].fieldGroups[ind2].fieldGroups[ind3].fields[ind4].mandatory == 2"
                                      v-model="formInfo.fieldGroups[ind].fieldGroups[ind2].fieldGroups[ind3].fields[ind4].input"
                                      @keypress="getValidation($event, value4.name, value4.id)"
                                      :ph="value4.label"
                                      :title="getDescriptionText(value4.description)"
                                    />
                                    <label for="email" class="form-item-label">
                                      {{
                                        value4.label
                                      }} <text v-if="value4.mandatory == '1'||value4.mandatory == '2'" class="mandatory-mark">*</text>
                                    </label>
                                    </div>
                                    <span class="error-msg" :id="'error_' + value4.id"></span>
                                  </div>
                                  <!-- Currency Field -->
                                  <div v-if="value4.type == 'currency'">
                                    <div class="reverse-col">
                                    <input
                                      class="form-text"
                                      type="text"
                                      :id="value4.name"
                                      :name="getFieldName(value4)"
                                      :disabled="formInfo.fieldGroups[ind].fieldGroups[ind2].fieldGroups[ind3].fields[ind4].mandatory == 2"
                                      v-model="formInfo.fieldGroups[ind].fieldGroups[ind2].fieldGroups[ind3].fields[ind4].input"
                                      @keypress="getValidation($event, value4.name, value4.id)"
                                     
                                      @keyup="formatCurrency(ind,ind2,ind3,ind4)"
                                      :ph="value4.label"
                                      :title="getDescriptionText(value4.description)"
                                    />
                                    <label for="email" class="form-item-label">
                                      {{
                                        value4.label
                                      }} <text v-if="value4.mandatory == '1'||value4.mandatory == '2'" class="mandatory-mark">*</text>
                                    </label>
                                    </div>
                                    <span class="error-msg" :id="'error_' + value4.id"></span>
                                  </div>
                                  <!-- Date Field -->
                                  <div v-if="value4.type == 'date'">
                                    <div class="reverse-col">
                                    <input
                                      class="form-text"
                                      type="date"
                                      :id="value4.name"
                                      :name="getFieldName(value4)"
                                      :minlength="value4.min"
                                      :maxlength="value4.max"
                                      max="9999-12-31"
                                      :disabled="formInfo.fieldGroups[ind].fieldGroups[ind2].fieldGroups[ind3].fields[ind4].mandatory == 2"
                                      v-model="formInfo.fieldGroups[ind].fieldGroups[ind2].fieldGroups[ind3].fields[ind4].input"
                                      @keypress="getValidation($event, value4.name, value4.id)"
                                      :ph="value4.label"
                                      :title="getDescriptionText(value4.description)"
                                    />
                                    <label for="email" class="form-item-label">
                                      {{
                                        value4.label
                                      }}
                                      <text v-if="value4.mandatory == '1'||value4.mandatory == '2'" class="mandatory-mark">*</text>
                                    </label>
                                    </div>
                                    <span class="error-msg" :id="'error_' + value4.id"></span>
                                  </div>
                                  <!-- Radio Button Field -->
                                  <template v-if="value4.type == 'boolean'">
                                    <div v-if="value4.type == 'boolean'" class="small-grid yn-radio-type">
                                      <span v-if="!value4.visibility ? true : checkVisibility(value4.visibility)"
                                        class="radio-span-wrap">
                                        <div for="email" class="form-item-label text-left mr-2">
                                          {{
                                              value4.label
                                            }} <text v-if="value4.mandatory == '1'||value4.mandatory == '2'" class="mandatory-mark">*</text>
                                          </div>
          
                                            <div class="d-flex radio-question">
                                              <div class="d-flex position-relative">
                                                <input class="checkbox-tools" type="radio" :name="getFieldName(value4)" v-bind:value="'1'"
                                                   v-model="formInfo.fieldGroups[ind].fieldGroups[ind2].fieldGroups[ind3].fields[ind4].input"
                                                   :disabled="formInfo.fieldGroups[ind].fieldGroups[ind2].fieldGroups[ind3].fields[ind4].mandatory == 2"
                                                   :title="getDescriptionText(value4.description)"
                                                   @click="getValidation($event, value4.name, value4.id, ind, ind2, ind3)"
                                                  >
                                                <label class="for-checkbox-tools mr-2" :for="value4.name">
                                                  Yes
                                                </label>
                                              </div>
                                              <div class="d-flex position-relative">
                                                <input class="checkbox-tools" type="radio" :name="getFieldName(value4)" v-bind:value="'0'"
                                                  v-model="formInfo.fieldGroups[ind].fieldGroups[ind2].fieldGroups[ind3].fields[ind4].input"
                                                   :disabled="formInfo.fieldGroups[ind].fieldGroups[ind2].fieldGroups[ind3].fields[ind4].mandatory == 2"
                                                   :title="getDescriptionText(value4.description)"
                                                   @click="getValidation($event, value4.name, value4.id, ind, ind2, ind3)"
                                                  >
                                                <label class="for-checkbox-tools" :for="value4.name">
                                                  No
                                                </label>
                                              </div>
                                            </div> 
                                          <span class="error-msg" :id="'error_' + value4.id"></span>
                                        </span>
                                      </div>
                                  </template>

                                  <!-- Single Select Field -->
                                  <div v-if="value4.type == 'single-select'">
                                    <div class="reverse-col">
                                      <select :name="getFieldName(value4)" @change="getValidation($event, value4.name, value4.id)" :id="value4.name" :disabled="formInfo.fieldGroups[ind].fieldGroups[ind2].fieldGroups[ind3].fields[ind4].mandatory == 2" v-model="formInfo.fieldGroups[ind].fieldGroups[ind2].fieldGroups[ind3].fields[ind4].input" :title="getDescriptionText(value4.description)">
                                        <option value="">Select</option>
                                        <option v-for="(item, ind5) in sortDropDown(value4.value)" :value="item.Value"
                                          :key="ind5">
                                          {{ item.Text }}
                                        </option>
                                      </select>
                                      <label for="email" class="form-item-label">
                                        {{
                                          value4.label
                                        }}<text v-if="value4.mandatory == '1'||value4.mandatory == '2'" class="mandatory-mark">*</text>
                                      </label>
                                    </div>
                                    <span class="error-msg" :id="'error_' + value4.id"></span>
                                  </div>
                                  <!-- Multi Select Field -->
                                  <div v-if="value4.type == 'multi-select'">
                                    <div class="reverse-col">
                                      <multiselect 
                                        :disabled="formInfo.fieldGroups[ind].fieldGroups[ind2].fieldGroups[ind3].fields[ind4].mandatory == 2 "
                                        v-model="formInfo.fieldGroups[ind].fieldGroups[ind2].fieldGroups[ind3].fields[ind4].input"
                                        mode="tags" :createTag="true" :options="getMultiSelectValues(value4.value)"
                                        :title="getDescriptionText(value4.description)">
                                        
                                      </multiselect>
                                      <label for="email" class="form-item-label">
                                        {{
                                          value4.label
                                        }}<text v-if="value4.mandatory == '1'||value4.mandatory == '2'" class="mandatory-mark">*</text>
                                      </label>
                                    </div>
                                    <span class="error-msg" :id="'error_' + value4.id"></span>
                                  </div>
                                  <!-- Static Field -->
                                  <div v-if="value4.type == 'static'">
                                    <div class="reverse-col">
                                      <label for="email" class="form-item-label">
                                        {{
                                            value4.label
                                        }}
                                      </label>
                                    </div>
                                  </div>
                                  <!-- Checkbox -->
                                  <div v-if="value4.type == 'checkbox'">
                                    <div class="checkbox-block">
                                      <input class="checkbox-effect checkbox-effect-1" type="checkbox" :id="value4.id" :name="value4.id" v-model="formInfo.fieldGroups[ind].fieldGroups[ind2].fieldGroups[ind3].fields[ind4].input" v-bind:value="'1'" :title="getDescriptionText(value4.description)" @change="getValidation($event, value4.name, value4.id, ind, ind2, ind3)">
                                      <label :for="value4.id">
                                        {{
                                          value4.label
                                        }} <text v-if="value4.mandatory == '1'|| value4.mandatory == '2'" class="mandatory-mark">*</text>
                                      </label>
                                    </div>
                                    <span class="error-msg" :id="'error_' + value4.id"></span>
                                  </div>
                                  <!-- File Input Field -->
                                  <div v-if="value4.type == 'file'">
                                    <div class="doc-upload d-flex doc-upload-lable">
                                      <div class="upload-input">
                                        <label class="form-item-label"> {{ value4.label }} <text v-if="value4.mandatory == '1'|| value4.mandatory == '2'" class="mandatory-mark">*</text></label>
                                        <input type="file" :id="value4.id" class="file-input" @change="selectFiles($event, value4, ind, ind2, ind3, ind4)" :title="getDescriptionText(value4.description)">
                                      </div>
                                      <label>&nbsp;</label>

                                      <button type="button" class="btn btn-primary eyeicon-btn" data-toggle="modal" data-target="#imageModal" v-if="formInfo.fieldGroups[ind].fieldGroups[ind2].fieldGroups[ind3].fields[ind4].input" @click="previewFiles(formInfo.fieldGroups[ind].fieldGroups[ind2].fieldGroups[ind3].fields[ind4].input)">
                                        <img src="~@/assets/images/eye.png" alt="" />
                                      </button>

                                      <button type="button" class="btn btn-primary remicon-btn" v-if="formInfo.fieldGroups[ind].fieldGroups[ind2].fieldGroups[ind3].fields[ind4].input" @click="removeFile(value4.id, ind, ind2, ind3, ind4)">
                                        <img src="~@/assets/images/remove-25.png" alt="" /> 
                                      </button>
                                    </div>
                                    <span class="error-msg" :id="'error_' + value4.id"></span>
                                  </div>
                                </div>
                              </template>
                            </div>
                          </div>
                          <!-- 33333 -->

                        </fieldset>

                      </template>

                    </fieldset>
                  </template>



                     <div class="button wrap d-flex justify-content-center">
                    <!-- <button type="button" class="next-btn mb-4" @click="goBack()" v-if="currentTab != 'proposerDetails'"
                      :class="currentTab != 'proposerDetails' ? completed : active">
                      <i><img src="~@/assets/images/previouse-arrow.png" /></i> Back
                      
                    </button>&nbsp;&nbsp;
                    <button type="submit" class="next-btn mb-4">
                      Continue <i><img src="~@/assets/images/next-arrow.png" /></i>
                    </button> -->
                  </div>

                  <hr>
        <complianceText/>
                   <div class=" d-flex justify-content-center">
                   <div
                      class="
                        footer-total-premium
                        d-flex
                        justify-content-between
                        align-items-center
                      "
                    >
                      <div class="total-premium">
                       
                        <b>Total Premium: </b>
                        &#8377;
                        <strong>{{
                          this.currency(this.selectedPlan.totalPremiumDetails[0].totalPremiumWithTax)
                        }}</strong
                        >/{{
                          getPayMode(selectedPlan.individualDetails[0].premiumDetails[0].mode)
                        }}
                        <span class="feature-name">
                           <img src="~@/assets/images/info-button.png" alt=""/> 
                          <div class="tooltip-sec">
                            <table>
                              <tr>
                                <td>  
                                  Premium:
                                </td>
                                <td>₹ 
                                  {{
                                  this.currency(this.selectedPlan.totalPremiumDetails[0].totalPremiumValue)
                                  }}
                                </td>
                              </tr>
                                <tr>
                                <td class="text-left">  
                                  Taxes:
                                </td>
                                <td>₹  
                                  {{this.currency(this.selectedPlan.totalPremiumDetails[0].totalTax)}}
                                </td>
                              </tr>
                            </table>
                          </div>
                        </span>
                        
                        <span class="premium-tax">Premium (Including Taxes)</span>
                      </div>
                    
                      <div class="bttn-grp d-flex">
                        
                     <button type="button" class="next-btn mb-4" @click="goBack()" v-if="currentTab != 'proposerDetails'"
                      :class="currentTab != 'proposerDetails' ? completed : active">
                      <i><img src="~@/assets/images/previouse-arrow.png" /></i> Back
                      
                    </button>&nbsp;&nbsp;
                    <button type="submit" class="next-btn mb-4">
                      Continue <i><img src="~@/assets/images/next-arrow.png" /></i>
                    </button> 
                        
                  </div>
                </div>
              </div>
                 

  
                </div>
                
          
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

   

    <!-- Modal -->
    <div class="modal fade" id="imageModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Selected File</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                  <div v-if="!fileToDisplay" class="error_msg text-center doc_upload_block2">No Document Selected</div>
                  <div v-else>
                    <img v-if="typeToDisplay == 'image'" class="image1" :src="fileToDisplay">
                    <iframe v-else width="100%" height="400px" :src="fileToDisplay"></iframe>
                  </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
    <!-- ./End Modal -->

  </div>

</template>
<script>
// @ is an alias to /src
import Multiselect from '@vueform/multiselect'
// import Multiselect from 'vue-multiselect'

import $ from "jquery";
import axios from "axios";
import ProposalLoading from "@/components/proposal-loading.vue";
import complianceText from "@/components/footer/complianceText.vue";
import { getDistributer, getInsuranceImages } from '@/mixins/common';
export default {
  name: "proposal",
  components: {
    ProposalLoading,
    Multiselect,
    complianceText
  },
  mixins: [getDistributer, getInsuranceImages],
  mounted() {
    //this.$nextTick(() => this.$refs.search.showCurrent(command))
    this.b2c = sessionStorage.getItem("diy")
    if (sessionStorage.getItem('uiQuoteId')) {
      this.uiQuoteId = JSON.parse(sessionStorage.getItem('uiQuoteId'));
    }
    if(sessionStorage.getItem("applicationNumber")){
      this.applicationNo = JSON.parse(sessionStorage.getItem("applicationNumber"));
    }
    console.log(this.b2c)
    this.getProposal();
    let quoteResp = JSON.parse(sessionStorage.getItem("selectedPlanForCheckout"));
    this.selectedPlan=quoteResp;

    // this.includeAddOnsPremium();
    // this.includeRidersPremium();
    console.log("Selected Plan",this.selectedPlan)
    this.selectedPlanData = JSON.parse(sessionStorage.getItem("selected_plan_data"));
    let quoteReq = JSON.parse(sessionStorage.getItem("quote_request"));
    this.quoteInfo = {
      'quoteResponse':quoteResp,
      'quoteRequest':quoteReq
    }
    if(this.selectedPlanData["insuranceAndProducts"]["insuranceCompanyCode"] == "HDFC")
       {
       this.applicationNo=this.selectedPlanData["trackInfo"]["varFields"][0]["fieldValue"]
       console.log("applicatioNo" ,this.applicationNo);
       
       
       }
    
    // DP Related
    this.userProfile = JSON.parse(sessionStorage.getItem("user_profile"));
    console.log("userData",this.userProfile)
    this.rmData = JSON.parse(sessionStorage.getItem("rm_data"));
    this.spDetails = JSON.parse(sessionStorage.getItem("sp_details_data"));
    
    console.log('this.rmData: ', this.rmData);
    console.log('this.spDetails: ', this.spDetails);

    if (sessionStorage.getItem('proposal_ui_id')) {
      this.proposalUiId = JSON.parse(sessionStorage.getItem('proposal_ui_id'));
    }
    
    if (sessionStorage.getItem('filledDataTab')) {
      this.currentTab = JSON.parse(sessionStorage.getItem("filledDataTab"));
    }

    // Setting Distributer ID
    this.distributorID = getDistributer()["distributerID"];
    // Print form info
    //console.log('shailendra', this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['residentStatus']);

    // Getting gateCriteriaData
    let tempGateCriteriaData = JSON.parse(sessionStorage.getItem("gateCriteriaData"));
    if(tempGateCriteriaData) {
      this.gateCriteriaData = tempGateCriteriaData.fieldGroups.personalInformation.fields;
      console.log('this.gateCriteriaData: ', this.gateCriteriaData);
    }
    
  },
  data() {
    return {
      selectedPlanData:[],
      applicationNo:"",
      proposalJson:{},
      dialog: false,
      distributorID: "0",
      loading: false,
      loadingPage:false,
      apiError: false,
      tempDistributer: "",
      errorCounter: 0,
      response: "",
      resData: "",
      selectedPlan:{},
      quoteRequestID: "",
      selectedPlan: "",
      insurerCode: "",
      productCode: "",
      responseData: {},
      formInfo: {},
      quoteInfo: {},
      tabsArr: [],
      b2c:true,
      // DP related 
      proposalUiId: 0,
      uiQuoteId: "",
      userProfile: "",
      rmData: "",
      spDetails: "",
      submittedTab: "",
      currentTab: "",
      permanentAddressDetailsCheck: false,
      firstLifeAssuredDetailsCheck: false,
      selectedMult: null,
      value: null,
      options: [
        'Hungary',
        'USA',
        'China',
        'China2',
        'China3',
      ],
      typeToDisplay: "",
      fileToDisplay: "",
      gateCriteriaData: "",
    }
  },
  watch: {
    dialog(val) {
      if (!val) return
      requestAnimationFrame(() => {
        this.$refs.focus.focus()
      })
    }
  },
  methods: {

    // includeAddOnsPremium() {
    //   let addOnsAmt = 0;
    //   let addOnsAmtTotal = 0;
    //   let addOnsTax = 0;
    //   this.selectedPlan.productDetails.AddOnSection.addOnSelected.forEach(
    //     (element, index) => {
    //       if( element.premiumIncludedFlag == 'No') {
    //         addOnsAmt += element.premiumDetails[0].premiumValue;
    //         addOnsTax += element.premiumDetails[0].tax[0].taxValue;
    //         addOnsAmtTotal += element.premiumDetails[0].totalPremiumValue;
    //       }
    //     }
    //   );
    //   this.selectedPlan.individualDetails[0].premiumDetails[0].premiumValue += addOnsAmt;
    //   this.selectedPlan.individualDetails[0].premiumDetails[0].tax[0].taxValue += addOnsTax;
    //   this.selectedPlan.individualDetails[0].premiumDetails[0].totalPremiumValue += addOnsAmtTotal;
    // },
    // includeRidersPremium() {
    //   let riderAmt = 0;
    //   let riderAmtTotal = 0;
    //   let riderTax = 0;
    //   this.selectedPlan.individualDetails[0].rider.forEach(
    //     (element, index) => {
    //       riderAmt += element.premiumDetails[0].riderPremiumValue;
    //       riderTax += element.premiumDetails[0].tax[0].taxValue;
    //       riderAmtTotal += element.premiumDetails[0].totalRiderPremiumValue;
    //     }
    //   );
    //   this.selectedPlan.individualDetails[0].premiumDetails[0].premiumValue += riderAmt;
    //   this.selectedPlan.individualDetails[0].premiumDetails[0].tax[0].taxValue += riderTax;
    //   this.selectedPlan.individualDetails[0].premiumDetails[0].totalPremiumValue += riderAmtTotal;
    // },
    getPinCodeData(val)
    {
          
        if (val.length == 6) {
          let headers = {};
          let reqData = [val];
          
          this.backendApi("getPincodeInfo", reqData, headers, "get")
            .then((response) => {
           
              if (response == 'error') {
              
                console.log('pincode api error: block');


              } else if (response && response.data) {
               
                let v = response.data.data[0];
                
                
                this.formInfo.fieldGroups.proposerDetails.fieldGroups.communicationAddressDetails.fields.city.input=v.city
                this.formInfo.fieldGroups.proposerDetails.fieldGroups.communicationAddressDetails.fields.state.input=v.state
                if(this.insurerCode=="TATA" )
                {
                   this.formInfo.fieldGroups.proposerDetails.fieldGroups.communicationAddressDetails.fields.countryOfResidence.input=v.country.toLowerCase()
                }
                else if( this.insurerCode=="ABSLIFEI")
                {
                  this.formInfo.fieldGroups.proposerDetails.fieldGroups.communicationAddressDetails.fields.zipCode.input=val
                  this.formInfo.fieldGroups.proposerDetails.fieldGroups.communicationAddressDetails.fields.country.input=v.country
                }
                else if( this.insurerCode=="MAXLIFE")
                {
                  this.formInfo.fieldGroups.proposerDetails.fieldGroups.communicationAddressDetails.fields.zipCode.input=val
                  this.formInfo.fieldGroups.proposerDetails.fieldGroups.communicationAddressDetails.fields.country.input=v.country
                }
                else if(this.insurerCode=="HDFC" )
                {
                  
                console.log("country data",this.formInfo.fieldGroups.proposerDetails.fieldGroups.communicationAddressDetails.fields.country)
                this.formInfo.fieldGroups.proposerDetails.fieldGroups.communicationAddressDetails.fields.zipCode.input=val
                this.formInfo.fieldGroups.proposerDetails.fieldGroups.communicationAddressDetails.fields.country.input=v.country

                }
                else{
                this.formInfo.fieldGroups.proposerDetails.fieldGroups.communicationAddressDetails.fields.zipCode.input=val;
                this.formInfo.fieldGroups.proposerDetails.fieldGroups.communicationAddressDetails.fields.country.input=v.country;
                }
                
                console.log("country data",this.formInfo.fieldGroups.proposerDetails.fieldGroups.communicationAddressDetails.fields.country)



              }
            })
            .catch((error) => {
              console.log('error msg block getting pincode info', error);
              
            });
        }
      
    },
    something() {
      if (value3.label = true) {
        formInfo.fieldGroups[ind].fieldGroups[ind2].fields[ind3].description;
      }
      else {
      }
    },
    getPayMode(val) {
      if (val == "M") {
        return "Month";
      } else if (val == "Q") {
        return "Quarter";
      } else if (val == "HY") {
        return "Half Year";
      } else if (val == "Y") {
        return "Year";
      } else if (val == "S") {
        return "Single Pay";
      }
    },
    getGender(val) {
      if (val == "Male") {
        return "M";
      } else if (val == "Female") {
        return "F";
      } else if (val == "Transgender") {
        return "T";
      }
    },
     currency(amt) {
      return new Intl.NumberFormat().format(amt)
    },
    saveProposal(sectionIndex) {
      //console.log('save proposal called', this.formInfo);
      //console.log('save proposal called2', this.formInfo.fieldGroups);
      //console.log('save proposal called3', this.formInfo['fieldGroups']);
      let NRI= "";
      try {
        NRI = (this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.residentStatus.input == 'indianResident')?'No':'Yes';
      } catch (error) {}

      this.formInfo.fieldGroups.traceInfo.fields['1SBproposalRefId'].input = this.proposalUiId;

      // DP Section
      // console.log("this.proposalUiId.toString()",this.proposalUiId.toString())
      this.convertProposalJson();
      let propoalData = {

        "proposal_ui_id": this.proposalUiId.toString(),
        "ui_quote_id": this.uiQuoteId,
        // "submit_response_id": "",
        "user_id": this.rmData.UserId,
        //"rm_code": "A0000001",
        "name": this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.firstName.input,
        "mobile_number": this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.mobileNumber.input,
        "emailId": this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.email.input,
        "dob": this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.dateOfBirth.input,
        "gender": this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.gender.input,
        "zipcode": this.formInfo.fieldGroups.proposerDetails.fieldGroups.communicationAddressDetails.fields.zipCode.input,
        "city": this.formInfo.fieldGroups.proposerDetails.fieldGroups.communicationAddressDetails.fields.city.input,
        "state": this.formInfo.fieldGroups.proposerDetails.fieldGroups.communicationAddressDetails.fields.state.input,
        "NRI": NRI,
        "medical": "",
        "insurance_company_code": this.insurerCode,
        "product_code": this.productCode,
        "proposal_json": JSON.stringify(this.proposalJson["data"]),
        "journey_status": "Draft Proposal",
        "tag_name" : sectionIndex
      };
      //  this.loading = true;
      this.loadingPage=true;
      var headers = {
        "quoteId":this.uiQuoteId,
        Authorization: "Basic " + window.btoa(process.env.VUE_APP_DATA_PER_USERNPASS),
        "Content-Type": "application/json",
      };
      if(this.uiQuoteId=='' || propoalData.ui_quote_id=='')
      {
        let msgError='We are facing some issue while saving proposal data'
        let msg=['UiQuoteId should not be empty or null']
        this.$router.push({ path: '/networkalert', query: {backUrl:'proposal-page', msg: msg, msgError: msgError}});
      }
      

      //console.log('qData: ', qData);
      this.backendApi("saveProposal", propoalData, headers)
        .then((response) => {
          //console.log('save quote response: ', response);
          // this.loading = false;
          this.loadingPage=false;
          this.activeNextTab(sectionIndex);
          if(response && response.error && response.error.length>0)
          {
            let msg=[]
            let msgError='We are facing some issue while saving proposal data'
              for(let i=0;i<response.errors.length;i++)
              {
                  msg.push(response.errors[i]["errorMessage"])
              }
              this.$router.push({ path: '/networkalert', query: {backUrl:'proposal-page', msgError: msgError, msg: msg }});

          }
          else if(response.status=="error")
          {
            let msgError='We are facing some issue while saving proposal data'
            this.$router.push({ path: '/networkalert', query: {backUrl:'proposal-page', msgError: msgError, msg: msg }});
          }
           else if (response && response.data) {
            this.proposalUiId = response.data.data.proposal_ui_id;
            this.uiQuoteId = response.data.data.ui_quote_id;

            console.log('this.proposalUiId: ', this.proposalUiId);
            sessionStorage.setItem("proposal_ui_id", JSON.stringify(this.proposalUiId));

            // Saving Logs
            let logData = {
              "ui_quote_id": this.uiQuoteId,
              "quote_request_id": this.proposalUiId,
              "mobile_number": this.userProfile.mobile,
              "dob": this.userProfile.dob,
              "page_name": "proposal",
              "tag_name": this.submittedTab,
              "data": ""//JSON.stringify(this.formInfo)
            };
            console.log('logData: ', logData);
            //this.saveLogData(logData);

          }
        })
        .catch((error) => {
          console.log('error msg block dp', error);
          let msg=['We are facing some issue from backend while saving proposal data ']
          this.$router.push({ path: '/networkalert', query: {backUrl:'proposal-page', msg: msg }});
        });
    },
    saveLogData(logData) {
      console.log('save log data called', logData);

      var headers = {
        "quoteId":this.uiQuoteId,
        Authorization: "Basic " + window.btoa(process.env.VUE_APP_DATA_PER_USERNPASS),
        "Content-Type": "application/json",
      };
      //console.log('logData: ', logData);
      this.backendApi("saveLogData", logData, headers)
        .then((response) => {
          //console.log('save quote response: ', response);
          if (response == 'error') {
            //self.apiError = true;
          } else if (response && response.data) {
            console.log('log data saved');
          }
        })
        .catch((error) => {
          console.log('error msg block dp', error);
        });
    },

    // default when mounted
    defaultData(fieldGroups,formInfo,quoteInfo){
      console.log("default func called");
      for(let fieldGroupKey in fieldGroups ){
        let fieldGroup = fieldGroups[fieldGroupKey];
        if ('fields' in fieldGroup){
          for(let fieldKey in fieldGroup['fields']){
            let field = fieldGroup['fields'][fieldKey];
            let exp = field['default'];
            if(exp){
              // console.log(exp)
              try {
                eval(exp);
              }
              catch (err) {
                console.log('error default exp: ', exp);
                console.log('error-->'+err);
              }
            }

          }
        } 
        if ('fieldGroups' in fieldGroup){
          this.defaultData(fieldGroup['fieldGroups'],formInfo,quoteInfo);
        }
      }
    },


    getProposal(apiData) {
      var self = this;
      //console.log("get proposal called");
      // this.selectedPlan = JSON.parse(localStorage.getItem("selectedPlanForCheckout"));
      // this.quoteRequestID = JSON.parse(localStorage.getItem("quoteRequestID"));
      this.selectedPlan = JSON.parse(sessionStorage.getItem("selectedPlanForCheckout"));
      this.quoteRequestID = JSON.parse(sessionStorage.getItem("quoteRequestID"));
      this.insurerCode = this.selectedPlan.insuranceAndProducts.insuranceCompanyCode;
      this.productCode = this.selectedPlan.insuranceAndProducts.productCode;
      //let productApiUrl = this.insurerCode + '/' + this.productCode;
      //let reqData = JSON.stringify(apiData);
      var headers = {
        "quoteId":this.uiQuoteId,
        Authorization: "Basic " + window.btoa(process.env.VUE_APP_USERNPASS),
        "Content-Type": "application/json",
      };
      this.loading = true;
      //let version = JSON.parse(process.env.VUE_APP_PROPOSAL_VERSION);
      //console.log('version: ', version)
      let reqData = [this.productCode, this.insurerCode, 1];

      // console.log('req data: ', reqData);

      this.backendApi("getProposal", reqData, headers, "get")
        .then((response) => {
          console.log('success proposal form');
          self.loading = false;
          self.apiError = false;
          if (response == 'error') {
            self.apiError = true;
        //  this.$router.push({ path: '/alert', query: { status: 'Error',backUrl:"proposal-page", msg: 'We are facing some issue while fetching proposal details' }});

          }
          else if(response.data.errors.length>0) 
          {
            
            let msg=[]
              for(let i=0;i<response.data.errors.length;i++)
              {
                  msg.push(response.data.errors[i]["errorIdentifier"])
              }
              
            this.$router.push({ path: '/networkalert', query: {backUrl:'proposal-page',msgError:"We are facing some issue while fetching proposal data",reqId:response.data.reqId, msg:msg  }});

          }
          else if (response && response.data) {
            self.loading = false;
            this.response = response.data;
             

            // Setting trace info and distributer info
            this.response.data.fieldGroups.traceInfo.fields['1SBQuoteRequestID'].input = this.quoteRequestID;
            this.response.data.fieldGroups.traceInfo.fields['1SBproposalRefId'].input = this.proposalUiId;
            // Commented
            //this.response.data.fieldGroups.distributor = this.tempDistributer; 
            //console.log('tmp.data.fieldGroups.distributor: ', this.response.data.fieldGroups.distributor);
            //this.response = this.getFinalObject(tempResponse);
            // console.log('this.response XXX: ', this.response);



            //############################# Setting up fieldGroups and other data #############################
            this.resData = this.response.data.fieldGroups;

            let tmpResData = [];
            for (var key in this.resData) {
              //console.log("key", key);
              if (key != "traceInfo" && key != "distributor") {
                //console.log('ttt: ', this.resData[key])
                tmpResData.push(this.resData[key]);
              }
            }


            // console.log('ppp: ', tmpResData);
            tmpResData.sort(
              (a, b) =>
                Number(a.order) -
                Number(b.order)
            );

            // console.log('sorting ', tmpResData)


            for (var key in tmpResData) {
              //console.log("key", key);
              if (key != "traceInfo" && key != "distributor") {
                //console.log('ttt: ', this.resData[key])
                this.responseData[tmpResData[key]['name']] = tmpResData[key];
                // Creating Tabs Array
                this.tabsArr.push(tmpResData[key]['name']);
              }
            }

            this.formInfo = this.response.data;
             
          
            for (var key in this.formInfo.fieldGroups) {
              //if (key != "traceInfo" && key != "distributor") {
              let data = this.formInfo['fieldGroups'][key];


              if (data.fields) {
                for (var key2 in data.fields) {
                  let data2 = this.formInfo['fieldGroups'][key]['fields'][key2];
                  if (data2.type == 'multi-select') {
                    this.formInfo['fieldGroups'][key]['fields'][key2]['input'] = [];
                  } else if (data2.type == 'boolean') {
                    this.formInfo['fieldGroups'][key]['fields'][key2]['input'] = "";
                  } else if (data2.type == 'number') {
                    this.formInfo['fieldGroups'][key]['fields'][key2]['input'] = 0;
                  } else if (data2.type == 'currency') {
                    this.formInfo['fieldGroups'][key]['fields'][key2]['input'] = 0;
                  }else {
                    this.formInfo['fieldGroups'][key]['fields'][key2]['input'] = "";
                  }
                }
              }

              // fieldGroups
              if (data.fieldGroups) {
                for (var key2 in data.fieldGroups) {
                  let data2 = this.formInfo['fieldGroups'][key]['fieldGroups'][key2];
                  for (var key3 in data2.fields) {
                    let data3 = this.formInfo['fieldGroups'][key]['fieldGroups'][key2]['fields'][key3];
                    if (data3.type == 'multi-select') {
                      //console.log('key3: ', key3)
                      this.formInfo['fieldGroups'][key]['fieldGroups'][key2]['fields'][key3]['input'] = [];
                    } else if (data3.type == 'boolean') {
                      this.formInfo['fieldGroups'][key]['fieldGroups'][key2]['fields'][key3]['input'] = "";
                    } else if (data3.type == 'number') {
                      this.formInfo['fieldGroups'][key]['fieldGroups'][key2]['fields'][key3]['input'] = 0;
                    } else if (data3.type == 'currency'){
                      this.formInfo['fieldGroups'][key]['fieldGroups'][key2]['fields'][key3]['input'] = 0;
                    } else {
                      this.formInfo['fieldGroups'][key]['fieldGroups'][key2]['fields'][key3]['input'] = "";
                    }
                  }


                  // 333333
                  // fieldGroups.fieldGroups
                  if (data2.fieldGroups) {
                    for (var key3 in data2.fieldGroups) {
                      let data3 = this.formInfo['fieldGroups'][key]['fieldGroups'][key2]['fieldGroups'][key3];
                      for (var key4 in data3.fields) {
                        let data4 = this.formInfo['fieldGroups'][key]['fieldGroups'][key2]['fieldGroups'][key3]['fields'][key4];
                        if (data4.type == 'multi-select') {
                          //console.log('key4: ', key4)
                          this.formInfo['fieldGroups'][key]['fieldGroups'][key2]['fieldGroups'][key3]['fields'][key4]['input'] = [];
                        } else if (data4.type == 'boolean') {
                          this.formInfo['fieldGroups'][key]['fieldGroups'][key2]['fieldGroups'][key3]['fields'][key4]['input'] = "";
                        } else if (data4.type == 'number') {
                          this.formInfo['fieldGroups'][key]['fieldGroups'][key2]['fieldGroups'][key3]['fields'][key4]['input'] = 0;
                        } else if (data4.type == 'currency') {
                          this.formInfo['fieldGroups'][key]['fieldGroups'][key2]['fieldGroups'][key3]['fields'][key4]['input'] = 0;
                        } else {
                          this.formInfo['fieldGroups'][key]['fieldGroups'][key2]['fieldGroups'][key3]['fields'][key4]['input'] = "";
                        }
                      }
                    }
                    // 333333

                  }
                }


              }
              //}
            }
           

            // Setting distributor details section

            if (this.response.data.fieldGroups.distributor.fields.distributorID) {
              this.response.data.fieldGroups.distributor.fields.distributorID.input = this.distributorID;
            }   


            //set details from spdetails api
            let spdetail_flag= process.env.VUE_APP_SPDETAIL_ONESB;
            if(this.spDetails!=null){
            if(spdetail_flag == 1)
            {
              if (this.response.data.fieldGroups.distributor.fields.agentCode)
              {
                this.response.data.fieldGroups.distributor.fields.agentCode.input = this.spDetails.spCode;
              }
              for(var attr in this.spDetails.attributes)
              {
                try{
                  this.response.data.fieldGroups.distributor.fields[attr].input = this.spDetails.attributes[attr];
                }catch(err){
                  console.log("SpDetails assignment failed",err);
                }            
              }
            }
            else
            {
              if (this.response.data.fieldGroups.distributor.fields.advisorCode) 
              {
                this.response.data.fieldGroups.distributor.fields.advisorCode.input = this.spDetails.advisorCode;
              }
              if (this.response.data.fieldGroups.distributor.fields.channelType)
              {
                this.response.data.fieldGroups.distributor.fields.channelType.input = this.spDetails.channelType;
              }
              if (this.response.data.fieldGroups.distributor.fields.cusBankAccNo)
              {
                this.response.data.fieldGroups.distributor.fields.cusBankAccNo.input = this.spDetails.cusBankAccNo;
              }
              if (this.response.data.fieldGroups.distributor.fields.bankName)
              {
                this.response.data.fieldGroups.distributor.fields.bankName.input = this.spDetails.bankName;
              }
              if (this.response.data.fieldGroups.distributor.fields.needRiskProfile)
              {
                this.response.data.fieldGroups.distributor.fields.needRiskProfile.input = this.spDetails.needRiskProfile;
              }
              if (this.response.data.fieldGroups.distributor.fields.csrLimCode)
              {
                this.response.data.fieldGroups.distributor.fields.csrLimCode.input = this.spDetails.csrLimCode;
              }
              if (this.response.data.fieldGroups.distributor.fields.cafosCode)
              {
                this.response.data.fieldGroups.distributor.fields.cafosCode.input = this.spDetails.cafosCode;
              }
              if (this.response.data.fieldGroups.distributor.fields.oppId)
              {
                this.response.data.fieldGroups.distributor.fields.oppId.input = this.spDetails.oppId;
              }
              if (this.response.data.fieldGroups.distributor.fields.fscCode)
              {
                this.response.data.fieldGroups.distributor.fields.fscCode.input = this.spDetails.fscCode;
              }
              if (this.response.data.fieldGroups.distributor.fields.bankBrnch)
              {
                this.response.data.fieldGroups.distributor.fields.bankBrnch.input = this.spDetails.bankBrnch;
              }            
              if (this.response.data.fieldGroups.distributor.fields.agentCode)
              {
                this.response.data.fieldGroups.distributor.fields.agentCode.input = this.spDetails.spCode;
              }
              if (this.response.data.fieldGroups.distributor.fields.lanNo)
              {
                this.response.data.fieldGroups.distributor.fields.lanNo.input = this.spDetails.lanNo;
              }
              if (this.response.data.fieldGroups.distributor.fields.selectedTab)
              {
                this.response.data.fieldGroups.distributor.fields.selectedTab.input = this.spDetails.selectedTab;
              }
              if (this.response.data.fieldGroups.distributor.fields.subChannel)
              {
                this.response.data.fieldGroups.distributor.fields.subChannel.input = this.spDetails.subChannel;
              }
              if (this.response.data.fieldGroups.distributor.fields.source)
              {
                this.response.data.fieldGroups.distributor.fields.source.input = this.spDetails.source;
              }
            }
          }

            
            if (this.response.data.fieldGroups.distributor.fields.channelType)
              {
                console.log(`diy logic ${typeof sessionStorage.getItem('diy')}`)
                if(sessionStorage.getItem('diy') == "true"){
                  this.response.data.fieldGroups.distributor.fields.channelType.input = 'B2C';
                }
                else
                {
                  this.response.data.fieldGroups.distributor.fields.channelType.input = 'B2B';
                }
              }
            
            // Setting up previously filled data
            let filledData = JSON.parse(sessionStorage.getItem("filledData"));
           
            if(this.userProfile.smoke == 'Yes')
            {
              if(this.formInfo['manufacturerId']=="ICICI")
              {
                this.formInfo['fieldGroups']['firstLifeAssuredDetails']['fieldGroups']['healthQuestionnaire']['fields']['Q4']['input'] = "1"
              }
              if(this.formInfo['manufacturerId']=="HDFC")
              {
                this.formInfo['fieldGroups']['firstLifeAssuredDetails']['fieldGroups']['healthQuestionnaire']['fieldGroups']['habits']['fields']['HDFCQ114']['input'] ="1"
                this.formInfo['fieldGroups']['firstLifeAssuredDetails']['fieldGroups']['healthQuestionnaire']['fieldGroups']['habits']['fields']['HDFCQ114']['mandatory']="2"                                                                                                                                                        
              }  
            }
            else
            {
              if(this.formInfo['manufacturerId']=="ICICI")
              {
                this.formInfo['fieldGroups']['firstLifeAssuredDetails']['fieldGroups']['healthQuestionnaire']['fields']['Q4']['input'] = "0"
              }
              if(this.formInfo['manufacturerId']=="HDFC")
              {
                this.formInfo['fieldGroups']['firstLifeAssuredDetails']['fieldGroups']['healthQuestionnaire']['fieldGroups']['habits']['fields']['HDFCQ114']['input'] = "0"
                this.formInfo['fieldGroups']['firstLifeAssuredDetails']['fieldGroups']['healthQuestionnaire']['fieldGroups']['habits']['fields']['HDFCQ114']['mandatory']="2" 
              }
            }
            this.defaultData(this.formInfo['fieldGroups'],this.formInfo,this.quoteInfo);

            // console.log("after defaulting--->");
            // console.log(this.formInfo)
            // console.log('filledData: ', filledData);
            
            if(this.insurerCode=="TATA" || this.insurerCode=="ABSLIFEI" || this.insurerCode=="HDFC" || this.insurerCode=="MAXLIFE" || this.insurerCode=="ICICI")
            {
                 this.getPinCodeData(this.userProfile.zipcode)
            }
           
            if (filledData) {
              // console.log('inside filled data', filledData);
              // console.log("form info: ", this.formInfo);

              if (this.productCode == filledData.productId) {

                // console.log('product id compared');
                //this.formInfo = filledData;
                let filledDataTab = JSON.parse(sessionStorage.getItem("filledDataTab"));

                for (var key in filledData.fieldGroups) {
                  if (key != "distributor") {
                    let data = filledData['fieldGroups'][key];

                    if (data.fields) {
                      for (var key2 in data.fields) {
                        let data2 = filledData['fieldGroups'][key]['fields'][key2];
                        if (data2.type == 'multi-select') {
                          this.formInfo['fieldGroups'][key]['fields'][key2]['input'] = filledData['fieldGroups'][key]['fields'][key2]['input'];
                        } else if (data2.type == 'boolean') {
                          this.formInfo['fieldGroups'][key]['fields'][key2]['input'] = filledData['fieldGroups'][key]['fields'][key2]['input'];
                        }  else if (data2.type == 'number') {
                          this.formInfo['fieldGroups'][key]['fields'][key2]['input'] = parseInt(filledData['fieldGroups'][key]['fields'][key2]['input']);
                        }  else if (data2.type == 'currency') {
                          // console.log("currency in filled data",(filledData['fieldGroups'][key]['fields'][key2]['input']))
                          this.formInfo['fieldGroups'][key]['fields'][key2]['input'] = new Intl.NumberFormat('en-IN').format(parseInt(filledData['fieldGroups'][key]['fields'][key2]['input']));
                        } else {
                          this.formInfo['fieldGroups'][key]['fields'][key2]['input'] = filledData['fieldGroups'][key]['fields'][key2]['input'];
                        }
                      }
                    }

                    // fieldGroups
                    if (data.fieldGroups) {
                      for (var key2 in data.fieldGroups) {
                        let data2 = filledData['fieldGroups'][key]['fieldGroups'][key2];
                        for (var key3 in data2.fields) {
                          let data3 = filledData['fieldGroups'][key]['fieldGroups'][key2]['fields'][key3];
                          if (data3.type == 'multi-select') {
                            try {
                              this.formInfo['fieldGroups'][key]['fieldGroups'][key2]['fields'][key3]['input'] = filledData['fieldGroups'][key]['fieldGroups'][key2]['fields'][key3]['input'];
                            } catch (err) {
                              console.log('error during assignment:', err);
                              console.log("this.formInfo['fieldGroups'][" + key + "]['fieldGroups'][" + key2 + "]['fields'][" + key3 + "][input]: " + filledData['fieldGroups'][key]['fieldGroups'][key2]['fields'][key3]['input']);
                            }
                          } else if (data3.type == 'boolean') {
                            try {
                              this.formInfo['fieldGroups'][key]['fieldGroups'][key2]['fields'][key3]['input'] = filledData['fieldGroups'][key]['fieldGroups'][key2]['fields'][key3]['input'];
                            }
                            catch (err) {
                              console.log('error during assignment:', err);
                              console.log("this.formInfo['fieldGroups'][" + key + "]['fieldGroups'][" + key2 + "]['fields'][" + key3 + "][input]: " + filledData['fieldGroups'][key]['fieldGroups'][key2]['fields'][key3]['input']);
                            }
                          } else if (data3.type == 'number') {
                            try {
                              this.formInfo['fieldGroups'][key]['fieldGroups'][key2]['fields'][key3]['input'] = parseInt(filledData['fieldGroups'][key]['fieldGroups'][key2]['fields'][key3]['input']);
                            }
                            catch (err) {
                              console.log('error during assignment:', err);
                              console.log("this.formInfo['fieldGroups'][" + key + "]['fieldGroups'][" + key2 + "]['fields'][" + key3 + "][input]: " + filledData['fieldGroups'][key]['fieldGroups'][key2]['fields'][key3]['input']);
                            }
                          } else if (data3.type == 'currency') {
                            try {
                              console.log("currency in filled data",(filledData['fieldGroups'][key]['fieldGroups'][key2]['fields'][key3]['input']))
                              this.formInfo['fieldGroups'][key]['fieldGroups'][key2]['fields'][key3]['input'] = new Intl.NumberFormat('en-IN').format(parseInt(filledData['fieldGroups'][key]['fieldGroups'][key2]['fields'][key3]['input']));
                            }
                            catch (err) {
                              console.log('error during assignment:', err);
                              console.log("this.formInfo['fieldGroups'][" + key + "]['fieldGroups'][" + key2 + "]['fields'][" + key3 + "][input]: " + filledData['fieldGroups'][key]['fieldGroups'][key2]['fields'][key3]['input']);
                            }
                          } else {
                            try {
                              this.formInfo['fieldGroups'][key]['fieldGroups'][key2]['fields'][key3]['input'] = filledData['fieldGroups'][key]['fieldGroups'][key2]['fields'][key3]['input'];
                            }
                            catch (err) {
                              console.log('error during assignment:', err);
                              console.log("this.formInfo['fieldGroups'][" + key + "]['fieldGroups'][" + key2 + "]['fields'][" + key3 + "][input]: " + filledData['fieldGroups'][key]['fieldGroups'][key2]['fields'][key3]['input']);
                            }
                          }
                        }

                        // 333333
                        // fieldGroups.fieldGroups
                        if (data2.fieldGroups) {
                          for (var key3 in data2.fieldGroups) {
                            let data3 = filledData['fieldGroups'][key]['fieldGroups'][key2]['fieldGroups'][key3];
                            for (var key4 in data3.fields) {
                              let data4 = filledData['fieldGroups'][key]['fieldGroups'][key2]['fieldGroups'][key3]['fields'][key4];
                              if (data4.type == 'multi-select') {
                                try {
                                  this.formInfo['fieldGroups'][key]['fieldGroups'][key2]['fieldGroups'][key3]['fields'][key4]['input'] = filledData['fieldGroups'][key]['fieldGroups'][key2]['fieldGroups'][key3]['fields'][key4]['input'];
                                } catch (err) {
                                  console.log('error during assignment:', err);
                                  console.log("this.formInfo['fieldGroups'][" + key + "]['fieldGroups'][" + key2 + "]['fields'][" + key3 + "]['fields'][" + key4 + "][input]" + filledData['fieldGroups'][key]['fieldGroups'][key2]['fieldGroups'][key3]['fields'][key4]['input']);
                                }
                              } else if (data4.type == 'boolean') {
                                try {
                                  this.formInfo['fieldGroups'][key]['fieldGroups'][key2]['fieldGroups'][key3]['fields'][key4]['input'] = filledData['fieldGroups'][key]['fieldGroups'][key2]['fieldGroups'][key3]['fields'][key4]['input'];
                                } catch (err) {
                                  console.log('error during assignment:', err);
                                  console.log("this.formInfo['fieldGroups'][" + key + "]['fieldGroups'][" + key2 + "]['fields'][" + key3 + "]['fields'][" + key4 + "][input]" + filledData['fieldGroups'][key]['fieldGroups'][key2]['fieldGroups'][key3]['fields'][key4]['input']);
                                }
                              } else if (data4.type == 'number') {
                                try {
                                  this.formInfo['fieldGroups'][key]['fieldGroups'][key2]['fieldGroups'][key3]['fields'][key4]['input'] = parseInt(filledData['fieldGroups'][key]['fieldGroups'][key2]['fieldGroups'][key3]['fields'][key4]['input']);
                                } catch (err) {
                                  console.log('error during assignment:', err);
                                  console.log("this.formInfo['fieldGroups'][" + key + "]['fieldGroups'][" + key2 + "]['fields'][" + key3 + "]['fields'][" + key4 + "][input]" + filledData['fieldGroups'][key]['fieldGroups'][key2]['fieldGroups'][key3]['fields'][key4]['input']);
                                }
                              } else if (data4.type == 'currency') {
                                try {
                                  console.log("inside currency filled data",(filledData['fieldGroups'][key]['fieldGroups'][key2]['fieldGroups'][key3]['fields'][key4]['input']))
                                  this.formInfo['fieldGroups'][key]['fieldGroups'][key2]['fieldGroups'][key3]['fields'][key4]['input'] = new Intl.NumberFormat('en-IN').format(parseInt(filledData['fieldGroups'][key]['fieldGroups'][key2]['fieldGroups'][key3]['fields'][key4]['input']));
                                } catch (err) {
                                  console.log('error during assignment:', err);
                                  console.log("this.formInfo['fieldGroups'][" + key + "]['fieldGroups'][" + key2 + "]['fields'][" + key3 + "]['fields'][" + key4 + "][input]" + filledData['fieldGroups'][key]['fieldGroups'][key2]['fieldGroups'][key3]['fields'][key4]['input']);
                                }
                              } else {

                                try {
                                  this.formInfo['fieldGroups'][key]['fieldGroups'][key2]['fieldGroups'][key3]['fields'][key4]['input'] = filledData['fieldGroups'][key]['fieldGroups'][key2]['fieldGroups'][key3]['fields'][key4]['input'];
                                } catch (err) {
                                  console.log('error during assignment:', err);
                                  console.log("this.formInfo['fieldGroups'][" + key + "]['fieldGroups'][" + key2 + "]['fields'][" + key3 + "]['fields'][" + key4 + "][input]" + filledData['fieldGroups'][key]['fieldGroups'][key2]['fieldGroups'][key3]['fields'][key4]['input']);
                                }

                                //this.formInfo['fieldGroups'][key]['fieldGroups'][key2]['fieldGroups'][key3]['fields'][key4]['input'] = filledData['fieldGroups'][key]['fieldGroups'][key2]['fieldGroups'][key3]['fields'][key4]['input'];
                              }
                            }
                          }
                        }
                        // 333333


                      }
                    }



                  }
                }

                if (filledDataTab) {
                  // console.log('inside filled data tab', filledDataTab);
                  setTimeout(function () {
                    self.activeTabAfterReload(filledDataTab);
                  }, 1000);
                }
              } else {
                // Removing previous data
                localStorage.removeItem('filledData');
                localStorage.removeItem('filledDataTab');
                sessionStorage.removeItem('filledData');
                sessionStorage.removeItem('filledDataTab');
              }
            }
            // End setting previously filled data
            try {
              if (this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.panNumber.input === "") {
                this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.panNumber.input = this.userProfile?.panNumber;
              }
            } catch (error) {

            }

            //############################./End Setting up fieldGroups and other data ###########################

          }
        })
        .catch((error) => {
          console.log('error msg block proposal', error);
          let msg=['We are facing some issue from backend while fetching proposal data']
          this.$router.push({ path: '/networkalert', query: {backUrl:'proposal-page', msg: msg }});
        });


      /* axios.get('https://demo.proposal.api.1silverbullet.tech/insurance/LifeTerm/proposal/' + productApiUrl )
      .then((response) => {
        self.loading = false;
        self.apiError = false;

        

      })
      .catch(function (error) {
        console.log("error block eecuted kk");
        self.loading = false;
        self.apiError = true;
        console.log(error);
      }); */

    },
    activePage(val) {
      if (val == "proposerDetails") {
        return "active";
      } else {
        return "disabled";
      }
    },
    isTabContentActive(val) {
      if (val == "proposerDetails") {
        return "show active";
      } else {
        return "";
      }
    },
    convertSentenceCase(value) {
      const result = value.replace(/([A-Z])/g, " $1");
      const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
      return finalResult;
    },
    getFinalObject(tempResponse) {
      //console.log('tempResponse: ', tempResponse)
      var tempString = JSON.stringify(tempResponse);
      var updatedString = tempString.replace(/'input' in formInfo\[/g, "formInfo[");
      var updatedString2 = updatedString.replace(/formInfo\[/g, "this.formInfo[");
      var updatedString3 = updatedString2.replace(/quoteInfo\[/g, "this.quoteInfo[");
      //console.log('final String: ', updatedString3);
      return JSON.parse(updatedString3);
    },
    getFieldName(field){
      this.default(field,this.quoteInfo,this.formInfo);
      return field.name;
    },
    checkVisibility(value) {
      //console.log('check Visibility called: ', value);
      // default expression evalutiona added along with visibility
      if (value) {
        let obj = this.getFinalObject(value);
        try {
          return eval(obj);
        }
        catch (err) {
          console.log('error exp: ', value);
        }
      } else {
        return true;
      }

    },
    default(field,quoteInfo,formInfo){
      if(("default" in field)==true){
        let obj = field.default;
        try{
          eval(obj);
        } catch (err) {
          console.log('error defualt exp: ', obj);
          console.log(err);
        }
      }
    },
    checkValidation(value, label = "") {
      //console.log('check Validation called: ', value);
      if (value) {
        let obj = this.getFinalObject(value);
        try {
          return eval(obj);
        }
        catch (err) {
          console.log('error exp 2: ', value);
          return true;
        }
      } else {
        //return false;
      }

    },
    formatCurrency(ind, ind2,ind3,ind4){
      if(ind3 == undefined && ind4 == undefined)
        this.formInfo.fieldGroups[ind].fields[ind2].input = this.formInfo.fieldGroups[ind].fields[ind2].input.replace(/\D/g, "").replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,");
      else if(ind4==undefined)
        this.formInfo.fieldGroups[ind].fieldGroups[ind2].fields[ind3].input = this.formInfo.fieldGroups[ind].fieldGroups[ind2].fields[ind3].input.replace(/\D/g, "").replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,");
      else
        this.formInfo.fieldGroups[ind].fieldGroups[ind2].fieldGroups[ind3].fields[ind4].input = this.formInfo.fieldGroups[ind].fieldGroups[ind2].fieldGroups[ind3].fields[ind4].input.replace(/\D/g, "").replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,");
      // event.target.value = event.target.value.replace(/\D/g, "").replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,")
    },
    getValidation(event, name, id, ind1 = "", ind2 = "", ind3 = "") {
      self = this;
      $("#error_" + id).text('');
      let val = event.target.value;
      // console.log('getValidation called');
      // console.log('ev: ', event);
      // console.log('id: ', id);
      // console.log('event: ', val);
      // console.log('field name: ', name);
      // console.log('ind1: ', ind1, 'ind2: ', ind2, 'ind3: ', ind3)
   
     if(this.insurerCode=="HDFC")
      {
        
      if(name=="relationWithLA" && ind1=="proposerDetails" && ind2 == "personalDetails" && val=="Self" )
      {
        
        this.getPinCodeData(this.userProfile.zipcode)
        this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.sourcetype.input = [];
      }
      else if( name=="relationWithLA" && ind1=="proposerDetails" && ind2 == "personalDetails" &&  val!="Self")
      {
        //remove all proposaer info in case of non self
        this.formInfo.fieldGroups.proposerDetails.fieldGroups.communicationAddressDetails.fields.zipCode.input=""
        this.formInfo.fieldGroups.proposerDetails.fieldGroups.communicationAddressDetails.fields.city.input=""
        this.formInfo.fieldGroups.proposerDetails.fieldGroups.communicationAddressDetails.fields.state.input=""
        this.formInfo.fieldGroups.proposerDetails.fieldGroups.communicationAddressDetails.fields.country.input=""

        this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.dateOfBirth.input = "";
          this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.mobileNumber.input = "";
         
          this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.gender.input = "";
          
          this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.annualIncome.input = "";

        
          this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.personalDetails.fields.dateOfBirth.input = "";
          this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.personalDetails.fields.mobileNumber.input = "";
          
          this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.annualIncome.input = "";
          
          this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.firstName.input = "";
          this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.lastName.input = "";
          this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.residentStatus.input ="";
          this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.occupation.input = "";
          this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.qualification.input = "";
          this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.annualIncome.input = "";
          // this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.sourcetype.input = [];

      }
      }
      // ZipCode Related Code
      if (name == 'zipCode' || name == 'zipcode' ) {
        if (val.length == 6) {
          let headers = {};
          let reqData = [val];
          //this.loading = true;
          this.backendApi("getPincodeInfo", reqData, headers, "get")
            .then((response) => {
              //this.loading = false;
              if (response == 'error') {
                //this.apiError = true;
                console.log('pincode api error: block');
                // this.$router.push({ path: '/alert', query: { status: 'Error', errorcode:response.status, msg: 'We are unable to get pincode information' }});
              } else if (response && response.data && response.data.errors && response.data.errors.length > 0) {
                $("#error_" + id).text("Zipcode is Invalid.");
                if (this.insurerCode == "HDFC") {
                  if (this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.relationWithLA.input != "Self" && ind1 == "proposerDetails" && ind2 == "communicationAddressDetails") {
                    this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.communicationAddressDetails.fields.zipCode.input = val
                    this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.communicationAddressDetails.fields.cityOther.input = "";
                    this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.communicationAddressDetails.fields.stateOther.input = "";
                    this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.communicationAddressDetails.fields.country.input = "";
                    this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.communicationAddressDetails.fields.city.input = "";
                    this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.communicationAddressDetails.fields.state.input = "";
                  }
                  else if (this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.relationWithLA.input != "Self" && ind1 == "firstLifeAssuredDetails" && ind2 == "professionalDetails") {
                    this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.zipCode.input = val
                    this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.employerCountry.input = "";
                    this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.city.input = "";
                    this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.state.input = "";
                  }
                }
                if (ind2 == 'zipCode') {
                  if (self.formInfo['fieldGroups'][ind1]['fields']['city']) {
                    self.formInfo['fieldGroups'][ind1]['fields']['city']['input'] = "";
                  }
                  if (self.formInfo['fieldGroups'][ind1]['fields']['state']) {
                    self.formInfo['fieldGroups'][ind1]['fields']['state']['input'] = "";
                  }
                  if (self.formInfo['fieldGroups'][ind1]['fields']['country']) {
                    self.formInfo['fieldGroups'][ind1]['fields']['country']['input'] = "";
                  }
                } else if (ind3 == 'zipCode' || ind3 == 'zipcode') {
                  if (self.formInfo['fieldGroups'][ind1]['fieldGroups'][ind2]['fields']['city']) {
                    self.formInfo['fieldGroups'][ind1]['fieldGroups'][ind2]['fields']['city']['input'] = "";
                  }
                  if (self.formInfo['fieldGroups'][ind1]['fieldGroups'][ind2]['fields']['state']) {
                    self.formInfo['fieldGroups'][ind1]['fieldGroups'][ind2]['fields']['state']['input'] = "";
                  }
                  if (self.formInfo['fieldGroups'][ind1]['fieldGroups'][ind2]['fields']['country']) {
                    if (this.insurerCode == "TATA") {
                      self.formInfo['fieldGroups'][ind1]['fieldGroups'][ind2]['fields']['country']['input'] = "";
                    }
                    else
                      self.formInfo['fieldGroups'][ind1]['fieldGroups'][ind2]['fields']['country']['input'] = "";
                  }
                } else {
                  if (self.formInfo['fieldGroups'][ind1]['fieldGroups'][ind2]['fieldGroups'][ind3]['fields']['city']) {
                    self.formInfo['fieldGroups'][ind1]['fieldGroups'][ind2]['fieldGroups'][ind3]['fields']['city']['input'] = "";
                  }
                  if (self.formInfo['fieldGroups'][ind1]['fieldGroups'][ind2]['fieldGroups'][ind3]['fields']['state']) {
                    self.formInfo['fieldGroups'][ind1]['fieldGroups'][ind2]['fieldGroups'][ind3]['fields']['state']['input'] = "";
                  }
                  if (self.formInfo['fieldGroups'][ind1]['fieldGroups'][ind2]['fieldGroups'][ind3]['fields']['country']) {
                    self.formInfo['fieldGroups'][ind1]['fieldGroups'][ind2]['fieldGroups'][ind3]['fields']['country']['input'] = "";
                  }
                }
              } else if (response && response.data) {
                //console.log('pincode response: ', response.data.data);
                let v = response.data.data[0];
                // console.log('v: ', v);

                if(this.insurerCode=="HDFC")
                {
                  if(this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.relationWithLA.input!="Self" &&  ind1=="proposerDetails" && ind2=="communicationAddressDetails")
                  {
                      this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.communicationAddressDetails.fields.zipCode.input=val
                      this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.communicationAddressDetails.fields.cityOther.input=v.city
                      this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.communicationAddressDetails.fields.stateOther.input=v.state
                      this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.communicationAddressDetails.fields.country.input=v.country
                      this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.communicationAddressDetails.fields.city.input=v.city
                      this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.communicationAddressDetails.fields.state.input=v.state
                 }
                 else if(this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.relationWithLA.input!="Self" &&  ind1=="firstLifeAssuredDetails" && ind2=="professionalDetails"){
                      this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.zipCode.input=val
                      this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.employerCountry.input=v.country
                      this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.city.input=v.city
                      this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.state.input=v.state
                 }
                }
                if (ind2 == 'zipCode') {
                  if (self.formInfo['fieldGroups'][ind1]['fields']['city']) {
                    self.formInfo['fieldGroups'][ind1]['fields']['city']['input'] = v.city;
                  }
                  if (self.formInfo['fieldGroups'][ind1]['fields']['state']) {
                    self.formInfo['fieldGroups'][ind1]['fields']['state']['input'] = v.state;
                  }
                  if (self.formInfo['fieldGroups'][ind1]['fields']['country']) {
                    self.formInfo['fieldGroups'][ind1]['fields']['country']['input'] = v.country;
                  }
                } else if (ind3 == 'zipCode' || ind3 == 'zipcode') {
                  if (self.formInfo['fieldGroups'][ind1]['fieldGroups'][ind2]['fields']['city']) {
                    self.formInfo['fieldGroups'][ind1]['fieldGroups'][ind2]['fields']['city']['input'] = v.city;
                  }
                  if (self.formInfo['fieldGroups'][ind1]['fieldGroups'][ind2]['fields']['state']) {
                    self.formInfo['fieldGroups'][ind1]['fieldGroups'][ind2]['fields']['state']['input'] = v.state;
                  }
                   if (self.formInfo['fieldGroups'][ind1]['fieldGroups'][ind2]['fields']['country']) {
                   if(this.insurerCode=="TATA"){
                     self.formInfo['fieldGroups'][ind1]['fieldGroups'][ind2]['fields']['country']['input'] = v.country.toLowerCase();
                  }
                  else
                    self.formInfo['fieldGroups'][ind1]['fieldGroups'][ind2]['fields']['country']['input'] = v.country;
                    }
                  } else {
                  if (self.formInfo['fieldGroups'][ind1]['fieldGroups'][ind2]['fieldGroups'][ind3]['fields']['city']) {
                    self.formInfo['fieldGroups'][ind1]['fieldGroups'][ind2]['fieldGroups'][ind3]['fields']['city']['input'] = v.city;
                  }
                  if (self.formInfo['fieldGroups'][ind1]['fieldGroups'][ind2]['fieldGroups'][ind3]['fields']['state']) {
                    self.formInfo['fieldGroups'][ind1]['fieldGroups'][ind2]['fieldGroups'][ind3]['fields']['state']['input'] = v.state;
                  }
                  if (self.formInfo['fieldGroups'][ind1]['fieldGroups'][ind2]['fieldGroups'][ind3]['fields']['country']) {
                    self.formInfo['fieldGroups'][ind1]['fieldGroups'][ind2]['fieldGroups'][ind3]['fields']['country']['input'] = v.country;
                  }
                }


              }
            })
            .catch((error) => {
              console.log('error msg block getting pincode info', error);
              // this.$router.push({ path: '/alert', query: { status: 'Error', errorcode:response.status, msg: 'We are unable to get pincode information' }});

              //this.loading = false;
              //this.apiError = true;
            });
        }
      }

      if(name.toLowerCase()=="ifsccode"){
        if(val.length==11){
          let headers = {"quoteId":this.uiQuoteId,};
          let reqData = [val.toUpperCase()];
          this.loadingPage = true;
          //this.loading = true;
          this.backendApi("getIFSCInfo", reqData, headers, "get")
            .then((response) => {
              this.loadingPage = false;
              if (response == 'error') {
                //this.apiError = true;
                console.log('IFSC Code api error: block');
              }
              else if(!response.data.data.bank || !response.data.data.branch){
                console.log("IFSC Code does not exists. If this is a valid IFSC, please contact your support team with cheque scan copy")
                $("#error_" + id).text("IFSC Code does not exists. If this is a valid IFSC, please contact your support team with cheque scan copy");
              }
              else if (response && response.data) {
                //console.log('pincode response: ', response.data.data);
                let ifscData = response.data.data;
                console.log(ifscData)
                // this.$router.push({ path: '/alert', query: { status: 'Error', errorcode:response.status, msg: 'We are unable to get pincode information' }});
                try{
                  if ("bankName" in this.formInfo.fieldGroups.payoutDetails.fields){
                    this.formInfo.fieldGroups.payoutDetails.fields.bankName.input = ifscData.bank
                  }
                  if( "branchName" in this.formInfo.fieldGroups.payoutDetails.fields){
                    this.formInfo.fieldGroups.payoutDetails.fields.branchName.input = ifscData.branch
                  }
                } catch (err) {
                  console.log('error in ifsc defaulting: ',err)
                }
              }
            })
            .catch((error) => {
              console.log('error msg block getting pincode info', error);
              // this.$router.push({ path: '/alert', query: { status: 'Error', errorcode:response.status, msg: 'We are unable to get pincode information' }});

              //this.loading = false;
              //this.apiError = true;
            });
        }
      }
      
     

      //for TATA
      if(this.insurerCode=="TATA")
      {
      if (name == 'relationWithLA') {
        // console.log('inseide relationWithFirstLifeAssured');
        // console.log('event name: ', val);
        if (val == 'self') {
          console.log("Self",val)
          // Setting Landing Page Data
          this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.dateOfBirth.input = this.userProfile.dob;
          this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.mobileNumber.input = this.userProfile.mobile;
          // Commented auto-population of gender field.
          // this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.gender.input = this.userProfile.gender;
          // this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.occupation.input = this.userProfile.employmenttype; // Commented on 01-07-2022
          //this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.annualIncome.input = parseInt(this.userProfile.annualincome);
          if(this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.annualIncome.type == "currency")
          {
            this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.annualIncome.input = new Intl.NumberFormat('en-IN').format(this.userProfile.annualincome);
          }
          else{
            this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.annualIncome.input = this.userProfile.annualincome;
          }
          //this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.qualification.input = this.userProfile.education; // Commented on 04-07-2022

          // Removing Data
          this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.personalDetails.fields.dateOfBirth.input = "";
          this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.personalDetails.fields.mobileNumber.input = "";
          // Commented auto-population of gender field.
          // this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.personalDetails.fields.gender.input = "";
          // this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.occupation.input = ""; // Commented on 01-07-2022
          //this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.annualIncome.input = "";
          this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.annualIncome.input = "";
          //this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.qualification.input = ""; // Commented on 04-07-2022

          // Setting Term Plan Quote Page Data // this.gateCriteriaData
          //console.log('this.gateCriteriaData.firstName.input : ', this.gateCriteriaData.firstName.input);
          //fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.firstName
          this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.firstName.input =  this.userProfile.fullname;
          this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.gender.input =  this.getGender(this.userProfile.gender);

          this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.firstName.input = this.gateCriteriaData.firstName.input;
          this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.lastName.input = this.gateCriteriaData.lastName.input;
          this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.residentStatus.input = this.gateCriteriaData.nationality.input;
          this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.occupation.input = this.gateCriteriaData.occupation.input;
          this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.qualification.input = this.gateCriteriaData.education.input;
          if(this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.annualIncome.type == "currency")
          {
            this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.annualIncome.input = new Intl.NumberFormat('en-IN').format(this.gateCriteriaData.annualIncome.input);
          }
          else{
            this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.annualIncome.input = this.gateCriteriaData.annualIncome.input;
          }

        } else {
          this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.personalDetails.fields.dateOfBirth.input = this.userProfile.dob;
          this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.personalDetails.fields.mobileNumber.input = this.userProfile.mobile;
          // Commented auto-population of gender field.
          // this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.personalDetails.fields.gender.input = this.userProfile.gender;
          // this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.occupation.input = this.userProfile.employmenttype; // Commented on 01-07-2022
          //this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.annualIncome.input = parseInt(this.userProfile.annualincome);
          this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.annualIncome.input = new Intl.NumberFormat('en-IN').format(this.userProfile.annualincome);
          //this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.qualification.input = this.userProfile.education; // Commented on 04-07-2022

          // Removing Data
          this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.dateOfBirth.input = "";
          this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.mobileNumber.input = "";
          // Commented auto-population of gender field.
          // this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.gender.input = "";
          // this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.occupation.input = ""; // Commented on 01-07-2022
          //this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.annualIncome.input = "";
          this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.annualIncome.input = "";
          //this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.qualification.input = ""; // Commented on 04-07-2022

          // Setting Term Plan Quote Page Data // this.gateCriteriaData
          // this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.personalDetails.fields.firstName.input = this.gateCriteriaData.firstName.input;
          // this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.personalDetails.fields.lastName.input = this.gateCriteriaData.lastName.input;
          // this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.personalDetails.fields.residentStatus.input = this.gateCriteriaData.nationality.input;
          // this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.occupation.input = this.gateCriteriaData.occupation.input;
          // this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.qualification.input = this.gateCriteriaData.education.input;
          // this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.annualIncome.input = this.gateCriteriaData.annualIncome.input;

          // Removing Data
          this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.firstName.input = "";
          this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.lastName.input = "";
          this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.residentStatus.input = "";
          this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.occupation.input = "";
          this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.qualification.input = "";
          this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.annualIncome.input = "";
        }
      }
      }
      // Relation with Life Assured
      if (name == 'relationWithFirstLifeAssured') {
        // console.log('inseide relationWithFirstLifeAssured');
        // console.log('event name: ', val);
        if (val == 'self') {
          console.log("Self",val)
          // Setting Landing Page Data
          this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.dateOfBirth.input = this.userProfile.dob;
          this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.mobileNumber.input = this.userProfile.mobile;
          // Commented auto-population of gender field.
          // this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.gender.input = this.userProfile.gender;
          // this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.occupation.input = this.userProfile.employmenttype; // Commented on 01-07-2022
          //this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.annualIncome.input = parseInt(this.userProfile.annualincome);
          if(this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.annualIncome.type == "currency")
          {
            this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.annualIncome.input = new Intl.NumberFormat('en-IN').format(this.userProfile.annualincome);
          }
          else{
            this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.annualIncome.input = this.userProfile.annualincome;
          }
          //this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.qualification.input = this.userProfile.education; // Commented on 04-07-2022

          // Removing Data
          this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.personalDetails.fields.dateOfBirth.input = "";
          this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.personalDetails.fields.mobileNumber.input = "";
          // Commented auto-population of gender field.
          // this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.personalDetails.fields.gender.input = "";
          // this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.occupation.input = ""; // Commented on 01-07-2022
          //this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.annualIncome.input = "";
          this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.annualIncome.input = "";
          //this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.qualification.input = ""; // Commented on 04-07-2022

          // Setting Term Plan Quote Page Data // this.gateCriteriaData
          //console.log('this.gateCriteriaData.firstName.input : ', this.gateCriteriaData.firstName.input);
          //fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.firstName
          this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.firstName.input =  this.userProfile.fullname;
          this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.gender.input =  this.getGender(this.userProfile.gender);

          // this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.firstName.input = this.gateCriteriaData.firstName.input;
          // this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.lastName.input = this.gateCriteriaData.lastName.input;
          // this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.residentStatus.input = this.gateCriteriaData.nationality.input;
          // this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.occupation.input = this.gateCriteriaData.occupation.input;
          // this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.qualification.input = this.gateCriteriaData.education.input;
          // this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.annualIncome.input = this.gateCriteriaData.annualIncome.input;

        } else {
          this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.personalDetails.fields.dateOfBirth.input = this.userProfile.dob;
          this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.personalDetails.fields.mobileNumber.input = this.userProfile.mobile;
          // Commented auto-population of gender field.
          // this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.personalDetails.fields.gender.input = this.userProfile.gender;
          // this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.occupation.input = this.userProfile.employmenttype; // Commented on 01-07-2022
          //this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.annualIncome.input = parseInt(this.userProfile.annualincome);
          this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.annualIncome.input = new Intl.NumberFormat('en-IN').format(this.userProfile.annualincome);
          //this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.qualification.input = this.userProfile.education; // Commented on 04-07-2022

          // Removing Data
          this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.dateOfBirth.input = "";
          this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.mobileNumber.input = "";
          // Commented auto-population of gender field.
          // this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.gender.input = "";
          // this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.occupation.input = ""; // Commented on 01-07-2022
          //this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.annualIncome.input = "";
          this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.annualIncome.input = "";
          //this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.qualification.input = ""; // Commented on 04-07-2022

          // Setting Term Plan Quote Page Data // this.gateCriteriaData
          // this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.personalDetails.fields.firstName.input = this.gateCriteriaData.firstName.input;
          // this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.personalDetails.fields.lastName.input = this.gateCriteriaData.lastName.input;
          // this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.personalDetails.fields.residentStatus.input = this.gateCriteriaData.nationality.input;
          // this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.occupation.input = this.gateCriteriaData.occupation.input;
          // this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.qualification.input = this.gateCriteriaData.education.input;
          // this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.annualIncome.input = this.gateCriteriaData.annualIncome.input;

          // Removing Data
          this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.firstName.input = "";
          this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.lastName.input = "";
          this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.residentStatus.input = "";
          this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.occupation.input = "";
          this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.qualification.input = "";
          this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.annualIncome.input = "";
        }
      }

      //console.log('path: ', path);
    },
    getValidation2(event, name, id, ind1 = "", ind2 = "", ind3 = "") {
      // console.log('getValidation2: called', name);
      let val = event.target.value;
      // console.log('ind1: ', ind1, 'ind2: ', ind2, 'ind3: ', ind3)
      // Permanent Address Same as Communication Address
      /* if (ind1 == 'proposerDetails' && ind2 == 'permanentAddressDetails' && ind3 == 'addressLine1') {
        //console.log('inside permanent address details');
        if (!this.formInfo.fieldGroups.proposerDetails.fieldGroups.permanentAddressDetails.fields.addressLine1.input && this.permanentAddressDetailsCheck == false) {
          this.permanentAddressDetailsCheck = true;
          // var res = confirm("Is your Permanent Address Details is same as Communication Address Details?");
          // if (res == true) {
          //   this.formInfo.fieldGroups.proposerDetails.fieldGroups.permanentAddressDetails.fields.addressLine1.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.communicationAddressDetails.fields.addressLine1.input;
          //   this.formInfo.fieldGroups.proposerDetails.fieldGroups.permanentAddressDetails.fields.addressLine2.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.communicationAddressDetails.fields.addressLine2.input;
          //   this.formInfo.fieldGroups.proposerDetails.fieldGroups.permanentAddressDetails.fields.addressLine3.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.communicationAddressDetails.fields.addressLine3.input;
          //   this.formInfo.fieldGroups.proposerDetails.fieldGroups.permanentAddressDetails.fields.landmark.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.communicationAddressDetails.fields.landmark.input;
          //   this.formInfo.fieldGroups.proposerDetails.fieldGroups.permanentAddressDetails.fields.zipCode.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.communicationAddressDetails.fields.zipCode.input;
          //   this.formInfo.fieldGroups.proposerDetails.fieldGroups.permanentAddressDetails.fields.city.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.communicationAddressDetails.fields.city.input;
          //   this.formInfo.fieldGroups.proposerDetails.fieldGroups.permanentAddressDetails.fields.state.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.communicationAddressDetails.fields.state.input;
          //   this.formInfo.fieldGroups.proposerDetails.fieldGroups.permanentAddressDetails.fields.country.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.communicationAddressDetails.fields.country.input;
          //   console.log('same as communication address clicked')
          // } else {
          //   console.log('same as communication address not clicked')
          // }
        }
      } */


      // Permanent Address Same as Communication Address
      /* if(ind1 == 'firstLifeAssuredDetails' && ind2 == 'personalDetails' && ind3 == 'firstName') {
        if(!this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.personalDetails.fields.firstName.input && this.firstLifeAssuredDetailsCheck == false) {
          this.firstLifeAssuredDetailsCheck = true;
          var res = confirm("Is your First Life Assured Details is same as Proposer Details?");
          if (res == true) {
            // Personal Details
            this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.personalDetails.fields.firstName.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.firstName.input;
            this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.personalDetails.fields.lastName.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.lastName.input;
            this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.personalDetails.fields.maritalStatus.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.maritalStatus.input;
            this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.personalDetails.fields.dateOfBirth.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.dateOfBirth.input;
            this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.personalDetails.fields.gender.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.gender.input;
            this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.personalDetails.fields.mobileNumber.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.mobileNumber.input;
            //this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.personalDetails.fields.stdNumber.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.stdNumber.input;
            //this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.personalDetails.fields.landLineNumber.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.landLineNumber.input;
            this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.personalDetails.fields.residentStatus.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.residentStatus.input;
            //this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.personalDetails.fields.aadharNumber.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.aadharNumber.input;
            this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.personalDetails.fields.nationality.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.nationality.input;
            this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.personalDetails.fields.zipCode.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.communicationAddressDetails.fields.zipCode.input;
            //this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.personalDetails.fields.zipCode.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.zipCode.input;

            // Professional Details
            this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.qualification.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.qualification.input;
            this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.annualIncome.input = parseInt(this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.annualIncome.input);
            this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.occupation.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.occupation.input;
            this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.nameOfOrganisation.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.nameOfOrganisation.input;
            this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.nameOfOrgDesc.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.nameOfOrgDesc.input;
            this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.organisationType1.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.organisationType1.input;
            //this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.organisationType2.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.organisationType2.input;
            //this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.organisationDesc.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.organisationDesc.input;
            this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.industryType.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.industryType.input;
            this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.industryTypeDesc.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.industryTypeDesc.input;
            this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.industryTypeQuestion.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.industryTypeQuestion.input;
            //this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.designation.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.designation.input;
            //this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.designation.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.designation.input;

            // KYC Details
            this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.kycDetails.fields.idProof.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.kycDetails.fields.idProof.input;
            this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.kycDetails.fields.addressProof.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.kycDetails.fields.addressProof.input;
            this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.kycDetails.fields.ageProof.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.kycDetails.fields.ageProof.input;
            this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.kycDetails.fields.itProof.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.kycDetails.fields.itProof.input;
            this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.kycDetails.fields.incomeProof.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.kycDetails.fields.incomeProof.input;
            // this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.kycDetails.fields.otherIDDesc.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.kycDetails.fields.otherIDDesc.input;
            // this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.kycDetails.fields.otherIDNumber.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.kycDetails.fields.otherIDNumber.input;
            // this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.kycDetails.fields.otherIDExpiryDate.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.kycDetails.fields.otherIDExpiryDate.input;

            // NRI Details
            this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.nriDetails.fields.passportNumber.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.nriDetails.fields.passportNumber.input;
            this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.nriDetails.fields.dateOfArrivingIndia.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.nriDetails.fields.dateOfArrivingIndia.input;
            this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.nriDetails.fields.dateOfLeavingIndia.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.nriDetails.fields.dateOfLeavingIndia.input;
            this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.nriDetails.fields.durationOfStayYears.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.nriDetails.fields.durationOfStayYears.input;
            this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.nriDetails.fields.durationOfStayMonths.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.nriDetails.fields.durationOfStayMonths.input;
            this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.nriDetails.fields.countryOfResidence.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.nriDetails.fields.countryOfResidence.input;
            this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.nriDetails.fields.countryName.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.nriDetails.fields.countryName.input;
            this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.nriDetails.fields.tinNo1.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.nriDetails.fields.tinNo1.input;
            this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.nriDetails.fields.tinNo2.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.nriDetails.fields.tinNo2.input;
            this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.nriDetails.fields.tinNo3.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.nriDetails.fields.tinNo3.input;
            this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.nriDetails.fields.birthCountry.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.nriDetails.fields.birthCountry.input;
            this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.nriDetails.fields.placeOfBirth.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.nriDetails.fields.placeOfBirth.input;
            this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.nriDetails.fields.countryOfNationality.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.nriDetails.fields.countryOfNationality.input;
            this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.nriDetails.fields.taxResidentUS.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.nriDetails.fields.taxResidentUS.input;
            this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.nriDetails.fields.purposeOfStay.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.nriDetails.fields.purposeOfStay.input;
            this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.nriDetails.fields.nameOfEmployer.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.nriDetails.fields.nameOfEmployer.input;
            this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.nriDetails.fields.trevelDetails.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.nriDetails.fields.trevelDetails.input;
            this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.nriDetails.fields.bankType.input = this.formInfo.fieldGroups.proposerDetails.fieldGroups.nriDetails.fields.bankType.input;
          }
          
        }
      } */

      //console.log('path: ', path);
    },
    submitForm(sectionIndex) {
      self = this;
      // Do something...

      // console.log("form submit called", sectionIndex);
      //console.log('tabs Array:', this.tabsArr);

      // Re-setting flag variables
      this.permanentAddressDetailsCheck = false;
      this.firstLifeAssuredDetailsCheck = false;


      this.errorCounter = 0;
      this.$nextTick(() => {
        //this.$refs.myForm.submit();

        console.log('Submitted Data: ', this.formInfo);
        //console.log('Submitted tab Data: ', this.formInfo['fieldGroups'][sectionIndex]);
        let subArray = this.formInfo['fieldGroups'][sectionIndex];
        
        // ############# Validation Section #############

        $(".error-msg").text('');
        // If Fields
        var scrollTop = '';
        for (var key in subArray.fields) {
          let data = subArray.fields[key];
          
          if (this.checkVisibility(data.visibility)) {
            let errorTxt = this.getDescriptionTextArray(data.description);
            //if((data.mandatory == '1') && (!data.input)) {
            if (data.input && data.type == 'currency') {
              console.log('inside currency: f ', data);
              var regex = /[.,]/g;
              let income = data.input.toString().replace(regex, '');
              data.input = income;
            }
              if ((data.mandatory == '1') && (data.type == 'checkbox' && data.input == false)) {
                this.errorCounter++;
                if (scrollTop == '') {
                  scrollTop = data.name;
                }
                $("#error_" + data.id).text('This field is required');
                console.log('invalid 1  mandatory:', data);
              } else if ((data.mandatory == '1') && (data.input.toString().length == 0)) {
              this.errorCounter++;
              if (scrollTop == '') {
                scrollTop = data.name;
              }
              // console.log(scrollTop);
              console.log('invalid fields: mandatory: dt', data.input, data.name);
              $("#error_" + data.id).text('This field is required');
            } else if (data.input && (data.pattern) && (this.checkPattern(data.input, data.pattern) == false)) {
              this.errorCounter++;
              if (scrollTop == '') {
                scrollTop = data.name;
              }
              $("#error_" + data.id).text(errorTxt[0]);
              console.log('data.id: ', data.id);
              console.log('rules 1 Incorrect: ', data.pattern);
            } else if (data.type == 'number' && ((data.input.toString().length > 0 && (data.max) ))) {
              if((data.input < data.min || data.input > data.max)){
                  this.errorCounter++;
                  if (scrollTop == '') {
                    scrollTop = data.name;
                  }
                  $("#error_" + data.id).text('Value should be between ' + data.min + ' and ' + data.max);
             }
              else if((this.checkValidation(data.validation, data.description) == false )){
                this.errorCounter++;
                if (scrollTop == '') {
                scrollTop = data.name;
              }
              
              $("#error_" + data.id).text(data.description);
              }
              console.log('rules number Incorrect: ', data.validation);
            } 
            else if (data.type == 'text'){
              if(data.max && data.mandatory == 1)
              {
                if(data.input.length > data.max)
                {
                  this.errorCounter++;
                  $("#error_" + data.id).text("Maximum allowed field length is " + data.max);
                }                      
                if(data.input.length < data.min)
                {
                  this.errorCounter++;
                  $("#error_" + data.id).text("Minimum allowed field length is " + data.min);
                }
              }
            }
            else if ((data.validation) && (data.input) && (this.checkValidation(data.validation, data.label) == false)) {
              this.errorCounter++;
              if (scrollTop == '') {
                scrollTop = data.name;
              }
              console.log('invalid fields rules: ', data.validation);
              $("#error_" + data.id).text(errorTxt[1]);
            }

          } else {
            // setting blank, previously filled data
            this.formInfo['fieldGroups'][sectionIndex]['fields'][key]['input'] = "";
          }
        }
        if (scrollTop != '') {
          var ele = document.getElementById(scrollTop);
          if(ele){
            ele.scrollIntoView();
            ele.focus();
            ele.placeholder = ele.getAttribute("ph");
          }
        }

        // If FieldGroups
        for (var key in subArray.fieldGroups) {

          let fieldsArr = subArray.fieldGroups[key].fields;
        
          for (var key2 in fieldsArr) {
            let data = fieldsArr[key2];
            
            //console.log('rules 1: ', data.name, data.validation);

            if (this.checkVisibility(data.visibility) && this.checkVisibility(subArray.fieldGroups[key].visibility)) {
              let errorTxt = this.getDescriptionTextArray(data.description);
              if (data.input && data.type == 'currency') {
                console.log('inside currency: f ', data);
                var regex = /[.,]/g;
                let income = data.input.toString().replace(regex, '');
                data.input = income;
              }
              if ((data.mandatory == '1') && (data.type == 'checkbox' && data.input == false)) {
                this.errorCounter++;
                if (scrollTop == '') {
                  scrollTop = data.name;
                }
                $("#error_" + data.id).text('This field is required');
                console.log('invalid 1  mandatory:', data);
                
              
              }
              else if(data.type=="multi-select" && (data.mandatory == '1') && (data.input.length==0))
                {
                  // if ((data.mandatory == '1') && (data.input.length==0)) {
                  
                  this.errorCounter++;
                  if (scrollTop == '') {
                    scrollTop = data.name;
                  }
                  $("#error_" + data.id).text('This field is required');
                  
                // } 
                } 
              else if ((data.mandatory == '1') && (data.input.toString().length == 0)) {
                this.errorCounter++;
                if (scrollTop == '') {
                  scrollTop = data.name;
                }
                $("#error_" + data.id).text('This field is required');
                console.log('invalid 1  mandatory:', data);
              } else if (data.input && (data.pattern) && (this.checkPattern(data.input, data.pattern) == false)) {
                this.errorCounter++;
                if (scrollTop == '') {
                  scrollTop = data.name;
                }
                $("#error_" + data.id).text(errorTxt[0]);
                console.log('data.id: ', data.id);
                console.log('rules 1 Incorrect: ', data.pattern);
              } else if (data.type == 'number' && ((data.input.toString().length > 0 && (data.max) ))) {
                  if((data.input < data.min || data.input > data.max)){
                                              this.errorCounter++;
                        if (scrollTop == '') {
                          scrollTop = data.name;
                        }
                        $("#error_" + data.id).text('Value should be between ' + data.min + ' and ' + data.max);
                  }
                    else if((this.checkValidation(data.validation, data.description) == false )){
                      this.errorCounter++;
                      if (scrollTop == '') {
                      scrollTop = data.name;
                    }
                    
                    $("#error_" + data.id).text(data.description);
                    }
                 } else if (data.type == 'text'){
                    if(data.max && data.mandatory == 1)
                  {
                    if(data.input.length > data.max)
                    {
                      this.errorCounter++;
                      $("#error_" + data.id).text("Maximum allowed field length is " + data.max);
                    }                      
                    if(data.input.length < data.min)
                    {
                      this.errorCounter++;
                      $("#error_" + data.id).text("Minimum allowed field length is " + data.min);
                    }
                      
                  }
              }else if ((data.validation != null) && (data.input) && (this.checkValidation(data.validation, data.description) == false)) {
                this.errorCounter++;
                if (scrollTop == '') {
                  scrollTop = data.name;
                }
                $("#error_" + data.id).text(errorTxt[1]);
                console.log('rules 1 invalid: ', data.validation);
              }

            } else {
              // setting blank, previously filled data
              this.formInfo['fieldGroups'][sectionIndex]['fieldGroups'][key]['fields'][key2]['input'] = "";

            }

          }


          // 33333
          for (var key2 in subArray.fieldGroups[key].fieldGroups) {
            let fieldsArr2 = subArray.fieldGroups[key].fieldGroups[key2].fields;
           
            for (var key3 in fieldsArr2) {
              let data = fieldsArr2[key3];
             
              if (this.checkVisibility(data.visibility) && this.checkVisibility(subArray.fieldGroups[key].visibility) && this.checkVisibility(subArray.fieldGroups[key].fieldGroups[key2].visibility)) {
                let errorTxt = this.getDescriptionTextArray(data.description);
                if (data.input && data.type == 'currency') {
                  console.log('inside currency: f ', data);
                  var regex = /[.,]/g;
                  let income = data.input.toString().replace(regex, '');
                  data.input = income;
                }
                console.log("fieldsArr2 data error",data.input, errorTxt)
                if ((data.mandatory == '1') && (data.type == 'checkbox' && data.input == false)) {
                  this.errorCounter++;
                  if (scrollTop == '') {
                    scrollTop = data.name;
                  }
                  $("#error_" + data.id).text('This field is required');
                  console.log('invalid 1  mandatory:', data);
                } 
                else if(data.type=="multi-select" && (data.mandatory == '1') && (data.input.length==0))
                {
                  // if ((data.mandatory == '1') && (data.input.length==0)) {
                  
                  this.errorCounter++;
                  if (scrollTop == '') {
                    scrollTop = data.name;
                  }
                  $("#error_" + data.id).text('This field is required');
                  
                // } 
                }
                else if ((data.mandatory == '1') && (data.input.toString().length == 0)) {
                  
                  this.errorCounter++;
                  if (scrollTop == '') {
                    scrollTop = data.name;
                  }
                  $("#error_" + data.id).text('This field is required');
                  console.log('fieldGroups3[key2]: ', subArray.fieldGroups[key].fieldGroups[key2].name)
                  console.log('invalid 1 3  mandatory:', data);
                } 
                else if (data.input && (data.pattern) && (this.checkPattern(data.input, data.pattern) == false)) {
                  this.errorCounter++;
                  if (scrollTop == '') {
                    scrollTop = data.name;
                  }
                  $("#error_" + data.id).text(errorTxt[0]);
                  console.log('rules 1 3 Incorrect: ', data.validation);
                } else if (data.type == 'number' && ((data.input.toString().length > 0 && (data.max) ))) {
                    if((data.input < data.min || data.input > data.max)){
                        this.errorCounter++;
                        if (scrollTop == '') {
                          scrollTop = data.name;
                        }
                        $("#error_" + data.id).text('Value should be between ' + data.min + ' and ' + data.max);
                  }
                    else if((this.checkValidation(data.validation, data.description) == false )){
                      this.errorCounter++;
                      if (scrollTop == '') {
                      scrollTop = data.name;
                    }
                    
                    $("#error_" + data.id).text(data.description);
                    }
                } else if (data.type == 'text'){
                  if(data.max && data.mandatory == 1)
                  {
                    if(data.input.length > data.max)
                    {
                      this.errorCounter++;
                      $("#error_" + data.id).text("Maximum allowed field length is " + data.max);
                    }                      
                    if(data.input.length < data.min)
                    {
                      this.errorCounter++;
                      $("#error_" + data.id).text("Minimum allowed field length is " + data.min);
                    }
                  }
                }
                
                else if ((data.validation != null) && (data.input) && (this.checkValidation(data.validation, data.description) == false)) {
                  this.errorCounter++;
                  if (scrollTop == '') {
                    scrollTop = data.name;
                  }
                  $("#error_" + data.id).text(errorTxt[1]);
                  console.log('rules 1 3 invalid: ', data.validation);
                }


              } else {
                // setting blank, previously filled data
                this.formInfo['fieldGroups'][sectionIndex]['fieldGroups'][key]['fieldGroups'][key2]['fields'][key3]['input'] = "";

              }

            }



          }
          // 33333
        }

        if (scrollTop != '') {
          
          var ele = document.getElementById(scrollTop);
          if(ele){
            ele.scrollIntoView();
            ele.focus();
            ele.placeholder = ele.getAttribute("ph");
          }

        }

        console.log('this.errorCounter: ', this.errorCounter)
        if (this.errorCounter == '0') {

          this.submittedTab = sectionIndex;

          console.log('submitted data modified: ', this.formInfo);

          // Calling Save Proposal
          this.saveProposal(sectionIndex); 
        }

        // ########### ./End Validation Section ###########
      });

    },
    saveProposalStatus(status){
      let propoalData = {
        "proposal_ui_id": this.proposalUiId.toString(),
        "journey_status": status
      };

      var headers = {
        "quoteId":this.uiQuoteId,
        Authorization: "Basic " + window.btoa(process.env.VUE_APP_DATA_PER_USERNPASS),
        "Content-Type": "application/json",
      };
      
      //console.log('qData: ', qData);
      this.backendApi("saveProposal", propoalData, headers)
        .then((response) => {

        })
        .catch((error) => {
          console.log('error saving proposal status', error);
        });
    },
    activeNextTab(currentTab) {
      let ckk = this.checkVisibility(this.formInfo['fieldGroups'][currentTab]['visibility']);
      if(ckk == true) {
        // Setting Up Filled Tab on localstorage
        let filledDataTab=""
        if(JSON.parse(sessionStorage.getItem("filledDataTab")))
        {
          filledDataTab = JSON.parse(sessionStorage.getItem("filledDataTab"))
        }
        if(this.tabsArr.indexOf(currentTab) ==0 && !filledDataTab)
        {
           localStorage.setItem("filledDataTab", JSON.stringify(currentTab));
           sessionStorage.setItem("filledDataTab", JSON.stringify(currentTab));
        }
        else if(this.tabsArr.indexOf(currentTab)>=this.tabsArr.indexOf(filledDataTab))
        {
          localStorage.setItem("filledDataTab", JSON.stringify(currentTab));
           sessionStorage.setItem("filledDataTab", JSON.stringify(currentTab));
        }
        
       
     
        localStorage.setItem("filledData", JSON.stringify(this.formInfo));
        sessionStorage.setItem("filledData", JSON.stringify(this.formInfo));
      }

      var tabIndex = this.tabsArr.indexOf(currentTab);
      let nextTab;
      if (tabIndex < this.tabsArr.length - 1) {
        nextTab = this.tabsArr[tabIndex + 1];
      } else {
        // If conntrol is on last tab
        if(sessionStorage.getItem('diy') == "true" && (this.formInfo['manufacturerId']=="TATA" || this.formInfo['manufacturerId']=="ICICI" || this.formInfo['manufacturerId']=="ABSLIFEI" || this.formInfo['manufacturerId']=="HDFC")){
          this.saveProposalStatus("Proposal Review");
          this.$router.push(`/proposaldetailscheckdiy?proposalId=${this.proposalUiId}&transactionId=${this.rmData['transaction_id']}`);
        } else {
          this.$router.push('/proposaldetailscheck');

        }
      }
      if (nextTab) {
        this.activeTab(nextTab);
      }
    },
    switchTab(value,ind)
    {
       let tabToActive=ind
      var tabIndex = this.tabsArr.indexOf(tabToActive);
      if (tabIndex >= 0) {
        console.log('inside prev tab true')
        let prevTab = this.tabsArr[tabIndex];
        let ckk = this.checkVisibility(this.formInfo['fieldGroups'][prevTab]['visibility']);
        if (ckk == true) {
          this.activeTab(prevTab);
        } else {
          this.activePrevTab(prevTab);
        }
      } else {
        this.$router.push('/checkout');
      }
    

    },
    activeTabAfterReload(filledDataTab)
    {
      let tabIndex=this.tabsArr.indexOf(filledDataTab)
      for(let i=0;i<=tabIndex;i++)
      {
        let tab=this.tabsArr[i];
        if ($("#proposalId_" + tab).hasClass("show active")) {
            $("#proposalId_" + tab).removeClass("show active");
          }

         if ($("#tablink_" + tab).hasClass("disabled")) {
          $("#tablink_" + tab).removeClass("disabled");
          $("#tablinka_" + tab).removeClass("disabled");
        }
        $("#tablink_" + tab).addClass('active');
        $("#tablinka_" + tab).addClass('active');
        $("#tablinka_" + tab).attr("aria-selected", "true");
      }
      $("#proposalId_" + filledDataTab).addClass('show active');
    },
    activeTab(tabToActive) {
      console.log('ativeTab Called', tabToActive)

      // Scroll Top
      this.scrollToTop();

      console.log('vb logic: ', this.formInfo['fieldGroups'][tabToActive]['visibility']);
      let ckk = this.checkVisibility(this.formInfo['fieldGroups'][tabToActive]['visibility']);
      if (ckk == true) {
        //console.log('this.tabsArr', this.tabsArr)
        this.currentTab = tabToActive;
        sessionStorage.setItem("currentTab", JSON.stringify(this.currentTab));

        for (var i = 0; i < this.tabsArr.length; i++) {
          var tab = this.tabsArr[i];
          if ($("#tablink_" + tab).hasClass("active")) {
            // $("#tablink_" + tab).removeClass("active");
            // $("#tablinka_" + tab).removeClass("active");
            // $("#tablinka_" + tab).attr("aria-selected", "false");
            // $("#tablink_" + tab).addClass("disabled");
            // $("#tablinka_" + tab).addClass("disabled");
          }
          /* if ($("#tablink_" + tab).hasClass("disabled")) {
            $("#tablink_" + tab).removeClass("disabled");
          } */
          if ($("#proposalId_" + tab).hasClass("show active")) {
            $("#proposalId_" + tab).removeClass("show active");
          }
          
          if ($("#tablink_" + tabToActive).hasClass("completed")) {
            $("#tablink_" + tabToActive).removeClass("completed");
            $("#tablinka_" + tabToActive).removeClass("completed");
          }
        }

        if ($("#tablink_" + tabToActive).hasClass("disabled")) {
          $("#tablink_" + tabToActive).removeClass("disabled");
          $("#tablinka_" + tabToActive).removeClass("disabled");
        }
        $("#tablink_" + tabToActive).addClass('active');
        $("#tablinka_" + tabToActive).addClass('active');
        $("#tablinka_" + tabToActive).attr("aria-selected", "true");
        $("#proposalId_" + tabToActive).addClass('show active');
      } else {
        this.activeNextTab(tabToActive);
      }

    },
    goBack() {
      this.currentTab = JSON.parse(sessionStorage.getItem("currentTab"));
      console.log('current tab: ', this.currentTab)
      var tabIndex = this.tabsArr.indexOf(this.currentTab);
      this.activePrevTab(this.currentTab);
    },
    activePrevTab(tabToActive) {
      
      var tabIndex = this.tabsArr.indexOf(tabToActive);
      if (tabIndex > 0) {
        console.log('inside prev tab true')
        let prevTab = this.tabsArr[tabIndex - 1];
        //console.log('uuu: ', this.formInfo['fieldGroups'][prevTab]['visibility']);
        let ckk = this.checkVisibility(this.formInfo['fieldGroups'][prevTab]['visibility']);
        if (ckk == true) {
          this.activeTab(prevTab);
        } else {
          this.activePrevTab(prevTab);
        }
      } else {
        this.$router.push('/checkout');
      }
    },
    getColSize(val, ind1, ind2) {
      if (val == 'file') {
        return 'col-md-12 form-control1 label-clicked';
      } else if (val == 'checkbox') {
        return 'col-md-12 form-control1 label-clicked';
      } else if (val == 'static') {
        return 'col-md-12 form-control1 label-clicked';
      } else if (val == 'multi-select') {
        return 'col-md-12 form-control1 label-clicked';
      } else if (val == 'boolean' && ind1 == 'firstLifeAssuredDetails' && ind2 == 'healthQuestionnaire') {
        return 'col-md-12 form-control1 label-clicked';
      } else if (ind1 == 'ACR') {
        return 'col-md-6 form-control1 label-clicked text-fild-wrap';
      }
      else {
        return 'col-md-4 form-control1 label-clicked';
      }
    },
    scrollToTop() {
      $('html, body').animate({ scrollTop: 0 }, 'fast');
    },
    sortDropDown(list) {
      //return list;
      if (list) {
        list = list.sort(sortbyname);
        function sortbyname(a, b) {
          if (a.Text.toLowerCase() < b.Text.toLowerCase()) return -1;
          if (a.Text.toLowerCase() > b.Text.toLowerCase()) return 1;
          return 0;
        }
        return list;
      }
    },
    async getcityBypincode(val) {

      return getPinCodes();

      /*  var host = window.location.protocol + "//" + window.location.host;
       console.log('host: ', host)
        const response = await axios.get(host + '/pincode_master.json?zipcode='+ val)
          return response.data.data; */

    },
    getMultiSelectValues(data) {
      //return data;
      // console.log('getMultiSelectValues called: ', data)
      let tempData = [];
      data.forEach(
        (element, index) => {
          tempData.push(element.Text)
        }
      );
      //console.log('getMultiSelectValues called: ', tempData)
      return tempData;
    },
    checkPattern(value, pattern) {
      try {
        return eval(eval(pattern).test(value));
      }
      catch {
        console.log('Invalid regular expression: ', pattern,value);
        return true;
      }
    },
    getInsurerImgs(value) {
      let img = getInsuranceImages();
      return require("../../assets/images/" + img[value]);
    },
    selectFiles(event, data, ind, ind2, ind3 = "", ind4="") {
      console.log('selectFiles called', data, 'ind: ', ind, 'ind2: ', ind2, 'ind3: ', ind3, 'ind4: ', ind4);
      let file = event.target.files[0];
      console.log(file.type);
      let fileSize = Math.round(file.size / 1024); 
      console.log('File :', file)
      console.log('File Size in kb:', fileSize)
      $("#error_" + data.id).text('');
      let errorTxt = this.getDescriptionTextArray(data.description);
      if(data.validation) {
        // let tmpVald = data.validation.replace(/'/g, "");
        // console.log('tmpVald: ', tmpVald);
        // let allowedArray = tmpVald.split(", ");
        console.log('file: ', file);
        // console.log('allowedArray: ', allowedArray);
        // let extension = file.name.split('.').pop();
        // let isValid = allowedArray.includes(extension);
        // console.log('isValid: ', isValid)
        if(file.name.split('.').length>2) {
          $("#error_" + data.id).text('The file type you selected is not allowed');
        } else if(fileSize <= data.min || fileSize >= data.max) {
          $("#error_" + data.id).text(errorTxt[0]);
        } else {
          console.log('success block');
          $("#error_" + data.id).text('');
          this.getBase64(file).then(
            data => {  
              if(ind4 != "") {
                this.formInfo['fieldGroups'][ind]['fieldGroups'][ind2]['fieldGroups'][ind3]['fields'][ind4]['input'] = data;
              } else if(ind3 != "" && ind4 == "") {
                this.formInfo['fieldGroups'][ind]['fieldGroups'][ind2]['fields'][ind3]['input'] = data;
              } else if(ind2 != "" && ind3 == "" && ind4 == "") {
                this.formInfo['fieldGroups'][ind]['fields'][ind2]['input'] = data;
              }
            } 
          )
        }
      } else {
        if(fileSize <= data.min || fileSize >= data.max) {
          $("#error_" + data.id).text(errorTxt[0]);
        } else if (file.name.split('.').length>2){
          $("#error_" + data.id).text('The file type you selected is not allowed');
        } 
        // else if (this.insurerCode == 'TATA' && data.name == "picUpload" && file.name.split('.').pop() != 'jpg'){
        //     $("#error_" + data.id).text('The file type should be "JPG" only');
        // } 
        else {
          $("#error_" + data.id).text('');
          this.getBase64(file).then(
            data => {
              if(ind4 != "") {
                this.formInfo['fieldGroups'][ind]['fieldGroups'][ind2]['fieldGroups'][ind3]['fields'][ind4]['input'] = data;
              } else if(ind3 != "" && ind4 == "") {
                this.formInfo['fieldGroups'][ind]['fieldGroups'][ind2]['fields'][ind3]['input'] = data;
              } else if(ind2 != "" && ind3 == "" && ind4 == "") {
                this.formInfo['fieldGroups'][ind]['fields'][ind2]['input'] = data;
              }
            } 
          )
        }
        
      }
      
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
      });
    },
    previewFiles(imgData) {
      console.log('previewFiles called: ', imgData)
      const type = imgData.split(';')[0].split('/')[0].split(':')[1];
      console.log('imgData type:', type);
      this.typeToDisplay = type;
      this.fileToDisplay = imgData;
    },
    removeFile(id, ind, ind2, ind3 = "", ind4 = "") {
      $("#"+id).val(null);
      if(ind4 != "") {
        this.formInfo['fieldGroups'][ind]['fieldGroups'][ind2]['fieldGroups'][ind3]['fields'][ind4]['input'] = "";
      } else if(ind3 != "" && ind4 == "") {
        this.formInfo['fieldGroups'][ind]['fieldGroups'][ind2]['fields'][ind3]['input'] = "";
      } else if(ind2 != "" && ind3 == "" && ind4 == "") {
        this.formInfo['fieldGroups'][ind]['fields'][ind2]['input'] = "";
      }
    },
    getDescriptionTextArray(str) {
      let tempArr = [];
      if(str) {
        var check = str.includes("#");
        if(check) {
          tempArr = str.split("#");
        } else {
          tempArr[0] = str;
          tempArr[1] = str;
        }
      } else {
        tempArr[0] = "";
        tempArr[1] = "";
      }
      
      return tempArr;
    },
    getDescriptionText(str) {
      let descText = "";
      if(str) {
        var check = str.includes("#");
        if(check) {
          let tempArr = str.split("#");
          descText = tempArr[0];
        } else {
          descText = str;
        }
      } else {
        descText = "";
      }
      
      return descText;
    },
    convertProposalJson()
    {
      console.log("response data after last tab",this.formInfo)
      this.proposalJson["data"]={}
      this.proposalJson["data"]=JSON.parse(JSON.stringify(this.formInfo))
      console.log("pro json",this.proposalJson)
      // for(let key in this.responseData)
      // {
      //   if(key==sectionIndex)
      //   {
          // this.proposalJson["data"]["fieldGroups"][sectionIndex]=JSON.parse(JSON.stringify(this.responseData[key]))
          for(let grandParentKey in this.proposalJson["data"])
          {
            // if(grandParentKey!="fieldGroups")
            // {
            //   delete this.proposalJson["data"][grandParentKey]
            // }
            if(grandParentKey=="fieldGroups"){
            //level 1
            for(let parentKey in this.proposalJson["data"][grandParentKey])
            {

            
          for(let checkfg in this.proposalJson["data"][grandParentKey][parentKey] )
          {
             if(checkfg!="fieldGroups" && checkfg!="fields")
             {
              
              console.log("section",checkfg)
              delete this.proposalJson["data"][grandParentKey][parentKey][checkfg]
             }
             else if(checkfg=="fieldGroups")
             {
              //level 2
                for(let childKey in this.proposalJson["data"][grandParentKey][parentKey][checkfg])
                {


                   for(let checkChildfg in this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey])
                   {
                    if(checkChildfg!="fields" && checkChildfg!="fieldGroups")
                    {
                      //  console.log("checkChildfg",checkChildfg)
                      delete this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg]
                    }
                    else if(checkChildfg=="fields")   
                    {
                        for(let childKeyfieldsChild in this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg])
                        {
                            for(let childKeyfieldsChildKey in this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg][childKeyfieldsChild])
                            {
                                 if(childKeyfieldsChildKey!="input")
                                 {
                                  delete this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg][childKeyfieldsChild][childKeyfieldsChildKey]
                                 }
                            }

                        }
                    }
                    else if(checkChildfg=="fieldGroups")
                    {
                      //level 3
                        for(let fg_fg_fg_key in this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg])
                        {
                              for(let fg_fg_fg_key_f in this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg][fg_fg_fg_key])
                              {
                                if(fg_fg_fg_key_f!="fields" && fg_fg_fg_key_f!="fieldGroups")
                                {
                                  delete this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg][fg_fg_fg_key][fg_fg_fg_key_f]
                                }
                                else if(fg_fg_fg_key_f=="fields")
                                {
                                  for(let fg_fg_fg_key_f_key in this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg][fg_fg_fg_key][fg_fg_fg_key_f])
                                  {
                                      for(let fg_fg_fg_key_f_key_input in this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg][fg_fg_fg_key][fg_fg_fg_key_f][fg_fg_fg_key_f_key])
                                      {
                                        if(fg_fg_fg_key_f_key_input!="input")
                                        {
                                          delete this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg][fg_fg_fg_key][fg_fg_fg_key_f][fg_fg_fg_key_f_key][fg_fg_fg_key_f_key_input]

                                        }
                                      }
                                  }
                                }
                                else if(fg_fg_fg_key_f=="fieldGroups")
                                  {
                                    //level 4
                                      for(let fg_fg_fg_key_f_key in this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg][fg_fg_fg_key][fg_fg_fg_key_f])
                                      {
                                            for(let fg_fg_fg_key_f_key_f in this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg][fg_fg_fg_key][fg_fg_fg_key_f][fg_fg_fg_key_f_key])
                                            {
                                              if(fg_fg_fg_key_f_key_f!="fields" && fg_fg_fg_key_f_key_f!="fieldGroups")
                                              {
                                                delete this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg][fg_fg_fg_key][fg_fg_fg_key_f][fg_fg_fg_key_f_key][fg_fg_fg_key_f_key_f]
                                              }
                                              else if(fg_fg_fg_key_f_key_f=="fields")
                                              {
                                                for(let fg_fg_fg_key_f_key_f_key in this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg][fg_fg_fg_key][fg_fg_fg_key_f][fg_fg_fg_key_f_key][fg_fg_fg_key_f_key_f])
                                                {
                                                    for(let fg_fg_fg_key_f_key_f_key_input in this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg][fg_fg_fg_key][fg_fg_fg_key_f][fg_fg_fg_key_f_key][fg_fg_fg_key_f_key_f][fg_fg_fg_key_f_key_f_key])
                                                    {
                                                      if(fg_fg_fg_key_f_key_f_key_input!="input")
                                                      {
                                                        delete this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg][fg_fg_fg_key][fg_fg_fg_key_f][fg_fg_fg_key_f_key][fg_fg_fg_key_f_key_f][fg_fg_fg_key_f_key_f_key][fg_fg_fg_key_f_key_f_key_input]

                                                      }
                                                    }
                                                }
                                              }
                                            }
                                      }
                                  }
                              }
                        }
                    }
                   }

                    
                }
             }
             else if(checkfg=="fields")
             {
              for(let childofFields in this.proposalJson["data"][grandParentKey][parentKey][checkfg])
              {
                   for(let childOfFieldsOfChildKey in this.proposalJson["data"][grandParentKey][parentKey][checkfg][childofFields])
                   {
                      if(childOfFieldsOfChildKey!="input")
                      {
                        delete this.proposalJson["data"][grandParentKey][parentKey][checkfg][childofFields][childOfFieldsOfChildKey]
                      }
                   }
              }
             }
          }
            }
            }
          }
          
          console.log("after filter proposal json",this.proposalJson)
      //   }
      // } 
    }

  },

};
</script>

<style scoped lang="scss">
@import "~@vueform/multiselect/themes/default.css";

@import "~@/assets/scss/components/proposal-page.scss";
</style>