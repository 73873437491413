<template>
    <div>
      <div v-if="this.rmData['RoleId']=='1'">
        <innerheader />
      </div>
      <div v-else>
        <innerheaderDIY/>
      </div>
        <thankyoureject />
    </div>
</template>

<script>
// @ is an alias to /src
import innerheaderDIY from "@/components/innerheader/innerheader";
import innerheader from "@/components/innerheader/innerHeaderDIY.vue";
import thankyoureject from "@/components/payment/thankYouReject.vue";

export default {
  name: 'thankyourejectdiy',
  components: {
     innerheader,
     thankyoureject,
     innerheaderDIY
  },
  data(){
    return{
       rmData:""
    }
  },
  mounted(){
     if(sessionStorage.getItem("rm_data"))
     {
      this.rmData= JSON.parse(sessionStorage.getItem("rm_data"));
     }
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/components/paymentsuccess.scss';
</style>