export const getRequestData = (api, data) => {
  
  return {
    body: data,
    url: apis[api]["url"],
    baseURL:apis[api]["baseURL"] == undefined ? process.env.VUE_APP_URL : process.env["VUE_APP_URL_" + apis[api]["baseURL"]],
    source:apis[api]["baseURL"] == undefined ? "DEFAULT": apis[api]["baseURL"],
  };
};

const apis = {
  customerInfo:{
    url:"/v2/lifeterm/customerInfo/param1",
    baseURL: "RMD"
  },
  updateInformation:{
    url:"/v2/lifeterm/updateInformation",
    baseURL: "RMD"
  },
  userLogin:{
    url: "/v2/lifeterm/login",
    baseURL: "RMD"
  },
  //get uploded files
  getUploadedFiles:{
    url: "/v2/lifeterm/files/param1",
    baseURL: "RMD"
  },
  //get file from S3 with file path
  getUploadedFileUrl:{
     url:"/v2/lifeterm/file?file_path=param1",
     baseURL: "RMD"
  },
  // saveQuote: {
  //   url: "/v1/quote",
  //   baseURL: "RMD"
  // },
  // saveQuoteResponse: {
  //   url: "/v1/quoteResponse",
  //   baseURL: "RMD"
  // },
  // saveLogData: {
  //   url: "/v1/logData",
  //   baseURL: "RMD"
  // },
  // getLogData: {
  //   url: "/v1/logdata?mobileNumber=param1&DOB=param2",
  //   baseURL: "RMD"
  // },

  //Store payments details in paymentDetails table
  savePaymentData:{
    url: "/v2/lifeterm/paymentDetails",
    baseURL: "RMD"
  },
  getPaymentData:{
    url: "/v2/lifeterm/paymentDetails?proposal_ui_id=param1",
    baseURL: "RMD"
  },
  // getPaymentDetailsByProposalUiId:{
  //   url: "/v2/lifeterm/paymentDetail?proposal_ui_id=param1",
  //   baseURL: "RMD"
  // },
  saveProposal: {
    url: "/v2/lifeterm/proposal/draft",
    baseURL: "RMD"
  },
  // deprecated
  // saveResumeURL:{
  //   url: "/v1/manufacturerResumeURL",
  //   baseURL: "RMD"
  // },
  // getResumeURL: {
  //   url: "/v1/manufacturerResumeURL?proposal_ui_id=param1",
  //   baseURL: "RMD"
  // },
  // deprecated
  // saveApplicationNumber: {
  //   url: "/v1/saveProposalManufacturerId",
  //   baseURL: "RMD"
  // },
  getProposalDetails: {         
    url: "/v2/lifeterm/proposal/draft?proposal_ui_id=param1",
    baseURL: "RMD"
  },
  getQuoteDetails: {         
    url: "/v2/lifeterm/quoteDetails?UiQuoteId=param1",
    baseURL: "RMD"
  },
  getQuoteResponseDetails: {         
    url: "/v2/lifeterm/quoteResponse?UiQuoteId=param1",
    baseURL: "RMD"
  },
  // getToken: {
  //   url: "/v1/getTokenProxy",
  //   baseURL: "RMD"
  // },
  // getSPDetails: {
  //   url: "/v1/SPDetailsProxy",
  //   baseURL: "RMD"
  // },
  getSPDetailsOneSB: {
    url: "/v2/lifeterm/spDetails?manufacturerId=param1",
    baseURL: "RMD"
  },
  
  // getProductInfo: {
  //   url: "/quote/getProductUIData?manufacturerId=param1&productId=param2",
  //   baseURL: "TEST"
  // },
  getPincodeInfo: {
    url: "/v2/lifeterm/zipcode?zipcode=param1",
    baseURL: "RMD"
  },
  getIFSCInfo: {
    url: "/v2/lifeterm/ifsc?ifsc_code=param1",
    baseURL: "RMD"
  },
  triggerPayment: {
    url: "/v2/lifeterm/hdfcPayment/param1",
    baseURL: "RMD"
  },
  getProposal: {
    url: "/v2/lifeterm/proposal?product_id=param1&manufacturer_id=param2&version=param3",
    baseURL: "RMD"
  },
  validateProposal:{
    url: "/v2/lifeterm/proposal/validate",
    baseURL: "RMD"
  },
  // submitProposal: {
  //   url: "proposal?version=1",
  //   baseURL: "PROPOSAL"
  // },
  // getProposalPoll: {
  //   url: "proposal/poll?productId=param1&manufacturerId=param2&requestId=param3",
  //   baseURL: "PROPOSAL"
  // },
  // getReqId: {
  //   url: "/quote"
  // },
  // getLifeTerm: {
  //   url: "/quote/param1"
  // },
  gateCriteria: {
    url: "/v2/lifeterm/gateCriteria?product_id=param1&manufacturer_id=param2&version=param3",
    baseURL:"RMD"
  },
  gateCriteriaSubmit: {
    url: "/v2/lifeterm/gateCriteria?productGroupId=lifeterm&productId=param1&manufacturerId=param2&version=param3",
    baseURL:"RMD"
  },
  getDocRequirements: {
    url: "/v2/lifeterm/document/requirements",
    baseURL: "RMD"
  },
  sendMail: {
    url: "/v2/mail/send",
    baseURL: "RMD"
  },
  ProposalStatus: {
    url: "/v2/lifeterm/proposalStatus?proposal_ui_id=param1&status_id=param2",
    baseURL: "RMD"
  },
  getProposalStatus: {
    url: "/v2/lifeterm/proposalStatus?proposal_ui_id=param1",
    baseURL: "RMD"
  },
  getRmDetails: {
    url: "/v2/lifeterm/user",
    baseURL: "RMD"
  },
  getRmDetailsDIY: {
    url: "/v2/lifeterm/user_diy",
    baseURL: "RMD"
  },
  // getDashboardData:{
  //   url: "/v2/lifeterm/dashboard?pageSize=param1&pageNumber=param2",
  //   baseURL: "RMD"
  // },
  
  // getRequirements:{
  //   url: "/v2/lifeterm/requirements?proposalId=param1",
  //   baseURL: "RMD"
  // },
  
  // sendSMS: {
  //   url: "/sendsms?username=param1&password=param2&to=param3&from=param4&udh=param5&text=param6",
  //   baseURL: "SMS"
  // },
  // getOTP: {
  //   url: "/sendotp",
  //   baseURL: "PROPOSAL"
  // },
  // submitOTP: {
  //   url: "/valotp",
  //   baseURL: "PROPOSAL"
  // },
  uploadDoc: {
    url: "/v2/lifeterm/document/upload",
    baseURL: "RMD"
  },
  // proposalApiGet: {
  //   url: "/v1/proposalApi",
  //   baseURL: "RMD"
  // },
  getWhatsappDetails: {
    url: "/v2/lifeterm/consentMaster",
    baseURL: "RMD"
  },




  getDashboardData:{
    url: "/v2/lifeterm/dashboard?pageSize=param1&pageNumber=param2&columnName=param3&filterText=param4&startDate=param5&endDate=param6",
    baseURL: "RMD"
  },
  saveProposalResponse: {
    url: "/v2/lifeterm/proposalResponse",
    baseURL: "RMD"
  },
  // getPaymentURL: {
  //   url: "/v1/payment/url",
  //   baseURL: "PAYMENT"
  // },
  quotationApi: {
    url: "/v2/lifeterm/quote",
    baseURL: "RMD"
  },
  
  
  sendOtpApi: {
    url: "/v2/lifeterm/otp/send",
    baseURL: "RMD"
  },
  valOtpApi: {
    url: "/v2/lifeterm/otp/validate",
    baseURL: "RMD"
  },
  proposalVersionApi: {
    url: "/v2/lifeterm/proposal/submit",
    baseURL: "RMD"
  },
  proposalPollApi: {
    url: "/v2/lifeterm/proposal/poll?product_id=param1&manufacturer_id=param2&request_id=param3",
    baseURL: "RMD"
  },
  getProductUiDataApi: {
    url: "/v2/lifeterm/product/information?manufacturer_id=param1&product_id=param2",
    baseURL: "RMD"
  },
  paymentUrlApi: {
    url: "/v2/lifeterm/payment",
    baseURL: "RMD"
  },
  quotationPoll: {
    url: "/v2/lifeterm/quote/poll?request_id=param1",
    baseURL: "RMD"
  },
  downloadReceipt:{
    url: "/v2/lifeterm/receipt/download",
    baseURL: "RMD"
  },
  uploadDocRequiremnts:{
    url: "/v2/lifeterm/documentUpload",
    baseURL: "RMD"
  },
  getUploadDocRequiremnts:{
    url: "/v2/lifeterm/documentUpload?id=param1",
    baseURL: "RMD"
  },
  getPendingUploadDocRequiremnts:{
    url: "/v2/lifeterm/documentUpload/pendingList?id=param1",
    baseURL: "RMD"
  },
  uploadDocStatus:{
    url: "/v2/lifeterm/documentUpload/status?applicationNumber=param1&docCategory=param2&status=param3",
    baseURL: "RMD"
  // },
  // redirectpage:{
  //   url: "/v1/1sb/redirect",
  //   baseURL: "RMD"
  },
  generateToken:{
    url: "/v2/token?tokenType=SESSION",
    baseURL: "RMD"
  },  
  refreshToken:{
    url: "/v2/token/refresh?tokenType=SESSION",
    baseURL: "RMD"
  },
  getProductName: {
    url: "/v2/lifeterm/productName?product_id=param1&manufacturer_id=param2",
    baseURL: "RMD"
  },
  sendEmailandSMS: {
    url: "/v2/lifeterm/communication",
    baseURL: "RMD"
  },
  getQuoteDetail:{
    url: "/v2/lifeterm/quoteDetail",
    baseURL: "RMD"
  },
  logout:{
    url:"/v2/lifeterm/logout",
    baseURL: "RMD"
  },
  proposalAppStatus:{
    url:"/v2/lifeterm/proposal/status",
    baseURL:"RMD"
  }
};