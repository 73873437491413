<template>
  <div>
    <div
      class="d-flex justify-content-between align-items-center ml-5 mr-5 mt-3"
    >
      <div class="d-flex align-items-center">
        <span class="mr-4">
          <img
            v-if="this.insuranceCompanyCode"
            :src="getInsurerImgs(this.insuranceCompanyCode)"
            alt=""
          />
        </span>
        <span class="d-inline-block align-middle brand-text">
          {{ this.productName }}
        </span>
      </div>
      <div class="propno">
        <span v-if="this.applicationNo"
          >Insurer Application Number: {{ this.applicationNo }}</span
        ><br />
        <span v-if="this.referenceNumber"
          >Reference Number: {{ this.referenceNumber }}</span
        >
      </div>
    </div>
    <div class="container">
      <div class="success-img-holder text-center mt-5 mb-3">
        <figure>
          <img src="~@/assets/images/payment_scuccessful.png" alt="" />
        </figure>
        <figcaption class="mb-4">
          <!-- <h1>Payment Successful!</h1> -->
          <h1>Thank You</h1>
          <br />
          <div v-if="this.proposalStatus">
            <span>{{ this.proposalStatus }}.</span><br /><br />
            <div
              class="prop-submission"
              v-if="this.proposalStatus=='Requirements Pending'"
              @click="reUploadDoc()"
            >
              <button
                class="d-flex dwnld-btn align-items-center justify-content-center"
              >
                Upload documents
              </button>
            </div>
          </div>

          <div v-else>
            <span
              >An email has been sent successfully to the customer’s registered
              email Id - {{ this.maskid }} and SMS on Mobile number
              {{ this.hiddennum }}. </span
            ><br /><br />
            <span
              >Customer has to review and submit the proposal along with making
              a payment.</span
            ><br />
          </div>
        </figcaption>
      </div>
    </div>
  </div>
  <hr>
  <complianceText/>

</template>


<script>
import complianceText from "@/components/footer/complianceText.vue";
import { getInsuranceImages, getDistributer } from "@/mixins/common";
export default {
  name: "paymnt",
  mixins: [getInsuranceImages, getDistributer],
  props: {},
  components: {
    complianceText
  },
  mounted() {
    this.proposalStatus = JSON.parse(
      sessionStorage.getItem("proposalStatusValue")
    );
    console.log(this.proposalStatus);
    if (sessionStorage.getItem("uiQuoteId"))
      this.referenceNumber = sessionStorage.getItem("uiQuoteId");

    this.selectedPlan = JSON.parse(
      sessionStorage.getItem("selected_plan_data")
    );
    if(sessionStorage.getItem("applicationNumber")){
      this.applicationNo = JSON.parse(sessionStorage.getItem("applicationNumber"));
    }
    this.insuranceCompanyCode =
      this.selectedPlan["insuranceAndProducts"]["insuranceCompanyCode"];
    this.productName = this.selectedPlan["insuranceAndProducts"]["productName"];
    if (
      this.selectedPlan["insuranceAndProducts"]["insuranceCompanyCode"] ==
      "HDFC"
    ) {
      this.applicationNo =
        this.selectedPlan["trackInfo"]["varFields"][0]["fieldValue"];
    }
    let s = this.$route.query.msg;
    this.proposalStatus = s;
    this.userData = JSON.parse(sessionStorage.getItem("filledData"));
    this.email =
      this.userData["fieldGroups"]["proposerDetails"]["fieldGroups"][
        "personalDetails"
      ]["fields"]["email"]["input"];
    this.mobile =
      this.userData["fieldGroups"]["proposerDetails"]["fieldGroups"][
        "personalDetails"
      ]["fields"]["mobileNumber"]["input"];
    console.log("emailmob", this.email, this.mobile);

    console.log("data", this.userData);
    this.maskid = this.email.replace(
      /^(.)(.*)(.@.*)$/,
      (_, a, b, c) => a + b.replace(/./g, "*") + c
    );
    this.hiddennum =
      this.mobile.slice(0, 2) + this.mobile.slice(2).replace(/.(?=...)/g, "*");
  },
  data() {
    return {
      proposalStatus: "",
      userData: {},
      email: "",
      mobile: "",
      maskid: "",
      hiddennum: "",
      referenceNumber: "",
      applicationNo: "",
      insuranceCompanyCode: "",
      productName: "",
      proposalStatus: "",
      distributorID: "",
    };
  },

  methods: {
    getInsurerImgs(value) {
      let img = getInsuranceImages();
      console.log("img", img);
      return require("../../assets/images/" + img[value]);
    },
    reUploadDoc() {
      this.proposalStatus = JSON.parse(
      sessionStorage.getItem("proposalStatusValue")
    );
      this.distributorID = getDistributer()["distributerID"];
      console.log("distributorID", this.distributerID);
      let filledData;
      if (sessionStorage.getItem("filledData")) {
        filledData = JSON.parse(sessionStorage.getItem("filledData"));
      }
      if (filledData["manufacturerId"] == "TATA" || filledData["manufacturerId"] == "ABSLIFEI") {
        let link =
          "/#/upload-doc-diy?distributorID=" +
          this.distributorID +
          "&insuranceCompanyCode=" +
          filledData["manufacturerId"] +
          "&applicationNo=" +
          filledData.fieldGroups.traceInfo.fields.manuApplicationNo.input +
          "&productCode=" +
          filledData["productId"];
        window.location.href = new URL(location).origin + link;
        // window.history.replaceState("", "", new URL(location).origin + link);
        // window.location.reload();
      } else {
        var headers = {
          Authorization:
            "Basic " + window.btoa(process.env.VUE_APP_DATA_PER_USERNPASS),
          "Content-Type": "application/json",
        };
        let qData = [this.paymentDetails["proposal_ui_id"]];
        this.backendApi("getProposalDetails", qData, headers, "get")
          .then((response) => {
            if (response == "error") {
              self.apiError = true;
            } else if (response && response.data) {
              if (response.data.resume_url != null)
                window.location.href = response.data.resume_url;
                // window.history.replaceState("", "", response.data[1]["resumeURL"]);
                // window.location.reload();
            }
          })
          .catch((error) => {
            console.log("error msg block dp", error);
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/components/paymentsuccess.scss";

// @import "~@/assets/scss/components/paymentfailed.scss";
</style>