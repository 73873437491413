import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Policyquote from '../views/policy-quote.vue'
import compareplan from '../views/compare-plan.vue'
import policydetails from '../views/policy-details.vue'
import checkout from '../views/checkout.vue'
import proposal from '../views/proposal.vue'
import proposaldetailscheck from '../views/proposaldetailscheck.vue'
import proposaldetailscheckdiy from '../views/proposaldetailscheckdiy.vue'
import thankYouReject from'../views/thankyourejectdiy.vue'
import thankYouhdfc from'../views/thankyoupageRM.vue'
import docsubmitted from'../views/doc-submitted.vue'
import dashboard from'../views/dashboard.vue'
import payment from'../views/payment.vue'
import uploaddocument from '../views/upload-doc.vue'
import uploaddocumentdiy from '../views/upload-doc-diy.vue'
import termplanqte from '../views/termplanqte.vue'
import thankYoumail from'../views/thankyoumail.vue'
import thankYoumailDIY from'../views/thankyoumaildiy.vue'
import alertpage from '../views/alertpage.vue'
import alertdiy from '../views/alert-diy.vue'
import networkalert from '../views/networkalert.vue'
import welcomeLogin from '../views/welcome-login.vue'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/dashboard',
    name: 'dashbord',
    component: dashboard,
    meta: { requiresAuth: true } 
  },
  {
    path: '/policy-quote',
    name: 'policyquote',
    component: Policyquote,
    meta: { requiresAuth: true } 
  },
  {
    path: '/compareplan',
    name: 'compareplan',
    component: compareplan,
    meta: { requiresAuth: true } 
  },
  {
    path: '/policydetails',
    name: 'policydetails',
    component: policydetails,
    meta: { requiresAuth: true } 
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: checkout,
    meta: { requiresAuth: true } 
  },
  {
    path: '/proposal',
    name: 'proposal',
    component: proposal,
    meta: { requiresAuth: true } 
  },
  {
    path: '/payment',
    name: 'paymnt',
    component: payment,
    meta: { requiresAuth: true } 
  },
  {
    path: '/proposaldetailscheck',
    name: 'proposaldetailscheck',
    component: proposaldetailscheck,
    meta: { requiresAuth: true } 
  },
  {
    path: '/thankYou',
    name: 'thankYouReject',
    component: thankYouReject,
    meta: { requiresAuth: true } 
  },
  
  {
    path: '/thankyoupage',
    name: 'thankYouPage',
    component: thankYouhdfc,
    meta: { requiresAuth: true } 
  },
  {
    path: '/docsubmitted',
    name: 'docsubmitted',
    component: docsubmitted,
    meta: { requiresAuth: true } 
  },
  {
    path: '/thankyoumail',
    name: 'thankYouMail',
    component: thankYoumail,
    meta: { requiresAuth: true } 
  },
  {
    path: '/thankyoumaildiy',
    name: 'thankYouMailDIY',
    component: thankYoumailDIY,
    meta: { requiresAuth: true } 
  },
  {
    path: '/alert',
    name: 'alertpage',
    component: alertpage,
  },
  {
    path: '/networkalert',
    name: 'networkalert',
    component: networkalert,
  },
  {
    path: '/alert-diy',
    name: 'alertdiy',
    component: alertdiy,
  },
  {
    path: '/proposaldetailscheckdiy',
    name: 'proposaldetailscheckdiy',
    component: proposaldetailscheckdiy,
    // meta: { requiresAuth: true } 
  },
  {
    path: '/upload-doc',
    name: 'uploaddoc',
    component: uploaddocument,
    meta: { requiresAuth: true } 
  },
  {
    path: '/upload-doc-diy',
    name: 'uploaddocdiy',
    component: uploaddocumentdiy,
    meta: { requiresAuth: true } 
  },
  {
    path: '/termplanqte',
    name: 'term-plan-qte',
    component: termplanqte,
    meta: { requiresAuth: true } 
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: welcomeLogin 
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) { 
    // always scroll to top
    return { top: 0 }
  }
})
export default router

// const router = createRouter({
//   history: createWebHistory(),
//   routes
// })
// export default router

// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes
// })
// export default router

// Add navigation guard
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
      const store = sessionStorage.getItem('vuex') ? JSON.parse(sessionStorage.getItem('vuex')) : '';
      const token = store?.token;
      
      if (!token) {
        next(`${window.location.origin}/#/networkalert?msg=Your session time is expired , kindly login again&backUrl=logout`)
      } else {
        next()
      }
  } else {
    next()
  }
})

window.onpopstate = function(event) {
  const currentRouteRequiresAuth = router.currentRoute.matched.some(record => record.meta.requiresAuth);
  
  if (!sessionStorage.getItem('vuex') && currentRouteRequiresAuth) {
    window.location.href = `${window.location.origin}/#/networkalert?msg=Your session time is expired , kindly login again&backUrl=logout`;
  }
};