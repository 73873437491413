<template>
  <div class="inner-page-wrapper">
    <div class="inner-page-bg">
      <div class="plan-page-container">
        <div class="plan-info text-center">
          You want to buy life cover of
          <div class="position-relative dashed-border d-inline-block">
            <span> <b>&#8377; {{ convertInLakh(filterPageValues["quoteAmount"]) }} </b> </span>
            <!-- <select name="lifecover" id="lifecover">
              <option value="">
                &#8377; {{ convertInLakh(filterPageValues["quoteAmount"]) }}
              </option>
            </select> -->
          </div>
          till age of
          <div class="position-relative dashed-border d-inline-block">
            <span> <b>&#8377; {{ filterPageValues["coverTillAge"] }} </b> </span>
          </div>
          and payment on
          <div class="position-relative dashed-border d-inline-block">
            <span> <b>&#8377; {{ getPPF(filterPageValues["premiumPayingFreq"]) }} </b> </span>
          </div>
          basis. <br />
          In my absence family will receive
          <div class="position-relative dashed-border d-inline-block">
            <span> <b>&#8377; {{ getFWR(filterPageValues["fwr"]) }} </b> </span>
          </div>
          and Cover Option is
          <div class="position-relative dashed-border d-inline-block">
            <span> <b>&#8377; {{ getCoverPlan(filterPageValues['coverOption']) }} </b> </span>
          </div>
        </div>

        <div class="plans-outer-section">
          <div class="row">
            <div class="col-md-3 col-lg-3 col-sm-3 sub-headings">
              <div class="row_1">
                <div
                  class="plan-option pt-3 pb-3 pr-3 pl-3 cursor-pointer"
                  @click="goBack"
                >
                  <div class="back-link text-center">
                    <img src="~@/assets/images/back-arrow.png" alt="" />Back To
                    Quote
                  </div>
                </div>
              </div>
              <div class="row_2">
                <span class="feature-name">Max Coverage Upto</span>
              </div>
              <!-- <div class="row_3">
                <span class="feature-name"
                  >Online Discount
                </span>
              </div>
              <div class="row_4">
                <span class="feature-name">Medical Required </span>
              </div> -->
              <div class="row_5">
                <span class="feature-name"
                  >Premium Payment Option
                </span>
              </div>
              <div class="row_6">
                <span class="feature-name"
                  >Return of Premium Plan
                </span>
              </div>
              <div class="row_7">
                <span class="feature-name">
                  Free Look Period
                </span>
              </div>
              <div class="row_8">
                <span class="feature-name">
                  Tax Benefit
                </span>
              </div>
              <div class="row_9">
                <span class="feature-name">
                  Terminal Illness
                </span>
              </div>
              <div class="row_10">
                <span class="feature-name">
                  Accelerated Critical Illness
                </span>
              </div>
              <div class="row_11">
               <span class="feature-name">
                  Accidental Death Benefit
                </span>
              </div>
              <div class="row_12">
                <span class="feature-name">
                  Waiver of Premium on permanent disability
                </span>
              </div>
              <div class="row_13">
                <span class="feature-name">
                  Waiver of premium on Critical Illness
                </span>
              </div>
              <div class="row_14">
                <span class="feature-name">
                  Return of Premium
                </span>
              </div>
              <div class="row_15">
               <span class="feature-name">
                  Spouse cover
                </span>
              </div>
              <div class="row_16">
                <span class="feature-name">
                  LifeStage Benefit
                </span>
              </div>
              <!-- <div class="row_17">
                <span class="feature-name"
                  >Option To Skip Your Premiums
                  <img src="~@/assets/images/info-button.png" alt=""
                /></span>
              </div> -->
              <div class="row_18"></div>
              <div class="row_19">
                <span class="feature-name"
                  >Year of Inception <img src="~@/assets/images/info-button.png" alt=""
                />
                  <i>Year of establishment of the insurance company. </i>
                </span>
              </div>
              <div class="row_20">
                <span class="feature-name"
                  >Solvency Ratio
                  <img src="~@/assets/images/info-button.png" alt=""
                />
                <i>A insurance company with high solvency ratio is more likely to be financially stable and hence more equiped to pay out claims.</i>
                </span>
              </div>
              <div class="row_21">
                <span class="feature-name"
                  >Grievances Solved
                  <img src="~@/assets/images/info-button.png" alt=""
                />
                <i>Percentage of solved customer grievances.</i>
                </span>
              </div>
              <div class="row_22">
                <span class="feature-name"
                  >Claim Settlement Ratio
                  <img src="~@/assets/images/info-button.png" alt=""
                />
                <i>The percentage of claims settled against number of claims received in the last financial year.</i>
                </span>
              </div>
              <div class="row_23">
                <span class="feature-name"
                  >Death Claim Paid
                  <img src="~@/assets/images/info-button.png" alt=""
                />
                <i>Total claim amount paid in the last financial year.</i>
                </span>
              </div>
              <div class="row_24">
                <span class="feature-name">Claim Complaints Ratio
                  <img src="~@/assets/images/info-button.png" alt=""/>
                <i>Number of claim related complaints per 10,000 claims raised</i>
                </span>
              </div>
              <div class="row_25">
                <span class="feature-name">Claim Settlement Promise
                  <img src="~@/assets/images/info-button.png" alt=""/>
                <i>Time taken by the insurance company to process and pay out the claims.</i>
                </span>
              </div>
              <div class="row_26">
                <span class="feature-name">No. of Branches
                  <img src="~@/assets/images/info-button.png" alt=""/>
                <i>Number of branches of the insurance company across India</i>
                </span>
              </div>
            </div>
            <div
              class="col-md-3 col-lg-3 col-sm-3"
              v-for="(dt3, ind3) in selectedForFilter"
              :key="ind3"
            >
              <div class="row_1">
                <div class="plan-details mr-4">
                  <div
                    class="
                      plan-card
                      position-relative
                      d-flex
                      justify-content-center
                      align-items-center
                      flex-column
                      pt-3
                      pb-3
                      pr-3
                      pl-3
                    "
                  >
                    <span
                      v-on:click="removeFromFilter(ind3)"
                      class="cursor-pointer"
                    >
                      <img src="~@/assets/images/cancle.png" alt="" />
                    </span>
                    <img
                      :src="
                        getInsurerImgs(
                          dt3.insuranceAndProducts.insuranceCompanyCode
                        )
                      "
                      alt=""
                    />
                    <h4>{{ dt3.insuranceAndProducts.productName }}</h4>
                    <div class="compare-plan-btn cursor-pointer" @click="goToDetails(dt3)">
                      <b
                        >&#8377;
                        {{
                          this.getPremiumAmt(dt3.individualDetails[0].premiumDetails)
                        }}</b
                      >
                      / {{ getPPF(filterPageValues["premiumPayingFreq"]) }}
                      <i class="icon-navigate_next"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row_2">
                <div
                  class="
                    min-270
                    pt-3
                    pb-3
                    pr-3
                    pl-3
                    mr-4
                    d-flex
                    align-items-center
                    flex-column
                    position-relative
                    feature-head
                    bg-white
                  "
                >
                  <span class="plan-head">Plan Basics</span>
                  <b class="feature-info"
                    >{{ dt3.supportingData.validRanges.PolicyTerm.max }} Yrs</b
                  >
                </div>
              </div>
              <!-- <div class="row_3">
                <div
                  class="
                    min-270
                    bg-white
                    pt-3
                    pb-3
                    pr-3
                    pl-3
                    mr-4
                    d-flex
                    align-items-center
                    flex-column
                    position-relative
                    feature-head
                    bg-white
                  "
                >
                  <b class="feature-info">{{
                    dt3.supportingData.Messages.MarketingMessages[0].Message
                  }}</b>
                </div>
              </div>
              <div class="row_4">
                <div
                  class="
                    min-270
                    bg-white
                    pt-3
                    pb-3
                    pr-3
                    pl-3
                    mr-4
                    d-flex
                    align-items-center
                    flex-column
                    position-relative
                    feature-head
                  "
                >
                  <b class="feature-info">
                    {{ dt3.supportingData.Messages.TypeOfMedical }}
                  </b>
                </div>
              </div> -->
              <div class="row_5">
                <div
                  class="
                    min-270
                    bg-white
                    pt-3
                    pb-3
                    pr-3
                    pl-3
                    mr-4
                    d-flex
                    align-items-center
                    flex-column
                    position-relative
                    feature-head
                  "
                >
                  <b class="feature-info">Regular /Limited </b>
                  <ul>
                    <li>Pay Till</li>
                    <!-- <li>
                      <i class="icon-keyboard_arrow_right"></i>entire policy
                      term
                    </li>
                    <li>
                      <i class="icon-keyboard_arrow_right"></i>5/8/10/12 years
                    </li> -->
                    <li>
                      <i class="icon-keyboard_arrow_right"></i>
                      {{ filterPageValues["coverTillAge"] }} years of age
                    </li>
                  </ul>
                </div>
              </div>
              <div class="row_6">
                <div
                  class="
                    min-270
                    bg-white
                    pt-3
                    pb-3
                    pr-3
                    pl-3
                    mr-4
                    d-flex
                    align-items-center
                    flex-column
                    position-relative
                    feature-head
                  "
                >
                  <div class="compare-plan-btn cursor-pointer" @click="goToDetails(dt3)">
                     <b>&#8377;
                        {{
                          this.getPremiumAmt(dt3.individualDetails[0].premiumDetails)
                        }}</b
                      >
                      / {{ getPPF(filterPageValues["premiumPayingFreq"]) }}
                     <i class="icon-navigate_next"></i>
                  </div>
                  <small>for {{ filterPageValues["coverTillAge"] }} yrs</small>
                </div>
              </div>
              <div class="row_7">
                <div
                  class="
                    min-270
                    bg-white
                    pt-3
                    pb-3
                    pr-3
                    pl-3
                    mr-4
                    d-flex
                    align-items-center
                    flex-column
                    position-relative
                    feature-head
                  "
                >
                  <b class="feature-info">30 Days</b>
                </div>
              </div>
              <div class="row_8">
                <div
                  class="
                    min-270
                    bg-white
                    pt-3
                    pb-3
                    pr-3
                    pl-3
                    mr-4
                    d-flex
                    align-items-center
                    flex-column
                    position-relative
                    feature-head
                  "
                >
                  <b class="feature-info">Yes</b>
                </div>
              </div>
              <div class="row_9">
                <div
                  class="
                    min-270
                    bg-white
                    pt-3
                    pb-3
                    pr-3
                    pl-3
                    mr-4
                    d-flex
                    align-items-center
                    flex-column
                    position-relative
                    feature-head
                  "
                >
                  <b class="feature-info blue-font" v-if="getInbuiltFeature(dt3.insuranceAndProducts.productCode, 10) == 'Yes'">
                    Free
                  </b>
                  <b class="feature-info" v-else-if="getInbuiltFeature(dt3.insuranceAndProducts.productCode, 10) == 'No'">
                    Paid
                  </b>
                  <img v-else src="~@/assets/images/cross.svg" alt="" />
                 
                </div>
              </div>
              <div class="row_10">
                <div
                  class="
                    min-270
                    bg-white
                    pt-3
                    pb-3
                    pr-3
                    pl-3
                    mr-4
                    d-flex
                    align-items-center
                    flex-column
                    position-relative
                    feature-head
                  "
                >
                  <b class="feature-info blue-font" v-if="getInbuiltFeature(dt3.insuranceAndProducts.productCode, 11) == 'Yes'">
                    Free
                  </b>
                  <b class="feature-info" v-else-if="getInbuiltFeature(dt3.insuranceAndProducts.productCode, 11) == 'No'">
                    Paid
                  </b>
                  <img v-else src="~@/assets/images/cross.svg" alt="" />

                </div>
              </div>
              <div class="row_11">
                <div
                  class="
                    min-270
                    bg-white
                    pt-3
                    pb-3
                    pr-3
                    pl-3
                    mr-4
                    d-flex
                    align-items-center
                    flex-column
                    position-relative
                    feature-head
                  "
                >
                  <b class="feature-info blue-font" v-if="getInbuiltFeature(dt3.insuranceAndProducts.productCode, 12) == 'Yes'">
                    Free
                  </b>
                  <b class="feature-info" v-else-if="getInbuiltFeature(dt3.insuranceAndProducts.productCode, 12) == 'No'">
                    Paid
                  </b>
                  <img v-else src="~@/assets/images/cross.svg" alt="" />
                </div>
              </div>
              <div class="row_12">
                <div
                  class="
                    min-270
                    bg-white
                    pt-3
                    pb-3
                    pr-3
                    pl-3
                    mr-4
                    d-flex
                    align-items-center
                    flex-column
                    position-relative
                    feature-head
                  "
                >
                  <b class="feature-info blue-font" v-if="getInbuiltFeature(dt3.insuranceAndProducts.productCode, 13) == 'Yes'">
                    Free
                  </b>
                  <b class="feature-info" v-else-if="getInbuiltFeature(dt3.insuranceAndProducts.productCode, 13) == 'No'">
                    Paid
                  </b>
                  <img v-else src="~@/assets/images/cross.svg" alt="" />
                </div>
              </div>
              <div class="row_13">
                <div
                  class="
                    min-270
                    bg-white
                    pt-3
                    pb-3
                    pr-3
                    pl-3
                    mr-4
                    d-flex
                    align-items-center
                    flex-column
                    position-relative
                    feature-head
                  "
                >
                  <b class="feature-info blue-font" v-if="getInbuiltFeature(dt3.insuranceAndProducts.productCode, 14) == 'Yes'">
                    Free
                  </b>
                  <b class="feature-info" v-else-if="getInbuiltFeature(dt3.insuranceAndProducts.productCode, 14) == 'No'">
                    Paid
                  </b>
                  <img v-else src="~@/assets/images/cross.svg" alt="" />
                </div>
              </div>
              <div class="row_14">
                <div
                  class="
                    min-270
                    bg-white
                    pt-3
                    pb-3
                    pr-3
                    pl-3
                    mr-4
                    d-flex
                    align-items-center
                    flex-column
                    position-relative
                    feature-head
                  "
                >
                  <b class="feature-info blue-font" v-if="getInbuiltFeature(dt3.insuranceAndProducts.productCode, 16) == 'Yes'">
                    Free
                  </b>
                  <b class="feature-info" v-else-if="getInbuiltFeature(dt3.insuranceAndProducts.productCode, 16) == 'No'">
                    Paid
                  </b>
                  <img v-else src="~@/assets/images/cross.svg" alt="" />
                </div>
              </div>
              <div class="row_15">
                <div
                  class="
                    min-270
                    bg-white
                    pt-3
                    pb-3
                    pr-3
                    pl-3
                    mr-4
                    d-flex
                    align-items-center
                    flex-column
                    position-relative
                    feature-head
                  "
                >
                  <b class="feature-info blue-font" v-if="getInbuiltFeature(dt3.insuranceAndProducts.productCode, 17) == 'Yes'">
                    Free
                  </b>
                  <b class="feature-info" v-else-if="getInbuiltFeature(dt3.insuranceAndProducts.productCode, 17) == 'No'">
                    Paid
                  </b>
                  <img v-else src="~@/assets/images/cross.svg" alt="" />
                </div>
              </div>
              <div class="row_16">
                <div
                  class="
                    min-270
                    bg-white
                    pt-3
                    pb-3
                    pr-3
                    pl-3
                    mr-4
                    d-flex
                    align-items-center
                    flex-column
                    position-relative
                    feature-head
                  "
                >
                  <b class="feature-info blue-font" v-if="getInbuiltFeature(dt3.insuranceAndProducts.productCode, 19) == 'Yes'">
                    Free
                  </b>
                  <b class="feature-info" v-else-if="getInbuiltFeature(dt3.insuranceAndProducts.productCode, 19) == 'No'">
                    Paid
                  </b>
                  <img v-else src="~@/assets/images/cross.svg" alt="" />
                </div>
              </div>
              <!-- <div class="row_17">
                <div
                  class="
                    min-270
                    bg-white
                    pt-3
                    pb-3
                    pr-3
                    pl-3
                    mr-4
                    d-flex
                    align-items-center
                    flex-column
                    position-relative
                    feature-head
                  "
                >
                  <img src="~@/assets/images/cross.svg" alt="" />
                </div>
              </div> -->
              <div class="row_18">
                <div
                  class="
                    min-270
                    bg-white
                    pt-3
                    pb-3
                    pr-3
                    pl-3
                    mr-4
                    d-flex
                    align-items-center
                    flex-column
                    position-relative
                    feature-head
                  "
                >
                  <b class="feature-info blue-font mb-3"
                    >Insurance company details</b
                  >
                  <!-- <img src="~@/assets/images/cross.svg" alt="" /> -->
                </div>
              </div>
              <div class="row_19">
                <div
                  class="
                    min-270
                    bg-white
                    pt-3
                    pb-3
                    pr-3
                    pl-3
                    mr-4
                    d-flex
                    align-items-center
                    flex-column
                    position-relative
                    feature-head
                  "
                >
                  <b class="feature-info blue-font mb-3"> {{ pageData[dt3.insuranceAndProducts.insuranceCompanyCode]['inception'] }} </b>
                </div>
              </div>
              <div class="row_20">
                <div
                  class="
                    min-270
                    bg-white
                    pt-3
                    pb-3
                    pr-3
                    pl-3
                    mr-4
                    d-flex
                    align-items-center
                    flex-column
                    position-relative
                    feature-head
                  "
                >
                  <b class="feature-info blue-font mb-3">{{ pageData[dt3.insuranceAndProducts.insuranceCompanyCode]['solvency_ratio'] }}</b>
                </div>
              </div>
              <div class="row_21">
                <div
                  class="
                    min-270
                    bg-white
                    pt-3
                    pb-3
                    pr-3
                    pl-3
                    mr-4
                    d-flex
                    align-items-center
                    flex-column
                    position-relative
                    feature-head
                  "
                >
                  <b class="feature-info blue-font mb-3">{{ pageData[dt3.insuranceAndProducts.insuranceCompanyCode]['grievances_solved'] }}%</b>
                </div>
              </div>
              <div class="row_22">
                <div
                  class="
                    min-270
                    bg-white
                    pt-3
                    pb-3
                    pr-3
                    pl-3
                    mr-4
                    d-flex
                    align-items-center
                    flex-column
                    position-relative
                    feature-head
                  "
                >
                  <b class="feature-info blue-font mb-3"
                    >{{
                      getCsValue(
                        dt3.supportingData.Messages.ClaimSettlementPercent
                      )
                    }}%</b
                  >
                </div>
              </div>
              <div class="row_23">
                <div
                  class="
                    min-270
                    bg-white
                    pt-3
                    pb-3
                    pr-3
                    pl-3
                    mr-4
                    d-flex
                    align-items-center
                    flex-column
                    position-relative
                    feature-head
                  "
                >
                  <b class="feature-info blue-font mb-3">{{ pageData[dt3.insuranceAndProducts.insuranceCompanyCode]['death_claim_paid'] }}</b>
                </div>
              </div>
              <div class="row_24">
                <div
                  class="
                    min-270
                    bg-white
                    pt-3
                    pb-3
                    pr-3
                    pl-3
                    mr-4
                    d-flex
                    align-items-center
                    flex-column
                    position-relative
                    feature-head
                  "
                >
                  <b class="feature-info blue-font mb-3">{{ pageData[dt3.insuranceAndProducts.insuranceCompanyCode]['claim_complaints_ratio'] }}</b>
                </div>
              </div>
              <div class="row_25">
                <div
                  class="
                    min-270
                    bg-white
                    pt-3
                    pb-3
                    pr-3
                    pl-3
                    mr-4
                    d-flex
                    align-items-center
                    flex-column
                    position-relative
                    feature-head
                  "
                >
                  <b class="feature-info blue-font mb-3">{{ pageData[dt3.insuranceAndProducts.insuranceCompanyCode]['claim_settlement_promise'] }}</b>
                </div>
              </div>
              <div class="row_26">
                <div
                  class="
                    rounded-bottom
                    min-270
                    bg-white
                    pt-3
                    pb-3
                    pr-3
                    pl-3
                    mr-4
                    d-flex
                    align-items-center
                    flex-column
                    position-relative
                    feature-head
                  "
                >
                  <b class="feature-info blue-font mb-3">{{ pageData[dt3.insuranceAndProducts.insuranceCompanyCode]['no_of_branches'] }}</b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import $ from "jquery";
import {getPolicyCover, getConpareData, getInsuranceImages, getAddOnData} from '@/mixins/common'
export default {
  name: "compareplan",
  components: {},
  mounted() {
    this.filterFlag = 0;
    this.idsToCompare = JSON.parse(localStorage.getItem("idsToCompare"));
    this.selectedForFilter = JSON.parse(
      localStorage.getItem("selectedForFilter")
    );
    console.log("this.idsToCompare: ", this.idsToCompare);
    console.log("this.selectedForFilter: ", this.selectedForFilter);
    this.filterPageValues = JSON.parse(
      sessionStorage.getItem("filterPageValues")
    );
    
    console.log("this.filterPageValues: ", this.filterPageValues);
    //console.log('fpv', this.filterPageValues["coverTillAge"]);
    this.pageData = getConpareData();
    this.apiData = JSON.parse(sessionStorage.getItem("quote_request"));
    this.premiumPaymentFrequency = this.apiData.product.premiumPaymentFrequency;

    console.log('ppf: ', this.premiumPaymentFrequency);
  },
  data() {
    return {
      idsToCompare: [],
      selectedForFilter: [],
      filterPageValues: [],
      filterFlag: 0,
      pageData: [],
      apiData: "",
      premiumPaymentFrequency: ""
    };
  },
  mixins:[getPolicyCover, getConpareData, getInsuranceImages, getAddOnData],
  methods: {
    goBack() { 
      console.log("go back called");
      //router.go(-1);
      //localStorage.setItem("filterFlag", JSON.stringify(1));
      //this.$router.back();
      this.$router.push('/policy-quote');
    },
    getInsurerImgs(value) {
      let img = getInsuranceImages();
      return require("../../assets/images/" + img[value]);
    },
    convertInLakh(amt) {
      if (amt < 10000000) {
        return amt / 100000 + "L";
      } else {
        return amt / 10000000 + "Cr.";
      }
    },
    getPPF(val) {
      if (val == "M") {
        return "Monthly";
      } else if (val == "Q") {
        return "Quarterly";
      } else if (val == "HY") {
        return "Half Yearly";
      } else if (val == "Y") {
        return "Yearly";
      }
    },
    getFWR(val) {
      if (val == "4") {
        return "Lumpsum Payout";
      } else if (val == "5") {
        return "Monthly Income";
      } else if (val == "6") {
        return "Lumpsum + Income";
      }
    },
    getCoverPlan(val) {
      if (val == "3") {
        return "Increasing Cover Plan";
      } else if (val == "2") {
        return "Decreasing Cover Plan";
      } else if (val == "1") {
        return "Level Cover Plan";
      }
    },
    removeFromFilter(ind) {
      let val = this.idsToCompare[ind];
      $("#plan" + val).prop("checked", false); // Unchecks it
      var index2 = this.idsToCompare.indexOf(val);
      if (index2 !== -1) {
        this.idsToCompare.splice(index2, 1);
      }
      this.selectedForFilter.splice(ind, 1);

      // setting in local db
      localStorage.setItem(
        "selectedForFilter",
        JSON.stringify(this.selectedForFilter)
      );
      localStorage.setItem("idsToCompare", JSON.stringify(this.idsToCompare));
    },
    getCsValue(value) {
      var obj;
      eval('obj='+value);
      //console.log(obj);
      var keys = Object.keys(obj);
      //console.log(keys);
      let len = keys.length;
      return obj[keys[len-1]];
    },
    checkAddOns(data, val) {
      let check = data.filter(function (data1) { return data1.optionSelected == val });
      console.log(data, val);
      if(check.length > 0) {
        return require("../../assets/images/tick-mark.svg");
      } else {
        return require("../../assets/images/cross.svg");
      }
    },
    goToDetails(data) {
      console.log('selected plan: ', data);
      //localStorage.setItem('selectedPlan', JSON.stringify(data));
      //localStorage.setItem('apiData', JSON.stringify(this.apiData));
      sessionStorage.setItem("selected_plan_data", JSON.stringify(data));
      if(data.insuranceAndProducts.insuranceCompanyCode == 'ABSLIFEI' || data.insuranceAndProducts.insuranceCompanyCode == 'TATA' || data.insuranceAndProducts.insuranceCompanyCode == 'HDFC' || data.insuranceAndProducts.insuranceCompanyCode == 'AEGON' || data.insuranceAndProducts.insuranceCompanyCode == 'MAXLIFE' || data.insuranceAndProducts.insuranceCompanyCode == 'ICICI') {
        this.$router.push('/termplanqte');
      } else {
        this.$router.push('/policydetails');
      }
    },
    getInbuiltFeature(productCode, addOnId) {
      try {
        let data = getAddOnData();
        let tmp = data[productCode][addOnId];
        return tmp;  
      } catch (error) {
        console.log(error);
      }
      
    },
    getPremiumAmt(data) {
      let itemIndex = data.findIndex(item => item.mode == this.premiumPaymentFrequency);
      if(itemIndex > -1) {
        return this.currency(data[itemIndex]['totalPremiumValue']);
      } else {
        return null;
      }
    },
    currency(amt) {
      return new Intl.NumberFormat().format(amt)
    }
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/components/compare-plan.scss";
</style>