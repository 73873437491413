<template>
  <div class="loading_block_outer" v-if="loading">
    <Loading3 msg='Fatching Payment Status'/>
    <div class="snippet" data-title=".dot-spin">
      <div class="stage">
        <div class="dot-spin"></div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="d-flex justify-content-between
          align-items-center mx-5 mt-3" >
        <div class="d-flex align-items-center">
          <span class="mr-4">
           <img v-if="this.insuranceCompanyCode"  :src="getInsurerImgs(this.insuranceCompanyCode)" alt=""/>
          </span>
          <span class="d-inline-block align-middle brand-text">
              {{ this.productName }}
            </span>
        </div>
        <div class="propno">
          <span v-if="this.applicationNo">Insurer Application Number: {{this.applicationNo}}</span><br>
      <span v-if="this.referenceNumber">Reference Number: {{this.referenceNumber}}</span>
        </div>
      </div>

    <div v-if="this.channelType=='B2C'" class="container">
      <div >
        <div class="success-img-holder text-center mt-5 mb-3">
          <figure>
            <img src="~@/assets/images/payment_scuccessful.png" alt="" />
          </figure>
          <figcaption class="mb-4">
            <h1>{{this.paymentStatus}}!</h1>
            <span>Thank you for an opportunity to Insure you!!!</span>
          </figcaption>
        </div>
        <div class="prop-submission">
          <span>Your proposal is submitted successfully to insurance company</span>
          <div class="d-flex prop-submission-holder">
            <div class="prop-submission-inner">
              <h5>Proposal Number</h5>
              <b v-if="this.applicationNo">{{this.applicationNo}}</b>
            </div>
            <div class="prop-submission-inner">
              <h5>Payment Status</h5>
              <a>
                <b>{{this.paymentStatus}}</b>
              </a>
            </div>
          </div>
          <button v-if="this.paymentStatus!='Payment Successful'"
            class="d-flex dwnld-btn align-items-center justify-content-center"
            @click="callPaymentUrl()">
            Click here for payment
          </button>
          <br>
          <p v-if="this.paymentStatus!='Payment Successful'">Please click <i @click="getDocRequirement()" class="refresh">refresh</i>  to see the updated status</p>
          
          <p v-if="this.paymentStatus=='Payment Successful'">If you have not uploaded document , kindly click to upload the same</p>
          
          <button v-if="this.paymentStatus=='Payment Successful'"
            class="d-flex dwnld-btn align-items-center justify-content-center"
            @click="redirectIPru()">
            Upload documents
          </button>
            <p style='color:#ee4105; font-weight:700;margin-top:10px;'>* <i>Please do not click on back button in the browser</i></p>
        </div>
      </div>
    </div> 
    <div v-else class="container">
        <div class="success-img-holder text-center mt-5 mb-3">
          <figure>
            <img src="~@/assets/images/payment_scuccessful.png" alt="" />
          </figure>
          <figcaption class="mb-4">
            <span>Your proposal is submitted successfully to insurance company</span><br>
            <span>HDFC LIFE will send email to the customer's registered email Id - {{ this.maskid }} and SMS to Mobile number {{this.hiddennum}}.</span>
          </figcaption>
          <div class="d-flex dwnld-btn align-items-center justify-content-center">
          <button 
            class="dwnld-btn"
            @click="redirectIPru()"
          >
            Upload documents
          </button>
            </div>
        </div>
    </div>
  </div>
</template>


<script>
import {getInsuranceImages, getDistributer} from '@/mixins/common';
import Loading3 from "@/components/loading3.vue";
export default {
  name: "paymnt",
  props: {  },
  mixins: [getInsuranceImages,getDistributer],
  components: {
    Loading3
  },
  data() {
    return {
      userData:{},
      email:"",
      mobile:"",
      maskid:"",
      hiddennum:"",
      referenceNumber:"",
      applicationNo:"",
      insuranceCompanyCode:"",
      productName:"",
      rmData:"",
      paymentStatus:"Payment Pending",
      docData:{},
      channelType:"",
      loading:false,
      paymentRedirectionHDFC:"",
    };
  },
  mounted() {

    if(sessionStorage.getItem("paymentRedirectionHDFC"))
    {
      this.paymentRedirectionHDFC = JSON.parse(sessionStorage.getItem("paymentRedirectionHDFC"))
    }
    if(sessionStorage.getItem("rm_data"))
      {
        this.rmData=JSON.parse(sessionStorage.getItem("rm_data"))
        if(this.rmData["RoleId"]=="1")
        {
        this.channelType="B2C"
        }
        else
        this.channelType="B2B"
      }
    if(sessionStorage.getItem("uiQuoteId"))
    this.referenceNumber=sessionStorage.getItem("uiQuoteId")
     this.selectedPlan = JSON.parse(sessionStorage.getItem("selected_plan_data"));
     this.insuranceCompanyCode=this.selectedPlan["insuranceAndProducts"]["insuranceCompanyCode"]
     this.productName=this.selectedPlan["insuranceAndProducts"]["productName"]
      if(this.selectedPlan["insuranceAndProducts"]["insuranceCompanyCode"] == "HDFC")
       {
       this.applicationNo=this.selectedPlan["trackInfo"]["varFields"][0]["fieldValue"]
       }
       if(sessionStorage.getItem("applicationNumber")){
          this.applicationNo = JSON.parse(sessionStorage.getItem("applicationNumber"));
        }
    // this.getDocRequirement()
    this.userData = JSON.parse(sessionStorage.getItem("filledData"));
    this.email = this.userData["fieldGroups"]["proposerDetails"]["fieldGroups"]["personalDetails"]["fields"]["email"]["input"]
    this.mobile = this.userData["fieldGroups"]["proposerDetails"]["fieldGroups"]["personalDetails"]["fields"]["mobileNumber"]["input"]
    
    this.maskid = this.email.replace(/^(.)(.*)(.@.*)$/,
     (_, a, b, c) => a + b.replace(/./g, '*') + c);
     this.hiddennum= this.mobile.slice(0, 2) + this.mobile.slice(2).replace(/.(?=...)/g, '*');

  },
  methods: {
    callPaymentUrl()
    {
      window.open(this.paymentRedirectionHDFC)
    },
     getInsurerImgs(value) {
        let img = getInsuranceImages();
        return require("../../assets/images/" + img[value]);
        },
    getDocRequirement()
    {
      this.loading=true
      let reqData={
                      "distributor": {
                          "distributorID": getDistributer()["distributerID"],
                          "agentID": "",
                          "agentType": this.rmData.RoleId =="4" ? "POSP" : "",
                          "salesChannel": "",
                          "channelType": this.channelType,
                          "varFields": [
                              {
                                  "fieldName": "",
                                  "fieldValue": ""
                              }
                          ]
                      },
                      "insuranceCompanyCode": this.insuranceCompanyCode,
                      "applicationNo": this.applicationNo
                  
                }
            
             let headers = {};

      this.backendApi("getDocRequirements", reqData, headers)
            .then((response) => {
              this.loading=false
              if(response && response.data.errors.length>0)	
              {	
                let msg=[]	
                for(let i=0;i<response.data.errors.length;i++)	
                {	
                    msg.push(response.data.errors[i]["errorDisplayMessage"])	
                }	
               this.$router.push({ path: '/alert-diy', query: {backUrl:'proposal-page',msgError:"We are facing some issue while fetching payment status", msg:msg  }});	
              }	
              else if(response && response.data.data)
              {
                this.docData=response.data.data.manufacturer.product[0].requirements
                for(let i=0;i<this.docData.length;i++)
                {
                  if(this.docData[i]["SBDocCategory"]=="payment")
                  {
                      if(this.docData[i]["manufacturerStatus"]=="Requirement raised")
                      {
                        this.paymentStatus="Payment Pending"
                      }
                      else if(this.docData[i]["manufacturerStatus"]=="Requirement accepted")
                      {
                        this.paymentStatus="Payment Successful"
                      }
                  }
                }
              }
            })
            .catch((error) => {
              this.loading=false
              console.log('error doc requirement', error);
            
            });
    },
    redirectIPru()
    {

      let filledData;
      if (sessionStorage.getItem('filledData')) {
        filledData = JSON.parse(sessionStorage.getItem("filledData"));
      }
      let proposalUiId;
      if(sessionStorage.getItem("proposal_ui_id"))
      {
        proposalUiId=sessionStorage.getItem("proposal_ui_id")
      }
      if(filledData['manufacturerId']=="TATA" || filledData['manufacturerId']=="ABSLIFEI" || filledData['manufacturerId']=="ICICI" || filledData['manufacturerId']=="HDFC")
      {
        let link = "/#/upload-doc-diy?distributorID=" + this.distributorID + "&insuranceCompanyCode="+filledData['manufacturerId']+"&applicationNo="+filledData.fieldGroups.traceInfo.fields.manuApplicationNo.input+"&productCode="+filledData["productId"];
        window.location.href = new URL(location).origin + link;
        // window.history.replaceState("", "", new URL(location).origin + link);
        // window.location.reload();
      }
      else
      {
        var  headers = {
          Authorization: "Basic " + window.btoa(process.env.VUE_APP_DATA_PER_USERNPASS),
          "Content-Type": "application/json",
        };
        let qData = [proposalUiId];
        this.backendApi("getProposalDetails", qData, headers, "get")
          .then((response) => {
            if(response == 'error') {
              self.apiError = true;
            } else if(response && response.data) {
              if(response.data.resume_url!=null)
                window.location.href = response.data.resume_url;
                // window.history.replaceState("", "", response.data[1]['resumeURL']);
                // window.location.reload();
            }
          })
          .catch((error) => {
            console.log('error msg block dp', error);
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/components/paymentsuccess.scss";
// @import "~@/assets/scss/components/paymentfailed.scss";
.dwnld-btn {
        color: $next-btn;
        font-size: 12px;
        font-family: $font-stack;
        background: $inner-page-bg;
        width: 251px;
        height: 40px;
        border: 1px solid #c9cacc;
        border-radius: 50px;
        margin: 0 auto;
        font-weight: 700;
        img {
            margin-right: 10px;
            width: 12px;
            height: 14px;
            object-fit: contain;
        }
    }
</style>