<template>
  <div class="loading_block_outer" v-if="loading">
    <SingleLoading  />
  </div>
  
  
  <div v-else class="pb-80">
    <div class="error-text pt-2 text-center" v-if="apiError">
      Insurance Gateway not responding, please try again
    </div>
    <div class="position-relative" v-if="selectedPlan">
      <div class="inner-page-bg pb-4">
        <div
          class="
            breakcrum-section
            d-flex
            justify-content-between
            align-items-center
          "
        >
          <ul class="d-flex justify-content-between align-items-center">
            <li><router-link to="/">Home</router-link> <i class="icon-keyboard_arrow_right"></i></li>
            <li><router-link to="/policy-quote">Term Insurance</router-link> <i class="icon-keyboard_arrow_right"></i></li>
            <li class="active">Term Plan Quote</li>
          </ul>
          <a target="_blank" :href="selectedPlan.supportingData.Messages.brochureLink">
            <img src="~@/assets/images/file.png" alt="" class="mr-1" />
            Know Your Plan In 2 Minutes
          </a>
        </div>
        <div
          class="
            plan-details-section
            d-flex
            align-items-center
            justify-content-between
          "
        >
          <!-- <router-link to="/policy-quote"> -->
          <span @click="goBack" class="cursor-pointer" >
            <img src="~@/assets/images/left-arrow.png" alt=""/>
          </span>
          <!-- </router-link> -->
          <div class="">
            <span class="d-inline-block align-middle brand-img">
              <img
                :src="
                  getInsurerImgs(
                    selectedPlan['insuranceAndProducts']['insuranceCompanyCode']
                  )
                "
                alt=""
              />
            </span>
            <span class="d-inline-block align-middle brand-text">
              {{ selectedPlan.insuranceAndProducts.productName }}
            </span>
          </div>
          <div class="feature-drpdwn-wrap d-flex">
            <div class="feature-drpdwn-wrap d-flex flex-column">
              <select
                name="lifecover"
                id="lifecover"
                @change="onChangeAmt($event)"
                v-model="selectedPlan.individualDetails[0].sumAssured"
              >
                <option
                  v-for="(value, ind) in qAmt"
                  :key="ind"
                  v-bind:value="value"
                >
                  &#8377; <span> {{ convertInLakh(value) }} </span>
                </option>
              </select>
              <span>Policy Cover</span>
            </div>
            <div class="feature-drpdwn-wrap d-flex flex-column">
              <select
                name="age"
                id="age"
                v-model="selectedAge"
                @change="getCoverTillAge($event)"
              >
                <option
                  v-for="(val, ind) in coverForArr"
                  :key="ind"
                  :value="val"
                >
                  {{ val }} Years
                </option>
              </select>
              <span>Cover Till Age</span>
            </div>
            <div class="feature-drpdwn-wrap d-flex flex-column pr-4">
              <select
                name="age"
                id="age"
                @change="getPremiumPaymentTerm($event)"
                v-model="premiumPaymentTerm"
              >
                <option
                  v-for="(val, ind) in premiumPaymentTermArr"
                  :key="ind"
                  :value="val"
                >
                  {{ val }} Years
                </option>
              </select>
              <span>Pay For</span>
            </div>
            <div class="feature-drpdwn-wrap d-flex flex-column">
              <select v-if="premiumPaymentFrequency == 'S'">
                <option value="S">Single Pay</option>
              </select>
              <select v-else
                name="age"
                id="age"
                @change="getPpf($event)"
                v-model="premiumPaymentFrequency"
              >
                <option v-for="(value, ind) in payModeList"
                  :key="ind"
                  v-bind:value="value">{{getPPF(value)}}</option>
              </select>
              <span>Mode of Payment</span>
            </div>
          </div>
          <div class="policy-price-amt">
            <b>
              &#8377;{{this.currency(this.selectedPlan.totalPremiumDetails[0].totalPremiumWithTax)}}</b
            >/{{
              getPayMode(
                selectedPlan.individualDetails[0].premiumDetails[0].mode
              )
            }}
          </div>
          <div>
            <button class="buy-now-btn">
              Buy now <i class="icon-keyboard_arrow_right"></i>
            </button>
          </div>
        </div>
        <div class="mob-feature-drpdwn-wrap">
          <div class="d-flex">
            <div class="feature-drpdwn-wrap d-flex flex-column">
              <select
                name="lifecover"
                id="lifecover"
                @change="onChangeAmt($event)"
                v-model="selectedPlan.individualDetails[0].sumAssured"
              >
                <option
                  v-for="(value, ind) in qAmt"
                  :key="ind"
                  v-bind:value="value"
                >
                  &#8377; <span> {{ convertInLakh(value) }} </span>
                </option>
              </select>
              <span>Policy Cover</span>
            </div>
            <div class="feature-drpdwn-wrap d-flex flex-column">
              <select
                name="age"
                id="age"
                v-model="selectedAge"
                @change="getCoverTillAge($event)"
              >
                <option
                  v-for="(val, ind) in coverForArr"
                  :key="ind"
                  :value="val"
                >
                  {{ val }} Years
                </option>
              </select>
              <span>Cover Till Age</span>
            </div>
            <div class="feature-drpdwn-wrap d-flex flex-column">
              <select
                name="age"
                id="age"
                @change="getPremiumPaymentTerm($event)"
                v-model="selectedPlan.productDetails.premiumPaymentTerm"
              >
                <option
                  v-for="(val, ind) in premiumPaymentTermArr"
                  :key="ind"
                  :value="val"
                >
                  {{ val }} Years
                </option>
              </select>
              <span>Pay For</span>
            </div>
            <div class="feature-drpdwn-wrap d-flex flex-column">
              <select
                name="age"
                id="age"
                @change="getPpf($event)"
                v-model="premiumPaymentFrequency"
              >
                <option v-for="(value, ind) in payModeList"
                  :key="ind"
                  v-bind:value="value">{{getPPF(value)}}</option>
              </select>
              <span>Mode of Payment</span>
            </div>
          </div>
        </div>
        <div class="row m-0 mt-4">
          <div class="tab-section col-md-8 pl-0">
            <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
              <a
                :class="'nav-link ' + isBlock1Active"
                id="nav-about-plan"
                data-toggle="tab"
                href="#about-plan"
                role="tab"
                aria-controls="about-plan"
                :aria-selected="isBlock1Selected"
                @click="tab1Clicked"
              >
                <span class="inactive-icon"
                  ><img src="~@/assets/images/about_plan_inactive.svg" alt=""
                /></span>
                <span class="active-icon"
                  ><img src="~@/assets/images/about_plan.svg" alt="" /></span
                >{{ productData.about_product?.sectionName }}
              </a>
              <a
                :class="'nav-link ' + isBlock2Active"
                id="nav-upgrade-plan"
                data-toggle="tab"
                href="#upgrade-plan"
                role="tab"
                aria-controls="upgrade-plan"
                :aria-selected="isBlock2Selected"
                @click="tab2Clicked"
              >
                <span class="inactive-icon"
                  ><img src="~@/assets/images/upgrade-plan-inactive.svg" alt=""
                /></span>
                <span class="active-icon"
                  ><img src="~@/assets/images/upgrade-plan.svg" alt="" /></span
                >Upgrade Plan
              </a>
              <a
                :class="'nav-link ' + isBlock3Active"
                id="nav-riders"
                data-toggle="tab"
                href="#riders"
                role="tab"
                aria-controls="riders"
                :aria-selected="isBlock3Selected"
                @click="tab3Clicked"
              >
                <span class="inactive-icon"
                  ><img src="~@/assets/images/riders_inactive.svg" alt=""
                /></span>
                <span class="active-icon"
                  ><img src="~@/assets/images/riders.svg" alt="" /></span
                >Addons/Riders
              </a>
            </div>
            <div class="tab-content py-3 px-3" id="nav-tabContent">
              <div
                :class="'tab-pane fade ' + tabContent1Show"
                id="about-plan"
                role="tabpanel"
                aria-labelledby="nav-about-plan"
              >
                <ul v-if="productData.about_product">
                  <li v-for="(data, index) in productData.about_product.subSection" :key="index">
                    <h4> {{data.header}} </h4>
                    <div class="tab-text" v-for="(data2, index2) in data.data" :key="index2">
                      {{ data2.data }}
                    </div>
                  </li>
                </ul>
              </div>
              <div
                :class="'tab-pane fade ' + tabContent2Show"
                id="upgrade-plan"
                role="tabpanel"
                aria-labelledby="nav-upgrade-plan"
              >
                <div class="benefit-grid">
                  <div class="benefit-section rounded mb-4">
                    <div  class="benefit-section-text">
                      <h5>Plan Options</h5>
                      <div >
                        <div>
                        <div class="benefit-grid">
                          <label v-for="(value, ind) in planMasterData" :key="ind">
                            <input
                              type="radio"
                              name="pmd"
                              v-bind:value="value.planName"
                              :checked="value.planId == selectedPlan.productDetails.planOption.optionSelected"
                              :disabled="planMasterData.length == 1"
                              v-on:click="getNewPlan(value.planName,selectedPlan)" />
                            <span class="border justify-content-center">
                              {{ value.planName }}
                            </span>
                          </label>
                        </div>
                      </div>
                      </div>
                    </div>
                  </div>
                  <div class="benefit-section rounded mb-4">
                    <div class="benefit-section-text">
                      <h5>Benefit Payout To Nominee</h5>
                      <p>In My Absence My Family Will Get</p>
                      <div >
                        <div class="benefit-grid">
                          <label
                            v-for="(value, ind) in optionSelectedArr"
                            :key="ind"
                          >
                            <input
                              type="radio"
                              name="bpn"
                              v-bind:value="value"
                              :checked="
                                value ==
                                selectedPlan.productDetails.DBPoption
                                  .optionSelected
                              "
                              :disabled="optionSelectedArr.length == 1"
                              v-on:click="getFwr"
                            />
                            <span class="border justify-content-center">
                              {{ getOptionSelectedVal(value) }}
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="
                      benefit-section
                      rounded
                      d-flex
                      justify-content-between
                      flex-column
                      position-relative
                      mb-4
                    "
                  >
                    <div class="benefit-section-text position-relative">
                      <h5>Pay Premium</h5>
                      <p>
                        Pay Premiums For A Few Years And Stay Covered For The
                        Entire Policy Duration
                      </p>
                      <div>
                        <div class="benefit-grid">
                          <label v-for="(value, ind) in ppoArr" :key="ind">
                            <input
                              type="radio"
                              name="pp"
                              v-bind:value="value"
                              :checked="value == selectedPlan.productDetails.premiumPaymentOption"
                              :disabled="ppoArr.length == 1"
                              v-on:click="getPayPremium" />
                            <span class="border justify-content-center">
                              {{ getPpoVal(value) }}
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  

                  <div 
                    class="
                      benefit-section
                      rounded
                      d-flex
                      justify-content-between
                      position-relative
                      flex-column
                      mb-4
                    " 
                  >
                    <div class="benefit-section-text position-relative">
                      <h5>Life Cover Option</h5>
                      <p>
                        Varying Sum Assured 
                      </p>
                      <div  class="benefit-grid">
                          <label v-for="(value, ind) in coverOptionConfigForArr" :key="ind">
                            
                            <input
                              type="radio"
                              name="co"
                              v-bind:value="value"
                               :disabled="coverOptionConfigForArr.length == 1"
                              :checked="value == selectedPlan.productDetails.CoverOption.optionSelected"
                              v-on:click="getCoverPremiumModal" />
                            <span class="border justify-content-center">
                              {{ getCoverOption(value) }}
                            </span>
                          </label>
                        </div>
                           
                       </div>
                       
                  </div>
                  <div
                    class="
                      benefit-section
                      rounded
                      d-flex
                      justify-content-between
                      flex-column
                      position-relative
                      mb-4
                    "
                    v-if="selectedPlan.supportingData.Messages.isWholeLifePlan =='1' && wholeLifeCoverOption()"
                  >
                  <div class="benefit-section-text position-relative" >
                    <div v-for="(data, index) in productData.key_feature.subSection" :key="index">
                      <div v-if="data.header == 'Whole Life cover'">
                      <h5>{{ data.header}}</h5>
                      <div class="position-absolute">
                        <button
                          class="add-plcy-btn"
                          @click="addWholeLife()"
                          v-if="isWholeLifeSelected == '0'"
                        >
                          Add
                        </button>
                        <button
                          class="remove-plcy-btn"
                          @click="removeWholeLife()"
                          v-if="isWholeLifeSelected != '0'"
                        >
                          Remove
                        </button>
                      </div>
                      <div v-for="(data2, index2) in data.data" :key="index2">
                      <p>
                        {{ data2.data }}
                      </p>
                    </div>
                    </div>
                    </div>
                  </div>
                  
                  </div>
                </div>
              </div>
              <div
                :class="'tab-pane fade ' + tabContent3Show"
                id="riders"
                role="tabpanel"
                aria-labelledby="nav-riders"
              >
                <div class="d-flex flex-column">
                  <div class="benefit-grid" v-if="countAddonsRiders(this.addOnsList, this.riderList)">
                    <!-- Addon Section -->
                    <div class="flex-fill benefit-section rounded mb-4" v-for="(dt2, ind2) in addOnsList" :key="ind2">
                      <div class="benefit-section-text">
                        <h5>
                          {{dt2.optionName}}
                          <span v-if="dt2.addOnSumAssured != null && checkAddOnSelection(dt2.optionSelected, this.selectedPlan.productDetails.AddOnSection.addOnSelected)"> 
                           ₹
                           {{ convertInLakh(getAddOnSelectionSA(dt2.optionSelected, this.selectedPlan.productDetails.AddOnSection.addOnSelected)) }}
                          </span>
                          <a href="#" v-if="dt2.addOnSumAssured != null && checkAddOnSelection(dt2.optionSelected, this.selectedPlan.productDetails.AddOnSection.addOnSelected)" @click="getSumAssuredValue(ind2, dt2.optionSelected, this.selectedPlan.productDetails.AddOnSection.addOnSelected)" data-toggle="modal" data-target="#editsumassured">
                            <img
                              src="~@/assets/images/edit-profile.png"
                              alt=""
                            />
                          </a>
                        </h5>
                        <div v-if="!(dt2.inbuiltFeature == 'Yes' && dt2.mandatoryInProduct == 'Yes')" class="position-absolute">

                          <button class="remove-plcy-btn" v-if="checkAddOnSelection(dt2.optionSelected, this.selectedPlan.productDetails.AddOnSection.addOnSelected)" @click="removeAddOns(dt2.optionSelected)">
                            Remove
                          </button>
                          <button class="add-plcy-btn" v-if="!checkAddOnSelection(dt2.optionSelected, this.selectedPlan.productDetails.AddOnSection.addOnSelected)" @click="addAddOns(dt2.optionSelected)">
                            Add
                          </button>
                        </div>
                        <p>
                          {{ dt2.optionDesc }}
                        </p>
                      </div>
                      <div
                        class="price border-top d-flex justify-content-between"
                      >
                        <b>Price</b>
                        <span v-if="checkAddOnSelection(dt2.optionSelected, this.selectedPlan.productDetails.AddOnSection.addOnSelected)"> 
                          <span v-if="dt2.premiumIncludedFlag != 'Yes'">
                            <b>₹ {{ this.currency(this.getAddOnPremium(dt2.optionSelected, this.selectedPlan.productDetails.AddOnSection.addOnSelected))}} </b>/ {{ getPPF(this.premiumPaymentFrequency) }}
                          </span>
                          <span v-else>
                            <b>Premium included</b>
                          </span>
                        </span>
                        <span v-else>
                          <span v-if="dt2.inbuiltFeature == 'Yes' && dt2.mandatoryInProduct == 'Yes'"> <b> </b></span>
                          <span v-else> <b> {{ dt2.temp_price }} </b></span>
                        </span>
                        
                      </div>
                      
                    </div>
                    

                    <!-- Rider Section -->
                    <div class="flex-fill benefit-section rounded mb-4" v-for="(dt3, ind3) in riderList" :key="ind3">
                      <div class="benefit-section-text">
                        <h5>
                          {{dt3.riderName}}
                          <span v-if="checkRiderSelection(dt3.ridercode, this.selectedPlan.individualDetails[0].rider)"> 
                            ₹ {{ convertInLakh(getRiderSelectionSA(dt3.ridercode, this.selectedPlan.individualDetails[0].rider)) }}
                          </span>
                          <!-- <span v-else>
                            ₹ {{ convertInLakh(dt3.riderSA) }}
                          </span> -->
                        </h5>
                        <div class="position-absolute">
                          <button class="add-plcy-btn" v-if="checkRiderSelection(dt3.ridercode, this.selectedPlan.individualDetails[0].rider) != true" @click="addRider(dt3.ridercode,dt3.riderName)">
                            Add
                          </button>
                          <button v-else class="remove-plcy-btn" @click="removeRider(dt3.ridercode)">
                            Remove
                          </button>
                          <a href="#" @click="getRiderSumAssuredValue(dt3.ridercode, dt3.riderName, this.selectedPlan.individualDetails[0].rider)" data-toggle="modal" data-target="#editridersumassured" v-if="checkRiderSelection(dt3.ridercode, this.selectedPlan.individualDetails[0].rider)">
                            <img
                              src="~@/assets/images/edit-profile.png"
                              alt=""
                            />
                          </a>
                        </div>
                        <p>
                          {{ dt3.riderFeatures }}
                        </p>
                      </div>
                      <div
                        class="price border-top d-flex justify-content-between"
                      >
                        <b>Price</b>
                        <span v-if="checkRiderSelection(dt3.ridercode, this.selectedPlan.individualDetails[0].rider)"> <b> ₹ {{ this.currency(getRiderSelectionPremium(dt3.ridercode, this.selectedPlan.individualDetails[0].rider)) }} </b></span>
                        <!-- <span v-else> <b> ₹ {{ this.currency(getRiderPriceByPPF(dt3.premiumDetails)) }} </b></span> -->
                        <span v-else> <b> {{dt3.temp_price}} </b></span>
                      </div>
                    </div>
                    <!-- ./ Rider Section-->
                  </div>
                  <div class="benefit-grid2" v-else>
                    No addon/Rider for the selected policy
                  </div>
                </div>
              </div>
            </div>
            <div class="mobile-slider" v-if="productData.key_feature">
              <div
                id="carouselExampleIndicators"
                class="carousel slide"
                data-ride="carousel"
              >
                <ol class="carousel-indicators">
                  <li v-for="(data, index) in productData.key_feature?.sectionName" :key="index"
                    data-target="#carouselExampleIndicators"
                    :data-slide-to="index"
                    :class="(index=='0')?'active':'test'"
                  ></li>
                </ol>
                <div class="carousel-inner">
                  <div class="rounded-top">
                    <h4>{{ productData.key_feature?.sectionName }}</h4>
                  </div>
                  <div v-for="(data, index) in productData.key_feature?.subSection" :key="index" :class="'carousel-item ' + getCarousel(index)">
                    <div class="prdct-fture-text">
                      <div class="media">
                        <img
                          class="mb-3"
                          :src="data.icon"
                          alt=""
                        />
                        <div class="media-body">
                          <h5 class="mt-0">{{ data.header }}</h5>
                          <div v-for="(data2, index2) in data.data" :key="index2">
                            {{ data2.data }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="plan-conditions-section mb-5">
              <h4>Boundary Conditions for this plan</h4>
              <div class="d-flex">
                <div class="orange-bg flex-fill">
                  <span>
                    <img src="~@/assets/images/life-cover.svg" alt="" />
                  </span>
                  <h5>Life Cover Amount</h5>
                  <div class="d-flex">
                    <div
                      class="
                        d-flex
                        flex-column
                        covering-limit
                        flex-fill
                        justify-content-start
                        align-items-start
                      "
                    >
                      Minimum
                      <b
                        v-if="
                          selectedPlan.supportingData.validRanges.SumAssured
                            .min != 'null'
                        "
                        >&#8377;
                        {{
                          convertInLakhRounded(
                            selectedPlan.supportingData.validRanges.SumAssured
                              .min
                          )
                        }}</b
                      >
                      <b
                        v-if="
                          selectedPlan.supportingData.validRanges.SumAssured
                            .min == 'null'
                        "
                      >
                        Not Applicable</b
                      >
                    </div>
                    <div
                      class="
                        d-flex
                        flex-column
                        covering-limit
                        flex-fill
                        justify-content-start
                        align-items-start
                      "
                    >
                      Maximum
                      <b
                        v-if="
                          selectedPlan.supportingData.validRanges.SumAssured
                            .max != 'null'
                        "
                        >&#8377;
                        {{
                          convertInLakhRounded(selectedPlan.supportingData.validRanges.SumAssured.max)
                        }}
                      </b>
                      <b
                        v-if="
                          selectedPlan.supportingData.validRanges.SumAssured
                            .max == 'null'
                        "
                      >
                        No Limit
                      </b>
                    </div>
                  </div>
                </div>
                <div class="orange-bg flex-fill">
                  <span>
                    <img src="~@/assets/images/cover-yourself.svg" alt="" />
                  </span>
                  <h5>Cover yourself Upto</h5>
                  <div class="d-flex">
                    <div
                      class="
                        d-flex
                        flex-column
                        covering-limit
                        flex-fill
                        justify-content-start
                        align-items-start
                      "
                    >
                      Minimum
                      <b
                        >{{
                          parseInt(selectedPlan.individualDetails[0].age) +
                          parseInt(
                            selectedPlan.supportingData.validRanges.PolicyTerm
                              .min
                          )
                        }}
                        years</b
                      >
                      <i
                        >(Policy Term
                        {{
                          //parseInt(this.selectedPlan.individualDetails[0].age) + parseInt(selectedPlan.supportingData.validRanges.PolicyTerm.min)
                          parseInt(selectedPlan.supportingData.validRanges.PolicyTerm.min)
                        }}
                        Yrs)</i
                      >
                    </div>
                    <div
                      class="
                        d-flex
                        flex-column
                        covering-limit
                        flex-fill
                        justify-content-start
                        align-items-start
                      "
                    >
                      Maximum
                      <b>{{
                          getMaxMaturityAge(selectedPlan.individualDetails[0].age, selectedPlan.supportingData.validRanges.PolicyTerm.max, selectedPlan.insuranceAndProducts.productCode)
                        }} years</b>
                      <i>
                        (Policy Term
                        {{
                          selectedPlan.supportingData.validRanges.PolicyTerm.max
                        }}
                        Yrs)
                      </i>
                    </div>
                  </div>
                </div>
                <div class="orange-bg flex-fill">
                  <span>
                    <img src="~@/assets/images/payment.svg" alt="" />
                  </span>
                  <h5>Frequency of payment</h5>
                  <div class="d-flex">
                    <div
                      class="
                        d-flex
                        flex-column
                        covering-limit
                        flex-fill
                        justify-content-start
                        align-items-start
                        mb-2
                      "
                      v-for="(value, ind) in selectedPlan.individualDetails[0]
                        .premiumDetails"
                      :key="ind"
                    >
                      {{ getPPF(value["mode"]) }}
                      <b>&#8377; {{ this.currency(value["totalPremiumValue"]) }}</b>
                    </div>
                  </div>

                  <!-- <p>
                    (save Rupee Icon ₹10,962 in lifetime by choosing Yearly)
                  </p> -->
                </div>
              </div>
            </div>
            <div id="plan-condition-slider" class="carousel slide plan-conditions-section" data-ride="carousel">
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <div class="orange-bg flex-fill">
                  <span>
                    <img src="~@/assets/images/life-cover.svg" alt="" />
                  </span>
                  <h5>Life Cover Amount</h5>
                  <div class="d-flex">
                    <div
                      class="
                        d-flex
                        flex-column
                        covering-limit
                        flex-fill
                        justify-content-start
                        align-items-start
                      "
                    >
                      Minimum
                      <b
                        v-if="
                          selectedPlan.supportingData.validRanges.SumAssured
                            .min != 'null'
                        "
                        >&#8377;
                        {{
                          convertInLakh(
                            selectedPlan.supportingData.validRanges.SumAssured
                              .min
                          )
                        }}</b
                      >
                      <b
                        v-if="
                          selectedPlan.supportingData.validRanges.SumAssured
                            .min == 'null'
                        "
                      >
                        Not Applicable</b
                      >
                    </div>
                    <div
                      class="
                        d-flex
                        flex-column
                        covering-limit
                        flex-fill
                        justify-content-start
                        align-items-start
                      "
                    >
                      Maximum
                      <b
                        v-if="
                          selectedPlan.supportingData.validRanges.SumAssured
                            .max != 'null'
                        "
                        >&#8377;
                        {{
                          convertInLakh(selectedPlan.supportingData.validRanges.SumAssured.max)
                        }}
                      </b>
                      <b
                        v-if="
                          selectedPlan.supportingData.validRanges.SumAssured
                            .max == 'null'
                        "
                      >
                        No Limit
                      </b>
                    </div>
                  </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="orange-bg flex-fill">
                  <span>
                    <img src="~@/assets/images/cover-yourself.svg" alt="" />
                  </span>
                  <h5>Cover yourself Upto</h5>
                  <div class="d-flex">
                    <div
                      class="
                        d-flex
                        flex-column
                        covering-limit
                        flex-fill
                        justify-content-start
                        align-items-start
                      "
                    >
                      Minimum
                      <b
                        >{{
                          parseInt(selectedPlan.individualDetails[0].age) +
                          parseInt(
                            selectedPlan.supportingData.validRanges.PolicyTerm
                              .min
                          )
                        }}
                        years</b
                      >
                      <i
                        >(Policy Term
                        {{
                          selectedPlan.supportingData.validRanges.PolicyTerm.min
                        }}
                        Yrs)</i
                      >
                    </div>
                    <div
                      class="
                        d-flex
                        flex-column
                        covering-limit
                        flex-fill
                        justify-content-start
                        align-items-start
                      "
                    >
                      Maximum
                      <b>{{
                          getMaxMaturityAge(selectedPlan.individualDetails[0].age, selectedPlan.supportingData.validRanges.PolicyTerm.max, selectedPlan.insuranceAndProducts.productCode)
                        }} years</b>
                      <i
                        >(Policy Term
                        {{
                          selectedPlan.supportingData.validRanges.PolicyTerm.max
                        }}
                        Yrs)</i
                      >
                    </div>
                  </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="orange-bg flex-fill">
                  <span>
                    <img src="~@/assets/images/payment.svg" alt="" />
                  </span>
                  <h5>Frequency of payment</h5>
                  <div class="d-flex">
                    <div
                      class="
                        d-flex
                        flex-column
                        covering-limit
                        flex-fill
                        justify-content-start
                        align-items-start
                        mb-2
                      "
                      v-for="(value, ind) in selectedPlan.individualDetails[0]
                        .premiumDetails"
                      :key="ind"
                    >
                      {{ getPPF(value["mode"]) }}
                      <b>&#8377; {{ this.currency(value["totalPremiumValue"]) }}</b>
                    </div>
                  </div>

                  <!-- <p>
                    (save Rupee Icon ₹10,962 in lifetime by choosing Yearly)
                  </p> -->
                  </div>
                </div>
              </div>
              <ol class="carousel-indicators">
                <li data-target="#plan-condition-slider" data-slide-to="0" class="active"></li>
                <li data-target="#plan-condition-slider" data-slide-to="1"></li>
                <li data-target="#plan-condition-slider" data-slide-to="2"></li>
              </ol>
            </div>
            <div class="pay-premium">
              <h5>
                Pay premiums for a few years and stay covered for the entire
                policy duration
              </h5>
              <h6>Choose from {{selectedPlan.supportingData.validRanges.PolicyTerm.min}} to {{selectedPlan.supportingData.validRanges.PolicyTerm.max}} years</h6>
              <div class="d-flex">
                <div class="d-flex pay-premium-div flex-column flex-fill mr-5" v-for="(data, index) in productData.permiumPaymentTerm.subSection" :key="index">
                  <span>
                    <img :src="data.icon" alt="" />
                  </span>
                  <h6>{{ data.header }}</h6>
                  <div v-for="(data2, index2) in data.data" :key="index2">
                   <p> {{ data2.data }}</p>
                  </div>
            
                </div>
              </div>
            </div>
            <div
              id="pay-premium-slider"
              class="carousel slide"
              data-ride="carousel"
            >
              <ol class="carousel-indicators">
                <li
                  data-target="#pay-premium-slider"
                  data-slide-to="0"
                  class="active"
                ></li>  
                <li data-target="#pay-premium-slider" data-slide-to="1"></li>
                <li data-target="#pay-premium-slider" data-slide-to="2"></li>
              </ol>
              <div class="carousel-inner">
                <div class="">
                  <h5>
                    Pay premiums for a few years and stay covered for the entire
                    policy duration
                  </h5>
                  <h6>Choose from 5 to 20 years</h6>
                  <div class="carousel-item active">
                    <div class="d-flex pay-premium-div flex-column flex-fill">
                      <span>
                        <img src="~@/assets/images/life-cover.svg" alt="" />
                      </span>
                      <h6>Regular Pay</h6>
                      <p>You pay premiums throughout the policy duration</p>
                    </div>
                  </div>
                  <div class="carousel-item">
                    <div class="d-flex pay-premium-div flex-column flex-fill">
                      <span>
                        <img src="~@/assets/images/primium-pay.svg" alt="" />
                      </span>
                      <h6>Limited Pay</h6>
                      <p>
                        Pay premiums for a few years and stay covered for the
                        entire policy duration. Choose from 5 , 10, 12 and 35
                        years
                      </p>
                    </div>
                  </div>
                  <div class="carousel-item">
                    <div class="d-flex pay-premium-div flex-column flex-fill">
                      <span>
                        <img src="~@/assets/images/primium-pay.svg" alt="" />
                      </span>
                      <h6>Single Pay</h6>
                      <p>
                        You pay premium only once and stay covered for the
                        entire policy duration.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="product-feature-section col-md-4 pr-0">
            <div class="prdct-fture-head rounded-top">
              <h4> {{productData.key_feature?.sectionName}} </h4>
            </div>
            <div class="prdct-fture-text position-relative rounded-bottom" v-if="productData.key_feature">
              <hr />
              <div class="media" v-for="(data, index) in productData.key_feature.subSection" :key="index">
                <img class="mr-3" :src="data.icon" alt="" />
                <div class="media-body">
                  <h5 class="mt-0">{{ data.header }}</h5>
                  <div v-for="(data2, index2) in data.data" :key="index2">
                    {{ data2.data }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <complianceText/>

      </div>

      <div
        class="
          footer-total-premium
          d-flex
          justify-content-between
          align-items-center
        "
      >
        <div class="total-premium">
          <b>Total Premium: </b>
          &#8377;
          <strong>{{
            this.currency(this.selectedPlan.totalPremiumDetails[0].totalPremiumWithTax)
          }}</strong
          >/{{
            getPayMode(selectedPlan.individualDetails[0].premiumDetails[0].mode)
          }}
          <span class="feature-name">
            <img src="~@/assets/images/info-button.png" alt=""/>
            <div class="tooltip-sec">
              <table>
                <tr>
                  <td>  
                    Premium:
                  </td>
                  <td>₹ 
                    {{
                    this.currency(this.selectedPlan.totalPremiumDetails[0].totalPremiumValue)
                    }}
                  </td>
                </tr>
                  <tr>
                  <td class="text-left">  
                    Taxes:
                  </td>
                  <td>₹  
                    {{this.currency(this.selectedPlan.totalPremiumDetails[0].totalTax)}}
                  </td>
                </tr>
              </table>
            </div>
          </span>
          <span class="premium-tax">Premium (Including Taxes)</span>
        </div>
        <!-- <div class="qt-link">
          <a href="">
            <img src="~@/assets/images/whatsapp.png" alt="" >
            <span>Send Quotes To Customer</span>
          </a>
        </div>
        <div class="qt-link">
          <a href="">
            <img src="~@/assets/images/whatsapp.png" alt="" >
            <span>Send Product Information To Customer</span>
          </a>
        </div> -->
        <div class="bttn-grp d-flex">
          <!-- <router-link to="/compareplan"> -->
            <button type="button" class="back-btn mr-3" @click="goBack">
              <i><img src="~@/assets/images/back-btn.png" /></i>Back
            </button>
          <!-- </router-link> -->
          <!-- <router-link to="/checkout"> -->
            <button type="button" class="next-btn" @click="goCheckout">
              Next <i><img src="~@/assets/images/next-arrow.png" /></i>
            </button>
          <!-- </router-link> -->
        </div>
      </div>
    </div>
    <div class="modal fade" id="editsumassured" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              {{ getOptSelectedText(this.addOnUpdateTitle) }}
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <h4>
              Enter your sum assured value
            </h4>
            <div class="benefit-input form-control1">
              <input type="text" v-model.trim="addOnUpdateSumAssured"/>
              <span class="blue-bg-span"> {{ convertInLakh(addOnUpdateSumAssured) }} </span>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="updateAddOnSumAssured()">Update</button>
          </div>
        </div>
      </div>
    </div>
 
     <div class="modal fade" id="editridersumassured" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              {{ (this.riderUpdateTitle) }}
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <h4>
              Enter your sum assured value
            </h4>
            <p>Sum assured between {{this.currency(this.minSumAssured)}} to {{this.currency(this.maxSumAssured)}}</p>
            <div class="benefit-input form-control1">
              <input type="text" v-model.trim="riderUpdateSumAssured"/>
              <span class="blue-bg-span"> {{ convertInLakh(riderUpdateSumAssured) }} </span>
              <p class="error-text" v-if="this.error.sumassuredval">{{ error.sumassuredval }}</p>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="updateRiderSumAssured()">Update</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="addridersumassured" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              {{ (this.riderUpdateTitle) }}
            </h5>
            <button type="button"  class="close" data-dismiss="modal" aria-label="Close"  @click="getfieldvalidation()" >
              <span aria-hidden="true" >&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <h4>
              Enter your sum assured value
            </h4>
            <p>Sum assured between {{this.currency(this.minSumAssured)}} to {{this.currency(this.maxSumAssured)}}</p>
            <div class="benefit-input form-control1">
              <input type="text" v-model.trim="this.riderUpdateSumAssured"  @keypress="isNumber($event)" />
              <span class="blue-bg-span"> {{ convertInLakh(riderUpdateSumAssured) }} </span>
              <p class="error-text" v-if="this.error.sumassuredval">{{ error.sumassuredval }}</p>
            </div>
              

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="addRiderSumAssured()">Add</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="lumpsumPayoutPercentModification" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div  class="modal-header" >
            <h5 class="modal-title" id="exampleModalLongTitle">
              Lumpsum Payout Percent
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
          </div>
        <div class="modal-body">
            <h4>
              lumpsum Payout Percent 
            </h4>
          <div class="benefit-input form-control1">
             <select v-model="this.lumpsumPayoutPercent">
             <option value="">Select</option>
             <option v-for="i=0 in parseInt(this.getP1C2PSDbp('lumpsumpayoutpercent')['max'])" :key="i">
                  {{i}}
              </option>
              </select>
          </div>
             <h4>
               Income Frequency
             </h4>
          <div class="benefit-input form-control1">
              <select v-model="this.incomeFrequency">
              <option value="">Select</option>
              <option v-for="(value, ind) in this.getP1C2PSDbp('incomefrequency')" :key="ind" v-bind:value="value">{{value}}</option>       
             </select>
          </div>
               <h4>
                 Income Term 
               </h4>
          <div class="benefit-input form-control1">
               <select v-model="this.incomeTerm">
                  <option value="">Select</option>
                  <option v-for="i in this.getP1C2PSDbp('incometerm')" :key="i">
                      {{i}} {{this.getP1C2PSDbp('incomeTermUnit')}}
                   </option>
               </select>
          </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="lumpsumPayoutPercent1()">Submit</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Lumpsum Payout Percent  -->
    <div class="modal fade" id="lumpsumPayoutPercent" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div  class="modal-header" >
            <h5 class="modal-title" id="exampleModalLongTitle">
              Lumpsum Payout Percent
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
          </div>
        <div class="modal-body">
            <h4>
              Enter Lumpsum Payout Percent 
            </h4>
        <div class="benefit-input form-control1">
              <input type="text" min="1" maxlength="2" v-model.trim="lumpsumPayoutPercent" @keypress="isNumber($event)"/>
              <p class="error-text" v-if="lumpsumPayoutPercentErr">
                {{ lumpsumPayoutPercentErr }}
              </p>
          </div>
      </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="lumpsumPayoutPercent1()">Submit</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="increasingModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Increasing Cover Percent
            </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
              </button>
      </div>
    <div class="modal-body">
            <h4>
              Choose Increasing Percent
            </h4>
            <div class="benefit-input form-control1">
               <select
                name="age"
                id="age"
                v-model="saChangeAmountPercent">
                <option v-for="(percent, ind) in this.fixPercent" :key="ind"
                  v-bind:value="percent.value">{{percent.name}}</option>
              </select>
             </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="savedatatostorage(saChangeAmountPercent)">Submit</button>
          </div>
        </div>
      </div>
    </div>
  <div class="modal fade" id="decreasingModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Decreasing Cover Percent
            </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
        </div>
          <div class="modal-body">
            <h4>
              Choose Decreasing Percent
            </h4>
            <div class="benefit-input form-control1">
            <select
                name="age"
                id="age"
                v-model="saChangeAmountPercent">
                <option v-for="(percent, ind) in this.fixPercent" :key="ind"
                v-bind:value="percent.value">{{percent.name}}</option>
            </select>
             </div>
             <h4 v-if="this?.selectedPlan?.insuranceAndProducts?.productCode == 'P1C2PS'">
              Choose Level cover period
            </h4>
            <div class="benefit-input form-control1" v-if="this?.selectedPlan?.insuranceAndProducts?.productCode == 'P1C2PS'">
              <select
                  name="levelCoverPeriod"
                  id="levelCoverPeriod"
                  v-model="levelCoverPeriod">
                  <option v-for="(percent, ind) in this.levelCoverPeriodArr" :key="ind"
                  v-bind:value="percent.value">{{percent.name}}</option>
              </select>
            </div>
      </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="savedatatostorage(saChangeAmountPercent, levelCoverPeriod)">Submit</button>
          </div>
        </div>
      </div>
    </div>
 </div>
</template>
<script>
import $ from "jquery";
import SingleLoading from "@/components/single-loading.vue";
import AlertPage from "../alert/alertpage.vue"
import complianceText from "@/components/footer/complianceText.vue";
import {getPolicyCover,getInsuranceImages,getOPTSelected,getDBPMaster,getPPOMaster,getPlanMaster,getLazyLoadingConfig,getMaxPolicyAge,getPlanData,getRider,getPayModeList,getAddOnsList,getCoverOptionMaster,getP1C2PSDbp,getABSLIMaster,getRiderSumAssured} from '@/mixins/common';

export default {
  name: "policydetails",
  components: {
    SingleLoading,
    AlertPage,
    complianceText
  },
  mixins:[getPolicyCover,getInsuranceImages,getOPTSelected,getDBPMaster,getPPOMaster,getPlanMaster,getLazyLoadingConfig,getMaxPolicyAge,getPlanData,getRider,getPayModeList,getAddOnsList,getCoverOptionMaster,getP1C2PSDbp,getABSLIMaster,getRiderSumAssured],
  mounted() {
    this.filterPageValues = JSON.parse(
            sessionStorage.getItem("filterPageValues")
        );    this.loading = true;
    this.setupData();
  },
  data() {
    return {
      selectedAddonresp:[],
      planData:"",
      filterPageValues: [],
      minIncomeTerm:5,
      alertID:"",
      coveroptionvisible:1,
      errorFlag:false,
      errorMsg:"",
      errorMsgList:[],
      backUrl:"",
      loading: false,
      currentTab: 1,
      selectedAddOnsIds:[],
      isBlock1Selected: "true",
      isBlock1Active: " active",
      tabContent1Show: " active show",
      isBlock2Selected: "false",
      isBlock2Active: "",
      tabContent2Show: "",
      isBlock3Selected: "false",
      isBlock3Active: "",
      tabContent3Show: "",
      selectedData: "",
      selectedPlan: "",
      selectedPlan2:"",
      filterFlag: 0,
      apiReqData: {},
      qAmt: [],
      premiumPaymentFrequency: "",
      saChangeAmountPercent:"",
      optionSelectedArr: [],
      DBPOptionSelected: "",
      ppoArr: [],
      planMasterData:[],
      premiumPayTermBlock: false,
      premiumPaymentTermArr: [],
      coverForArr: [],
      coverOptionConfigForArr:[],
      selectedAge: "",
      returnOfPremiumPercentage: 0,
      isWholeLifeSelected: 0,
      indexUpdateAddOn: "",
      addOnUpdateTitle: "",
      addOnUpdateSumAssured: 0,
      productData: "",
      userData: "",
      uiQuoteId: "",
      quoteRequestID: "",
      wholeLifeAge: "",
      lumpsumPayoutPercent: "",
      incomeFrequency:"",
      incomeTerm:"",
      lumpsumPayoutPercentErr: "",
      payPremium: "",
      premiumPaymentTerm: "",
      riderList: "",
      indexUpdateRider: "",
      riderUpdateTitle: "",
      riderUpdateSumAssured: "",
      payModeList: "",
      addOnsList: [],
      riderCodeToAdd: "",
      spdata: {},
      spdataFlagForSQ: false,
      pollapiflag: false,
      pollRequestId: "",
      polling: null,
      apiCallinginterval: "",
      saveQuoteRequest:{},
      timer: 0,
      fixPercent:[],
      totalPremiumValue: 0,
      minSumAssured:"",
      maxSumAssured:"",
      currentRidderCode:"",
      error :{
        sumassuredval: null,
      },
      levelCoverPeriod: '',
      levelCoverPeriodArr: [],
    };
  },
  methods: {
    wholeLifeCoverOption()
    {
     if(this.selectedPlan2.insuranceAndProducts.productCode=="ABDGSHLD")
     return getABSLIMaster()['wholeLifeCover'];
     else
     return true;
    },
    setupData()
    {
      this.filterFlag = 0;
  
      let quote_request = JSON.parse(sessionStorage.getItem("quote_request"));
      let selected_plan_data = JSON.parse(sessionStorage.getItem("selected_plan_data"));
      this.apiReqData = JSON.parse(sessionStorage.getItem("quote_request"));
      this.apiCallinginterval = parseInt(getLazyLoadingConfig()['inerval']);
      this.selectedData = JSON.parse(sessionStorage.getItem("selected_plan_data"));
      this.selectedPlan2 = this.selectedData;
      // End session data
      let spdetail_flag= process.env.VUE_APP_SPDETAIL_ONESB;
      if(spdetail_flag == 1)
      {
        // if(!sessionStorage.getItem("sp_details_data")){
          if (sessionStorage.getItem("rm_data")){
            this.rmData = JSON.parse(sessionStorage.getItem("rm_data"));
            if( this.rmData['RoleId']!=="1"){
              this.getSPDetailsOneSB(this.selectedPlan2.insuranceAndProducts.insuranceCompanyCode);
            }
            this.quotationSQcall();

          } 
      }
      this.userData = JSON.parse(localStorage.getItem("userProfile"));
      // Setting Mandatory Fields for Single Quote
      
    },
    getAge(dateString) {	
      var today = new Date();	
      var birthDate = new Date(dateString);	
      var age = today.getFullYear() - birthDate.getFullYear();	
      var m = today.getMonth() - birthDate.getMonth();	
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {	
        age--;	
      }	
      if (age < 99 && age>0) {	
        return age;	
      }	
      	
    },
    quotationSQcall(){
      this.apiReqData.typeOfQuote = "Single Quote";
      this.apiReqData.distributor.channelType = this.rmData.RoleId !=="1" ? "B2B" : "B2C";
      let filterPageValues = JSON.parse(sessionStorage.getItem("filterPageValues"));
      // if(this.selectedPlan2.insuranceAndProducts.insuranceCompanyCode == 'ICICI' || this.selectedPlan2.insuranceAndProducts.insuranceCompanyCode == 'MAXLIFE') {
      //   this.apiReqData.personalInformation.individualDetails[0].quoteAmount = this.selectedPlan2.individualDetails[0].sumAssured;
      //   this.apiReqData.product.premiumPaymentTerm = this.selectedPlan2.productDetails.premiumPaymentTerm;
      //   this.apiReqData.product.policyTerm = this.selectedPlan2.productDetails.policyTerm;
      // }
      this.apiReqData.product.insuranceAndProducts = [
        {
          insuranceCompanyCode:
            this.selectedPlan2.insuranceAndProducts.insuranceCompanyCode,
          productCode: [this.selectedPlan2.insuranceAndProducts.productCode],
        },
      ];
      this.apiReqData.personalInformation.individualDetails[0].age = this.getAge(this.apiReqData.personalInformation.individualDetails[0].dateOfBirth);
      this.apiReqData.product.DBPoption.OptionSelected =
        this.selectedPlan2.productDetails.DBPoption.optionSelected;
      if(this.selectedPlan2.productDetails.DBPoption.incomeTerm != null)
        this.apiReqData.product.DBPoption.incomeTerm = this.selectedPlan2.productDetails.DBPoption.incomeTerm;
      if(this.selectedPlan2.productDetails.DBPoption.incomeTermUnit != null)
        this.apiReqData.product.DBPoption.incomeTermUnit = this.selectedPlan2.productDetails.DBPoption.incomeTermUnit;
      if(this.selectedPlan2.productDetails.DBPoption.lumpsumPayoutPercent) {
        this.apiReqData.product.DBPoption.lumpsumPayoutPercent = this.selectedPlan2.productDetails.DBPoption.lumpsumPayoutPercent;
      }
      try{
        this.apiReqData.product.coverOption.OptionSelected =
        this.selectedPlan2.productDetails.CoverOption.optionSelected;
        if(this.selectedPlan2.productDetails.CoverOption.SAChangePercent != null)
          this.apiReqData.product.coverOption.SAChangePercent = this.selectedPlan2.productDetails.CoverOption.SAChangePercent;
      }catch(err){
        console.log("Error in assignment of CoverOption",err);
      }
      
      this.apiReqData.product.premiumPaymentOption =
        this.selectedPlan2.productDetails.premiumPaymentOption;

      this.apiReqData.product.premiumPaymentFrequency = filterPageValues.premiumPayingFreq
      
      let selectedAddOns = this.getAddOns();
      let tmpPlanId = this.getPlanId(this.selectedData.insuranceAndProducts.productCode, this.selectedAddOnsIds);
      if(tmpPlanId > 0) {
        this.apiReqData.product.planOption.OptionSelected = "";
      } else {

        if(this.selectedPlan2.insuranceAndProducts.insuranceCompanyCode == 'HDFC' || this.selectedPlan2.insuranceAndProducts.insuranceCompanyCode == 'ABSLIFEI') {
          this.apiReqData.product.planOption.OptionSelected = "";
        } else {
          this.apiReqData.product.planOption.OptionSelected = "";
        }
        
      }
      this.apiReqData.product.AddOnSection.AddOns = this.getAddOns();

      // Adding Riders
      this.apiReqData.personalInformation.individualDetails[0].riderDetails = this.getRiders();

      // Removing spouse array
      let indSpouse = this.selectedAddOnsIds.indexOf('17');

      if(indSpouse > -1) {
        console.log('found spouse');
        this.apiReqData.personalInformation.individualDetails[1].quoteAmount = (this.selectedPlan2.individualDetails[0].sumAssured / 2);
      } else {
        console.log('spouse not found');  
        this.apiReqData.personalInformation.individualDetails.splice(1, 1);
        console.log('apppppp: ', this.apiReqData.personalInformation.individualDetails);
      }

      // End Setting up request values for Single Quote

      // Upgrade plan code from checlout page
      if (localStorage.getItem("updatedePlanInfoClicked")) {
        let ckk = JSON.parse(localStorage.getItem("updatedePlanInfoClicked"));
        if(ckk == '2') {
          this.tab2Clicked();
        } else if(ckk == '3') {
          this.tab3Clicked();
        }
        localStorage.removeItem('updatedePlanInfoClicked');
      }
      this.getProductInfo(this.selectedPlan2.insuranceAndProducts.insuranceCompanyCode, this.selectedPlan2.insuranceAndProducts.productCode);
      
      this.getPolicy(this.apiReqData);

      // test data related function
      this.productData = {
        "insuranceCompanyCode":"ICICI",
        "productCode":"T40",
        "about_product": {
            "sectionName":"About Product",
            "subSection":[
              {
                  "header":"The product in a nutshell",
                  "icon":"",
                  "data":[
                    {
                        "data":"Death Benefit: In the unfortunate event of death of the life covered (Life Assured) during the term of the policy, the nominee shall receive the Death Benefit",
                        "icon":""
                    },
                    {
                        "data":"Accidental Death Benefit available with this plan.",
                        "icon":""
                    }
                  ]
              },
              {
                  "header":"Paid benefits",
                  "icon":"",
                  "data":[
                    {
                        "data":"Accidental Death Benefit - An amount equal to the Sum Assured chosen by you (subject to a maximum of 2 Crores) will be paid only in the unfortunate death of the Life Assured due to an accident .",
                        "icon":""
                    }
                  ]
              }
            ],
            "disclaimers":[
              "",
              ""
            ]
        },
        "key_feature":{
            "sectionName":"Key Product Features",
            "subSection":[
              {
                  "header":"Tax Benefits",
                  "icon":"",
                  "data":[
                    {
                        "data":"Premiums paid are tax-free under section 80 C.",
                        "icon":""
                    },
                    {
                        "data":"When you opt for Critical Illness benefit you can get additional tax benefit under section 80D.",
                        "icon":""
                    },
                    {
                        "data":"Claim amount received by you or your nominee is tax free under section 10(10D).",
                        "icon":""
                    }
                  ]
              },
              {
                  "header":"Mode of Premium Payment: Single premium.",
                  "icon":""
              }
            ],
            "disclaimers":[
              "",
              ""
            ]
        },
        "varSection":[
            {
              "sectionCode":"",
              "sectionName":"",
              "subSection":[
                  {
                    "header":"",
                    "icon":"",
                    "data":[
                        {
                          "data":"",
                          "icon":""
                        },
                        {
                          "data":"",
                          "icon":""
                        }
                    ]
                  }
              ],
              "disclaimers":[
                  "",
                  ""
              ]
            }
        ],
        "insCompanyLogo":"",
        "productLogo":""
      }; 

      // console.log('this.testData: ', this.testData);
      // End test data related function
    },
    reqTimeOut() {
      this.timer = setTimeout(() => {
        this.pollapiflag = true;
        this.$router.push({ path: '/alert', query: { status: 'Error',backUrl:"policy-details", msg: "No response received, Please try again" }});
      }, 180000);
    },
    pollData(){
      this.reqTimeOut();
      this.disableButtons = true;
      this.polling = setInterval(() => {
        //if(this.noOfCalls < this.noOfTimesAPICalls) {
        //console.log("PollData PollDataFlag: "+ this.pollapiflag);
        if(this.pollapiflag == false){
          this.getLifeTerm();
        } else {
          this.loading = false;

          // if(this.responseData.length == 0) {
          //   this.erMsg = 'No products found as per your selected filter. Please change the filters.';
          // }
          // clearTimeout(this.timer);
          this.beforeDestroy();
        }
      }, this.apiCallinginterval)
    },
    beforeDestroy () {
      //console.log("before destroy called");
      // this.disableButtons = false;
      clearInterval(this.polling);
    },
    getSPDetailsOneSB(man_code) {
      let reqData = [man_code];
      let headers ={};
      this.backendApi("getSPDetailsOneSB", reqData, headers, "get")
      .then((response) => {
        if(response == 'error') {
          self.apiError = true;
        } else if(response && response.data) {
          console.log('response sp details: ', response.data);
          if(response.data.length == 0)
          {
            //redirection to error page and on go back button redirect to homepage
            let msg=['SP details are not available']
            this.$router.push({ path: '/networkalert', query: {backUrl:'policy-quote', msg: msg }});
          }
          this.spdata = response.data.data;
          sessionStorage.setItem("sp_details_data", JSON.stringify(response.data.data));
          localStorage.setItem("sp_details_data", JSON.stringify(response.data.data));
        }
      })
      .catch((error) => {
        console.log('error msg block spdetailOneSB API', error);
        let msg=['SP details are not available']
        this.$router.push({ path: '/networkalert', query: {backUrl:'policy-quote', msg: msg }});
      });
    },
    getProductInfo(insurerCode, productCode) {
      var self = this;
      console.log("get product info called");
      // var headers = {
      //   Authorization: "Basic " + window.btoa(process.env.VUE_APP_USERNPASS),
      //   "Content-Type": "application/json",
      // };
      let headers = {};
      let reqData = [insurerCode, productCode];
      this.backendApi("getProductUiDataApi", reqData, headers, "get")
        .then((response) => {
          //this.loading = false;
          if(response == 'error') {
            //self.apiError = true;
            console.log('api error: block');
          } else if(response && response.data) {
            this.productData = response.data.data;
            console.log("productData",this.productData)
          } 
        })
        .catch((error) => {
          console.log('error msg block product info', error);
          self.loading = false;
          self.apiError = true;
          let msg=['We are facing some issue from backend while fetching product data']
          this.$router.push({ path: '/networkalert', query: {backUrl:'policy-quote', msg: msg }});
        });
    },
    getCoverforProduct(){
            if(this.selectedPlan.insuranceAndProducts.productCode =='ABDGSHLD')
              {
             if(this.dbp=='4' && this.cover =='2')
               { 
                    this.fixPercent = [{name:"25%", value:25}, {name:"50%", value:50}];
               }
               else if(this.dbp=='4' && this.cover =='3')
               {
                   this.fixPercent = [{name:"5%", value:5},{name:"10%", value:10}];
              }
              else if(this.dbp=='6' && this.cover =='3')
              {
                   this.fixPercent = [{name:"0.5%", value:0.5}];
              }
              else if(this.dbp=='5' && this.cover =='3')
              {
                  this.fixPercent = [{name:"1.25%", value:1.25}];
                }
                else if(this.dbp=='7' && this.cover =='3')
                {
                  this.fixPercent = [{name:"1.25%", value:1.25}];
              }
          }    
    },
    getLifeTerm(){
      var self = this;
      this.loading = true;
      this.pollapiflag = false;
      let headers = {};
      let reqData = [this.pollRequestId];
    this.backendApi("quotationPoll", reqData, headers, "get")
        .then((response) => {
    
          if(response == 'error') {
            //self.apiError = true;
          } else if(response && response.data) {
          if(response.data.data.request){
            this.saveQuoteRequest = response.data.data.request;
            sessionStorage.setItem("quote_request", JSON.stringify(response.data.data.request));
            
          }
          if(response.data?.data?.quote?.length > 0) {
                sessionStorage.setItem("quote_request", JSON.stringify(this.apiReqData));
                if(response.data?.data?.quote[0].insuranceAndProducts.insuranceCompanyCode =="ABSLIFEI"){
                sessionStorage.setItem("applicationNumber",JSON.stringify(response.data.data.quote[0].trackInfo.varFields[0].fieldValue));
                }
                clearTimeout(this.timer);
                this.loading = false;
                this.apiError = false;
                let quoteresp = JSON.parse(JSON.stringify(response.data.data.quote));
                this.selectedPlan = response.data.data.quote[0];
                this.addOnsList = [];
                this.selectedAddonresp = [];
                this.totalPremiumValue= response.data.data.quote[0].totalPremiumDetails[0].totalPremiumWithTax;
                this.premium= response.data.data.quote[0].totalPremiumDetails[0].totalPremiumValue;
                this.taxes= response.data.data.quote[0].totalPremiumDetails[0].totalTax;
                console.log("this.totalPremiumValue",this.totalPremiumValue);

                this.quoteRequestID = response.data.reqId;
                if(response.data.data.request){
                  sessionStorage.setItem("quote_request", JSON.stringify(response.data.data.request));
                }
                sessionStorage.setItem("selectedPlanForCheckout", JSON.stringify(this.selectedPlan));

                this.selectedPlan.productDetails.AddOnSection.addOnSelected.forEach(
                  (element, index) => {
                    if (!this.selectedAddonresp.includes(element.optionSelected)) {
                        this.selectedAddonresp.push(element.optionSelected);
                    }     
                    console.log("selectedAddonresp",this.selectedAddonresp)
                    this.selectedPlan.productDetails.AddOnSection.addOnSelected[index]['checked'] = true;
                  }
                );

                this.selectedPlan.individualDetails[0].rider.forEach(
                  (element, index) => {
                    this.selectedPlan.individualDetails[0].rider[index]['checked'] = true;
                  }
              );
                sessionStorage.setItem("selected_plan_data", JSON.stringify(this.selectedPlan));
                sessionStorage.setItem("quoteRequestID", JSON.stringify(response.data.reqId));
                this.riderList = this.getRiderList(this.selectedPlan.insuranceAndProducts.productCode);
                 let addonsListtemp = [] 
                 
                 addonsListtemp = this.getAddOnsList(this.selectedPlan.insuranceAndProducts.productCode);
                 this.selectedAddonresp.forEach(optionSelected => {
                    let selectedOption = addonsListtemp.find(option => option.optionSelected === optionSelected);
                    if (selectedOption && !this.addOnsList.some(option => option.optionSelected === selectedOption.optionSelected)) {
                        this.addOnsList.push(selectedOption);
                    }
                });
                 console.log("addonsListtemp",addonsListtemp)
                 const existingOptionSelected = this.addOnsList.map(addon => addon.optionSelected);
                  const newAddOns = addonsListtemp.filter(addon =>
                    addon.inbuiltFeature === 'No' &&
                    addon.mandatoryInProduct === 'No' &&
                    !existingOptionSelected.includes(addon.optionSelected)
                  );
                  this.addOnsList = this.addOnsList.concat(newAddOns);
                 console.log("this.addOnsList",this.addOnsList)
                this.dbp = this.selectedData.productDetails.DBPoption.optionSelected;
               this.cover=  this.selectedData.productDetails.CoverOption.optionSelected;   
               this.getCoverforProduct();

        // ########################### Setting Required Values ###########################
                
                this.payModeList = this.getPayModeList(this.selectedPlan.insuranceAndProducts.insuranceCompanyCode);
                console.log('this.payModeList: ', this.payModeList)
                this.wholeLifeAge = this.selectedPlan.supportingData.Messages.wholeLifeAge;
                this.payPremium = this.selectedPlan.productDetails.premiumPaymentOption;

                if(this.selectedPlan.productDetails.premiumPaymentOption == '3') {
                  this.premiumPaymentTermArr = this.getPaymentTermValues();
                } else {
                  this.premiumPaymentTermArr = [
                    this.selectedPlan.productDetails.premiumPaymentTerm,
                  ];
                }
                
                this.selectedAge =
                  parseInt(this.selectedPlan.individualDetails[0].age) +
                  parseInt(this.selectedPlan.productDetails.policyTerm);

                  
                // Setting Premium Payment Term
                this.premiumPaymentTerm = this.selectedPlan.productDetails.premiumPaymentTerm;

                // Setting Premium Payment Frequency
                this.premiumPaymentFrequency = this.selectedPlan.productDetails.premiumPaymentFrequency;

                this.optionSelectedArr = this.getFwrConfig(
                  this.selectedPlan.insuranceAndProducts.productCode
                );

                this.ppoArr = this.getPpoConfig(
                  this.selectedPlan.insuranceAndProducts.productCode
                );

                this.coverOptionConfigForArr=this.getCoverOptionCofig(this.selectedPlan.insuranceAndProducts.productCode)
                
                this.planMasterData = getPlanMaster()[this.selectedPlan.insuranceAndProducts.productCode] 
                // this.getPlanMasterConfig(
                //   this.selectedPlan.insuranceAndProducts.productCode
                // );
                console.log("planMasterData",this.planMasterData)



                this.returnOfPremiumPercentage = this.apiReqData.product.AddOnSection.returnOfPremium.returnOfPremiumPercentage;
                if (
                  this.selectedPlan.productDetails.policyTerm ==
                  this.wholeLifeAge - parseInt(this.selectedPlan.individualDetails[0].age)
                ) {
                  //console.log("if block executed");
                  this.isWholeLifeSelected = 1;
                } else {
                  //console.log("else block executed");
                  this.isWholeLifeSelected = 0;
                }

                // this.includeAddOnsPremium();
                // this.includeRidersPremium();

                this.qAmt = self.getPolicyCover2(this.selectedPlan.supportingData.validRanges.SumAssured);

                // Get Cover for Values
                if(this.isWholeLifeSelected == 1) {
                  this.setCoverFor(1);
                } else {
                  this.setCoverFor();
                }

              // // localStorage.setItem("responseData", JSON.stringify(this.responseData));

              // // Quote response api section
              // let qResData = {
              //   "ui_quote_id": this.uiQuoteId,
              //   "quote_response_id": response.data.reqId,
              //   "annual_income": this.userData.annualincome,
              //   "quote_response_data": JSON.stringify(response.data.data.quote)
              // };

              // this.saveQuoteResponse(qResData);
            } 

            // Stop Polling
            // console.log("resposne PollDataFlag: "+response.data.data.isPollComplete);
            if(response.data.data.isPollComplete != undefined  && response.data.data.isPollComplete != false) {
              if(response.data.data.isPollComplete == true) {
                this.pollapiflag = true;
                this.uiQuoteId = response.data.data.ui_quote_id;
                sessionStorage.setItem("uiQuoteId", JSON.stringify(this.uiQuoteId));
                if (sessionStorage.getItem('proposal_ui_id')) {
                  sessionStorage.removeItem('proposal_ui_id');
                }
                clearTimeout(this.timer);
              }
            }
            if(response.data.errors.length >0)
            {
              if(response.data.errors[0].errorIdentifier == "INVALID_REQUEST_ID")
              {
                this.pollapiflag = true;
              }        
              else{
                let msg=[]
                this.errorMsgList=[]
                  for(let i=0;i<response.data.errors.length;i++)
                  {
                      msg.push(response.data.errors[i]["errorMessage"])
                  }
                  this.errorMsg="We are facing some issue while fetching quotation Api"
                this.pollapiflag = true;
                clearTimeout(this.timer);
                this.$router.push({ path: '/networkalert', query: {backUrl:'policy-details',msgError:this.errorMsg, reqId:response.data.reqId,  msg:msg }});
              }      
            }

            console.log("PollDataFlag: "+ this.pollapiflag);
            

          }

        })
        .catch((error) => {
          console.log('error msg block', error);
          self.loading = false;
          //self.apiError = true;
        });
    },
  getQuotationApi(apiData)
    {
      if (sessionStorage.getItem("quote_request") && sessionStorage.getItem("quote_request_checkout_flag") == 1){
        apiData = JSON.parse(sessionStorage.getItem("quote_request"));
        this.apiReqData = JSON.parse(sessionStorage.getItem("quote_request"));
        this.tab3Clicked();
        sessionStorage.removeItem('quote_request_checkout_flag');
      }

      // Setting riderPremiumPaymentTerm
      apiData.personalInformation.individualDetails[0].riderDetails.forEach(
        (element, index) => {
          apiData.personalInformation.individualDetails[0].riderDetails[index].riderPremiumPayingTerm = apiData.product.premiumPaymentTerm;
          apiData.personalInformation.individualDetails[0].riderDetails[index].riderTerm = apiData.product.policyTerm;
        }
      );

      //sessionStorage.setItem("quote_request", JSON.stringify(apiData));
      sessionStorage.setItem("quote_request_flag", JSON.stringify(1));
      var self = this;
      console.log("get policy called");
      // New Format
      this.loading = true;
      // let reqData = {"data":apiData};
      let headers = {};
      this.backendApi("quotationApi", apiData, headers)
        .then((response) => { 
          
          // this.loading = false;
          this.apiError = false;
          if(response == 'error') {
            self.apiError = true;
            this.loading = false;
          }
          else if(response.data.errors.length>0)
          {
            let msg=[]
            this.errorMsgList=[]
              for(let i=0;i<response.data.errors.length;i++)
              {
                  msg.push(response.data.errors[i]["errorMessage"])
              }
              this.errorMsg="We are facing some issue while fetching quotation Api"
              
              if(this.errorFlag == true) //added this condition to catch error to redirect to policy-detail only
                this.$router.push({ path: '/alert', query: {backUrl:'policy-details',msgError:this.errorMsg, reqId:response.data.reqId,  msg:msg }});
              else                      //added this condition to catch error while redirecting from termplanqte or policy-quote to policy-details
                this.$router.push({ path: '/networkalert', query: {backUrl:'policy-details',msgError:this.errorMsg, reqId:response.data.reqId,  msg:msg }});
            
          }
          else if(response.data.errors.length == 0){
            this.pollRequestId = response.data.reqId;
            this.errorFlag= true;
            setTimeout(function(){
              self.getLifeTerm();
              self.pollData();
          },1000);
            
          }
          // else if(response && response.data.data.quote){ 
          //   this.saveQuote(response.data.data);
          //   console.log(response);
          //   this.loading = false;
          //   this.apiError = false;
          //   let quoteresp = JSON.parse(JSON.stringify(response.data.data.quote));
          //   this.selectedPlan = response.data.data.quote[0];
          //   this.quoteRequestID = response.data.reqId;
          //   sessionStorage.setItem("quote_request", JSON.stringify(response.data.data.request));
          //   sessionStorage.setItem("selectedPlanForCheckout", JSON.stringify(this.selectedPlan));

          //   this.selectedPlan.productDetails.AddOnSection.addOnSelected.forEach(
          //     (element, index) => {
          //       this.selectedPlan.productDetails.AddOnSection.addOnSelected[index]['checked'] = true;
          //     }
          //   );

          //   this.selectedPlan.individualDetails[0].rider.forEach(
          //     (element, index) => {
          //       this.selectedPlan.individualDetails[0].rider[index]['checked'] = true;
          //     }
          // );
            
          //   sessionStorage.setItem("selected_plan_data", JSON.stringify(this.selectedPlan));
          //   sessionStorage.setItem("quoteRequestID", JSON.stringify(response.data.reqId));
          //   this.riderList = this.getRiderList(this.selectedPlan.insuranceAndProducts.productCode);
          //   this.addOnsList = this.getAddOnsList(this.selectedPlan.insuranceAndProducts.productCode);

            

          //   // ########################### Setting Required Values ###########################
            
          //   this.payModeList = this.getPayModeList(this.selectedPlan.insuranceAndProducts.insuranceCompanyCode);
          //   console.log('this.payModeList: ', this.payModeList)
          //   this.wholeLifeAge = this.selectedPlan.supportingData.Messages.wholeLifeAge;
          //   this.payPremium = this.selectedPlan.productDetails.premiumPaymentOption;

          //   if(this.selectedPlan.productDetails.premiumPaymentOption == '3') {
          //     this.premiumPaymentTermArr = this.getPaymentTermValues();
          //   } else {
          //     this.premiumPaymentTermArr = [
          //       this.selectedPlan.productDetails.premiumPaymentTerm,
          //     ];
          //   }
            
          //   this.selectedAge =
          //     parseInt(this.selectedPlan.individualDetails[0].age) +
          //     parseInt(this.selectedPlan.productDetails.policyTerm);

              
          //   // Setting Premium Payment Term
          //   this.premiumPaymentTerm = this.selectedPlan.productDetails.premiumPaymentTerm;

          //   // Setting Premium Payment Frequency
          //   this.premiumPaymentFrequency = this.selectedPlan.productDetails.premiumPaymentFrequency;

          //   this.optionSelectedArr = this.getFwrConfig(
          //     this.selectedPlan.insuranceAndProducts.productCode
          //   );

          //   this.ppoArr = this.getPpoConfig(
          //     this.selectedPlan.insuranceAndProducts.productCode
          //   );

          //   this.returnOfPremiumPercentage = this.apiReqData.product.AddOnSection.returnOfPremium.returnOfPremiumPercentage;
          //   if (
          //     this.selectedPlan.productDetails.policyTerm ==
          //     this.wholeLifeAge - parseInt(this.selectedPlan.individualDetails[0].age)
          //   ) {
          //     //console.log("if block executed");
          //     this.isWholeLifeSelected = 1;
          //   } else {
          //     //console.log("else block executed");
          //     this.isWholeLifeSelected = 0;
          //   }

          //   this.includeAddOnsPremium();
          //   this.includeRidersPremium();

          //   this.qAmt = self.getPolicyCover2(this.selectedPlan.supportingData.validRanges.SumAssured);

          //   // Get Cover for Values
          //   if(this.isWholeLifeSelected == 1) {
          //     this.setCoverFor(1);
          //   } else {
          //     this.setCoverFor();
          //   }

          //   // ########################### End Setting Required Values ###########################

               
          // }
          

         
        })
        .catch((error) => {
          console.log('error msg', error);
          this.loading = false;
          this.apiError = false;
           let msg=['We are facing some issue from backend while fetching quotation Api']
          this.$router.push({ path: '/networkalert', query: {backUrl:'policy-details', msg: msg }});
        });
    },
 getPolicy(apiData) {
      apiData.product.planOption.OptionSelected = "";
      if(sessionStorage.getItem('sp_details_data'))
      {
        // this.saveQuote(apiData);
        this.getQuotationApi(apiData)
      }
      else{
        // setTimeout(this.saveQuote(apiData), 5000);
        setTimeout(this.getQuotationApi(apiData), 5000);
      }
    },
    goBack() {
      console.log("go back called: ", this.selectedPlan.insuranceAndProducts.insuranceCompanyCode);
      //router.go(-1);
      //localStorage.setItem("filterFlag", JSON.stringify(1));
      //this.$router.back();
      if(this.selectedPlan.insuranceAndProducts.insuranceCompanyCode == 'TATA'||this.selectedPlan.insuranceAndProducts.insuranceCompanyCode == 'HDFC' ||this.selectedPlan.insuranceAndProducts.insuranceCompanyCode == 'ICICI' ||this.selectedPlan.insuranceAndProducts.insuranceCompanyCode == 'ABSLIFEI' ||this.selectedPlan.insuranceAndProducts.insuranceCompanyCode == 'MAXLIFE') {
        this.$router.push('/termplanqte');
      } else {
        this.$router.push('/policy-quote');
      }
     
    },
    tab1Clicked() {
      this.currentTab = 1;
      this.isBlock2Selected = "false";
      this.isBlock2Active = "";
      this.tabContent2Show = "";

      this.isBlock3Selected = "false";
      this.isBlock3Active = "";
      this.tabContent3Show = "";

      this.isBlock1Selected = "true";
      this.isBlock1Active = "active";
      this.tabContent1Show = "active show";
    },
    tab2Clicked() {
      this.currentTab = 2;
      this.isBlock1Selected = "false";
      this.isBlock1Active = "";
      this.tabContent1Show = "";

      this.isBlock3Selected = "false";
      this.isBlock3Active = "";
      this.tabContent3Show = "";

      this.isBlock2Selected = "true";
      this.isBlock2Active = "active";
      this.tabContent2Show = "active show";
    },
    tab3Clicked() {
      this.currentTab = 3;
      this.isBlock1Selected = "false";
      this.isBlock1Active = "";
      this.tabContent1Show = "";

      this.isBlock2Selected = "false";
      this.isBlock2Active = "";
      this.tabContent2Show = "";

      this.isBlock3Selected = "true";
      this.isBlock3Active = "active";
      this.tabContent3Show = "active show";
    },
    goCheckout() {
      if(this.currentTab == 1) {
        this.tab2Clicked();
      } else if(this.currentTab == 2) {
        this.tab3Clicked();
      } else { 
        
        if(this.premiumPaymentTermArr.length > 1) {
          //console.log('limited number of years selected');
          //console.log('selectedPlan.productDetails.premiumPaymentTerm: ', this.selectedPlan.productDetails.premiumPaymentTerm)
          //console.log('this.apiReqData.product.premiumPaymentTerm: ', this.apiReqData.product.premiumPaymentTerm)
          if(this.apiReqData.product.premiumPaymentTerm != 0) {
            sessionStorage.setItem('quote_request',JSON.stringify(this.apiReqData));
            this.$router.push('/checkout');
          } else {
            alert('Please Select Pay For Value First, Then Proceed');
          }
        } else {
          sessionStorage.setItem('quote_request',JSON.stringify(this.apiReqData));
          this.$router.push('/checkout');
          // this.$router.push('/networkalert');
        }
        
      }
    },
    getInsurerImgs(value) {
      let img = getInsuranceImages();
      return require("../../assets/images/" + img[value]);
    },
    getMaxAge(value) {
      let ages = getMaxPolicyAge();
      return ages[value];
    },
    getPPF(val) {
      if (val == "M") {
        return "Monthly";
      } else if (val == "Q") {
        return "Quarterly";
      } else if (val == "HY") {
        return "Half Yearly";
      } else if (val == "Y") {
        return "Yearly";
      } else if (val == "S"){
        return "Single";
      }
    },
    getPayMode(val) {
      if (val == "M") {
        return "Month";
      } else if (val == "Q") {
        return "Quarter";
      } else if (val == "HY") {
        return "Half Year";
      } else if (val == "Y") {
        return "Year";
      } else if (val == "S") {
        return "Single Pay";
      }
    },
    getAddOns() {
      console.log('getAddOns called: ')
      let addOnsArr = [];
      this.selectedAddOnsIds = [];
      this.selectedPlan2.productDetails.AddOnSection.addOnSelected.forEach(
        (element, index) => {
          let tempArr = {
            OptionSelected: element.optionSelected,
            addOnSumAssured: element.addOnSumAssured,
            varFields: [
              {
                fieldName: "",
                fieldValue: "",
              },
            ],
          };
          if (element.checked == true || element.premiumIncludedFlag == 'Yes' && element.inbuiltFeature == 'Yes'
          ) {
            addOnsArr.push(tempArr);
            this.selectedAddOnsIds.push(element.optionSelected);
          }
        }
      );
      return addOnsArr;
    },
    getRiders() {
      let addRiderArr = [];
      this.selectedPlan2.individualDetails[0].rider.forEach(
          (element, index) => {
              let tempArr = {
                  riderCode: element.ridercode,
                  riderTerm: parseInt(element.riderTerm),
                  riderSA: parseInt(element.riderSA),
                  riderPremiumPayingTerm: parseInt(element.riderPremiumPayingTerm)
              }

              if(element.checked == true) {
                  addRiderArr.push(tempArr);
              }
          }
      );
      return addRiderArr;
    },
    getPlanId(productCode, selectedAdOns) {
      console.log('pcode: ', productCode, 'selectedAdOns: ', selectedAdOns)
      //let policyArr = getPlanData();
      //console.log('policy array: 99', policyArr);
      let productArray = getPlanData()[productCode];
      console.log('selected productArray: ', productArray);
      var planid = 0;
      for(var key in productArray) {
        let tempArr = productArray[key];
        if(tempArr.sort().join(',')=== selectedAdOns.sort().join(',')){
          console.log('matched: Key => ', key);
          planid = key;
        }
      }
      return planid;
    },
    onChangeAmt(event) {
      //console.log(event.target.value);
      this.getPreviousRequest();
      this.apiReqData.personalInformation.individualDetails[0].quoteAmount =
        parseInt(event.target.value);
        sessionStorage.setItem('quote_request',JSON.stringify(this.apiReqData));
      //this.apiReqData.product.AddOnSection.
      //console.log(this.apiReqData);
      this.getPolicy(this.apiReqData);
    },
    getConfigrations() {
      //console.log("get configrations called");
    },
    getPpf(event) {
      console.log(event.target.value);
      this.getPreviousRequest();
      this.premiumPaymentFrequency = event.target.value;
      this.apiReqData.product.premiumPaymentFrequency = this.premiumPaymentFrequency;
      this.filterPageValues.premiumPayingFreq= this.premiumPaymentFrequency;
      sessionStorage.setItem('filterPageValues',JSON.stringify(this.filterPageValues));
      sessionStorage.setItem('quote_request',JSON.stringify(this.apiReqData));
      // this.apiReqData.product.premiumPaymentOption = event.target.value;
      if(event.target.value === 'S'){
        this.apiReqData.product.premiumPaymentTerm = 1;
      }
      if (this.apiReqData.product.insuranceAndProducts[0].productCode[0] === 'ABDGSHLD' && event.target.value === 'S') {
        this.apiReqData.product.AddOnSection.AddOns = this.getAddOns2(19);
      }
      this.getPolicy(this.apiReqData);
    },
    getOptionSelectedVal(val) {
      let data = getDBPMaster();
      return data[val];
    },
    getFwr(event) {
      //console.log(event.target.value);
      this.getPreviousRequest();
      this.DBPOptionSelected = event.target.value;
      //console.log(this.DBPOptionSelected);
      if(this.DBPOptionSelected == 6) {
        if(this.selectedPlan.insuranceAndProducts.productCode == 'P1C2PS' || this.selectedPlan.insuranceAndProducts.productCode == '154')
          {
            $("#lumpsumPayoutPercentModification").modal("show");
          }
          else{
            $("#lumpsumPayoutPercent").modal("show");
          }
        
        //this.apiReqData.product.DBPoption.lumpsumPayoutPercent = 50;
      } else {
        this.apiReqData.product.DBPoption.lumpsumPayoutPercent = 0;
        this.apiReqData.product.DBPoption.OptionSelected = this.DBPOptionSelected;
        //console.log(this.apiReqData);
        this.getPolicy(this.apiReqData);
      }
    },
    getPpoVal(val) {
      let data = getPPOMaster();
            return data[val];
    },
    getCoverOption(val){
      let data = getCoverOptionMaster()
      return data[val];
    },
    getP1C2PSDbp(val){
      let data =getP1C2PSDbp()
      this.minIncomeTerm=parseInt(data['incometerm']['min'])
      return data[val];
    },
    getNewPlan(value, selectedPlan) {
      let planName = value;
      let pCode = selectedPlan.insuranceAndProducts.productCode;
      let planData = this.getPlanIdByPlanName(pCode, planName);
      sessionStorage.setItem("planData",JSON.stringify(planData));
      if (planData && Array.isArray(planData.addonsReq)) {
        let allAddOnsArr = [];
        planData.addonsReq.forEach(addonReq => {
          let addonArr = [];
          let tempArr = {
            OptionSelected: addonReq.id,
            addOnSumAssured: parseInt(addonReq.addOnSumAssured),
            varFields: [
              {
                fieldName: "",
                fieldValue: "",
              },
            ],
          };
          addonArr.push(tempArr);
          console.log("addonArr", addonArr);
          allAddOnsArr.push(...addonArr);
        });

        this.apiReqData.product.AddOnSection.AddOns = allAddOnsArr;
        this.apiReqData.product.coverOption.SAChangePercent = null;
        this.apiReqData.product.coverOption.levelCoverPeriod = null;

        if (planData.coverOption != null && planData.coverOption != "") {
        this.apiReqData.product.coverOption.OptionSelected = planData.coverOption;
        }

        if (planData.ppo != null && planData.ppo != "") {
            this.apiReqData.product.premiumPaymentOption = planData.ppo;
        }
        if (planData.ppt != null && planData.ppt != "") {
            this.apiReqData.product.premiumPaymentTerm = planData.ppt;
        }

        if (planData.SAChangePercent) {
            this.apiReqData.product.coverOption.SAChangePercent = parseInt(planData.SAChangePercent);
        }
        if (planData.levelCoverPeriod) {
        this.apiReqData.product.coverOption.levelCoverPeriod = planData.levelCoverPeriod;
        }
      }
      sessionStorage.setItem("quote_request", JSON.stringify(this.apiReqData));
      this.getPolicy(this.apiReqData);
      console.log(planName, planData, "this.newPlan");
    },
    getPlanIdByPlanName(pCode, planName) {
      let tempArr = getPlanMaster()[pCode];
      for (let planId in tempArr) {
        let planData = tempArr[planId];
        if (planData.planName === planName) {
        return {
        planId: planId,
        planName: planData.planName,
        addonsReq: planData.addonsReq,
        coverOption: planData.coverOption,
        ppo: planData.PPO,
        ppt: planData.PPT,
        SAChangePercent: planData.SAChangePercent,
        levelCoverPeriod: planData.levelCoverPeriod
         }; 
       }
     }
    },
    getPayPremium(event) {
      //console.log(event.target.value);
      this.payPremium = event.target.value;
      this.getPreviousRequest();
      if (event.target.value == 3) {
        console.log('limited no. called', event.target.value);
        this.apiReqData.product.premiumPaymentOption = event.target.value;
        console.log('apireqdata :gg ',this.apiReqData )
        sessionStorage.setItem("quote_request", JSON.stringify(this.apiReqData));
        this.premiumPaymentTermArr = this.getPaymentTermValues();
        this.apiReqData.product.premiumPaymentTerm = 0;
        if(this.apiReqData.product.premiumPaymentFrequency == 'S') {
          this.apiReqData.product.premiumPaymentFrequency = 'M';
        }
              } else if(event.target.value == 1) {
        this.apiReqData.product.premiumPaymentOption = event.target.value;
        this.apiReqData.product.premiumPaymentTerm = parseInt(event.target.value);
        this.apiReqData.product.premiumPaymentFrequency  = 'S';
        this.filterPageValues.premiumPayingFreq= this.apiReqData.product.premiumPaymentFrequency;
        sessionStorage.setItem('filterPageValues',JSON.stringify(this.filterPageValues));
        if(this.apiReqData.product.insuranceAndProducts[0].productCode[0] === 'ABDGSHLD'){
          this.apiReqData.product.AddOnSection.AddOns = this.getAddOns2(19);
        }
        this.getPolicy(this.apiReqData);
      } else if(event.target.value == 2) {
        console.log('for entire policy term called');
        this.apiReqData.product.premiumPaymentOption = event.target.value;
        this.apiReqData.product.premiumPaymentTerm = this.selectedPlan.productDetails.policyTerm;
        if(this.apiReqData.product.premiumPaymentFrequency == 'S') {
          this.apiReqData.product.premiumPaymentFrequency = 'M';
        }
        this.premiumPaymentTermArr = [
          this.selectedPlan.productDetails.premiumPaymentTerm,
        ];
        this.getPolicy(this.apiReqData);
      } else {
        console.log('pay for 60 years called', event.target.value);
        this.apiReqData.product.premiumPaymentOption = event.target.value;
        console.log('apireqdata :gg ',this.apiReqData )
        sessionStorage.setItem("quote_request", JSON.stringify(this.apiReqData));
        this.premiumPaymentTermArr = this.getPaymentTermValues();
        if(this.apiReqData.product.premiumPaymentFrequency == 'S') {
          this.apiReqData.product.premiumPaymentFrequency = 'M';
        }
        this.apiReqData.product.premiumPaymentOption = "3";
        sessionStorage.setItem("quote_request", JSON.stringify(this.apiReqData));
        // this.getPolicy(this.apiReqData);
      }
      
    },

    getCoverPremiumModal(event){	
      if(this.selectedPlan2.insuranceAndProducts.productCode == 'ABDGSHLD'){	
      if(event.target.value == 2)	
      {	
       this.fixPercent = [{name:"25%", value:25},{name:"50%", value:50}];      	
      }	
      else if(event.target.value==3)	
      {	
       this.fixPercent = [{name:"0.5%", value:0.5},{name:"5%", value:5},{name:"10%", value:10},{name:"1.25%", value:1.25}]	
      }	
      }	
      else if(this.selectedPlan2.insuranceAndProducts.productCode == 'P1C2PS' )	
      {	
      if(event.target.value==2)	
      {	
        const fixPercentData = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
        this.fixPercent = fixPercentData.map((percent) => ({
          name: percent.toString() + "%",
          value: percent
        }));
        const levelCoverPeriodArrData = [];
        for (let i = this.selectedPlan.productDetails.premiumPaymentTerm; i <= this.selectedPlan.productDetails.policyTerm - 5; i++) {
          levelCoverPeriodArrData.push(i);
        }
        this.levelCoverPeriodArr = levelCoverPeriodArrData.map((percent) => ({
          name: percent.toString(),
          value: percent
        }));
      }	
      else if(event.target.value==3)	
       {	
        this.fixPercent = [{name:"5% every subsequent year", value:5},{name:"10% every 5 Years", value:10}]; 
       }	
    }	
      if(event.target.value==2 && this.fixPercent && this.fixPercent.length > 0)	
      {	
       this.apiReqData.product.coverOption.OptionSelected = 2	
       $("#decreasingModal").modal("show");	
      }	
     else if(event.target.value==3 && this.fixPercent && this.fixPercent.length > 0)	
     {	
       this.apiReqData.product.coverOption.OptionSelected = 3	
       $("#increasingModal").modal("show");	
    }	else {
      if(this.apiReqData.product.coverOption.OptionSelected !== "1"){
        this.apiReqData.product.coverOption.OptionSelected = 1;
        this.savedatatostorage(0,0);
      }
    }
  },
    getCoverTillAge(event) {
      console.log('cover till age called')
      console.log(event.target.value);
      this.getPreviousRequest();
      if(this.payPremium == 3) {
        this.apiReqData.product.policyTerm =
        parseInt(event.target.value) -
        parseInt(this.selectedPlan.individualDetails[0].age);
        this.premiumPaymentTerm = "";
        sessionStorage.setItem("quote_request", JSON.stringify(this.apiReqData));
        console.log('inside 3');
        this.premiumPaymentTermArr = this.getPaymentTermValues();
      } else {
        this.apiReqData.product.policyTerm =
        parseInt(event.target.value) -
        parseInt(this.selectedPlan.individualDetails[0].age);
        this.apiReqData.product.premiumPaymentTerm =
          parseInt(event.target.value) -
          parseInt(this.selectedPlan.individualDetails[0].age);
        this.getPolicy(this.apiReqData);
      }
      
    },
    getPremiumPaymentTerm(event) {
      //console.log(event.target.value);
      //this.getPreviousRequest();
      //this.apiReqData.product.premiumPaymentTerm = parseInt(event.target.value) - parseInt(this.selectedPlan.individualDetails[0].age);
      this.apiReqData.product.premiumPaymentTerm = parseInt(event.target.value);
      this.getPolicy(this.apiReqData);
    },
    addReturnOfPremium() {
      this.returnOfPremiumPercentage = 100;
      this.apiReqData.product.AddOnSection.returnOfPremium.returnOfPremiumPercentage =
        this.returnOfPremiumPercentage;
      this.getPolicy(this.apiReqData);
    },
    removeReturnOfPremium() {
      this.returnOfPremiumPercentage = 0;
      this.apiReqData.product.AddOnSection.returnOfPremium.returnOfPremiumPercentage =
        this.returnOfPremiumPercentage;
      this.getPolicy(this.apiReqData);
    },
    addWholeLife() {
      this.isWholeLifeSelected = 1;
      this.getPreviousRequest();
      sessionStorage.setItem("isWholeLifeSelected", JSON.stringify(this.isWholeLifeSelected));
      this.apiReqData.product.policyTerm =
        this.wholeLifeAge - parseInt(this.selectedPlan.individualDetails[0].age);
      if(this.apiReqData.product.premiumPaymentOption == 3) {
        this.premiumPaymentTermArr = this.getPaymentTermValues();
        this.premiumPaymentTerm = "";
        this.getPolicy(this.apiReqData);
      } else {
        this.apiReqData.product.premiumPaymentTerm =
        this.wholeLifeAge - parseInt(this.selectedPlan.individualDetails[0].age);
        this.getPolicy(this.apiReqData);
      } 
    },
    removeWholeLife() {
      this.getPreviousRequest();
       if(this.apiReqData.product.insuranceAndProducts[0].insuranceCompanyCode=="HDFC")
       {
        this.apiReqData.product.premiumPaymentOption="2"
       }
      this.apiReqData.product.policyTerm =
        65 - parseInt(this.selectedPlan.individualDetails[0].age);
      this.apiReqData.product.premiumPaymentTerm =
        65 - parseInt(this.selectedPlan.individualDetails[0].age);
      this.isWholeLifeSelected = 0;
      sessionStorage.removeItem("isWholeLifeSelected");
      //this.coverForArr.pop();
      //this.setCoverFor();
      this.getPolicy(this.apiReqData);
    },

    // Configrations
    getFwrConfig(pCode) {
      // Based on DBPMaster
      //console.log('getFwrConfig called', pCode)
      let tempArr = [];
      if (pCode == "T50" || pCode == "T51") {
        tempArr = [4, 5, 6, 7];
      } else if (pCode == "T52") {
        tempArr = [4];
      } else if (pCode == "T53") {
        tempArr = [4];
      } else if (pCode == "40038") {
        tempArr = [4, 5, 6];
      } else if (pCode == "12023") {
        tempArr = [4, 5, 6];
      } else if (pCode == "12013") { // copy of 12023 for testing purpose only
        tempArr = [4, 5, 6];
      } else if (pCode == "T54") {
        tempArr = [4];
      } else if (pCode == "T38") {
        tempArr = [4];
      } else if (pCode == "T41") {
        tempArr = [4];
      } else if (pCode == "T39") {
        tempArr = [4];
      } else if (pCode == "T40") {
        tempArr = [4];
      } else if (pCode == "T55") {
        tempArr = [4, 5, 6, 7];
      } else if (pCode == "T56") {
        tempArr = [4, 5, 6 , 7];
      } else if (pCode == "T43") {
        tempArr = [4];
      } else if (pCode == "T58") {
        tempArr = [4];
      } else if (pCode == "T59") {
        tempArr = [4];
      } else if (pCode == "T60") {
        tempArr = [4];
      } else if (pCode == "T61") {
        tempArr = [4];
      } else if (pCode == "P1C2PL") {
        tempArr = [4];
      } else if (pCode == "12003") {
        tempArr = [4, 6, 8];
      } else if (pCode == "12013") {
        tempArr = [4, 5, 7];
      } else if (pCode == "SamRakSp") {
        tempArr = [4];
      } else if (pCode == "25") {
        tempArr = [4, 6];
      } else if (pCode == "ABTLSHLD"){
        tempArr = [4, 6, 8];
      } else if (pCode == "ABDGSHLD"){
        tempArr = getABSLIMaster()['PayoutToNominee'];
      } else if(pCode =="P1C2PS"){
        tempArr =[4,6];
      } else if(pCode == "154"){
        tempArr = [4];
      } else if (pCode == "345"){
        tempArr = [4, 6];
      } else if (pCode == "321"){
        tempArr = [4];
      }
      return tempArr;
    },
    // getPlanMasterConfig(pCode){
    //   let tempArr = [];
    //   tempArr = getPlanMaster()[pCode];
    //   let valuesArray = [];
    //   for (let planId in tempArr) {
    //     valuesArray.push(tempArr[planId]);
    //   }
    //   tempArr = valuesArray;
    //   return tempArr;
    // },
    getPpoConfig(pCode) {
      //productMaster table
      //console.log("getppocalled: ", pCode);
      let tempArr = [];
      if (pCode == "T50") {
        tempArr = [1];
      } else if (pCode == "T51") {
        tempArr = [2, 3];
      } else if (pCode == "T52") {
        tempArr = [1];
      } else if (pCode == "T53") {
        tempArr = [2, 3];
      } else if (pCode == "40038") {
        tempArr = [2, 3];
      } else if (pCode == "T54") {
        tempArr = [2];
      } else if (pCode == "T38") {
        tempArr = [2];
      } else if (pCode == "T41") {
        tempArr = [2];
      } else if (pCode == "T39") {
        tempArr = [1];
      } else if (pCode == "T40") {
        tempArr = [1];
      } else if (pCode == "T55") {
        tempArr = [1];
      } else if (pCode == "T56") {
        tempArr = [2, 3];
      } else if (pCode == "T43") {
        tempArr = [2];
      } else if (pCode == "T58") {
        tempArr = [1];
      } else if (pCode == "T59") {
        tempArr = [2, 3];
      } else if (pCode == "T60") {
        tempArr = [1];
      } else if (pCode == "T61") {
        tempArr = [2, 3];
      } else if (pCode == "P1C2PL") {
        tempArr = [1, 2, 3];
      } else if (pCode == "12003") {
        tempArr = [2, 3];
      } else if (pCode == "12013") {
        tempArr = [1, 2, 3];
      } else if (pCode == "SamRakSp") {
        tempArr = [1, 2, 3];
      } else if (pCode == "25") {
        tempArr = [1, 3];
      } else if (pCode == 'ABTLSHLD'){
        tempArr = [1, 2, 3];
      } else if (pCode == 'ABDGSHLD'){
        tempArr = [1, 2, 3];
      }else if(pCode =="P1C2PS"){
        tempArr = [1, 2, 3];
      } else if (pCode == '154'){
        tempArr = [1, 2, 3, 4];
      } else if (pCode == "345") {
        tempArr = [2, 3, 4];
      } else if (pCode == "321"){
        tempArr = [2, 3, 4];
      }
      return tempArr;
    },
    getCoverOptionCofig(pCode) {
      let tempArr = [];
      if (pCode == "T50") {
        tempArr = [1];
      } else if (pCode == "T51") {
        tempArr = [1];
      } else if (pCode == "T52") {
        tempArr = [1];
      } else if (pCode == "T53") {
        tempArr = [1];
      } else if (pCode == "40038") {
        tempArr = [1, 3];
      } else if (pCode == "12023") {
        tempArr = [1,3];
      } else if (pCode == "P1C2PL") {
        tempArr = [1, 2];
      }else if (pCode == "25") {
        tempArr = [1];
      } else if (pCode == "26") {
        tempArr = [1];
      } else if (pCode == "SamRakSp") {
        tempArr = [1];
      } else if (pCode == 'ABTLSHLD'){
        tempArr = [1, 2, 3];
      } else if (pCode == 'ABDGSHLD'){
        tempArr = getABSLIMaster()['coverOption'];
      }else if(pCode =="P1C2PS"){
        tempArr =[1,2,3];
      } else if (pCode == '154'){
        tempArr = [1, 3];
      } else if (pCode == "345"){
        tempArr = [1];
      } else if(pCode == "321"){
        tempArr = [1];
      }
      return tempArr;
    },
    getPaymentTermValues() {
      //productMaster table

      let pCode = this.selectedPlan.insuranceAndProducts.productCode;
      let policyTerm = this.apiReqData.product.policyTerm;
      let age = this.selectedPlan.individualDetails[0].age;
      let tempArr = [];
      if (pCode == "T51") {
        if(this.isWholeLifeSelected == 1) {
          tempArr = [10, 60 - age];
        } else {
          if(this.selectedAge < 65) {
            tempArr = [5, 7, 10, policyTerm - 5];
          } else {
            tempArr = [5, 7, 10, policyTerm - 5, 60 - age];
          }
        }
        
      } else if (pCode == "T53") {
        tempArr = [policyTerm - 5];
      } else if (pCode == "40038") {
        tempArr = [60 - (age +1)];
      } else if (pCode == "T56") {
        tempArr = [5, 10, policyTerm-5];
      } else if (pCode == "T59") {
        tempArr = [5, 10];
      } else if (pCode == "T60") {
        tempArr = [];
      } else if (pCode == "T61") {
        tempArr = [5, 10, policyTerm-5];
      } else if (pCode == "P1C2PL") {
        if(this.isWholeLifeSelected == 1) {

            if(this.selectedPlan.productDetails.planOption.optionSelected=='31' ||  this.selectedPlan.productDetails.premiumPaymentOption=='3'){
            tempArr = [5, 10, 15];
            }
          }   
        else if(this.selectedPlan.productDetails.planOption.optionSelected=='32'){
            tempArr = [5, 10];
            }  
        else{
        for(var i = 5; i< policyTerm; i++) {
          tempArr.push(i);
         }
        }
      } else if (pCode == "12003") {
        tempArr = [10];
      } else if (pCode == "12023") {
        tempArr = [5, 10, 15, 20, (60-age)];
      } else if (pCode == "12013") {
        tempArr = [15, policyTerm];
      } else if (pCode == "SamRakSp") {
        //tempArr = [5, 82];
        if(this.selectedPlan.productDetails.AddOnSection.returnOfPremium.returnOfPremiumPercentage == '100') {
          tempArr = [5, 7, 10, 12, 15];
        } else {
          //let endVal = this.getMinOfTwo(this.selectedPlan.productDetails.policyTerm, 81);
          let endVal = this.getMinOfTwo(this.apiReqData.product.policyTerm, 81);
          //endCoverFor = parseInt(this.selectedPlan.individualDetails[0].age) + this.getMinOfTwo(100 - parseInt(this.selectedPlan.individualDetails[0].age), 82);
          console.log('inside ellll: ', endVal);
          for(var i=5; i <= endVal; i++) {
            tempArr.push(i);
          }
        }
        
      } else if (pCode == "25") {
        tempArr = [5, 47];
      } else if (pCode == "ABTLSHLD"){
        tempArr = [6, 8, 10];
      } else if (pCode == "ABDGSHLD"){
        if(this.selectedPlan.productDetails.planOption.optionSelected=='36' || this.selectedPlan.productDetails.planOption.optionSelected=='37'){
             if( this.selectedPlan.productDetails.premiumPaymentOption=='3'){
                tempArr = [5];
                console.log("tempArr",tempArr);
             }
        }
        else if(age >= 18 && age <= 54)
          tempArr = [5,7,10,12,15,20,(60-age)];
        else if(age >= 55 && age <= 60)
          tempArr = [5,7,10,12,15,20];
        else if(age >= 61 && age <= 65)
           tempArr = [5,7,10,12,15];  
        // tempArr = [5,7,10,12,15,20,(60-age)];
      } else if (pCode == "P1C2PS") {
        if (["102", "103", "153"].includes(this.selectedPlan.productDetails.planOption.optionSelected)) {
          for (let i = 3; i < 85 - age; i++) {
            tempArr.push(i);
          }
        } else if (["104", "154"].includes(this.selectedPlan.productDetails.planOption.optionSelected)) {
          for (let i = 5; i < 85 - age; i++) {
            tempArr.push(i);
          }
        }
        else if (["154"].includes(this.selectedPlan.productDetails.planOption.optionSelected)) {
          for (let i = 7; i < 85 - age; i++) {
            tempArr.push(i);
          }
        }
      } else if (pCode == "154"){
        console.log("this.selectedPlan.productDetails.premiumPaymentOption", this.apiReqData.product.premiumPaymentOption)
        if(this.apiReqData.product.premiumPaymentOption =='4'){
          tempArr = [60-age];
          this.premiumPaymentTerm = 60-age;
        }
        else{
        tempArr = [5, 10, 12, 15];
        }
      } else if(pCode == "345" || pCode == "321"){
        if(this.apiReqData.product.premiumPaymentOption =='4'){
          tempArr = [60-age];
          this.premiumPaymentTerm = 60-age;
        }
        else{
        tempArr = [5, 6, 10, 12, 15, 20, 60-age]
        }
      } else if(pCode == "321"){
        if(this.isWholeLifeSelected == 1) {
          for(let i=5; i <= 42; i++) {
            tempArr.push(i);
          }
        }
        else if(this.selectedPlan.productDetails.AddOnSection.returnOfPremium.returnOfPremiumPercentage == '100'){
          if(this.selectedPlan.productDetails.premiumPaymentOption=='3'){
            for(let i=5; i <= 35; i++) {
              tempArr.push(i);
            }
          }
          else{
            for(let i=10; i <= 50; i++) {
              tempArr.push(i);
            }
          }
        }
        else{
          if(this.selectedPlan.productDetails.premiumPaymentOption=='3'){
            for(let i=5; i <= 42; i++) {
              tempArr.push(i);
            }
          }
          else{
            for(let i=10; i <= 85-age; i++) {
              tempArr.push(i);
            }
          }
        }
      } 
      console.log('mp arr:', tempArr)
      let tmpArr2 = [];
      if(this.apiReqData.product.premiumPaymentOption =='4'){
        tmpArr2= tempArr;
      }
      else{
      tempArr.sort(function(a, b) {
        return a - b;
      });
      }
      
     
      // this.selectedPlan.productDetails.policyTerm
      tempArr.forEach(
        (element, index) => {
        if(element <= this.apiReqData.product.policyTerm && element != 0) {
          tmpArr2.push(element);
        }
      });

      var uniqueValues = tmpArr2.filter(this.onlyUnique);
      console.log('uniqueValues: ', uniqueValues)
      return uniqueValues;
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },
    getOptSelectedText(value) {
      let optSelected = getOPTSelected();
      return optSelected[value];
    },
    convertInLakh(amt) {
      if (amt < 10000000) {
        return amt / 100000 + "L";
      } else {
        return amt / 10000000 + "Cr.";
      }
    },
    convertInLakhRounded(amt) {
      if (amt < 10000000) {
        let x = Math.trunc(amt / 100000);
        return x + "L";
      } else {
        let x = Math.trunc(amt / 10000000);
        return x + "Cr.";
      }
    },
    getCarousel(val) {
      if(val == '0') {
        return 'active';
      }
      return '';
    },
    getSumAssuredValue(ind, optionselected, selectedAddOnsArray) {
      //console.log('index: ', ind, 'option selected: ', optionselected, 'sumassured: ', sumassured);
      this.indexUpdateAddOn = this.getAddSelectionIndex(optionselected, selectedAddOnsArray);
      this.addOnUpdateTitle = optionselected;
      this.addOnUpdateSumAssured = this.getAddOnSelectionSA(optionselected, selectedAddOnsArray);
    },
    updateAddOnSumAssured() { 
      //this.selectedData.productDetails.AddOnSection.addOnSelected[this.indexUpdateAddOn].addOnSumAssured = this.addOnUpdateSumAssured;
      $("#editsumassured").modal("hide");
      this.apiReqData.product.AddOnSection.AddOns[this.indexUpdateAddOn].addOnSumAssured = parseInt(this.addOnUpdateSumAssured);
      this.getPolicy(this.apiReqData);
    },
    getRiderSumAssuredValue(riderCode, riderText, riderArray) {	
      this.indexUpdateRider = this.getRiderSelectionIndex(riderCode, this.apiReqData.personalInformation.individualDetails[0].riderDetails);	
      this.riderUpdateTitle = riderText;	
      this.currentRidderCode = riderCode;	
      let RIDER_SUM_ASSURED = getRiderSumAssured()	
      this.minSumAssured = RIDER_SUM_ASSURED[this.selectedPlan.insuranceAndProducts.insuranceCompanyCode][riderCode]['min']	
      this.maxSumAssured = RIDER_SUM_ASSURED[this.selectedPlan.insuranceAndProducts.insuranceCompanyCode][riderCode]['max']	
      this.riderUpdateSumAssured = this.getRiderSelectionSA(riderCode, riderArray);	
      	
    },
    updateRiderSumAssured() {	
    let RIDER_SUM_ASSURED = getRiderSumAssured()	
    let minValue = RIDER_SUM_ASSURED[this.selectedPlan.insuranceAndProducts.insuranceCompanyCode][this.currentRidderCode]['min']	
    let maxValue = RIDER_SUM_ASSURED[this.selectedPlan.insuranceAndProducts.insuranceCompanyCode][this.currentRidderCode]['max']	
    let riderSumAssured = parseInt(this.riderUpdateSumAssured)	
    if(this.riderUpdateSumAssured == ""){	
     this.error["sumassuredval"] = "Enter your sum assured value"	
     return true	
     }	
     else if(riderSumAssured<minValue || riderSumAssured>maxValue)	
     {	
      this.error["sumassuredval"] = "Please enter values within the specified limits"	
     }	
     else if(riderSumAssured>=minValue && riderSumAssured<=maxValue)	
     {	
      $("#editridersumassured").modal("hide");	
      this.apiReqData.personalInformation.individualDetails[0].riderDetails[this.indexUpdateRider].riderSA = parseInt(this.riderUpdateSumAssured);	
      this.error["sumassuredval"]=""	
      this.getPolicy(this.apiReqData);	
     }	
    },
    currency(amt) {
      // return new Intl.NumberFormat().format(amt)
      let tmpAmt = parseFloat(amt).toFixed(2);
      return new Intl.NumberFormat('en-IN').format(tmpAmt);
    },
    getAddOns2(adOnId) {
      let addOnsArr = [];
      this.selectedAddOnsIds = [];
      console.log('addOnId to remove: ', adOnId);
      //this.selectedPlan2.productDetails.AddOnSection.addOnSelected.forEach(
      this.selectedPlan.productDetails.AddOnSection.addOnSelected.forEach(
        (element, index) => {
          let tempArr = {
            OptionSelected: element.optionSelected,
            addOnSumAssured: element.addOnSumAssured,
            varFields: [
              {
                fieldName: "",
                fieldValue: "",
              },
            ],
          };

          //if(element.checked == true || element.premiumIncludedFlag == 'Yes') {
            if(adOnId != element.optionSelected) {
              addOnsArr.push(tempArr);
              this.selectedAddOnsIds.push(element.optionSelected);
            }
          //}
        }
      );

      console.log('addOnsArr: ', addOnsArr);
      return addOnsArr;
    },
    removeAddOns(adOnId) {
      this.apiReqData.product.AddOnSection.AddOns = this.getAddOns2(adOnId);
      if(adOnId == '16') {
        this.apiReqData.product.AddOnSection.returnOfPremium.returnOfPremiumPercentage = 0;
      }
      let tmpPlanId = this.getPlanId(this.selectedPlan.insuranceAndProducts.productCode, this.selectedAddOnsIds);
      console.log('temp plan id: ', tmpPlanId);

      if(tmpPlanId > 0) {
        this.apiReqData.product.planOption.OptionSelected = tmpPlanId;
      } 
      this.getPolicy(this.apiReqData);
    },
    addAddOns(adOnId) {
      console.log('adOnId called');
      let itemIndex = this.addOnsList.findIndex(item => item.optionSelected == adOnId);
      if(itemIndex > -1) {

        if(adOnId == '16') {
          this.apiReqData.product.AddOnSection.returnOfPremium.returnOfPremiumPercentage = 100;
        }

        let tmp = this.addOnsList[itemIndex];
        let tempArr = {
          OptionSelected: tmp.optionSelected,
          addOnSumAssured: tmp.addOnSumAssured,
          varFields: [
            {
              fieldName: "",
              fieldValue: "",
            },
          ],
        };
        this.apiReqData.product.AddOnSection.AddOns.push(tempArr);
        this.selectedAddOnsIds.push(tmp.optionSelected);
        


        //-------------
        let tmpPlanId = this.getPlanId(this.selectedData.insuranceAndProducts.productCode, this.selectedAddOnsIds);
        console.log('temp plan id: ', tmpPlanId);

        if(tmpPlanId > 0) {
          // this.apiReqData.product.planOption.OptionSelected = tmpPlanId;
          this.apiReqData.product.planOption.OptionSelected = "";
        } else {
          if(this.selectedPlan2.insuranceAndProducts.insuranceCompanyCode == 'HDFC') {
            // this.apiReqData.product.planOption.OptionSelected = this.selectedPlan2.productDetails.planOption.optionSelected;
            this.apiReqData.product.planOption.OptionSelected = ""
          } else {
            this.apiReqData.product.planOption.OptionSelected = "";
          }
          
        }
        //-------------
        
        this.getPolicy(this.apiReqData);
      }
    },
    includeAddOnsPremium() {
      let addOnsAmt = 0;
      let addOnsAmtTotal = 0;
      let addOnsTax = 0;
      this.selectedPlan.productDetails.AddOnSection.addOnSelected.forEach(
        (element, index) => {
          if( element.premiumIncludedFlag == 'No') {
            addOnsAmt += element.premiumDetails[0].premiumValue;
            addOnsTax += element.premiumDetails[0].tax[0].taxValue;
            addOnsAmtTotal += element.premiumDetails[0].totalPremiumValue;
          }
        }
      );
      this.selectedPlan.individualDetails[0].premiumDetails[0].premiumValue += addOnsAmt;
      this.selectedPlan.individualDetails[0].premiumDetails[0].tax[0].taxValue += addOnsTax;
      this.selectedPlan.individualDetails[0].premiumDetails[0].totalPremiumValue += addOnsAmtTotal;
    },
    includeRidersPremium() {
      let riderAmt = 0;
      let riderAmtTotal = 0;
      let riderTax = 0;
      this.selectedPlan.individualDetails[0].rider.forEach(
        (element, index) => {
          riderAmt += element.premiumDetails[0].riderPremiumValue;
          riderTax += element.premiumDetails[0].tax[0].taxValue;
          riderAmtTotal += element.premiumDetails[0].totalRiderPremiumValue;
        }
      );
      this.selectedPlan.individualDetails[0].premiumDetails[0].premiumValue += riderAmt;
      this.selectedPlan.individualDetails[0].premiumDetails[0].tax[0].taxValue += riderTax;
      this.selectedPlan.individualDetails[0].premiumDetails[0].totalPremiumValue += riderAmtTotal;
    },
    lumpsumPayoutPercent1() {
      console.log('lumpsumPayoutPercentModification called');
      this.lumpsumPayoutPercentErr = "";
      if(this.selectedPlan.insuranceAndProducts.productCode == 'P1C2PS' || this.selectedPlan.insuranceAndProducts.productCode == '154') {
        console.log("option selected",this.DBPOptionSelected)
        console.log("lumpsumpayeour percen",typeof(parseInt(this.lumpsumPayoutPercent)))
        this.apiReqData.product.DBPoption.OptionSelected = this.DBPOptionSelected;
        this.apiReqData.product.DBPoption.lumpsumPayoutPercent = parseInt(this.lumpsumPayoutPercent);
        this.apiReqData.product.DBPoption.incomeFrequency = this.incomeFrequency;
        this.apiReqData.product.DBPoption.incomeTerm =parseInt(this.incomeTerm)
        this.apiReqData.product.DBPoption.incomeTermUnit = this.getP1C2PSDbp('incomeTermUnit')
        $("#lumpsumPayoutPercentModification").modal("hide");
        console.log("API Request Data",this.apiReqData)
        this.getPolicy(this.apiReqData);
        

      } 
      else if(this.lumpsumPayoutPercent > 0 && this.selectedPlan.insuranceAndProducts.productCode !== 'P1C2PS') {
        $("#lumpsumPayoutPercent").modal("hide");
        console.log("option selected",this.DBPOptionSelected)
        console.log("lumpsumpayeour percen",typeof(parseInt(this.lumpsumPayoutPercent)))
        this.apiReqData.product.DBPoption.OptionSelected = this.DBPOptionSelected;
        this.apiReqData.product.DBPoption.lumpsumPayoutPercent = parseInt(this.lumpsumPayoutPercent);
        	
        

        this.getPolicy(this.apiReqData);
      } 
      else {
        this.lumpsumPayoutPercentErr = 'Please enter lumpsum payout percent';
      }

    },
    savedatatostorage(saChangeAmountPercentVal, levelCoverPeriodVal = 0) {
      if(saChangeAmountPercentVal === 0 && (this.apiReqData.product.coverOption.OptionSelected === 1 || this.apiReqData.product.coverOption.OptionSelected === '1')){
        delete this.apiReqData.product.coverOption.SAChangePercent;
      } else {
        this.apiReqData.product.coverOption.SAChangePercent = saChangeAmountPercentVal;
      }
      if(this.selectedPlan.insuranceAndProducts.productCode == 'P1C2PS'){
        this.apiReqData.product.coverOption.levelCoverPeriod = levelCoverPeriodVal;
      }
      this.getPolicy(this.apiReqData);
      $("#increasingModal").modal("hide");
      $("#decreasingModal").modal("hide");
    },
    // Numbers only
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      console.log(charCode);
      //if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode == 46) {
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    getPolicyCover2(data) {
    
      let arr = [];
      for(var i = parseInt(data.min); i <= parseInt(data.max); i = i + parseInt(data.multipleOf)) {
        arr.push(i);
      }
    
      return arr;
    },
    setCoverFor(val = "") {
      console.log('setCoverFor called: ', val);
      this.coverForArr = [];
      if(val == 1) {
        // this.coverForArr.push(parseInt(this.wholeLifeAge));
        let startAge = parseInt(this.selectedPlan.individualDetails[0].age) +  parseInt(this.selectedPlan.supportingData.validRanges.PolicyTerm.min);
        for (var i = startAge; i <= parseInt(this.wholeLifeAge); i++) {
            this.coverForArr.push(i);
        }
      } else {
        // Setting Cover For
        console.log('inside else 1')
        let startCoverFor = parseInt(this.selectedPlan.individualDetails[0].age) +  parseInt(this.selectedPlan.supportingData.validRanges.PolicyTerm.min);
        if(this.selectedPlan.insuranceAndProducts.productCode == 'SamRakSp') {
            console.log('inside SamRakSp pay');
          // Specially for TATA (SamRakSp)
          let endCoverFor;
          if(parseInt(this.selectedPlan.individualDetails[0].age)<60)
          {
            if(this.selectedPlan.productDetails.AddOnSection.returnOfPremium.returnOfPremiumPercentage == '100') { // if ROP
              if(this.selectedPlan.productDetails.premiumPaymentOption == '3') { // Limited Pay
                endCoverFor = parseInt(this.selectedPlan.individualDetails[0].age) + this.getMinOfTwo(100 - parseInt(this.selectedPlan.individualDetails[0].age), 60);
              } else { // Regular Pay and Single Pay
                console.log('inside regular pay');
                endCoverFor = parseInt(this.selectedPlan.individualDetails[0].age) + this.getMinOfTwo(100 - parseInt(this.selectedPlan.individualDetails[0].age), 60);
                console.log('endCoverFor irp: ', endCoverFor);
              }
            } else { // Non ROP
              endCoverFor = parseInt(this.selectedPlan.individualDetails[0].age) + this.getMinOfTwo(100 - parseInt(this.selectedPlan.individualDetails[0].age), 82);
            }
            console.log('start cover for: ', startCoverFor, 'end cover for: ', endCoverFor)
            for (var i = startCoverFor; i <= endCoverFor; i++) {
              this.coverForArr.push(i);
            }
            this.coverForArr.push(parseInt(this.wholeLifeAge));
          }
          else{
            if(this.selectedPlan.productDetails.AddOnSection.returnOfPremium.returnOfPremiumPercentage == '100') { // if ROP
              if(this.selectedPlan.productDetails.premiumPaymentOption == '3') { // Limited Pay
                endCoverFor = parseInt(this.selectedPlan.individualDetails[0].age) + this.getMinOfTwo(85 - parseInt(this.selectedPlan.individualDetails[0].age), 50);
              } else { // Regular Pay and Single Pay
                console.log('inside regular pay');
                endCoverFor = parseInt(this.selectedPlan.individualDetails[0].age) + this.getMinOfTwo(85 - parseInt(this.selectedPlan.individualDetails[0].age), 40);
                console.log('endCoverFor irp: ', endCoverFor);
              }
            } else { // Non ROP
              endCoverFor = parseInt(this.selectedPlan.individualDetails[0].age) + this.getMinOfTwo(85 - parseInt(this.selectedPlan.individualDetails[0].age), 82);
            }
            console.log('start cover for: ', startCoverFor, 'end cover for: ', endCoverFor)
            for (var i = startCoverFor; i <= endCoverFor; i++) {
              this.coverForArr.push(i);
            }
          }

          
          
        } else {
          let endCoverFor = this.getMaxMaturityAge(this.selectedPlan.individualDetails[0].age, this.selectedPlan.supportingData.validRanges.PolicyTerm.max, this.selectedPlan.insuranceAndProducts.productCode);
          //console.log('PPP startCoverFor', startCoverFor, 'endCoverFor', endCoverFor)
          for (var i = startCoverFor; i <= endCoverFor; i++) {
            this.coverForArr.push(i);
          }
          this.coverForArr.push(parseInt(this.wholeLifeAge));
        }
        
        
      }

      this.coverForArr = this.coverForArr.filter(this.onlyUnique);

    },
    getMaxMaturityAge(age, policyTerm, productCode) {

      //console.log('getMaxAge called: age: ', age, 'policyTerm: ', policyTerm, 'productCode: ', productCode);
      let tmp;
      let maximumMaturityAge = getMaxPolicyAge()[productCode];
      if((parseInt(age) + parseInt(policyTerm)) < parseInt(maximumMaturityAge)) {
        //console.log('getMaxAge if: ');
        tmp = parseInt(age) + parseInt(policyTerm);
      } else {
        //console.log('getMaxAge else: ');
        tmp = parseInt(maximumMaturityAge);
      }
      //console.log('tmp: ', tmp)
      return tmp;
    },
    getRiderList(pCode) {
      return getRider()[pCode];
    },
    checkAddOnSelection(addOnID, selectedAddOnsArray) {
      //console.log('checkAddOnSelection called: ', addOnID, selectedAddOnsArray);
      let itemIndex = selectedAddOnsArray.findIndex(item => item.optionSelected == addOnID);
      if(itemIndex > -1) {
        return true;
      } else {
        return false;
      }
    },
    getAddOnPremium(addOnID, selectedAddOnsArray) {
      console.log('getAddOnPremium called:');
      let itemIndex = selectedAddOnsArray.findIndex(item => item.optionSelected == addOnID);
      if(itemIndex > -1) {
        return selectedAddOnsArray[itemIndex]['premiumDetails'][0]['totalPremiumValue'];
      } else {
        return false;
      }
    },
    checkRiderSelection(riderCode, selectedRidersArray) {
      let itemIndex = selectedRidersArray.findIndex(item => item.ridercode == riderCode);
      if(itemIndex > -1) {
        return true;
      } else {
        return false;
      }
    },
    getRiderSelectionSA(riderCode, selectedRidersArray) {
      let itemIndex = selectedRidersArray.findIndex(item => item.ridercode == riderCode);
      if(itemIndex > -1) {
        return selectedRidersArray[itemIndex].riderSA;
      }
    },
    getAddOnSelectionSA(addOnID, selectedAddOnsArray) {
      let itemIndex = selectedAddOnsArray.findIndex(item => item.optionSelected == addOnID);
      if(itemIndex > -1) {
        return selectedAddOnsArray[itemIndex].addOnSumAssured;
      }
    },
    getRiderSelectionPremium(riderCode, selectedRidersArray) {
      let itemIndex = selectedRidersArray.findIndex(item => item.ridercode == riderCode);
      if(itemIndex > -1) {
        return selectedRidersArray[itemIndex].premiumDetails[0].totalRiderPremiumValue;
      }
    },
    getRiderSelectionIndex(riderCode, selectedRidersArray) {
      let itemIndex = selectedRidersArray.findIndex(item => item.riderCode == riderCode);
      if(itemIndex > -1) {
        return itemIndex;
      }
    },
    getAddSelectionIndex(addOnID, selectedAddOnsArray) {
      let itemIndex = selectedAddOnsArray.findIndex(item => item.optionSelected == addOnID);
      if(itemIndex > -1) {
        return itemIndex;
      }
    },
    getRiderPriceByPPF(premiumDetails) {
      let itemIndex = premiumDetails.findIndex(item => item.mode == this.premiumPaymentFrequency);
      if(itemIndex > -1) {
        return premiumDetails[itemIndex].totalRiderPremiumValue;
      } else {
        return 0;
      }
    },
    addRider(riderCode,riderName) { 	
      this.riderCodeToAdd = riderCode;	
      this.riderUpdateTitle = riderName;	
      let RIDER_SUM_ASSURED = getRiderSumAssured()	
      this.minSumAssured = RIDER_SUM_ASSURED[this.selectedPlan.insuranceAndProducts.insuranceCompanyCode][riderCode]['min']	
      this.maxSumAssured = RIDER_SUM_ASSURED[this.selectedPlan.insuranceAndProducts.insuranceCompanyCode][riderCode]['max']	
      this.currentRidderCode = riderCode	
      $("#addridersumassured").modal("show");	
      	
    },
    getfieldvalidation(){
     this.error["sumassuredval"] = "";
     this.riderUpdateSumAssured = ""
    
    },
    addRiderSumAssured(){
    let RIDER_SUM_ASSURED = getRiderSumAssured()
    let minValue = RIDER_SUM_ASSURED[this.selectedPlan.insuranceAndProducts.insuranceCompanyCode][this.currentRidderCode]['min']
    let maxValue = RIDER_SUM_ASSURED[this.selectedPlan.insuranceAndProducts.insuranceCompanyCode][this.currentRidderCode]['max']
    let riderSumAssured = parseInt(this.riderUpdateSumAssured)
    if(this.riderUpdateSumAssured == ""){
     this.error["sumassuredval"] = "Enter your sum assured value"
     return true
     }
     else if(riderSumAssured<minValue || riderSumAssured>maxValue)
     {
      this.error["sumassuredval"] = "Please enter values within the specified limits"
     }
     else if(riderSumAssured>=minValue && riderSumAssured<=maxValue)
     {
        this.error["sumassuredval"] = ""
     $("#addridersumassured").modal("hide");
      console.log('add rider called: ', this.riderCodeToAdd);
      let itemIndex = this.riderList.findIndex(item => item.ridercode == this.riderCodeToAdd);
      this.error["sumassuredval"]=""
      if(itemIndex > -1) {
        let tmp1 = this.riderList[itemIndex];
        let tmp = {
          riderCode: tmp1['ridercode'],
          //riderPremiumPayingTerm: parseInt(tmp1['riderPremiumPayingTerm']),
          //riderSA: parseInt(tmp1['riderSA']),
          riderSA: parseInt(this.riderUpdateSumAssured),
          //riderTerm: parseInt(tmp1['riderTerm']),
        }
        // Reset riderUpdateSumAssured
        this.riderUpdateSumAssured = 0;
        this.apiReqData.personalInformation.individualDetails[0].riderDetails.push(tmp);
        this.getPolicy(this.apiReqData);
      } 
     }
    },
    removeRider(riderCode) {
      console.log('remove rider called: ',riderCode,  this.apiReqData);
      let itemIndex = this.apiReqData.personalInformation.individualDetails[0].riderDetails.findIndex(item => item.riderCode == riderCode);
      if(itemIndex > -1) {
        this.apiReqData.personalInformation.individualDetails[0].riderDetails.splice(itemIndex, 1);
        this.getPolicy(this.apiReqData);
      } 
    },
    getPayModeList(insurerCode){
      return getPayModeList()[insurerCode];
    },
    getMinOfTwo(val1, val2) {
      if(val1 > val2) {
        return val2;
      }
      return val1;
    },
    getPreviousRequest() {
      this.apiReqData = JSON.parse(sessionStorage.getItem("quote_request"));
    },
    getAddOnsList(pCode) {
      
      return getAddOnsList()[pCode];
    },
    countAddonsRiders(list1, list2) {
      console.log('list : ', list1 );
      console.log('list2 : ', list2 );
      let len1 = 0;
      let len2 = 0;
      if(list1) {
        len1 = list1.length;
      }
      if(list2) {
        len2 = list2.length;
      }
      return (len1 + len2);
    }
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/components/policy-details.scss";
</style>