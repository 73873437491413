<template>
  <div class="loading_block_outer" v-if="loading">
    <Loading3 msg='Fetching the Customer Details' />
  </div>
  <div class="inner-page-wrapper" v-else>
    <div class="welcome-page d-flex align-items-center">
      <div class="container">
        <div class="row">
          <div class="col-md-6 left-col"></div>
          <div class="col-md-6 right-col">
            <div class="right-col-wrapper">
              <div class="form-wrapper">
                <form>
                  <div class="form-group">
                    <label class="form-label">Customer Id</label>
                    <div class="input-wrapper">
                      <input type="text" class="form-control" v-model="customerId" placeholder="Enter your Customer Id"
                        required @input="handleNumber">
                    </div>
                    <p class="error_msg" v-if="error_msg">{{ error_msg }}</p>
                  </div>
                  <div class="form-btn">
                    <button type="button" class="btn btn-primary" @click="gotoDetail()" :disabled="!customerId">Fetch
                      Customer Detail</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import Loading3 from "@/components/loading3.vue";
export default {
  name: "welcome",
  components: {
    Loading3,
  },
  mixins: [],
  mounted() {

  },
  data() {
    return {
      customerId: "",
      loading: false,
      error_msg: "",
    }
  },
  methods: {
    async gotoDetail() {
      this.error_msg = "";
      if (!this.customerId) {
        return;
      }
      this.loading = true;
      let qData = {
        "custId": this.customerId
      }
      try {
        const response = await this.backendApi("updateInformation", qData);
        if (response == 'error') {
          this.loading = false;
          self.apiError = true;
        } else if (response && response.data) {
          console.log('response updateInformation: ', response.data)
          if (response.data.data.status == "success") {
            await this.getCustomerInfo();
          }
          if (response.data.errors.length > 0) {
            this.loading = false;
            this.error_msg = response.data.errors[0].errorDisplayMessage;
          }
        }
      } catch (error) {
        console.error('Error fetching customer information:', error);
      }
    },

    async getCustomerInfo() {
      try {
        let qData = [this.customerId];
        var headers = {};
        const response = await this.backendApi("customerInfo", qData, headers, "get");
        this.loading = false;
        console.log("resp in response", response);
        if (response.data.data) {
          sessionStorage.setItem("customerInfo", JSON.stringify(response.data.data));
          this.$router.push("/");
        }
        if (response.data.errors.length > 0) {
          this.loading = false;
          this.error_msg = response.data.errors[0].errorDisplayMessage;
        }
      } catch (error) {
        console.error('Error fetching customer information:', error);
      }
    },

    handleNumber() {
      this.customerId = this.customerId.replace(/[^0-9]/g, '');
    }
  }

};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/components/welcome.scss";

.loading_block_outer {
  .loading_block {
    margin: 0 auto 0 auto;
    max-width: 530px;
    width: 95%;
    height: 380px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    font-family: $font-stack;
    z-index: 1;
    padding: 0 20px;

    @media only screen and (max-width: $tablet1) {
      width: 95%;
    }

    .carousel-item {
      margin-bottom: 10px;

      p {
        font-size: 16px;
        color: #5c5c5c;
        font-family: $font-stack;
        font-weight: $font-weight-medium;
        margin-bottom: 0;

        @media only screen and (max-width: $tablet1) {
          font-size: 14px;
        }

        .red-text {
          color: #dc0505;
        }
      }

      b {
        font-weight: $font-weight-medium;
        text-transform: uppercase;
        color: $main-bg;
      }
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
  }
}

.error_msg {
  color: red !important;
}
</style>