<template>
  <div class="inner-header d-flex justify-content-between align-items-center">
    <div v-if="disabledLink()" @click="logout()" class="brand-logo">
      <a href=""><img></a>
    </div>
     <div v-if="!disabledLink()" class="brand-logo">
      <a href=""><img></a>
    </div>
    <div class="d-flex justify-content-between align-items-center">
      <button v-if="disabledLink()" @click="logout()" class="dashbrd icon-cta d-flex justify-content-center align-items-center">
        <img src="~@/assets/images/icons8-home-48.png" alt="" class="dashboard-img"/> 
        <span class="tooltip-label">Home</span>        
      </button>
      <router-link v-if="!disabledLink()" to="/" class="dashbrd icon-cta d-flex justify-content-center align-items-center">
        <img src="~@/assets/images/icons8-home-48.png" alt="" class="dashboard-img"/> 
        <span class="tooltip-label">Home</span>        
      </router-link>
      <!-- <button v-if="this.multiQuote && disabledLink()" @click="logout()"  class="dashbrd d-flex justify-content-center align-items-center">
        <img src="~@/assets/images/dashboard-icon.svg" alt="" class="dashboard-img d-md-none"/> 
        <span class="d-none d-md-block">Term Insurance</span>        
      </button> -->
      <!-- <router-link v-if="this.multiQuote && !disabledLink()" to="/policy-quote" class="dashbrd d-flex justify-content-center align-items-center">
        <img src="~@/assets/images/dashboard-icon.svg" alt="" class="dashboard-img d-md-none"/> 
        <span class="d-none d-md-block">Term Insurance</span>        
      </router-link> -->
      <div v-if="this.rmData['RoleId']=='1'">
        <button v-if="disabledLink()" @click="logout()" class="dashbrd d-flex justify-content-center align-items-center">
          <img src="~@/assets/images/dashboard-icon.svg" alt="" class="dashboard-img d-md-none"/> 
          <span class="d-none d-md-block">My Policies</span>        
        </button>
        <router-link v-if="!disabledLink()" to="/dashboard" class="dashbrd d-flex justify-content-center align-items-center">
          <img src="~@/assets/images/dashboard-icon.svg" alt="" class="dashboard-img d-md-none"/> 
          <span class="d-none d-md-block">My Policies</span>        
        </router-link>
      </div>
      <div v-else>
        <button v-if="disabledLink()" @click="logout()" class="dashbrd icon-cta d-flex justify-content-center align-items-center">
          <img src="~@/assets/images/icons8-dashboard-64.png" alt="" class="dashboard-img"/> 
          <span class="tooltip-label">Dashboard</span>        
        </button>
        <router-link v-if="!disabledLink()" to="/dashboard" class="dashbrd icon-cta d-flex justify-content-center align-items-center">
          <img src="~@/assets/images/icons8-dashboard-64.png" alt="" class="dashboard-img"/> 
          <span class="tooltip-label">Dashboard</span>        
        </router-link>
        </div>
      <div class="dropdown header-profile dropdown-menu-right">
        <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <img src="~@/assets/images/user-dropdown.png" alt="">
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <ul class="mb-0">
            <li class="align-items-center d-flex"> 
              <img src="~@/assets/images/icon1.png" alt=""/> {{ rmData.Name }}
            </li>
            <li class="align-items-center d-flex"> 
              <img src="~@/assets/images/icon2.png" alt=""/> {{ rmData.Mobile }}
            </li>
            <li class="align-items-center d-flex"> 
              <img src="~@/assets/images/icon3.png" alt=""/> {{ rmData.Email }}
            </li>
            <li class="align-items-center d-flex">
              <img src="~@/assets/images/left-arrow-grey.png" alt=""/> 
              <span class="logout cursor-pointer" @click="logout()">Logout</span> 
              <div class="loading_block_outer" v-if="loading">
                <Loading3 msg='Redirecting to login page'/>
              </div> 
            </li>
          </ul>
        </div>
      </div>
      <div class="modal" id="tokenWarningModal" tabindex="-1" role="dialog" aria-labelledby="tokenWarningModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Session Expiry Warning</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <a>
                    <span class="p-2" style="text-align: center; font-weight: bold; color: #0a3f88; float: right;"> Your session is about expire. Please click Ok to continue</span>
                </a>
            </div>
            <div class="modal-footer justify-content-center">
                <button type="button" class="next-btn mb-4" @click="tokenExpireWarningCancel = true" style="width:100px" >Cancel</button>
                <button type="button" class="next-btn mb-4" @click="tokenExpireWarning = true" style="width:100px" >Ok</button>
            </div>
            </div>
        </div>
        </div>
    </div>

    
  </div>
</template>

<script>
import $ from "jquery";
import {getLogoutURL,getDistributer} from '@/mixins/common';
import Loading3 from "@/components/loading3.vue";
import axios from "axios";

export default {
  name: "innerheader",
  emits: ["clicked-rm-details"],
  props: {
    msg: String,
  },
  components: {
    Loading3    
  },
  mixins:[getLogoutURL,getDistributer],

  
  mounted() {
    if (window.location.hostname === "insureuat.hdfcsec.com") {
      this.deleteAllCookies();
    }
    let rmId = this.$route.query.id;
    let productId = this.$route.query.productId;
    let quoteAmount = this.$route.query.quoteAmount;
    let manufacturerId=this.$route.query.manufacturerId;
    let ppf = this.$route.query.premiumPaymentFrequency;
    let pt = this.$route.query.policyTerm;
    let ppt=this.$route.query.premiumPaymentTerm;

    if(productId !== undefined){
      this.deepLinkParams.productId = productId;
    }
    if(quoteAmount != undefined){
      this.deepLinkParams.quoteAmount = quoteAmount;
    }
    if(manufacturerId !=undefined){
      this.deepLinkParams.manufacturerId = manufacturerId;
    }
    if(ppt !== undefined){
      this.deepLinkParams.premiumPaymentTerm = ppt;
    }
    if(pt != undefined){
      this.deepLinkParams.policyTerm = pt;
    }
    if(ppf !=undefined){
      this.deepLinkParams.premiumPaymentFrequency = ppf;
    }


    if(productId != undefined || quoteAmount != undefined || manufacturerId != undefined)
    {
      sessionStorage.setItem("deepLinkParams",JSON.stringify(this.deepLinkParams));
    }
    let diy = this.$route.query.diy ? this.$route.query.diy : false;
    if(sessionStorage.getItem("user_profile")==null)
     this.multiQuote=false
    else
     this.multiQuote=true;

    if(sessionStorage.getItem("diy") == null){
      sessionStorage.setItem("diy",diy)
    }
    if (this.$store.state.token && sessionStorage.getItem("rm_data")) {
      this.rmData = JSON.parse(sessionStorage.getItem("rm_data"));
      if(JSON.parse(sessionStorage.getItem("sessionExpiryTime")) == true){
        this.tokenWarning();
       }
       if(rmId && (rmId!=this.rmData["transaction_id"])){
        sessionStorage.clear();
        localStorage.clear();
        this.startSession(rmId)
      }
    } else if(!this.$store.state.token){
      if(rmId){
        this.startSession(rmId)
      }
    }
    this.distributorLogo = getDistributer()["distributorLogo"];

  },
  unmounted() {
    clearInterval(this.intervalTime); // - Clears Interval
  },
  data() {
    return {
      multiQuote:false,
      rmData: "",
      loading: false,
      tokenExpireWarning: false,
      tokenExpireWarningCancel: false,
      intervalTime: null,
      deepLinkParams:{
        productId: undefined,
        manufacturerId : undefined,
        quoteAmount: undefined,
        premiumPaymentTerm: undefined,
        premiumPaymentFrequency: undefined,
        policyTerm: undefined
      }
    };
  },
  methods: {

    getAllCookies() {
      var cookies = {};
      var cookieArray = document.cookie.split(';');
      for (var i = 0; i < cookieArray.length; i++) {
        var cookie = cookieArray[i].trim();
        var parts = cookie.split('=');
        var name = parts[0];
        var value = parts.slice(1).join('=');
        try {
          value = decodeURIComponent(value);
        } catch (error) {
          // If decoding fails, leave the value as is
        }
        cookies[name] = value;
      }

      return cookies;
    },
    deleteAllCookies() {
      var allCookies = this.getAllCookies();
      for (var cookieName in allCookies) {
        if (allCookies.hasOwnProperty(cookieName)) {
          document.cookie = `${cookieName}=; expires=` + new Date(Date.now() - 10 * 24 * 60 * 60 * 1000).toUTCString() + "; domain=.hdfcsec.com; path=/";
        }
      }
    },

    disabledLink()
    {
    if(sessionStorage.getItem('vuex')==null)
    return true;
    else
    return false;
    },
    getToken() {
      this.backendApi("getToken")
      .then((response) => {
        //console.log('save quote response: ', response);
          if(response == 'error') {
          self.apiError = true;
        } else if(response && response.data){
          //console.log('response token: ', response.data)

          let token = response.data.Data.Token;
          let USERID = response.data.Data.UserID;
          let key = this.rmData.UserId;

          let spdetail_flag= process.env.VUE_APP_SPDETAIL_ONESB;
          if(spdetail_flag == 0)
          {
            this.getSPDetails(token, USERID, key);
          }
        }

        
      })
      .catch((error) => {
        let msg=['went wrong, Please try again after some time ']
      this.$router.push({ path: '/networkalert', query: {backUrl:'dashboard', msg: msg}});
        console.log('error msg block get token', error);
        let tmpData = {
            "advisorCode": "149306",
            "channelType": "BR",
            "cusBankAccNo": "",
            "bankName": "BBBJ",
            "needRiskProfile": "",
            "csrLimCode": "99999 (Dummy code)",
            "cafosCode": "",
            "oppId": "",
            "fscCode": "01325137",
            "spCode": "109337",
            "bankBrnch": "SBIN",
            "source": "0BBJ",
            "lanNo": "",
            "selectedTab": "",
            "subChannel": ""
          };
          
          sessionStorage.setItem("sp_details_data", JSON.stringify(tmpData));
          localStorage.setItem("sp_details_data", JSON.stringify(tmpData));
      });
    },
    getSPDetails(token, USERID, key) {
      let qData = {
        "data":{
          "UserID": USERID, 
          "Token": token,  
          "Key": key 
        }
      }
      this.backendApi("getSPDetails", qData)
      .then((response) => {
          if(response == 'error') {
          self.apiError = true;
        } else if(response && response.data) {
          //console.log('response sp details: ', response.data)
          // sessionStorage.setItem("sp_details_data", JSON.stringify(response.data.Data));
          // localStorage.setItem("sp_details_data", JSON.stringify(response.data.Data));
          let tmpData = {
            "advisorCode": "00050007",
            "channelType": "BR",
            "cusBankAccNo": "",
            "bankName": "BBBJ",
            "needRiskProfile": "",
            "csrLimCode": "99999",
            "cafosCode": "",
            "oppId": "",
            "fscCode": "01370250",
            "spCode": "93432",
            "bankBrnch": "BJ00",
            "source": "0BBJ",
            "lanNo": "",
            "selectedTab": "",
            "subChannel": ""
          };
          
          sessionStorage.setItem("sp_details_data", JSON.stringify(tmpData));
          localStorage.setItem("sp_details_data", JSON.stringify(tmpData));
        }
      })
      .catch((error) => {
        console.log('error msg block sp', error);
      });
    },
    async logout() {
      const response = await this.backendApi('logout');
      if (response.data == null || response.data.data) {
        sessionStorage.clear();
        localStorage.clear();
        window.history.replaceState({}, document.title, '/#/');
        if (this.rmData && this.rmData.LogOutUrl && this.rmData.LogOutUrl !== null) {
          window.location.href = this.rmData.LogOutUrl;
        } else {
          if (this.rmData['RoleId'] == '1') {
            window.location.href = getDistributer()['loginUrlB2C'];
          }
          else {
            window.location.href = getDistributer()['loginUrlB2B'];
          }
        }
      }
    },
    // logout(){

    //   this.backendApi("logout", {}, {},"post")
    //   .then((resp) => {
    //     sessionStorage.clear();
    //     localStorage.clear();
    //     let location = getLogoutURL();
    //     this.loading = true;
    //     window.location.href(location);
    //     // window.history.replaceState("", "", location);
    //     // window.location.reload();
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     console.log("Failed to start session");
    //   })

    // },
    startSession(rmId){
      let data = {
        transactionId: rmId
      }
      this.backendApi("generateToken", data)
      .then((resp) => {
        console.log(resp.data)
        if(resp.data.errors.length>0){
          this.$router.push({ path: '/networkalert', query: {msg: resp.data.errors[0]['errorMessage'] }});
        }
        let token = resp.data.data.token.access_token;
        this.fetchRmDetails(token)
      })
      .catch((error) => {
        console.log("Failed to start session");
      })
    },
    fetchRmDetails(token){
      this.backendApi("getRmDetails", [], {"Authorization":`Bearer ${token}`}, "get")
        .then((response) => {
          //console.log('save quote response: ', response);
          if(response == 'error') {
            //self.apiError = true;
            // alert('RM data not fetched, please refresh the page');
          } else if(response && response.data) {
            this.rmData = response.data.data;
            console.log(response);
            console.log('rmData: 4: ', this.rmData);
            if(this.rmData != undefined)
            {
              sessionStorage.setItem("rm_data", JSON.stringify(this.rmData));
              localStorage.setItem("rm_data", JSON.stringify(this.rmData));
              sessionStorage.setItem("sessionExpiryTime",'true');
              if(this.rmData["RoleId"]=="1"){
                sessionStorage.setItem('diy','true');
              }
            }
          }
          if(!this.rmData){
            this.$store.commit('setToken',null)
          } else {
            this.$store.commit('setToken',token)
            this.$emit('clicked-rm-details', this.rmData);
            if (location.href.includes("dashboard")) {
              const newLocation = location.origin + "/#/dashboard";
              location.href = newLocation;
              window.location.reload();
            } else {  
                if(getDistributer()["distributerID"] == "KVB"){
                const newLocation = location.origin + "/#/welcome";
                location.href = newLocation;
                }
                else{
                  window.location.href = new URL(location).origin;
                }
            }
          }
        })
        .catch((error) => {
          console.log('error msg block dp', error);
        });
    },
    tokenWarning() {
    let self = this;
    this.intervalTime = setInterval( function(){
      let decodedToken = self.parseJwt(self.$store.state.token)
      // console.log("kjsdbnbv",((decodedToken['expires'] - Date.now() / 1000) / 60));
      let timer = ((decodedToken['expires'] - Date.now() / 1000) / 60);
      if (timer <= 1 && timer > 0) {
        // token getting expired (have to refresh)
        $("#tokenWarningModal").modal("show");
        if(self.tokenExpireWarningCancel == true){
          sessionStorage.setItem("sessionExpiryTime",'false');
          clearInterval(self.intervalTime);
          $("#tokenWarningModal").modal("hide");
        }
        if(self.tokenExpireWarning == true){
          $("#tokenWarningModal").modal("hide");
          let data = {
            currentToken: self.$store.state.token
          }
          self.backendApi("refreshToken", data)
          .then((resp) => {
            if(resp.data.errors.length>0){
              self.$router.push({ path: '/networkalert', query: {msg: resp.data.errors[0]['errorMessage'] }});
            }
            self.tokenExpireWarning = false;
            let token = resp.data.data.newToken.access_token;
            self.$store.commit('setToken',token);
            self.callTokenWarning();
          })
          .catch((error) => {
            console.log("Failed to start session");
          })
        }
      }
      else if(timer <= 0){
        sessionStorage.setItem("sessionExpiryTime",'false');
        clearInterval(self.intervalTime);
         $("#tokenWarningModal").modal("hide");
        sessionStorage.removeItem('vuex')
        window.location.href = `${window.location.origin}/#/networkalert?msg=Your session time is expired , kindly login again&backUrl=logout`
        // window.history.replaceState("", "", `${window.location.origin}/#/networkalert?msg=Your session time is expired , kindly login again&backUrl=logout`);
        // window.location.reload();
      }
    },1000);
  },
  callTokenWarning(){
    clearInterval(this.intervalTime);
    this.tokenWarning();
  },
  parseJwt(token) {
    return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
   }
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/components/inner-header.scss";
</style>